import React, { useState, MouseEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { paymentsClient } from "../../../../db/accessor";

const COLUMNS = [
  {
    Header: "Payment Number",
    accessor: "payment_number",
    width: "30%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Type",
    accessor: "type",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. Check, Cash, Credit",
    showSort: true,
    searchlightField: "PAYMENTTYPE",
    sortField: "PAYMENTTYPE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, false),
  },
  {
    Header: "Payment Date",
    accessor: "payment_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDATE",
    sortField: "PAYMENTDATE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false),
  },
  {
    Header: "Payment Amount",
    accessor: "payment_amount",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "PAYMENTAMOUNT",
    sortField: "PAYMENTAMOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Invoices",
    accessor: "invoices",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    // TODO: Change this to the appropriate field, Link up with API team
    searchlightField: "INVOICECOUNT",
    sortField: "INVOICECOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "left",
    Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
  },
];

/**
 * * Define the Payments Tab of the Customer Detail's page
 *   TODO: Add API call to (pending) new Payments view (From Shane)
 */
export default function Payments(): React.ReactElement {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchParser = (fetchResult: PaymentSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.paymentId,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_id: record.paymentId,
            payment_number: record.referenceCode,
            type: record.paymentType,
            payment_date: record.paymentDate,
            payment_amount: record.paymentAmount,
            invoices: record.invoiceList,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { payment_id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`/AR/payments/${row.original.payment_id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "Payments",
          displayName: "Payments",
          rowSelectToken: "paymentId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: paymentsClient.querySummaries,
            fetchParser: fetchParser,
          },
          columns: COLUMNS,
          handleRowClick: handleRowClick,
          export: {
            exportFileName: "Customer Details - Payments",
          },
          defaultSort: "PAYMENTDATE, REFERENCECODE",
          defaultSortToken: "DESC",
          predefinedFilters: [
            {
              route: "payments",
              searchlightFilter: `(CUSTOMERID eq '${customerId}')`,
            },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showSearchbar: false,
        showExportBtn: true,
        showCaption: true,
        showRowSelect: true,
        showNavigation: true,
      }}
    />
  );
}
