/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router";
import ClosedInvoices from "./ClosedInvoices/ClosedInvoices";
import Profile from "./Profile/Profile";
import Activities from "./Activities/Activities";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import Header from "./Header/Header";
import OpenInvoices from "./OpenInvoices/OpenInvoices";
import Payments from "./Payments/Payments";
import Contacts from "./Contacts/Contacts";
import Documents from "./Documents/Documents";
import ContactDetail from "./Contacts/ContactDetail/ContactDetail";
import { CustomerContext } from "../../../contexts/CustomerContext";
import Loading from "../../library/Loading/Loading";
import Error from "../../library/Error/Error";

export default function CustomerDetail(): React.ReactElement {
  const { path } = useRouteMatch();
  const { customerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { getCustomerDetails } = React.useContext(CustomerContext) as CustomerType;

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      await getCustomerDetails(customerId);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return errorMessage ? (
    <div className="customer-detail-wrapper">
      <Error isRelative>{errorMessage}</Error>
    </div>
  ) : isLoading ? (
    <div className="customer-detail-wrapper">
      <Loading isRelative />
    </div>
  ) : (
    <Switch>
      <Route
        exact
        path={`${path}/contactProfile/:contactId`}
        component={() => {
          return (
            <div>
              <ContactDetail />
            </div>
          );
        }}
      />
      <div className="customer-detail-wrapper">
        <div className="head">
          <Header />
        </div>
        <Route
          exact
          path={`${path}`}
          render={() => {
            return (
              <div className="body-table">
                <Activities />
              </div>
            );
          }}
        />

        <Route
          exact
          path={`${path}/profile`}
          component={() => {
            return (
              <div className="body">
                <Profile />
              </div>
            );
          }}
        />
        <Route
          exact
          path={`${path}/open_invoices`}
          component={() => {
            return (
              <div className="body-table">
                <OpenInvoices />
              </div>
            );
          }}
        />
        <Route
          exact
          path={`${path}/closed_invoices`}
          component={() => {
            return (
              <div className="body-table">
                <ClosedInvoices />
              </div>
            );
          }}
        />
        <Route
          exact
          path={`${path}/payments`}
          component={() => {
            return (
              <div className="body-table">
                <Payments />
              </div>
            );
          }}
        />
        <Route
          exact
          path={`${path}/contacts`}
          component={() => {
            return (
              <div className="body-table">
                <Contacts />
              </div>
            );
          }}
        />

        <Route
          exact
          path={`${path}/documents`}
          component={() => {
            return (
              <div className="body-table">
                <Documents />
              </div>
            );
          }}
        />
      </div>
    </Switch>
  );
}
