import React, { ReactElement } from "react";
import { UserPic } from "../../Icons/Icons";
import "./Userpic.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  id?: string;
}

/**
 * * Defines the User Picture Button
 */
export default function Userpic(props: Props): ReactElement {
  return (
    <button id={props.id} className={`userpic-btn ${props.onClick ? "userpic-btn-hoverable" : ""} ${props.className ?? ""}`} onClick={props.onClick} type="button">
      {props.icon ?? <UserPic />}
    </button>
  );
}
