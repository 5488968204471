import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { log } from "react-fullstory";
import { Input } from "../../library/Input/Input";
import Button from "../../library/Button/Button";
import Autocomplete from "../../library/Autocomplete/Autocomplete";
import { BackArrow, Search } from "../../library/Icons/Icons";
import { AppContext } from "../../../contexts/AppContext";
import { definitionsClient } from "../../../db/accessor";
import Utils from "../../../utils/utils";
import { OnboardStep } from "../../../types/enums";
import { OnboardContext } from "../../../contexts/OnboardContext";
import "./OtherERP.scss";

function OtherERP(): React.ReactElement {
  const { setOnboardStep } = React.useContext(OnboardContext) as OnboardType;
  const { setSelectedERP, setOnboardData, createLead } = React.useContext(AppContext) as AppType;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [otherERP, setOtherERP] = useState<string>("");
  const [waitList, setWaitlist] = useState(false);
  const [unsupportedERPs, setUnsupportedERPs] = useState<UnsupportedERPModel[]>([]);
  const [showBackButton, setShowBackButton] = useState(true);
  const emailRef = useRef<HTMLInputElement>();

  const handleCreateLead = async () => {
    if (!Utils.validEmail(email)) {
      setEmailError(true);
      emailRef?.current?.focus();
    } else {
      try {
        createLead([{ email, erpSystem: otherERP }]);
      } catch (error: any) {
        log("warn", error.toString());
      } finally {
        setWaitlist(true);
        setShowBackButton(false);
      }
    }
  };

  const handleGoBack = () => {
    setOnboardData((prev: OnboardDataModel) => {
      return { ...prev, erp: { name: "", appId: "", data: { authCode: "", realmId: "" } } };
    });
    setSelectedERP(null);
    setOnboardStep(OnboardStep.SelectERP);
  };

  // On mount, get list of Unsupported ERPs
  useEffect(() => {
    const getUnsupportedERPs = async () => {
      setUnsupportedERPs(
        (await definitionsClient.getUnsupportedERPs()).map((item: UnsupportedERPModel) => {
          return {
            erpSystemId: item.erpSystemId,
            name: item.name,
            isSupported: false,
          };
        })
      );
    };
    if (unsupportedERPs.length === 0) {
      getUnsupportedERPs();
    }
  }, []);

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  return (
    <div className="other-erp-wrapper">
      {showBackButton && (
        <Button
          className="back-arrow-button"
          variant="transparent"
          icon={<BackArrow />}
          alignIcon="left"
          onClick={() => handleGoBack()}
          data-testid="SelectERPGoBack"
        >
          Select Your Accounting System
        </Button>
      )}

      {!waitList ? (
        <>
          <h2>We're still working on supporting your accounting system.</h2>
          <p className="p-body">
            In the meantime, let us know which accounting system your team uses and we'll let you know as soon as it's available.
          </p>

          <form onSubmit={(e) => e.preventDefault()} id="WaitlistForm">
            <div className="form-row">
              <Input
                label="Email Address"
                placeholder="sboone@boone.com"
                defaultValue=""
                autocomplete="email"
                className="form-field"
                onChange={(value: string) => {
                  setEmail(value);
                  setEmailError(false);
                }}
                data-testid="SelectERPEmailAddress"
                inputRef={emailRef}
                errorMessage={emailError ? "Invalid email address" : undefined}
              />
            </div>

            <div className="form-row">
              <div>
                <label htmlFor="UnsupportedERPInput">Accounting System</label>
                <Autocomplete
                  id="UnsupportedERPInput"
                  options={unsupportedERPs.map((option) => option.name)}
                  freeSolo
                  autoSelect
                  staticIcon
                  popupIcon={<Search />}
                  className="form-field"
                  onInputChange={(event, value: string) => setOtherERP(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Sage 300"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  data-testid="UnsupportedERPInput"
                />
              </div>
            </div>

            <div className="button-row">
              <Button
                className=""
                variant="primary"
                size="lg"
                onClick={() => handleCreateLead()}
                disabled={!(email && otherERP)}
                data-testid="SelectERPCreateLeadButton"
              >
                Add Me To The Waitlist
              </Button>
              <Button className="ml-16" variant="secondary" size="lg" onClick={() => handleGoBack()} data-testid="SelectERPChangeERPButton">
                Change to Quickbooks Online or Xero
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <h1>Thank you for your interest!</h1>
          <p className="p-body">We've added you to our waitlist and will email you as soon as we support your accounting system.</p>
          <p className="p-body desc">
            While you wait, take a look around at some of our other product offerings, or get in touch with us about when we might have support for{" "}
            <strong>{otherERP}</strong>.
          </p>
          <div className="button-row">
            <Button
              variant="primary"
              size="lg"
              onClick={() => window.location.assign("https://lockstep.io/product")}
              data-testid="WaitlistOtherProductsButton"
            >
              Show Me Other Products
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default OtherERP;
