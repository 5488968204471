import React, { useRef, useState, useEffect } from "react";

import Chip from "../../components/library/Chip/Chip";
import { calcDaysBetween, formatDate, formatTime } from "../../db/utils/date";
import Utils from "../utils";
import { EmailAttachment, PrimaryContact, UserPic } from "../../components/library/Icons/Icons";
import "./TableUtils.scss";
import { Tooltip } from "@mui/material";

export default class TableUtils {
  public static withFormattedCellString(child: string, isLight?: boolean, style?: string): React.ReactElement {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [disableHover, setDisableHover] = useState(false);

    function handleResize() {
      if (wrapperRef) {
        if ((wrapperRef.current?.offsetWidth ?? 0) < (wrapperRef.current?.scrollWidth ?? 0)) {
          setDisableHover(false);
        } else {
          setDisableHover(true);
        }
      }
    }

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    return (
      <Tooltip title={child} followCursor disableHoverListener={disableHover}>
        <div ref={wrapperRef} className={`table-formatted-cell ${style} ${isLight ? "light" : ""}`}>
          {child}
        </div>
      </Tooltip>
    );
  }

  public static withFormattedCellElement(child: React.ReactElement, isLight?: boolean): React.ReactElement {
    return <div className={`table-formatted-cell ${isLight ? "light" : ""}`}>{child}</div>;
  }

  public static formatString(str: string | null, isLight?: boolean, style?: string): React.ReactElement {
    return this.withFormattedCellString(str || "N/A", isLight, style);
  }

  public static formatContactString(value: string | null) {
    return <div className={"table-formatted-cell contact-bold"}>{value}</div>;
  }

  public static formatNumber(num: number | null, isLight?: boolean): React.ReactElement {
    return this.withFormattedCellString(num !== null ? num.toString() : "N/A", isLight);
  }

  public static formatId(id: string | null, isLight?: boolean): React.ReactElement {
    return this.withFormattedCellString(id ? `#${id}` : "N/A", isLight);
  }

  public static formatPhoneNumber(value: string | null, style?: string): React.ReactElement {
    return <div className={`table-formatted-cell ${style}`}>{Utils.formatPhoneNumber(value ?? "N/A")}</div>;
  }

  public static formatStringWithIcon(value: string | null): React.ReactElement {
    return (
      <div className={"table-formatted-cell contact-bold icon-wrapper"}>
        <div className="profile-wrapper">
          <UserPic />
        </div>
        <div className="user-name">{value}</div>
      </div>
    );
  }

  public static formatIcon(value: string | null): React.ReactElement {
    let tooltipText;
    if (value === "primary") {
      tooltipText = "Primary";
    }
    return (
      <div className={`table-formatted-cell`}>
        <Tooltip title={tooltipText ?? ""} followCursor disableHoverListener={tooltipText ? false : true}>
          <div className={"icon-wrapper"}>{value === "primary" ? <PrimaryContact /> : ""}</div>
        </Tooltip>
      </div>
    );
  }

  public static formatActivity(value: string | { subject: string | null; body: string | null } | null, isUnread: boolean): React.ReactElement {
    if (value) {
      if (value instanceof Object) {
        return (
          <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
            {value.subject && value.body ? (
              <p className="subject">
                {value.subject}
                <span className="body"> - {value.body}</span>
              </p>
            ) : value.subject ? (
              <p className="subject">{value.subject}</p>
            ) : value.body ? (
              <p className="body">{value.body}</p>
            ) : (
              this.withFormattedCellString("N/A", false)
            )}
          </div>
        );
      } else {
        return (
          <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
            <p className="subject">{value}</p>
          </div>
        );
      }
    } else {
      return this.withFormattedCellString("N/A", false);
    }
  }

  public static formatCurrency(num: number | null, isLight?: boolean): React.ReactElement {
    const currency =
      num !== null
        ? new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(num)
        : "N/A";
    return this.withFormattedCellString(currency, isLight);
  }

  public static formatAttachmentIcon(hasAttachment?: boolean): React.ReactElement {
    if (!hasAttachment) return <></>;
    return this.withFormattedCellElement(<EmailAttachment />);
  }

  public static formatActivityBody(content: string[] | null, isUnread?: boolean): React.ReactElement {
    if (content) {
      if (isUnread) {
        return (
          <>
            <div className={`table-formatted-cell bold`}>
              <span>{content[0]}</span> <span className="email-content">- {content[1]}</span>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={`table-formatted-cell read-bold`}>
              <span>{content[0]}</span> <span className="email-content">- {content[1]}</span>
            </div>
          </>
        );
      }
    } else {
      return this.withFormattedCellString("N/A", false);
    }
  }

  public static formatActivitySimple(value: string | null, isUnread?: boolean): React.ReactElement {
    if (!value) return this.withFormattedCellString("N/A", false);
    return (
      <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
        <p className="subject">{value}</p>
      </div>
    );
  }

  public static formatActivitySentDate(date: string | null, isUnread?: boolean): React.ReactElement {
    if (date) {
      const diffDays = calcDaysBetween(date.split("T")[0]);
      if (diffDays == 0) {
        return this.formatActivitySimple(formatTime(date), isUnread);
      }
      if (diffDays == 1) {
        return this.formatActivitySimple("Yesterday", isUnread);
      }
      return this.formatActivitySimple(formatDate(date), isUnread);
    } else {
      return this.withFormattedCellString("N/A");
    }
  }

  public static formatDate(date: string | null, isLight?: boolean, style?: string): React.ReactElement {
    return this.withFormattedCellString(formatDate(date), isLight, style);
  }

  public static formatLastActivity(date: string | null, isLight?: boolean): React.ReactElement {
    const diffDays = calcDaysBetween(date);
    const lastActivity =
      diffDays === null
        ? "N/A"
        : diffDays === 0
        ? "Today"
        : diffDays === 1
        ? "Yesterday"
        : diffDays >= 2 && diffDays <= 7
        ? `${diffDays} days ago`
        : formatDate(date);
    return this.withFormattedCellString(lastActivity, isLight);
  }

  public static formatDaysLate(num: number | null, isLight?: boolean): React.ReactElement {
    const daysLate = num !== null ? (num <= 0 ? "N/A" : num === 1 ? "1 day" : `${num} days`) : "N/A";
    return this.withFormattedCellString(daysLate, isLight);
  }

  public static formatActivityStatus(status: string | null): React.ReactElement {
    if (status) {
      if (status === "active") {
        return this.withFormattedCellElement(<Chip variant="active" text="ACTIVE" size="lg" />);
      }
      if (status === "closed") {
        return this.withFormattedCellElement(<Chip variant="activity-closed" text="CLOSED" size="lg" />);
      }
    }
    return this.withFormattedCellElement(<Chip variant="not-started" text="NOT STARTED" size="lg" />);
  }

  public static formatDocumentStatus(status: string | null): React.ReactElement {
    if (status && status === "active") {
      return this.withFormattedCellElement(<Chip variant="active" text="ACTIVE" size="lg" />);
    }
    return this.withFormattedCellElement(<Chip variant="activity-closed" text="ARCHIVED" size="lg" />);
  }

  public static formatStatus(dueDate: string | null, status?: string | null, isLight?: boolean): React.ReactElement {
    // Check for an empty date
    if (status && status === "Closed") {
      return this.withFormattedCellElement(<Chip variant="closed" text="CLOSED" size="lg" />, isLight);
    }
    if (!dueDate || !dueDate.trim()) {
      return this.withFormattedCellElement(<Chip variant="open" text="OPEN" size="lg" />, isLight);
    }
    const diffDays = calcDaysBetween(dueDate);
    if (diffDays === null) {
      return this.withFormattedCellElement(<Chip variant="grey" text="N/A" size="lg" />, isLight);
    }
    if (diffDays <= 0) {
      return this.withFormattedCellElement(<Chip variant="open" text="OPEN" size="lg" />, isLight);
    } else if (diffDays <= 30) {
      return this.withFormattedCellElement(<Chip variant="late1" text="PAST DUE 1-30" size="lg" />, isLight);
    } else if (diffDays >= 31 && diffDays <= 60) {
      return this.withFormattedCellElement(<Chip variant="late31" text="PAST DUE 31-60" size="lg" />, isLight);
    } else if (diffDays >= 61 && diffDays <= 90) {
      return this.withFormattedCellElement(<Chip variant="late61" text="PAST DUE 61-90" size="lg" />, isLight);
    } else {
      return this.withFormattedCellElement(<Chip variant="late91" text="PAST DUE 91+" size="lg" />, isLight);
    }
  }

  public static formatPaymentType(type: string | null, isLight?: boolean): React.ReactElement {
    let paymentType = "N/A";
    if (type === "Check") {
      paymentType = "Check";
    } else if (type === "Wire") {
      paymentType = "Wire";
    } else if (type === "Cash") {
      paymentType = "Cash";
    } else if (type === "CreditCard") {
      paymentType = "Credit Card";
    } else if (type === "CR") {
      paymentType = "Cash Receipt";
    } else if (type === "RV") {
      paymentType = "Reversal";
    }
    return this.withFormattedCellString(paymentType, isLight);
  }

  public static formatList(list: string[] | null, isLight?: boolean): React.ReactElement {
    let invoiceText = "";
    let invoiceTooltip: string | React.ReactElement = "";

    if (Array.isArray(list) && list.length > 0) {
      if (list.length === 1) {
        invoiceText = invoiceTooltip = list[0];
      } else {
        invoiceText = `${list.length} Applications`;
        invoiceTooltip = (
          <div>
            {list.map((elem: string, index: number) => {
              return (
                <>
                  {elem}
                  {index !== list.length - 1 && <br />}
                </>
              );
            })}
          </div>
        );
      }
    } else {
      invoiceText = invoiceTooltip = "N/A";
    }

    const wrapperRef = useRef<HTMLDivElement>(null);
    const [disableHover, setDisableHover] = useState(false);

    function handleResize() {
      if (wrapperRef) {
        if ((wrapperRef.current?.offsetWidth ?? 0) < (wrapperRef.current?.scrollWidth ?? 0) || (list?.length ?? 0) > 1) {
          setDisableHover(false);
        } else {
          setDisableHover(true);
        }
      }
    }

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    return (
      <Tooltip title={invoiceTooltip} followCursor disableHoverListener={disableHover}>
        <div ref={wrapperRef} className={`table-formatted-cell ${isLight ? "light" : ""}`}>
          {invoiceText}
        </div>
      </Tooltip>
    );
  }
}
