import React from "react";
import { Dashboard, Invoices, Settings, Payments, AllCustomers, User, Unassigned, AllActivities } from "../../Icons/Icons";
import "./Activity.scss";

export type ActivityVariants = "dashboard" | "customers" | "invoices" | "settings" | "payments" | "mine" | "unassigned" | "all";

interface Props {
  className?: string;
  variant?: ActivityVariants;
  children?: React.ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  count?: number;
}

/**
 * * Defines the Activity Button
 */
export default function Activity(props: Props): React.ReactElement {
  return (
    <div className={`activity-wrapper ${props.className ?? ""}`}>
      <button className={`btn activity-btn ${props.isActive ? "activity-btn-active" : ""}`}>
        {props.variant === "dashboard" ? (
          <Dashboard />
        ) : props.variant === "invoices" ? (
          <Invoices />
        ) : props.variant === "settings" ? (
          <Settings />
        ) : props.variant === "payments" ? (
          <Payments />
        ) : props.variant === "mine" ? (
          <User />
        ) : props.variant === "unassigned" ? (
          <Unassigned />
        ) : props.variant === "all" ? (
          <AllActivities />
        ) : props.variant === "customers" ? (
          <AllCustomers className="all-customers" />
        ) : (
          <div className={"network-variant"}>{props.children?.toString().charAt(0).toUpperCase()}</div>
        )}
      </button>
      {props.children && (
        <p className={`activity-text ${props.isActive ? "activity-text-active" : ""}`}>
          {props.children === "Customers" ? "All Customer Connections" : props.children}
          {props.count && <span className="activity-count">{props.count}</span>}
        </p>
      )}
    </div>
  );
}
