import React from "react";

import errorImg from "../../assets/404.svg";
import "./FourOFourError.scss";

const FourOFourError: React.FC = () => {
  return (
    <div className={`FourOFour-bg`}>
      <div className={`FourOFour-card`}>
        <img src={errorImg} alt="404" />
        <p className={`FourOFour-card-header`}>Oops!</p>

        <p className={`FourOFour-card-text`}>
          The page you requested could not be found.
        </p>

        <span className={`FourOFour-card-span`}>
          Please{" "}
          <a className={`FourOFour-card-link`} href={window.location.protocol + "//" + window.location.host}>sign in</a>{" "}
          to access Lockstep Insights.
        </span>
      </div>
    </div>
  );
};

export default FourOFourError;
