import React, { useRef, Dispatch, SetStateAction, useState, useEffect } from "react";
import Table from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { Input } from "../../../library/Input/Input";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { TextField, Modal } from "@mui/material";
import Button from "../../../library/Button/Button";
import { Check } from "../../../library/Icons/Icons";
import "./Contacts.scss";
import { DataMode } from "../../../../types/enums";
import { contactsClient } from "../../../../db/accessor";
import { codeDefinitionsClient } from "../../../../db/accessor";
import { useParams } from "react-router-dom";

type ContactsProps = {
  setDataMode: Dispatch<SetStateAction<DataMode>>;
  tableData: TableData[];
  setTableData: Dispatch<SetStateAction<TableData[]>>;
  fetchParser: (response: FetchResult, variant?: FetchVariant) => Partial<TableData>[];
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageCount: number;
  setPageCount: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  setShowEditToast: Dispatch<SetStateAction<boolean>>;
  setEditError: Dispatch<SetStateAction<boolean>>;
};

/**
 * * Define the Contacts Tab of the Customer Detail's page
 */
export default function ContactsView(props: ContactsProps): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const [changedContacts, setChangedContacts] = useState<Record<string, Partial<ContactModel>>>({});
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [dropOptions, setDropOptions] = useState<{ label: string; id: string }[]>([]);
  const tableRef = useRef(null);

  async function fetchRoles() {
    await codeDefinitionsClient
      .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
      .then((data: CodeDefinitionModelFetchResult) => {
        setDropOptions(
          data.records?.map((role: CodeDefinitionModel) => {
            return {
              id: role.codeDefinitionId,
              label: role.codeDescription ?? "",
            };
          }) ?? []
        );
      });
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  const COLUMNS = [
    { Header: "", accessor: "space", width: "7%", alignment: "left" },
    {
      Header: "Name",
      accessor: "name",
      width: "22%",
      showFilter: true,
      showSort: true,
      sortField: "CONTACTNAME",
      searchlightField: "CONTACTNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string | null; row: { original: { id: string; disableCheckbox: boolean } } }) {
        if (!props.row.original.disableCheckbox) {
          return (
            <div className="table-formatted-cell">
              <Input
                defaultValue={props.value}
                onChange={(value) =>
                  setChangedContacts((prev: Record<string, Partial<ContactModel>>) => {
                    prev[props.row.original.id] = { ...prev[props.row.original.id], contactName: value };
                    return prev;
                  })
                }
                className={"table-input name"}
                placeholder=""
              />
            </div>
          );
        } else {
          return TableUtils.formatContactString(props.value);
        }
      },
    },
    {
      Header: "Role",
      accessor: "role",
      width: "22%",
      showFilter: true,
      showSort: true,
      searchlightField: "ROLECODE",
      sortField: "ROLECODE, CONTACTNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string | null; row: { original: { id: string; disableCheckbox: boolean } } }) {
        const [currentRole, setCurrentRole] = useState<string>(props.value ?? "");
        if (!props.row.original.disableCheckbox) {
          return (
            <div className="table-formatted-cell roles-dropdown">
              <Autocomplete
                options={dropOptions}
                onChange={(_event, value: { label: string }) =>
                  setChangedContacts((prev: Record<string, Partial<ContactModel>>) => {
                    setCurrentRole(value.label);
                    prev[props.row.original.id] = { ...prev[props.row.original.id], roleCode: value.label };
                    return prev;
                  })
                }
                value={currentRole}
                blurOnSelect
                disableClearable
                disablePortal
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value;
                }}
              />
            </div>
          );
        } else {
          return TableUtils.formatContactString(props.value);
        }
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "23%",
      showFilter: true,
      showSort: true,
      searchlightField: "EMAILADDRESS",
      sortField: "EMAILADDRESS, CONTACTNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string | null; row: { original: { id: string; disableCheckbox: boolean } } }) {
        if (!props.row.original.disableCheckbox) {
          return (
            <div className="table-formatted-cell">
              <Input
                defaultValue={props.value}
                onChange={(value) =>
                  setChangedContacts((prev: Record<string, Partial<ContactModel>>) => {
                    prev[props.row.original.id] = { ...prev[props.row.original.id], emailAddress: value };
                    return prev;
                  })
                }
                className={"table-input email"}
                placeholder=""
              />
            </div>
          );
        } else {
          return TableUtils.formatString(props.value, false, "contact-bold");
        }
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      width: "16.25%",
      showFilter: true,
      showSort: true,
      searchlightField: "PHONE",
      sortField: "PHONE, CONTACTNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string | null; row: { original: { id: string; disableCheckbox: boolean } } }) {
        if (!props.row.original.disableCheckbox) {
          return (
            <div className="table-formatted-cell">
              <Input
                defaultValue={props.value}
                onChange={(value) =>
                  setChangedContacts((prev: Record<string, Partial<ContactModel>>) => {
                    prev[props.row.original.id] = { ...prev[props.row.original.id], phone: value };
                    return prev;
                  })
                }
                className={"table-input phone"}
                placeholder=""
              />
            </div>
          );
        } else {
          return TableUtils.formatPhoneNumber(props.value, "contact-bold");
        }
      },
    },
    {
      Header: "Last Updated",
      accessor: "last_updated",
      width: "16.25%",
      showSort: true,
      showFilter: true,
      searchlightField: "MODIFIED",
      sortField: "MODIFIED",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
    },
  ];

  async function handlePatchContacts() {
    for (const [key, value] of Object.entries(changedContacts)) {
      try {
        await contactsClient.patchContact(key, value);
      } catch (error: any) {
        props.setEditError(true);
        console.log(error);
      } finally {
        props.setEditError(false);
      }
    }
  }

  async function saveContactEdits() {
    await handlePatchContacts();
    props.setShowEditToast(true);
    props.setDataMode(DataMode.View);
  }

  function cancelContactEdits() {
    setShowCancelModal(false);
    props.setDataMode(DataMode.View);
  }

  function handleRowClick() {
    console.log("click");
  }

  return (
    <>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Contacts",
            displayName: "Contacts",
            data: {
              tableData: props.tableData,
              setTableData: props.setTableData,
              fetchCall: contactsClient.queryContacts,
              fetchParser: props.fetchParser,
            },
            columns: COLUMNS,
            handleRowClick: handleRowClick,
            defaultSort: "CONTACTNAME",
            defaultSortToken: "",
            columnDeps: [dropOptions],
            predefinedFilters: [
              {
                route: "contacts",
                searchlightFilter: `(companyId eq '${customerId}') and (isActive eq true)`,
              },
            ],
          },
        ]}
        states={{
          isLoading: props.isLoading,
          setLoading: props.setLoading,
          isError: props.isError,
          setError: props.setError,
          errorMessage: props.errorMessage,
          setErrorMessage: props.setErrorMessage,
        }}
        pagination={{
          pageCount: props.pageCount,
          setPageCount: props.setPageCount,
          pageNumber: props.pageNumber,
          setPageNumber: props.setPageNumber,
          totalCount: props.totalCount,
          setTotalCount: props.setTotalCount,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: false,
          showNavigation: true,
        }}
        headerBtns={[
          {
            component: (
              <Button icon={<Check />} alignIcon="left" disabled={props.isLoading} onClick={() => saveContactEdits()}>
                Save
              </Button>
            ),
            hideOnRowSelect: false,
          },
          {
            component: (
              <Button variant="secondary" alignIcon="left" disabled={props.isLoading} onClick={() => setShowCancelModal(true)}>
                Cancel
              </Button>
            ),
            hideOnRowSelect: false,
          },
        ]}
      />
      {showCancelModal && (
        <Modal open={showCancelModal} onClose={() => setShowCancelModal(false)}>
          <div className="as-modal">
            <h3>Cancel Edit</h3>
            <p>Are you sure you want to cancel your changes?</p>
            <div className="btn-grp">
              <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                No
              </Button>
              <Button variant="error" onClick={() => cancelContactEdits()}>
                Yes, Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
