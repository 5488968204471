import React, { ReactElement, useContext, useRef, useState } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import EditAccountingSoftware from "./EditAccountingSoftware/EditAccountingSoftware";
import { AppContext } from "../../../contexts/AppContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { syncClient, enrollmentsClient } from "../../../db/accessor";
import { formatTimeElapsed } from "../../../db/utils/date";
import { UserActions } from "../../../types/enums";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import AccountingSoftwareModal from "./AccountingSoftwareModal/AccountingSoftwareModal";
import Table from "../../library/Table/Table";
import QuickbooksLogo from "../../../assets/qb-logo.svg";
import XeroLogo from "../../../assets/XeroLogo.svg";
import Chip from "../../library/Chip/Chip";
import Button from "../../library/Button/Button";
import "./AccountingSoftware.scss";

export default function AccountingSoftware(): React.ReactElement {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastSeverity, setToastSeverity] = useState<string>("success");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const tableRef = useRef(null);

  const { hasPermission, userAccountName } = useContext(AppContext) as AppType;
  const { setSyncHasUpdated, lastSync } = useContext(SettingsContext) as SettingsType;
  const [showModal, setShowModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [modalAppId, setModalAppId] = useState<string>("");
  const [modalIsActive, setModalIsActive] = useState<boolean>(false);
  const [modalErpName, setModalErpName] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleIsActive = (appEnrollmentId: string, isActive: boolean, erpName: string) => {
    setShowModal(true);
    setModalIsActive(isActive);
    setModalErpName(erpName);
    setModalAppId(appEnrollmentId);
  };

  const handleRunSync = (appEnrollmentId: string) => {
    try {
      syncClient.startSync({ appEnrollmentId: appEnrollmentId }).then(() => {
        setSyncHasUpdated((prev: number): number => {
          return prev + 1;
        });
        (tableRef?.current as any)?.softRefresh();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const COLUMNS = [
    {
      Header: "Company Name",
      accessor: "company_name",
      width: "15%",
      showSort: false,
      searchlightField: "NAME",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(<div className="black-text">{props.value}</div>);
      },
    },
    {
      Header: "Accounting Software",
      accessor: "accounting_software",
      width: "20%",
      showSort: false,
      searchlightField: "SOFTWARE",
      alignment: "center",
      Cell: function format(props: { value: string }) {
        let erpImage: ReactElement;

        switch (props.value) {
          case "Quickbooks Online":
            erpImage = <img src={QuickbooksLogo} style={{ width: "180px", height: "35px" }} className="erp-logo" alt={props.value} />;
            break;
          case "Xero":
            erpImage = <img src={XeroLogo} style={{ width: "35px", height: "35px" }} className="erp-logo" alt={props.value} />;
            break;
          default:
            erpImage = <></>;
        }

        return TableUtils.withFormattedCellElement(erpImage);
      },
    },
    {
      Header: "Status",
      accessor: "is_active",
      width: "20%",
      showSort: false,
      searchlightField: "ISACTIVE",
      alignment: "left",
      Cell: function format(props: { value: boolean }) {
        return TableUtils.withFormattedCellElement(
          props.value ? (
            <Chip text="CONNECTED" variant="open" className="connected-chip" />
          ) : (
            <Chip text="DISCONNECTED" variant="late61" className="disconnected-chip" />
          )
        );
      },
    },
    {
      Header: "Last Sync",
      accessor: "last_sync",
      width: "20%",
      showSort: false,
      searchlightField: "MODIFIED",
      alignment: "left",
      HeaderTooltip: "We will automatically run your sync for you nightly at 2am eastern standard time.",
      Cell: function format(props: { value: string | null }) {
        if (!props.value) {
          return TableUtils.formatString("N/A");
        }
        return TableUtils.formatString(formatTimeElapsed(props.value) + " ago");
      },
    },
    {
      Header: "",
      accessor: "action",
      width: "25%",
      showSort: false,
      alignment: "left",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: function format(props: { row: { original: AccountingGridData } }) {
        return (
          <div className="accounting-software-actions">
            {((props.row.original.is_active && hasPermission(UserActions.RunSync)) ||
              (!props.row.original.is_active && hasPermission(UserActions.EditAccountingSoftware))) && (
              <Button
                variant="secondary"
                size="sm"
                loading={lastSync.statusCode === "Connector" || lastSync.statusCode === "Ready" || lastSync.statusCode === "In Progress"}
                onClick={() => {
                  if (!props.row.original.is_active) {
                    toggleIsActive(props.row.original.app_Id, props.row.original.is_active, props.row.original.accounting_software);
                  } else {
                    handleRunSync(props.row.original.app_Id);
                  }
                }}
              >
                {props.row.original.is_active ? "Run Sync" : "Reconnect"}
              </Button>
            )}
            {hasPermission(UserActions.EditAccountingSoftware) && (
              <Button
                variant="secondary"
                size="sm"
                className="actionBtn-disable"
                // TODO: Handle disconnect logic
                onClick={() => console.info("Disconnect Logic TBD")}
              >
                Disconnect
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const fetchParser = (fetchResult: AppEnrollmentModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: AppEnrollmentModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.appId,
          }),
          ...((variant === "export" || variant === "all") && {
            company_name: userAccountName,
            nightly_sync: record?.syncScheduleIsActive,
            is_active: record?.isActive,
            last_sync: record?.lastSync?.modified ?? null,
            status: record?.lastSync?.statusCode ?? "N/A",
            result_message: record?.lastSync?.processResultMessage ?? "N/A",
            accounting_software: record?.app.name,
            app_Id: record?.appEnrollmentId,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  return (
    <div className="accounting-software-wrapper">
      <div className={`table-wrapper ${totalCount === 0 ? "d-none" : ""}`}>
        <h4>Connect your accounting software to get the most up-to-date insights on your data.</h4>
        <Table
          ref={tableRef}
          dataSets={[
            {
              id: "Accounting Software",
              displayName: "Accounting Software",
              rowSelectToken: "appId",
              data: {
                tableData: tableData,
                setTableData: setTableData,
                fetchCall: enrollmentsClient.getEnrollments,
                fetchParser: fetchParser,
                includeOption: "App, LastSync",
              },
              columns: COLUMNS,
              columnDeps: [lastSync.modified],
              hiddenColumns: hasPermission(UserActions.RunSync) ? undefined : ["action"],
              handleRowClick: () => {
                return null;
              },
              // TODO: Replace this with a filter using AppType! Pending API Work
              predefinedFilters: [
                {
                  route: "accountingSoftware",
                  searchlightFilter: "(APPID in ('00000000-0000-0000-0000-000000000001', '00000000-0000-0000-0000-000000000002'))",
                },
              ],
            },
          ]}
          states={{
            isLoading: isLoading,
            setLoading: setLoading,
            isError: isError,
            setError: setError,
            errorMessage: errorMessage,
            setErrorMessage: setErrorMessage,
          }}
          pagination={{
            pageCount: pageCount,
            setPageCount: setPageCount,
            pageNumber: pageNumber,
            setPageNumber: setPageNumber,
            totalCount: totalCount,
            setTotalCount: setTotalCount,
          }}
          toggles={{
            showCaption: false,
            showRowSelect: false,
            showNavigation: false,
            showSearchbar: false,
            showExportBtn: false,
          }}
        />
        <AccountingSoftwareModal
          show={showModal}
          setShow={setShowModal}
          isActive={modalIsActive}
          erpName={modalErpName}
          appId={modalAppId}
          refresh={() => (tableRef?.current as any)?.softRefresh()}
          setShowToast={setShowToast}
          setToastMessage={setToastMessage}
          setToastSeverity={setToastSeverity}
        />
      </div>
      {!isLoading && totalCount === 0 && (
        <EditAccountingSoftware
          setShowToast={setShowToast}
          setToastMessage={setToastMessage}
          refreshData={() => (tableRef?.current as any)?.softRefresh()}
          setToastSeverity={setToastSeverity}
        />
      )}
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
