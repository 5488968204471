/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { default as TopRisksWidget } from "./Widgets/TopCustomers/TopCustomers";
import { default as TopRisksDrilldown } from "./Drilldowns/TopCustomers/TopCustomers";
import { default as AgingWidget } from "./Widgets/Aging/Aging";
import { default as AgingDrilldown } from "./Drilldowns/Aging/Aging";
import { default as ReceivablesRiskWidget } from "./Widgets/ReceivablesRisk/ReceivablesRisk";
import { default as ReceivablesRiskDrilldown } from "./Drilldowns/ReceivablesRisk/ReceivablesRisk";
import { default as DaysSalesOutstandingWidget } from "./Widgets/DaysSalesOutstanding/DaysSalesOutstanding";
import { default as DaysSalesOutstandingDrilldown } from "./Drilldowns/DaysSalesOutstanding/DaysSalesOutstanding";
import { default as ReceivablesSummaryWidget } from "./Widgets/ReceivablesSummary/ReceivablesSummary";
import { default as ReceivablesSummaryDrilldown } from "./Drilldowns/ReceivablesSummary/ReceivablesSummary";
import CompanyProfile from "./Profiles/CompanyProfile/CompanyProfile";
import MyProfile from "./Profiles/MyProfile/MyProfile";
import "./Dashboard.scss";

const routes = [
  {
    displayName: "Dashboard",
    routeName: "",
  },
  {
    displayName: "Receivables Summary",
    routeName: "receivables_summary",
    component: <ReceivablesSummaryDrilldown />,
  },
  {
    displayName: "Receivables Risk",
    routeName: "receivables_risk",
    component: <ReceivablesRiskDrilldown />,
  },
  {
    displayName: "Days Sales Outstanding",
    routeName: "days_sales_outstanding",
    component: <DaysSalesOutstandingDrilldown />,
  },
  {
    displayName: "Current AR Aging",
    routeName: "aging",
    component: <AgingDrilldown />,
  },
  {
    displayName: "Top 5 Customers with Invoices Past Due",
    routeName: "top_customers",
    component: <TopRisksDrilldown />,
  },
  // TODO: Enable when the profile APIs are ready
  // {
  //   displayName: "My Profile",
  //   routeName: "my_profile",
  //   component: <MyProfile />,
  // },
  // {
  //   displayName: "Company Profile",
  //   routeName: "company_profile",
  //   component: <CompanyProfile />,
  // },
];

/**
 * * Defines the Dashboard Page
 */
export default function Dashboard(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => {
          return (
            <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
              <div className="db-wrapper">
                {/* Header of Dashboard (Welcome) */}
                <div className="head">
                  <p className="welcome-main">Welcome to Lockstep Insights</p>
                  <p className="welcome-sub">See the health of your accounts receivable instantly.</p>
                </div>
                {/* Body of Dashboard (Widget Wrapper) */}
                <div className="content">
                  <div className="grid">
                    <div className="rowA">
                      <AgingWidget />
                      <TopRisksWidget />
                    </div>
                    <div className="rowB">
                      <ReceivablesSummaryWidget />
                      <DaysSalesOutstandingWidget />
                      <ReceivablesRiskWidget />
                    </div>
                  </div>
                </div>
              </div>
            </OverlayScrollbarsComponent>
          );
        }}
      />

      {
        routes && routes.slice(1).map((val) => {
          return (
            <Route
              path={`${path}/${val.routeName}`}
              key={`${val.routeName}`}
              render={() => {
                if (val.component) {
                  return val.component;
                } else {
                  return <></>;
                }
              }}
            />
          );
        })
      }
    </Switch>
  );
}
