// Imports from react
import React, { useState, useRef, useEffect, KeyboardEvent } from "react";
// Import assets
import CloseImg from "../../../../assets/Close.svg";
// Import reusable styled-components
import Button, { ButtonProps } from "../../Button/Button";
import { Input } from "../../Input/Input";
// Imports from react-router-dom
import { useHistory } from "react-router-dom";
import { DropdownArrow, Filter } from "../../Icons/Icons";
import "./FilterButton.scss";
import Dropdown from "../../Dropdown/Dropdown";
import { InvoiceStatusType } from "../../../../types/enums";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";

// Defines Filter Button Props
interface FilterButtonProps extends ButtonProps {
  // TODO: Find type for headerProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerProps: any;
  anchor: "left" | "right" | "center";
}

/**
 * * Defines the Filter Button on a Table
 */
export function FilterButton(props: FilterButtonProps): React.ReactElement {
  // References the Filter Button's Popup
  const popupRef = useRef<HTMLDivElement>(null);
  // Indicates hover state
  const [isHover, setIsHover] = useState<boolean>(false);
  // Indicates whether popup is shown
  const [isPopup, setIsPopup] = useState<boolean>(false);
  // Indicates whether a filter has been set
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [altDisplayText, setAltDisplayText] = useState<string>("Select");
  // Holds the input value from the input bar, defaults to query string
  const [input, setInput] = useState<string | null>(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(props.headerProps.id);
    if (value) setIsFilter(true);
    return value;
  });
  const { setLastFilterItem } = React.useContext(InvoiceContext) as InvoiceType;

  // Get History handler from react-router-dom
  const history = useHistory();

  // Trigger reload data with new filter (Add current filter)
  function handleSetFilter(value: string | null) {
    // Set filter on and close popup
    setIsFilter(true);
    setIsPopup(false);
    setIsHover(false);
    setLastFilterItem(props.headerProps.id);

    // Add filter to query string
    const params = new URLSearchParams(window.location.search);
    params.delete(props.headerProps.id);
    if (value) params.append(props.headerProps.id, value);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  const setDropStatusText = (value: string | null) => {
    if (!value) {
      setInput(null);
      setAltDisplayText("Select");
      return;
    }
    if (value) {
      setAltDisplayText(InvoiceStatusType[parseInt(value)]);
      setInput(value);
      return;
    }
  };

  // Trigger reload data with new filter (Delete current filter)
  function handleClearFilter() {
    // Set filter off and close popup
    setIsFilter(false);
    setIsPopup(false);
    setIsHover(false);

    // Delete filter from query string
    const params = new URLSearchParams(window.location.search);
    params.delete(props.headerProps.id);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && input) handleSetFilter(input);
    else if (e.key === "Escape") {
      setIsPopup(false);
      setIsHover(false);
    }
  }

  // Handles closing popup when clicking outside
  useEffect(() => {
    // Toggle off popup if clicking outside of the wrapper (TODO: remove any)
    function handleClickOutside(e: Event) {
      if (popupRef.current && !popupRef.current.contains(e.target as unknown as Node)) {
        setIsPopup(false);
      }
    }
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return props.headerProps.showFilter ? (
    <div className={`filter-wrapper`}>
      <div
        className={`filter-btn-wrapper`}
        onClick={() => setIsPopup((prev) => !prev)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Filter
          className={`filter-icon ${isPopup || isHover ? "filter-icon-hover" : ""} ${isFilter ? "filter-icon-active" : ""} `}
          isFilter={isFilter}
        />
      </div>
      {isPopup && (
        <div className={`filter-btn-popup ${props.anchor ? `filter-btn-popup-${props.anchor}` : ""}`} ref={popupRef}>
          <button
            className={`filter-btn-popup-close`}
            onClick={() => {
              setIsPopup(false);
              setIsHover(false);
            }}
          >
            <img src={CloseImg} alt="close popup" />
          </button>
          <p className={`filter-btn-popup-title`}>Filter By {props.headerProps.Header}</p>
          {!props.headerProps.dropdownOptions ? (
            <Input
              placeholder={props.headerProps.popupPlaceholder}
              defaultValue={input}
              onChange={setInput}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
            />
          ) : (
            <Dropdown
              options={props.headerProps.dropdownOptions}
              className={`sort-dropdown filter-dropdown`}
              onClick={setInput}
              onSelect={(onSelectValue: string | null) => setDropStatusText(onSelectValue)}
              defaultValue={input}
              displayValue={altDisplayText}
              iconAlign="right"
              icon={<DropdownArrow />}
            />
          )}
          <div className={`filter-btn-popup-grp`}>
            <Button
              className={`filter-btn-popup-btns`}
              size="sm"
              variant="grey"
              disabled={!isFilter || props.disabled}
              onClick={() => handleClearFilter()}
            >
              Clear Filters
            </Button>
            <Button size="sm" className={`filter-btn-popup-btns`} disabled={!input || props.disabled} onClick={() => handleSetFilter(input)}>
              Filter
            </Button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
