/* eslint-disable react/prop-types */
import React, { useState, Dispatch, useRef, SetStateAction, useEffect } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { UserActions } from "../../../types/enums";
import { enrollmentsClient } from "../../../db/accessor";
import { getIconURL } from "../../../utils/IconUtils/IconUtils";
import Gmail from "../../../assets/GmailLogo.svg";
import CenterPopup from "../../library/CenterPopup/CenterPopup";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { MicrosoftLogin } from "react-microsoft-login";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import Chip from "../../library/Chip/Chip";
import Button from "../../library/Button/Button";
import { formatTimeElapsed } from "../../../db/utils/date";
import Table from "../../library/Table/Table";
import "./EmailSettings.scss";

type ViewEmailSettingsProps = {
  setToast: Dispatch<SetStateAction<ToastData>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onResponseSuccessCallback: number;
};
export default function ViewEmailSettings(props: ViewEmailSettingsProps): React.ReactElement {
  const { hasPermission } = React.useContext(AppContext) as AppType;

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showRemoveDataButton, setShowRemoveDataButton] = useState<boolean>(false);

  const [removeAuthCallback, setRemoveAuthCallback] = useState<() => void>(() => void 0);
  const [connectionToRemove, setConnectionToRemove] = useState<string>("");

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const tableRef = useRef(null);

  // Hack to call connectEmail from parent
  useEffect(() => {
    const connectEmail = () => {
      (tableRef?.current as any)?.softRefresh();
      props.setToast({
        show: true,
        severity: "success",
        text: "Email Connected",
      });
    };
    if (props.onResponseSuccessCallback) {
      connectEmail();
    }
  }, [props.onResponseSuccessCallback]);

  const handleRemoveClick = (e: React.MouseEvent, rowData: EmailSettingGridData, rowOnClick: () => void) => {
    e.stopPropagation();
    setConnectionToRemove(rowData.id);
    setShowRemoveModal(true);
    setShowRemoveDataButton(rowData.status === "CONNECTED");
    setRemoveAuthCallback(() => rowOnClick);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReconnectClick = (e: React.MouseEvent, rowData: EmailSettingGridData, onClick: () => void) => {
    // TODO: To be implmented in another story
    e.stopPropagation();
    onClick();
  };

  /**
   * * Helper method to set App Enrollment to Active
   * @param id - Database ID of App Enrollment
   */
  const setActiveEnrollment = async (id: string) => {
    await enrollmentsClient.changeEnrollment(id, { isActive: true }).then(() => {
      // TODO: Add toast
      (tableRef?.current as any)?.softRefresh();
    });
  };

  /**
   * * Outlook Auth handler function
   * @param error -  Error state of Outlook auth
   * @param data - Data state of Outlook auth
   * @param row - Current row data for App Enrollment in Table
   */
  const outlookAuthHandler = (error: any, data: any, row: EmailSettingGridData) => {
    if (!error && data) {
      sessionStorage.removeItem("msal.idtoken");
      setActiveEnrollment(row.id);
      props.setToast({ show: true, severity: "success", text: "Email Connected" });
    } else {
      props.setToast({ show: true, severity: "error", text: "Email Connection Unsuccessful" });
    }
  };

  const COLUMNS = [
    {
      Header: "Email Address",
      accessor: "emailAddress",
      width: "20%",
      showSort: true,
      searchlightField: "EMAIL",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(<div className="black-text">{props.value}</div>);
      },
    },
    {
      Header: "Email Connection",
      accessor: "emailConnection",
      width: "20%",
      showSort: true,
      searchlightField: "EMAILCONNECTION",
      alignment: "left",
      Cell: function format(props: {
        value: boolean;
        row: {
          index: number;
          original: EmailSettingGridData;
        };
      }) {
        return (
          <div className="emailSettings-formatted-cell">
            <img src={props.row.original.emailConnection} className="emailSettings-table-connector" alt={"Email Logo"} />
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "20%",
      showSort: true,
      searchlightField: "STATUS",
      alignment: "left",
      Cell: function format(props: {
        row: {
          original: EmailSettingGridData;
        };
      }) {
        return TableUtils.withFormattedCellElement(
          props.row.original.status === "CONNECTED" ? (
            <Chip text="CONNECTED" variant="open" className="connected-chip" />
          ) : (
            <Chip text="DISCONNECTED" variant="late61" className="disconnected-chip" />
          )
        );
      },
    },
    {
      Header: "Last Sync",
      accessor: "lastSync",
      width: "20%",
      showSort: true,
      searchlightField: "MODIFIED",
      alignment: "left",
      Cell: function format(props: { value: string | null }) {
        if (!props.value) {
          return TableUtils.formatString("N/A");
        }
        return TableUtils.formatString(formatTimeElapsed(props.value) + " ago");
      },
    },
    {
      Header: "",
      accessor: "removeButton",
      width: "20%",
      alignment: "right",
      Cell: function format(cellProps: {
        value: boolean;
        row: {
          index: number;
          original: EmailSettingGridData;
        };
      }) {
        return (
          <div className={`emailSettings-formatted-cell`}>
            <div className="emailSettings-button-cell">
              <div className="emailSettings-button-grp">
                {cellProps.row.original.status === "DISCONNECTED" &&
                  (cellProps.row.original.emailName === "Gmail" ? (
                    <GoogleLogin
                      key={cellProps.row.original.emailName}
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
                      render={(renderProps) => (
                        <Button
                          className="reconnect-btn"
                          variant="secondary"
                          onClick={(e: React.MouseEvent) => handleReconnectClick(e, cellProps.row.original, renderProps.onClick)}
                        >
                          Reconnect
                        </Button>
                      )}
                      onSuccess={() => setActiveEnrollment(cellProps.row.original.id)}
                      onFailure={() => props.setToast({ show: true, severity: "error", text: "Email Connection Unsuccessful" })}
                    />
                  ) : cellProps.row.original.emailName === "Microsoft Outlook" ? (
                    <div className={"outlook-custom-button"}>
                      <MicrosoftLogin
                        clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? ""}
                        authCallback={(error: any, data: any) => outlookAuthHandler(error, data, cellProps.row.original)}
                      >
                        <Button className="reconnect-btn" size="sm" variant="secondary">
                          Reconnect
                        </Button>
                      </MicrosoftLogin>
                    </div>
                  ) : (
                    <></>
                  ))}
                {cellProps.row.original.emailConnection === Gmail ? (
                  <GoogleLogout
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
                    render={(renderProps) => (
                      <Button
                        className="remove-btn"
                        variant="secondary"
                        onClick={(e: React.MouseEvent) => handleRemoveClick(e, cellProps.row.original, renderProps.onClick)}
                      >
                        Remove
                      </Button>
                    )}
                    onLogoutSuccess={() => void 0}
                  />
                ) : (
                  <Button
                    className="remove-btn"
                    variant="secondary"
                    size="sm"
                    onClick={(e: React.MouseEvent) => handleRemoveClick(e, cellProps.row.original, () => void 0)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  // Sets isActive on AppEnrollment to false
  const handleRemoveKeepDataClick = async () => {
    await enrollmentsClient.changeEnrollment(connectionToRemove, { isActive: false }).then(() => {
      removeAuthCallback();
      setRemoveAuthCallback(() => void 0);
      setShowRemoveModal(false);
      setConnectionToRemove("");
      props.setToast({ show: true, severity: "success", text: "Connection Removed" });
      (tableRef?.current as any)?.softRefresh();
    });
  };

  // Deletes AppEnrollment
  const handleRemoveDataClick = async () => {
    await enrollmentsClient.deleteEnrollment(connectionToRemove).then(() => {
      removeAuthCallback();
      setRemoveAuthCallback(() => void 0);
      setShowRemoveModal(false);
      setConnectionToRemove("");
      props.setToast({ show: true, severity: "success", text: "Connection Removed" });
      (tableRef?.current as any)?.softRefresh();
    });
  };

  const parseEmailFromRecord = (record: AppEnrollmentModel) => {
    const emailDef = record.customFieldDefinitions.find((e) => e.customFieldLabel === "Email");
    const emailVal = record.customFieldValues.find((e) => e.customFieldDefinitionId === emailDef?.customFieldDefinitionId);
    return emailVal?.stringValue ?? "N/A";
  };

  const fetchParser = (fetchResult: AppEnrollmentModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: AppEnrollmentModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.appEnrollmentId,
          }),
          ...((variant === "export" || variant === "all") && {
            emailAddress: parseEmailFromRecord(record),
            emailConnection: getIconURL(record?.app.iconUrl),
            status: record?.isActive ? "CONNECTED" : "DISCONNECTED",
            lastSync: record?.lastSync?.modified ?? "N/A",
            removeButton: hasPermission(UserActions.AddEmailClient),
            emailName: record?.app.name,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  return (
    <>
      <div className="emailSettings-header">
        <p className="emailSettings-header-text">Connect your team's shared email client to manage your workflow with Lockstep Activities.</p>
      </div>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Email",
            displayName: "Email",
            rowSelectToken: "",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: enrollmentsClient.getEnrollments,
              fetchParser: fetchParser,
              includeOption: "App, LastSync, CustomFields",
            },
            columns: COLUMNS,
            hiddenColumns: hasPermission(UserActions.AddEmailClient) ? undefined : ["removeButton"],
            handleRowClick: () => {
              return null;
            },
            // TODO: Replace this with a filter using AppType! Pending API Work
            predefinedFilters: [
              {
                route: "emailSettings",
                searchlightFilter: "(APPID in ('00000000-0000-0000-0001-000000000003'))",
              },
            ],
          },
        ]}
        states={{
          isLoading: props.isLoading,
          setLoading: props.setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: props.totalCount,
          setTotalCount: props.setTotalCount,
        }}
        toggles={{
          showCaption: false,
          showRowSelect: false,
          showNavigation: false,
          showExportBtn: false,
          showSearchbar: false,
        }}
      />
      {showRemoveModal && (
        <CenterPopup
          headerText="Remove Email Connection?"
          onClickCancel={() => setShowRemoveModal(false)}
          rightBtnText="Remove and Delete Data"
          onClickRightBtn={handleRemoveDataClick}
          middleRightBtnText={showRemoveDataButton ? "Remove" : undefined}
          onClickMiddleRightBtn={showRemoveDataButton ? handleRemoveKeepDataClick : undefined}
          mainDisplay={{
            Cell: function format() {
              return (
                <>
                  Removing your email impact your team's Lockstep experience. You can simply remove the connection, or remove the connection and
                  delete the existing data. Are you sure you want to remove your email connection?
                </>
              );
            },
          }}
        />
      )}
    </>
  );
}
