/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import Dashboard from "../Dashboard/Dashboard";
import Activities from "../Activities/Activities";
import Customers from "../Customers/Customers";
import Invoices from "../Invoices/Invoices";
import Settings from "../Settings/Settings";
import PrivateRoute from "../../auth/PrivateRoute";
import InvoiceProvider from "../../contexts/InvoiceContext";
import CustomerProvider from "../../contexts/CustomerContext";
import DashboardProvider from "../../contexts/DashboardContext";
import PaymentProvider from "../../contexts/PaymentContext";
import { AppContext } from "../../contexts/AppContext";
import Payments from "../Payments/Payments";
import UserContentHead from "./UserContentHead/UserContentHead";
import UserContentSidebar from "./UserContentSidebar/UserContentSidebar";
import MyProfile from "../Dashboard/Profiles/MyProfile/MyProfile";
import CompanyProfile from "../Dashboard/Profiles/CompanyProfile/CompanyProfile";
import "./UserContent.scss";
import Profiles from "../Dashboard/Profiles/Profiles";
import SettingsProvider from "../../contexts/SettingsContext";
/**
 * * Defines the Main Content being displayed to the user for a given Route
 */
const UserContent = (): React.ReactElement => {
  const { syncError } = React.useContext(AppContext) as AppType;
  const { path } = useRouteMatch();
  const { show } = useIntercom();

  return (
    <SettingsProvider>
      <div className="uc-wrapper">
        <div className="head">
          <UserContentHead />
        </div>
        <div className="body">
          <div className="left">
            <UserContentSidebar />
          </div>
          <div className="right">
            <div className="content">
              <DashboardProvider>
                <CustomerProvider>
                  <InvoiceProvider>
                    <PaymentProvider>
                      <Switch>
                        <Redirect push exact from={path} to={`${path}/dashboard`} />

                        {!syncError ? (
                          <>
                            <PrivateRoute
                              path={`${path}/dashboard`}
                              render={() => {
                                return <Dashboard />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/customers`}
                              render={() => {
                                return <Customers />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/activities`}
                              render={() => {
                                return <Activities />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/invoices`}
                              component={() => {
                                return <Invoices />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/settings`}
                              component={() => {
                                return <Settings />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/payments`}
                              component={() => {
                                return <Payments />;
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/profiles`}
                              component={() => {
                                return <Profiles />;
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <PrivateRoute
                              exact
                              path={`${path}/dashboard`}
                              render={() => {
                                return (
                                  <div className="sync-error-wrapper">
                                    <div>
                                      <p>
                                        We encountered a problem retrieving data from your accounting system. This issue has been recorded and our
                                        engineering team is on it.
                                      </p>

                                      <p>
                                        In the meantime, you can chat with our support team by clicking&nbsp;
                                        <a
                                          data-testid="SyncFailedChatWithSupportLink"
                                          href="#"
                                          rel="noreferrer"
                                          onClick={(event) => {
                                            event.preventDefault();
                                            show();
                                          }}
                                        >
                                          here
                                        </a>
                                        .
                                      </p>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <PrivateRoute
                              path={`${path}/settings`}
                              component={() => {
                                return <Settings />;
                              }}
                            />
                          </>
                        )}

                        <Redirect push from={path} to={`${path}/dashboard`} />
                      </Switch>
                    </PaymentProvider>
                  </InvoiceProvider>
                </CustomerProvider>
              </DashboardProvider>
            </div>
          </div>
        </div>
      </div>
    </SettingsProvider>
  );
};

export default UserContent;
