import React from "react";
import { useIntercom } from "react-use-intercom";

import Button from "../../library/Button/Button";
import "./OnboardError.scss";

interface OnboardErrorProps {
  onTryAgain: () => void;
}

export default function OnboardError(props: OnboardErrorProps): React.ReactElement {
  const { show } = useIntercom();

  return (
    <div className="onboard-error-wrapper">
      <h1>Something Went Wrong.</h1>
      <p className="p-body error-text">
        {"We had a problem finalizing your account. This issue has been recorded and our engineering team is on it."}
      </p>

      <p className="body1 second-row">
        {"In the meantime, you can chat with our support team, or you can "}
        <a
          data-testid="OnboardErrorTryAgainLink"
          className="try-again-link"
          href="#"
          rel="noreferrer"
          onClick={(event) => {
            event.preventDefault();
            props.onTryAgain();
          }}
        >
          try again
        </a>
        .
      </p>

      <div className="button-container">
        <Button variant="primary" size="lg" data-testid="ChatWithSupportButton" onClick={() => show()}>
          Chat with Support
        </Button>
        <Button
          className="ml-16"
          variant="secondary"
          size="lg"
          data-testid="OnboardErrorTryAgainButton"
          onClick={() => {
            props.onTryAgain();
          }}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
}
