import APIClient from "../APIClient";

export class LeadsClient {
  /**
   * * POST on /api/v1/Leads
   * @param body - LeadsModel that contains the email and ERP system which the user has requested.
   * @returns Promise of type LeadsModel
   */
  createLead(body: LeadsModel[]): Promise<LeadsModel[]> {
    const url = "api/v1/Leads";

    return APIClient.post(url, body, undefined, true).then(response => {
      return response.data;
    });
  }
}
