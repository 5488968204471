import APIClient from "../APIClient";

export class PaymentsClient {
  /**
   * * GET on /api/v1/Payments/views/detail
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @returns Promise of type PaymentDetailModel or Exception
   */
  getDetailView(filter?: string): Promise<PaymentDetailModel> {
    let url = "api/v1/Payments/views/detail";

    if (filter !== undefined) {
      url += "?filter=" + encodeURIComponent("" + filter) + "&";
    }

    url = url.replace(/[&]$/, "");

    return APIClient.get(url).then(response => {
      return response.data.records[0];
    });
  }

  /**
   * * GET on /api/v1/Payments/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type PaymentSummaryModelFetchResult or Exception
   */
  querySummaries(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<PaymentSummaryModelFetchResult> {
    const url = "api/v1/Payments/views/summary";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then(response => {
      return response.data;
    });
  }
}
