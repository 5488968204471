import React, { useContext, useEffect, useState } from "react";
import { definitionsClient, enrollmentsClient } from "../../../../db/accessor";
import QuickbooksLogo from "../../../../assets/qb-logo.svg";
// import XeroLogo from "../../../../assets/XeroLogo.svg";
import { buildAuthRedirectURL } from "../../../../auth/authConfig";
import Utils from "../../../../utils/utils";
import { AppContext } from "../../../../contexts/AppContext";
import { UserActions } from "../../../../types/enums";
import NoConnectionSVG from "../../../../assets/NoConnection.svg";
import Loading from "../../../library/Loading/Loading";
import "./EditAccountingSoftware.scss";
import { Modal, TextField } from "@mui/material";
import Button from "../../../library/Button/Button";
import { Search } from "../../../library/Icons/Icons";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { useMsal } from "@azure/msal-react";

const erpList: ERPModel[] = [
  {
    name: "Quickbooks Online",
    appId: "00000000-0000-0000-0000-000000000001",
    iconUrl: QuickbooksLogo,
    width: "",
  },
  // TODO: Reenable Xero after ERP Connector as been fully tested
  //   {
  //     name: "Xero",
  //     appId: "00000000-0000-0000-0000-000000000002",
  //     iconUrl: XeroLogo,
  //     width: "",
  //   },
];

type EditAccountingSoftwareProps = {
  setToastSeverity: (prev: string) => void;
  setShowToast: (prev: boolean) => void;
  setToastMessage: (prev: string) => void;
  refreshData: () => void;
};

export default function EditAccountingSoftware(props: EditAccountingSoftwareProps): React.ReactElement {
  const { hasPermission, userStatus, createLead } = useContext(AppContext) as AppType;
  const [showOtherModal, setShowOtherModal] = useState<boolean>(false);
  const [unsupportedERPs, setUnsupportedERPs] = useState<UnsupportedERPModel[]>([]);
  const [otherERP, setOtherERP] = useState<string>("");
  const { accounts } = useMsal();

  const handlePopup = (erpName: string, appId: string) => {
    const url = buildAuthRedirectURL(erpName, appId);
    Utils.openPopup(url, "", window, 900, 800);
  };

  function handleSend() {
    createLead([{ email: accounts[0]?.username, erpSystem: otherERP }]);
    setShowOtherModal(false);
    props.setToastMessage("Request sent");
    props.setShowToast(true);
    props.setToastSeverity("success");
  }
  
  function handleError() {
    props.setShowToast(true);
    props.setToastMessage("Accounting Software Connection Unsuccessful");
    props.setToastSeverity("error");
  }

  useEffect(() => {
    const cb = async (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (event.data.type === "auth_code") {
          const body: Partial<AppEnrollmentModel>[] = [
            {
              appId: event.data.appId,
              isActive: true,
              syncScheduleIsActive: true,
              connectorInfo: {
                authCode: event.data.code,
                realmId: event.data.realmId,
                redirectUri: process.env.REACT_APP_REDIRECT_URL ?? null,
                email: null
              },
            },
          ];
          try {
            enrollmentsClient.createEnrollment(body).then(() => {
              props.setShowToast(true);
              props.setToastMessage("Accounting Software Connected");
              props.setToastSeverity("success");
              props.refreshData();
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            handleError();
          }
        } else if (event.data.type === "erp_error") {
          handleError();
        }
      }
    };
    window.addEventListener("message", cb);
    return () => {
      window.removeEventListener("message", cb);
    };
  }, []);

  // On mount, get list of Unsupported ERPs
  useEffect(() => {
    const getUnsupportedERPs = async () => {
      setUnsupportedERPs(
        (await definitionsClient.getUnsupportedERPs()).map((item: UnsupportedERPModel) => {
          return {
            erpSystemId: item.erpSystemId,
            name: item.name,
            isSupported: false,
          };
        })
      );
    };
    if (unsupportedERPs.length === 0) {
      getUnsupportedERPs();
    }
  }, []);

  if (!hasPermission(UserActions.EditAccountingSoftware)) {
    return (
      <div className="as-select-erp-wrapper">
        {userStatus?.roles.length === 0 ? (
          <Loading />
        ) : (
          <div className="invalid-permission">
            <img src={NoConnectionSVG} alt="NoConnectionSVG" />
            <span>Your team doesn’t have their accounting software connected to Lockstep.</span>
            <span>Contact your team’s Owner or an Admin to get your accounting software connected.</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="as-select-erp-wrapper">
      <h4>
        Connect your accounting software to get the most up-to-date insights on your data. Select your accounting software below to get started.
      </h4>
      <div className="btn-grid">
        {erpList.map((erp: ERPModel) => {
          return (
            <div className="erp-btn" key={erp.appId} onClick={() => handlePopup(erp.name, erp.appId)}>
              <img src={erp.iconUrl} alt={erp.name} />
            </div>
          );
        })}
        <div className="erp-btn" onClick={() => setShowOtherModal(true)}>
          <h2>Other</h2>
        </div>
      </div>
      <Modal open={showOtherModal} onClose={() => setShowOtherModal(false)}>
        <div className="as-modal">
          <h3>Other Connections</h3>
          <p>We are constantly looking to expand our suite of accounting software connections.</p>
          <p>Let us know which accounting software you use and a member of our team will be in touch when it becomes available.</p>
          <div className="form-row">
            <label htmlFor="UnsupportedERPInput">Your Accounting System</label>
            <Autocomplete
              id="UnsupportedERPInput"
              options={unsupportedERPs.map((option) => option.name)}
              freeSolo
              autoSelect
              staticIcon
              popupIcon={<Search />}
              onInputChange={(event, value: string) => setOtherERP(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Sage 300"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              data-testid="UnsupportedERPInput"
            />
          </div>
          <div className="btn-grp">
            <Button variant="secondary" onClick={() => setShowOtherModal(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleSend()}>Send</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
