import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationResult, MsalAuthenticationTemplate } from "@azure/msal-react";
import Loading from "../components/library/Loading/Loading";
import Error from "../components/library/Error/Error";
import { loginRequest } from "./authConfig";

function ErrorComponent({ error }: MsalAuthenticationResult) {
  const { instance } = useMsal();
  // When a user backs out of the Forgot Password screen, a specific error is thrown that we should simply intercept and redirect to login
  if (error && error?.errorMessage.includes("AADB2C90091")){
    instance.loginRedirect(loginRequest);
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Error isRelative>
        Error Message:{" "}
        {error && error?.errorCode ? error.errorCode : "Unknown Error"}
      </Error>
    </div>
  );
}

function LoadingComponent() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loading isRelative />
    </div>
  );
}

export default function PrivateRoute({ ...routeProps }: RouteProps): React.ReactElement {
  const authRequest = { ...loginRequest };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <Route {...routeProps} />
    </MsalAuthenticationTemplate>
  );
}
