import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import Navbar from "../../../library/Navbar/Navbar";
import AgingTable from "./AgingTable/AgingTable";
// TODO: Add back AgingHeader once API work has finalized
import AgingHeader from "./AgingHeader/AgingHeader";
import "../Drilldowns.scss";

const routes = [
  {
    displayName: "Late 91+ Days",
    routeName: "?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Late 61-90 Days",
    routeName: "late_61_90?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Late 31-60 Days",
    routeName: "late_31_60?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Late 1-30 Days",
    routeName: "late_1_30?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Future Due",
    routeName: "future_due?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
];

export default function Aging(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(0, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "Current AR Aging", routeName: "aging?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "DSO", routeName: "days_sales_outstanding?sort=DSO+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Receivables Risk", routeName: "receivables_risk?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "Receivables Summary", routeName: "receivables_summary?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Top Customers", routeName: "top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC" },
          ]}
          dropdownDefault={"Current AR Aging"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(0, path.lastIndexOf("/"))}`}
          persistQueryParams
          tooltip="The total open invoice balance, and the duration for which invoices have been outstanding."
        />
      </div>
      <div className="body">
        <div className="content">
          {/* TODO: Add back AgingHeader after API work has been done */}
          <div className="content-card">
            <AgingHeader />
          </div>
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <AgingTable />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
