import React, { useState } from "react";

import { companiesClient } from "../db/accessor";

export const CustomerContext = React.createContext<CustomerType | null>(null);

export interface CustomerProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }: CustomerProviderProps) => {
  const [company, setCompany] = useState<CustomerDetailsModel>({} as CustomerDetailsModel);

  // GET on /api/v1/Companies/views/customer-summary/{id}
  async function getCustomerDetails(id: string): Promise<CustomerDetailsModel> {
    return companiesClient.getCustomerDetails(id).then((data) => {
      setCompany(data);
      return data;
    });
  }

  return (
    <CustomerContext.Provider
      value={{
        getCustomerDetails,
        company,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
