import React, { useState, MouseEvent } from "react";
import { useParams, useHistory } from "react-router-dom";
import Table from "../../../library/Table/Table";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { invoicesClient } from "../../../../db/accessor";

const COLUMNS = [
  {
    Header: "Invoice Number",
    accessor: "invoice_number",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "13.75%",
    showFilter: true,
    popupPlaceholder: "ex. Open, Closed, Past Due",
    showSort: true,
    searchlightField: "STATUS",
    sortField: "STATUS, DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { due_date: string | null } } }) =>
      TableUtils.formatStatus(props.row.original.due_date, props.value, false),
  },
  {
    Header: "Invoice Date",
    accessor: "invoice_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "INVOICEDATE",
    sortField: "INVOICEDATE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false),
  },
  {
    Header: "Due Date",
    accessor: "due_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDUEDATE",
    sortField: "PAYMENTDUEDATE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false),
  },
  {
    Header: "Invoice Amount",
    accessor: "invoice_amount",
    width: "13.75%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICEAMOUNT",
    sortField: "INVOICEAMOUNT, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Outstanding Amount",
    accessor: "outstanding_amount",
    width: "22.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "OUTSTANDINGBALANCE",
    sortField: "OUTSTANDINGBALANCE, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value, false),
  },
];

/**
 * * Define the Invoices Tab of the Customer Detail's page
 */
export default function ClosedInvoices(): React.ReactElement {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const { company } = React.useContext(CustomerContext) as CustomerType;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchParser = (fetchResult: InvoiceSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoiceId,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record.invoiceNumber,
            status: record.status,
            invoice_date: record.invoiceDate,
            due_date: record.paymentDueDate,
            invoice_amount: record.invoiceAmount,
            outstanding_amount: record.outstandingBalance,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  // Handles navigation to Invoices details page on row click
  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`/AR/invoices/${row.original.id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "Invoices",
          displayName: "Invoices",
          rowSelectToken: "invoiceId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: invoicesClient.getInvoiceSummaries,
            fetchParser: fetchParser,
          },
          columns: COLUMNS,
          export: {
            exportFileName: `${company?.name ?? "N/A"} - Closed Invoices`,
          },
          handleRowClick: handleRowClick,
          defaultSort: "PAYMENTDUEDATE, INVOICENUMBER",
          defaultSortToken: "DESC",
          predefinedFilters: [
            {
              route: "closed_invoices",
              searchlightFilter: `(CUSTOMERID eq '${customerId}') AND (STATUS eq 'Closed')`,
            },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showSearchbar: false,
        showExportBtn: true,
        showCaption: true,
        showRowSelect: true,
        showNavigation: true,
      }}
    />
  );
}
