import React, { useState, useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Modal, TextField } from "@mui/material";

import Userpic from "../../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../../library/Button/Button";
import { DropdownArrow, Edit as EditSVG, Email, Fax, LinkedIn, Location, Phone, Position, Save, Upload } from "../../../../library/Icons/Icons";
import { DataMode } from "../../../../../types/enums";
import { Input } from "../../../../library/Input/Input";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import Dropdown from "../../../../library/Dropdown/Dropdown";
import { countries } from "../../../../../Countries";
import { formatDate } from "../../../.././../db/utils/date";
import { contactsClient } from "../../../../../db/accessor";
import { useParams } from "react-router";
import { codeDefinitionsClient } from "../../../../../db/accessor";
import Utils from "../../../../../utils/utils";

type EditContactProfileProps = {
  setDataMode: (prev: DataMode) => void;
  setShowEditSuccess: (prev: boolean) => void;
  setToastError: (prev: boolean) => void;
  setContactData: (data: ContactModel) => void;
  defaultData: Partial<ContactModel>;
  companyData: CompanyModel;
};

export default function EditContactProfile(props: EditContactProfileProps): React.ReactElement {
  const [contactProfileInfo, setContactProfileInfo] = useState<Partial<ContactModel>>(props.defaultData);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { contactId } = useParams<{ contactId: string }>();
  const [dropOptions, setDropOptions] = useState<{ value: string; onClickValue: string; id: string }[]>([]);
  const [editContactNameError, setEditContactNameError] = useState<string>("");
  const [editContactEmailError, setEditContactEmailError] = useState<string>("");

  async function fetchRoles() {
    await codeDefinitionsClient
      .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
      .then((data: CodeDefinitionModelFetchResult) => {
        setDropOptions(
          data.records?.map((role: CodeDefinitionModel) => {
            return {
              id: role.codeDefinitionId,
              value: role.codeDescription ?? "",
              onClickValue: role.codeDescription ?? "",
            };
          }) ?? []
        );
      });
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  async function handleUpdateContact() {
    try {
      props.setContactData(await contactsClient.patchContact(contactId, contactProfileInfo));
    } catch (error: any) {
      props.setToastError(true);
      console.log(error);
    }
  }

  const handleSave = () => {
    let valid = true;
    if (!contactProfileInfo.contactName) {
      valid = false;
      setEditContactNameError("NO NAME GIVEN");
    } else {
      setEditContactNameError("");
    }
    if (!contactProfileInfo.emailAddress) {
      valid = false;
      setEditContactEmailError("NO EMAIL GIVEN");
    } else if (!Utils.validEmail(contactProfileInfo.emailAddress)) {
      valid = false;
      setEditContactEmailError("INVALID EMAIL GIVEN");
    } else if (
      props.companyData.contacts &&
      props.companyData.contacts.some((elem) => elem.contactId !== contactId && elem.emailAddress === contactProfileInfo.emailAddress)
    ) {
      valid = false;
      setEditContactEmailError("THERE IS A CONTACT WITH THIS EMAIL ADDRESS");
    } else {
      setEditContactEmailError("");
    }
    if (valid) {
      handleUpdateContact();
      props.setDataMode(DataMode.View);
      props.setShowEditSuccess(true);
    }
  };

  const handleUpload = () => {
    console.log("handleUpload");
  };

  return (
    <div className="edit-profile">
      <div className="profile-edit-bar">
        <div className="btn-grp">
          <Button icon={<Save />} variant="primary" size="lg" alignIcon={"left"} onClick={() => handleSave()}>
            Save
          </Button>
          <Button variant="secondary" size="lg" onClick={() => setShowCancelModal(true)}>
            Cancel
          </Button>
        </div>
        <div className="last-updated">
          <p>Updated {formatDate(contactProfileInfo.modified ?? "N/A")}</p>
          {/* TODO: replace by you with modifiedUserName when that is tracked by the API */}
          {/* <p>by {contactProfileData.modifiedUserId}</p> */}
          <p>by You</p>
        </div>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
        <form onSubmit={(e) => e.preventDefault()} className="container" id="EditMyProfileForm">
          <div className="name-row">
            <Userpic id="user-pic" />
            <Button icon={<Upload />} variant="secondary" size="lg" alignIcon={"left"} className="upload-btn" onClick={() => handleUpload()}>
              Upload Photo
            </Button>
          </div>
          <div className="form-row">
            <div className="tag">
              <EditSVG />
            </div>
            <Input
              label={"Name (Required)"}
              defaultValue={contactProfileInfo.contactName}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, contactName: value };
                })
              }
              className={"field-md"}
              placeholder="Chelsea Henley"
              data-testid="EditMyName"
              required
              errorMessage={editContactNameError}
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Position />
            </div>
            <div className="sb-wrapper">
              <span className="sb-label-container">
                <label className="sb-label">Role</label>
              </span>
              <Dropdown
                icon={<DropdownArrow />}
                className={"role-dropdown field-md"}
                defaultValue={contactProfileInfo.roleCode}
                onClick={(onClickValue: string) =>
                  setContactProfileInfo((prev: Partial<ContactModel>) => {
                    return {
                      ...prev,
                      roleCode: onClickValue,
                    };
                  })
                }
                options={dropOptions}
                displayValue={""}
              />
            </div>
            <Input
              label={"Title"}
              defaultValue={contactProfileInfo.title}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, title: value };
                })
              }
              className={"title-field"}
              placeholder="AR Associate"
              data-testid="EditTitleName"
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Email />
            </div>
            <Input
              label={"Email (Required)"}
              defaultValue={contactProfileInfo.emailAddress}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, emailAddress: value };
                })
              }
              className={"field-md"}
              placeholder="ap@gekko.com"
              data-testid="EditEmail"
              required
              errorMessage={editContactEmailError}
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Phone />
            </div>
            <Input
              label={"Phone"}
              defaultValue={contactProfileInfo.phone}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, phone: value };
                })
              }
              className={"field-md"}
              placeholder="(123) 456-7890"
              data-testid="EditPhone"
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Fax />
            </div>
            <Input
              label={"Fax"}
              defaultValue={contactProfileInfo.fax}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, fax: value };
                })
              }
              className={"field-md"}
              placeholder="(123) 456-7890"
              data-testid="EditFax"
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Location />
            </div>
            <Input
              label={"Street Address"}
              defaultValue={contactProfileInfo.address1}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, address1: value };
                })
              }
              className={"street-field"}
              placeholder="123 Main Street"
              data-testid="EditStreetAddr"
            />
            <Input
              label={"Unit or Suite"}
              defaultValue={contactProfileInfo.address2}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, address2: value };
                })
              }
              className={"field-sm addr-field"}
              placeholder="2"
              data-testid="EditStreetAddr"
            />
          </div>
          <div className="form-row address-row">
            <Input
              label={"Postal Code"}
              defaultValue={contactProfileInfo.postalCode}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, postalCode: value };
                })
              }
              className={"field-sm no-icon-field"}
              placeholder="94016"
              data-testid="EditZipcode"
            />
            <Input
              label={"City"}
              defaultValue={contactProfileInfo.city}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, city: value };
                })
              }
              className={"city-field addr-field"}
              placeholder="San Fransisco"
              data-testid="EditCity"
            />
            <Input
              label={"State/Region"}
              defaultValue={contactProfileInfo.stateRegion}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, stateRegion: value };
                })
              }
              className={"field-sm addr-field"}
              placeholder="CA"
              data-testid="EditState"
            />
          </div>
          <div className="form-row address-row">
            <div className="sb-wrapper no-icon-field">
              <div className="sb-label-container">
                <label className="sb-label" htmlFor="DefaultCountryInput">
                  Country
                </label>
              </div>
              <Autocomplete
                id="DefaultCountryInput"
                data-testid="EditMyProfileCountry"
                options={countries}
                className="country-field"
                onChange={(_: any, value: { label: string; id: string } | null) => {
                  setContactProfileInfo((prev: Partial<ContactModel>) => {
                    return { ...prev, countryCode: value?.id ?? null };
                  });
                }}
                value={
                  countries.filter((country) => {
                    return country.id === contactProfileInfo.countryCode;
                  })[0]?.label
                }
                disableClearable
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      autoComplete: "country",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="tag">
              <LinkedIn />
            </div>
            <Input
              label={"LinkedIn"}
              defaultValue={contactProfileInfo.webpageUrl}
              onChange={(value) =>
                setContactProfileInfo((prev: Partial<ContactModel>) => {
                  return { ...prev, webpageUrl: value };
                })
              }
              className={"linkedin-field"}
              placeholder="www.linkedin.com/company/gekkointernational"
              data-testid="EditLinkedin"
            />
          </div>
        </form>
      </OverlayScrollbarsComponent>
      {showCancelModal && (
        <Modal open={showCancelModal} onClose={() => setShowCancelModal(false)}>
          <div className="as-modal">
            <h3>Cancel Edit</h3>
            <p>Are you sure you want to cancel your changes?</p>
            <div className="btn-grp">
              <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                No
              </Button>
              <Button variant="error" onClick={() => props.setDataMode(DataMode.View)}>
                Yes, Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
