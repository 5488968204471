import React, { useEffect, useRef, useState } from "react";
import Table from "../../../library/Table/Table";
import "./RemovedMembers.scss";
import { UserPic } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { UserActions } from "../../../../types/enums";
import { AppContext } from "../../../../contexts/AppContext";
import { userAccountsClient } from "../../../../db/accessor";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { Snackbar, Alert, AlertColor } from "@mui/material";

export default function RemovedMembers(): React.ReactElement {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { hasPermission, userRoles } = React.useContext(AppContext) as AppType;

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastSeverity, setToastSeverity] = useState<string>("success");
  const [toastMsg, setToastMsg] = useState<string>("");

  const tableRef = useRef(null);

  const handleInviteClick = async (id: string) => {
    return await userAccountsClient
      .postReenable(id)
      .then(() => {
        (tableRef?.current as any)?.softRefresh();
        setToastMsg("Invitation Sent");
        setToastSeverity("success");
      })
      .catch(() => {
        setToastMsg("Invitation was not sent");
        setToastSeverity("error");
      })
      .finally(() => {
        setShowToast(true);
      });
  };

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
      width: "27%",
      showSort: true,
      showFilter: true,
      popupPlaceholder: "abbie blue",
      searchlightField: "USERNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell userPermission-name-col">
            <div className="icon-wrapper">
              <UserPic />
            </div>
            <p className="body1">{props.value}</p>
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "28%",
      showSort: true,
      showFilter: true,
      popupPlaceholder: "abbieblue@example.com",
      searchlightField: "EMAIL",
      searchlightToken: "CONTAINS",
      sortField: "EMAIL, USERNAME",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{props.value}</p>
          </div>
        );
      },
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      width: "28%",
      showSort: true,
      showFilter: false,
      searchlightField: "ACCOUNTINGROLECODEDEFID",
      searchlightToken: "CONTAINS",
      sortField: "ACCOUNTINGROLECODEDEFID, USERNAME",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        const role = userRoles.find((userRole: UserRoleModel) => userRole.userRoleId === props.value)?.userRoleName;
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{role}</p>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "invite_button",
      width: "10%",
      showSort: false,
      showFilter: false,
      alignment: "left",
      Cell: function format(props: { row: { original: { id: string } } }) {
        return TableUtils.withFormattedCellElement(
          <Button variant="secondary" size="sm" onClick={() => handleInviteClick(props.row.original.id)}>
            Invite
          </Button>
        );
      },
    },
  ];

  const fetchParser = (fetchResult: UserAccountModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: UserAccountModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.userId,
          }),
          ...((variant === "export" || variant === "all") && {
            name: record?.userName,
            email: record?.email,
            permissions: record?.userRole,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const handleRowClick = () => {
    return null;
  };

  useEffect(() => {
    return () => {
      setPageNumber(0);
      setPageCount(0);
      setTotalCount(0);
    };
  }, []);

  return (
    <div className={`removed-wrapper`}>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Removed Members",
            displayName: "Removed Members",
            rowSelectToken: "email",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: userAccountsClient.queryUserAccounts,
              fetchParser: fetchParser,
            },
            columns: COLUMNS,
            hiddenColumns: hasPermission(UserActions.RemovedMembersInvite) ? undefined : ["invite_button"],
            handleRowClick: handleRowClick,
            defaultSort: "USERNAME",
            defaultSortToken: "DESC",
            predefinedFilters: [
              {
                route: "removed",
                searchlightFilter: "(STATUS EQ 'Disabled')",
              },
            ],
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
        }}
        toggles={{
          showCaption: false,
          showRowSelect: hasPermission(UserActions.RemovedMembersCheckbox),
          showNavigation: true,
          showExportBtn: false,
          showSearchbar: false,
        }}
      />
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
