import React from 'react';
import Button from "../Button/Button";
import './SoftAlert.scss';

interface SoftAlertProps {
    alertMessage: string;
    buttonText: string;
    onClick: () => void;
    badParam: string;
}

export default function SoftAlert(props: SoftAlertProps): React.ReactElement {
    return (
        <div className="sa-container">
            <h2 className="alert-message">{props.alertMessage}</h2>
            <div className="sa-btn-row">
                <Button 
                    className="sa-clear-btn"
                    variant="primary"
                    size="lg"
                    onClick={() => {
                        props.onClick();
                    }}
                >
                    {props.buttonText}
                </Button>
            </div>
        </div>
    )
}