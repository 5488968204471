import APIClient from "../APIClient";

export class EnrollmentsClient {
  getEnrollments(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<AppEnrollmentModelFetchResult> {
    const url = "api/v1/AppEnrollments/query";

    const options = {
      params: {
        filter,
        include: include ?? "App,LastSync",
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  changeEnrollment(id: string, body: { isActive: boolean }): Promise<AppEnrollmentModel> {
    const url = `api/v1/AppEnrollments/${id}`;

    return APIClient.patch(url, body).then((response) => {
      return response.data;
    });
  }

  createEnrollment(body: Partial<AppEnrollmentModel>[]): Promise<AppEnrollmentModel[]> {
    const url = "api/v1/AppEnrollments";

    return APIClient.post(url, body).then((response) => {
      return response.data;
    });
  }

  deleteEnrollment(id: string): Promise<ActionResultModel> {
    const url = `api/v1/AppEnrollments/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  } 
}
