import React from "react";
import Userpic from "../Userpic/Userpic";
import { Company } from "../../Icons/Icons";
import "./CompanyProfile.scss";

// Defines Company Profile Props
interface Props {
  accountType: string;
  companyName: string;
}

/**
 * * Defines Company Profile for Navigation Side Bar
 */
export default function CompanyProfile(props: Props): React.ReactElement {
  return (
    <div className={`companyProfile-wrapper`}>
      <div>
        <Userpic icon={<Company/>}></Userpic>
      </div>
      <div>
        <p className={`companyName`}>{props.companyName}</p>
        <p className={`accountType`}>{props.accountType}</p>
      </div>
    </div>
  );
}
