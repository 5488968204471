import React, { useState } from "react";
import { default as View } from "./ContactsView";
import { default as Edit } from "./ContactsEdit";
import { DataMode } from "../../../../types/enums";
import { Snackbar, Alert } from "@mui/material";

export default function Contacts(): React.ReactElement {
  const [dataMode, setDataMode] = React.useState<DataMode>(DataMode.View);
  const [showEditToast, setShowEditToast] = useState<boolean>(false);
  const [editError, setEditError] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchParser = (fetchResult: ContactModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: ContactModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.contactId,
          }),
          ...((variant === "export" || variant === "all") && {
            name: record?.contactName,
            role: record?.roleCode,
            email: record?.emailAddress,
            phone_number: record?.phone,
            last_updated: record?.modified,
            //TODO: wire these to values once we have access to them
            icon: record?.contactName === "Primary" ? "primary" : "",
          }),
          ...(variant === "all" && {
            disableCheckbox: record?.appEnrollmentId ? true : false,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  return (
    <>
      {dataMode === DataMode.View && (
        <View
          setDataMode={setDataMode}
          tableData={tableData}
          setTableData={setTableData}
          fetchParser={fetchParser}
          isLoading={isLoading}
          setLoading={setLoading}
          isError={isError}
          setError={setError}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={pageCount}
          setPageCount={setPageCount}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
        />
      )}
      {dataMode === DataMode.Edit && (
        <Edit
          setDataMode={setDataMode}
          tableData={tableData}
          setTableData={setTableData}
          fetchParser={fetchParser}
          isLoading={isLoading}
          setLoading={setLoading}
          isError={isError}
          setError={setError}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={pageCount}
          setPageCount={setPageCount}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          setShowEditToast={setShowEditToast}
          setEditError={setEditError}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showEditToast}
        onClose={() => setShowEditToast(false)}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setShowEditToast(false)}
          severity={editError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowEditToast(false)}>
              CLOSE
            </p>
          }
        >
          {editError ? "Error Message" : "Contacts Edited"}
        </Alert>
      </Snackbar>
    </>
  );
}
