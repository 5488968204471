import React, { ReactNode } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
export interface ButtonProps {
  className?: string;
  variant?: "primary" | "secondary" | "transparent" | "grey" | "error" | "solo-icon";
  size?: "sm" | "lg";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  icon?: ReactNode;
  alignIcon?: "left" | "right";
  type?: "submit" | "reset" | "button";
  form?: string;
  loading?: boolean;
  tooltip?: string;
}

export default function Button(props: ButtonProps): React.ReactElement {
  return (
    <Tooltip title={props.tooltip ?? ""} followCursor>
      <button
        className={`
        btn ${props.icon ? "icon-btn" : ""} ${props.icon && props.alignIcon ? `icon-btn-${props.alignIcon}` : ""}
        btn-${props.variant ?? "primary"}
        btn-${props.size === "sm" ? "sm" : "lg"}
        ${props.loading ? "btn-loading" : ""}
        ${props.className ? props.className : ""}
      `}
        onClick={props.onClick}
        disabled={props.disabled || props.loading}
        type={props.type}
        form={props.form}
      >
        {props.icon && props.alignIcon === "left" ? props.icon : null}
        {props.children && (<p className={`btn-text${props.size === "sm" ? "-sm" : ""}`}>{props.children}</p>)}
        {props.icon && props.alignIcon === "right" ? props.icon : null}
        <div className={`btn-load-wrapper btn-load-wrapper-${props.variant ?? "primary"}`}>
          <CircularProgress size={18} />
        </div>
      </button>
    </Tooltip>
  );
}
