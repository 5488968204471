import { createTheme } from "@mui/material";
import styles from "../css/_export.module.scss";

export const primaryTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          padding: styles.large,
          width: "19.5rem",
          boxShadow: "0 8px 12px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.1)",
        },
        modal: { backgroundColor: "rgba(0, 0, 0, 0)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          padding: "0px",
          borderRadius: "2px",
          "&.Mui-focused": {
            border: `1px solid ${styles.grey70}`,
          },
        },
        inputRoot: {
          backgroundColor: styles.grey98,
          color: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          height: "40px",
          width: "100%",
          padding: "0px !important",
          borderRadius: "2px",
          "&:hover": {
            backgroundColor: styles.grey96,
          },
          "&.Mui-focused": {
            backgroundColor: styles.grey93,
          },
          "&.Mui-focused input": {
            color: styles.primary,
          },
          "&.Mui-focused input:focus::placeholder": {
            color: "transparent",
          },
          ".MuiAutocomplete-endAdornment": {
            marginRight: "12px",
            button: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            svg: {
              fill: styles.grey60,
            },
            top: "unset",
          },
          ".MuiInput-input": {
            padding: "0px",
          },
          ".MuiAutocomplete-clearIndicator": {
            display: "none",
          },
        },
        input: {
          textIndent: "0.75rem",
          height: "100%",
          fontSize: styles.fontSizeSM,
          paddingRight: "47px !important",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        listbox: {
          maxHeight: "205px",
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
          boxShadow: "none",
          "&::-webkit-scrollbar-track": {
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: "2px",
            backgroundColor: styles.grey96,
            height: "80px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: styles.grey90,
          },
        },
        paper: {
          borderRadius: "0px 0px 2px 2px",
          boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
        noOptions: {
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "success" },
          style: {
            height: "56px",
            borderRadius: "2px",
            backgroundColor: styles.connectedGreen,
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "22px",
            padding: "0px 24px 0px 22px",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0px",
              padding: "0px",
              width: "20px",
              height: "20px",
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
            },
            "& .MuiAlert-message": {
              color: styles.white100,
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              padding: "0px",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              color: styles.white100,
            },
          },
        },
        {
          props: { severity: "error" },
          style: {
            height: "56px",
            borderRadius: "2px",
            backgroundColor: styles.red,
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "22px",
            padding: "0px 24px 0px 22px",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0px",
              padding: "0px",
              width: "20px",
              height: "20px",
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
            },
            "& .MuiAlert-message": {
              color: styles.white100,
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              padding: "0px",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              margin: "0px",
              cursor: "pointer",
              color: styles.white100,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontWeight: 500,
          fontSize: styles.fontSizeXS,
          lineHeight: styles.lineHeightSM,
          color: styles.white100,
          fontStyle: "normal",
          padding: "8px",
          borderRadius: "2px",

          "& .MuiTooltip-arrow::before": {
            color: styles.primary,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& > svg": {
            fill: styles.grey60,
          },
          "&:hover > svg": {
            fill: styles.primary,
          },
        },
      },
    },
  },
});
