import React, { Dispatch, useState } from "react";
import { Alert, Drawer, Snackbar, TextField } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { Archive } from "../../../../library/Icons/Icons";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { activitiesClient, notesClient } from "../../../../../db/accessor";
import { ActivityStatus, NoteType } from "../../../../../types/enums";

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityCloseProps = {
  activityId: string;
  noteValue: string;
  setNoteValue: Dispatch<string>;
  reason: string;
  setReason: Dispatch<string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityClose(props: ActivityCloseProps): React.ReactElement {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);

  const handleClose = async () => {
    try {
      const updatedActivity = await activitiesClient.patch(props.activityId, {
        activityStatus: ActivityStatus.Archived,
        isOpen: false,
        isArchived: true,
      });

      await notesClient.post([
        {
          tableKey: "Activity",
          objectKey: updatedActivity.activityId,
          noteText: `(Archived) Note: ${props.noteValue} Reason: ${props.reason}`,
          noteType: NoteType.Note,
        },
      ]);
    } catch (e: unknown) {
      setToastError(true);
    } finally {
      props.setNoteValue("");
      setShowToast(true);
      setOpen(false);
    }
  };

  return (
    <div className="activity-archive">
      <Button
        icon={<Archive />}
        onClick={() => setOpen(true)}
        variant={"solo-icon"}
        alignIcon={"left"}
        tooltip={"Archive"}
        className={"detail-icon-btn"}
      />

      <Drawer anchor="right" open={isOpen} onClose={() => setOpen(false)} disablePortal>
        <div className="activity-flyout">
          <h3 className="header">Archive This Assignment</h3>
          <p className="subheader body1">Archiving this assignment will mean no additional activities can be added.</p>
          <TextArea label={"Add A Note"} placeholder={""} onChange={props.setNoteValue} defaultValue={props.noteValue} />
          <label>Why Are You Archiving This?</label>
          <Autocomplete
            options={["Reason D", "Reason E", "Reason F"]}
            onChange={async (_event, value: string) => {
              props.setReason(value);
            }}
            value={props.reason}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  placeholder: "Choose A Reason",
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
          />
          <div className="btn-grp">
            <Button variant="secondary" size="lg" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button size="lg" onClick={() => handleClose()}>
              Archive
            </Button>
          </div>
        </div>
      </Drawer>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              ARCHIVE
            </p>
          }
        >
          {toastError ? "Activity was not archived" : "Activity Archived"}
        </Alert>
      </Snackbar>
    </div>
  );
}
