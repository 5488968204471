import { Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import "./ARHeader.scss";

type HeaderType = {
  type: string;
  customLabel?: string;
};

export interface Props {
  list: HeaderType[];
  reportDate: string;
}

export default function ARHeader(props: Props): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [ARHeader, setARHeader] = useState<ArHeaderInfoModel>({} as ArHeaderInfoModel);
  const { getARHeader } = React.useContext(AppContext) as AppType;

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);
      try {
        await getARHeader(props.reportDate).then((data) => {
          setARHeader(data);
        });
      } catch (error: any) {
        setError(true);
        console.log(error);
      }
      setLoading(false);
    })();
  }, [location.pathname]);

  return (
    <div className="ARHeader-wrapper">
      {isLoading ? (
        props.list.map((_, index: number) => {
          return <Skeleton key={`ARHeader-${index}`} variant="text" width={96} height={20} animation="wave" />;
        })
      ) : isError ? (
        <span className="text-span">
          <span className="text-label">Oops! Something Went Wrong... </span>
        </span>
      ) : (
        props.list.map((item: HeaderType, index: number) => {
          let label = "";
          let value: string | number = "";
          switch (item.type) {
            case "reportPeriod":
              label = item.customLabel ?? "Report Period";
              value = ARHeader.reportPeriod ?? "N/A";
              break;
            case "totalCustomers":
              label = item.customLabel ?? "Customers";
              value = ARHeader?.totalCustomers ?? 0;
              break;
            case "totalInvoices":
              label = item.customLabel ?? "Invoices";
              value = ARHeader?.totalInvoices ?? 0;
              break;
            case "totalInvoicedAmount":
              label = item.customLabel ?? "Amount Invoiced";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalInvoicedAmount ?? 0);
              break;
            case "totalUnappliedPayments":
              label = item.customLabel ?? "Total Unapplied Payment Amount";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalUnappliedPayments ?? 0);
              break;
            case "totalCollected":
              label = item.customLabel ?? "Amount Collected";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalCollected ?? 0);
              break;
            case "totalArAmount":
              label = item.customLabel ?? "Total Balance";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalArAmount ?? 0);
              break;
            case "totalInvoicesOpen":
              label = item.customLabel ?? "Open Invoices";
              value = isNaN(ARHeader.totalInvoices - ARHeader.totalInvoicesPaid) ? 0 : ARHeader.totalInvoices - ARHeader.totalInvoicesPaid;
              break;
            case "totalInvoicesPaid":
              label = item.customLabel ?? "Invoices Paid";
              value = ARHeader?.totalInvoicesPaid ?? 0;
              break;
            case "totalInvoicesPastDue":
              label = item.customLabel ?? "Past Due Invoices";
              value = ARHeader?.totalInvoicesPastDue ?? 0;
              break;
            case "totalInvoices90DaysPastDue":
              label = item.customLabel ?? "Past Due Invoices";
              value = ARHeader?.totalInvoices90DaysPastDue ?? 0;
              break;
            case "totalPastDueAmount":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalPastDueAmount ?? 0);
              break;
            case "totalPastDueAmount90Days":
              label = item.customLabel ?? "Past Due Amount";
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalPastDueAmount90Days ?? 0);
              break;
            case "percentageOfTotalAr":
              label = item.customLabel ?? "Percentage of Total AR";
              value = `${ARHeader.percentageOfTotalAr ?? 0}%`;
              break;
            case "percentageOfTotalAr90DaysPastDue":
              label = item.customLabel ?? "Percentage of Total AR";
              value = `${ARHeader.percentageOfTotalAr90DaysPastDue ?? 0}%`;
              break;
            case "dso":
              label = item.customLabel ?? "Days Sales Outstanding";
              value = ARHeader?.dso ?? 0;
              break;
            case "totalInvoiceAmountCurrentYear":
              label = item.customLabel ?? `Total Invoiced in ${DateTime.now().toFormat("yyyy")}`;
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalInvoiceAmountCurrentYear ?? 0);
              break;
            case "totalInvoiceAmountPreviousYear":
              label = item.customLabel ?? `Total Invoiced in ${DateTime.now().minus({ years: 1 }).toFormat("yyyy")}`;
              value = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(ARHeader.totalInvoiceAmountPreviousYear ?? 0);
              break;
            default:
              label = "INVALID TYPE";
              break;
          }
          return (
            <span className="text-span" key={`ARHeader-${index}`}>
              {value} <span className="text-label">{label}</span>
            </span>
          );
        })
      )}
    </div>
  );
}
