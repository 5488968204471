import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import LockstepLogo from "../../assets/Lockstep_Icon_With_Text_Color_RGB.svg";
import ERPSelect from "../../components/Onboard/ERPSelect/ERPSelect";
import PersonalInfo from "../../components/Onboard/PersonalInfo/PersonalInfo";
import CompanyInfo from "../../components/Onboard/CompanyInfo/CompanyInfo";
import CreateAccount from "../../components/Onboard/CreateAccount/CreateAccount";
import ExistingAccount from "../../components/Onboard/ExistingAccount/ExistingAccount";
import { OnboardContext } from "../../contexts/OnboardContext";
import { OnboardStep } from "../../types/enums";
import OtherERP from "../../components/Onboard/OtherERP/OtherERP";
import ConnectEmail from "../../components/Onboard/ConnectEmail/ConnectEmail";
import { userAccountsClient } from "../../db/accessor";
import { AppContext } from "../../contexts/AppContext";
import CreateInvitedAccount from "../../components/Onboard/CreateInvitedAccount/CreateInvitedAccount";
import "./Onboard.scss";
import { Snackbar, Alert, AlertColor } from "@mui/material";

const Onboard: React.FC = () => {
  const { setOnboardData } = React.useContext(AppContext) as AppType;
  const { setOnboardStep, onboardStep, isInvited, setIsInvited } = React.useContext(OnboardContext) as OnboardType;
  const { boot, shutdown, update } = useIntercom();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastSeverity, setToastSeverity] = useState<string>("success");
  const [toastMsg, setToastMsg] = useState<string>("");

  shutdown();
  boot();
  update({ hideDefaultLauncher: false });

  useEffect(() => {
    (async function getInviteStatus() {
      const inviteCode = new URLSearchParams(window.location.search).get("code");
      if (inviteCode) {
        setIsLoading(true);
        await userAccountsClient
          .getInvite(inviteCode)
          .then((value: InviteDataModel) => {
            if (value?.userStatus === "Invited") {
              setIsInvited(true);
              setOnboardData((prev: OnboardDataModel) => {
                return { ...prev, email: value?.email || "" };
              });
              setOnboardStep(OnboardStep.CreateInvitedAccount);
            }
          })
          .catch(() => {
            setToastSeverity("error");
            setToastMsg("Invalid Invitation Code");
            setShowToast(true);
          });
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="onboard-wrapper">
      <div className="onboard-content">
        <div className="nav-wrapper">
          <img className="nav-logo" src={LockstepLogo} alt="Lockstep logo" />
          {isLoading ? (
            <></>
          ) : isInvited ? (
            <ol>
              <li data-testid="NavCreateAccount" className={onboardStep === OnboardStep.CreateInvitedAccount ? "selected" : ""}>
                Create Your Account
              </li>
              <li data-testid="NavUpdatePersonalInfo" className={onboardStep === OnboardStep.PersonalInfo ? "selected" : ""}>
                Update Your Personal Info
              </li>
            </ol>
          ) : (
            <ol>
              <li
                data-testid="NavSelectErp"
                className={onboardStep === OnboardStep.SelectERP || onboardStep === OnboardStep.OtherERP ? "selected" : ""}
              >
                Select Your Accounting System
              </li>
              <li
                data-testid="NavCreateAccount"
                className={onboardStep === OnboardStep.CreateAccount || onboardStep === OnboardStep.ExistingAccount ? "selected" : ""}
              >
                Create Your Account
              </li>
              <li data-testid="NavUpdatePersonalInfo" className={onboardStep === OnboardStep.PersonalInfo ? "selected" : ""}>
                Update Your Personal Info
              </li>
              <li data-testid="NavUpdateCompanyInfo" className={onboardStep === OnboardStep.CompanyInfo ? "selected" : ""}>
                Update Your Company Info
              </li>
              <li data-testid="NavConnectEmail" className={onboardStep === OnboardStep.ConnectEmail ? "selected" : ""}>
                Connect Your Email
              </li>
            </ol>
          )}
        </div>
        <div
          className={`content-wrapper ${
            onboardStep === OnboardStep.CreateAccount || onboardStep === OnboardStep.CreateInvitedAccount ? "no-padding" : ""
          }`}
        >
          {!isLoading && (
            <>
              {onboardStep === OnboardStep.SelectERP ? <ERPSelect /> : null}
              {onboardStep === OnboardStep.OtherERP ? <OtherERP /> : null}
              {onboardStep === OnboardStep.CreateAccount ? <CreateAccount /> : null}
              {onboardStep === OnboardStep.CreateInvitedAccount ? <CreateInvitedAccount /> : null}
              {onboardStep === OnboardStep.ExistingAccount ? <ExistingAccount /> : null}
              {onboardStep === OnboardStep.PersonalInfo ? <PersonalInfo /> : null}
              {onboardStep === OnboardStep.CompanyInfo ? <CompanyInfo /> : null}
              {onboardStep === OnboardStep.ConnectEmail ? <ConnectEmail /> : null}
            </>
          )}
        </div>
      </div>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Onboard;
