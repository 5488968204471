import React from "react";
import { AppContext } from "../../../contexts/AppContext";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { OnboardStep } from "../../../types/enums";

function CreateInvitedAccount(): React.ReactElement {
  const { onboardData } = React.useContext(AppContext) as AppType;
  const { setOnboardStep, setBearerToken } = React.useContext(OnboardContext) as OnboardType;

  // Callbacks from B2C IFrame
  window.onmessage = async function (event) {
    // B2C Account Creation callback
    if (event?.data?.type === "id_token") {
      setBearerToken(event?.data?.code);
      setOnboardStep(OnboardStep.PersonalInfo);
    }
  };

  return (
    <div className="create-account-wrapper">
      <iframe
        frameBorder="0"
        width="100%"
        height="100%"
        src={`https://${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${
          process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY
        }/oauth2/v2.0/authorize?p=B2C_1A_EMBEDDED_SIGNUP_B&client_id=${
          process.env.REACT_APP_AZURE_CLIENT_ID
        }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
          window.location.origin + "/signupSuccess"
        )}&scope=openid&response_type=id_token&prompt=login&response_mode=query&login_hint=${onboardData.email}`}
      />
    </div>
  );
}

export default CreateInvitedAccount;
