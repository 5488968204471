import React, { useState, MouseEvent, useRef, Dispatch, SetStateAction } from "react";
import Table from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { Input } from "../../../library/Input/Input";
import Dropdown from "../../../library/Dropdown/Dropdown";
import Button from "../../../library/Button/Button";
import { DropdownArrow, PlusSign, Email, Delete } from "../../../library/Icons/Icons";
import Utils from "../../../../utils/utils";
import { contactsClient, companiesClient } from "../../../../db/accessor";
import "./Contacts.scss";
import { useParams, useHistory } from "react-router-dom";
import { Alert, Drawer, Modal, Snackbar } from "@mui/material";
import { DataMode } from "../../../../types/enums";

const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    width: "22%",
    showFilter: true,
    showSort: true,
    sortField: "CONTACTNAME",
    searchlightField: "CONTACTNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatStringWithIcon(props.value),
  },
  {
    Header: "",
    accessor: "icon",
    width: "3%",
    showFilter: false,
    showSort: false,
    alignment: "left",
    Cell: (props: { value: string }) => TableUtils.formatIcon(props.value),
  },
  {
    Header: "Role",
    accessor: "role",
    width: "20%",
    showFilter: true,
    showSort: true,
    searchlightField: "ROLECODE",
    sortField: "ROLECODE, CONTACTNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatContactString(props.value),
  },
  {
    Header: "Email",
    accessor: "email",
    width: "22.5%",
    showFilter: true,
    showSort: true,
    searchlightField: "EMAILADDRESS",
    sortField: "EMAILADDRESS, CONTACTNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
  },
  {
    Header: "Phone Number",
    accessor: "phone_number",
    width: "16.25%",
    showFilter: true,
    showSort: true,
    searchlightField: "PHONE",
    sortField: "PHONE, CONTACTNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatPhoneNumber(props.value, "contact-bold"),
  },
  {
    Header: "Last Updated",
    accessor: "last_updated",
    width: "16.25%",
    showSort: true,
    showFilter: true,
    searchlightField: "MODIFIED",
    sortField: "MODIFIED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
  },
];

type ContactsProps = {
  setDataMode: Dispatch<SetStateAction<DataMode>>;
  tableData: TableData[];
  setTableData: Dispatch<SetStateAction<TableData[]>>;
  fetchParser: (response: FetchResult, variant?: FetchVariant) => Partial<TableData>[];
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageCount: number;
  setPageCount: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
};

/**
 * * Define the Contacts Tab of the Customer Detail's page
 */
export default function ContactsView(props: ContactsProps): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();

  const [addContactFlyout, setAddContactFlyout] = useState<boolean>(false);
  const [newContactName, setNewContactName] = useState<string>("");
  const [newContactRole, setNewContactRole] = useState<string>("Unknown");
  const [newContactEmail, setNewContactEmail] = useState<string>("");
  const [newContactPhone, setNewContactPhone] = useState<string>("");
  const [newContactPrimary, setNewContactPrimary] = useState<boolean>(false);
  const [addContactNameError, setAddContactNameError] = useState<string>("");
  const [addContactEmailError, setAddContactEmailError] = useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [toast, setToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [addToast, setAddToast] = useState<boolean>(false);
  const [addToastError, setAddToastError] = useState<boolean>(false);
  const tableRef = useRef(null);

  const handleCloseFlyout = () => {
    setAddContactEmailError("");
    setAddContactNameError("");
    setNewContactName("");
    setNewContactEmail("");
    setNewContactPhone("");
    setNewContactRole("Unknown");
    setNewContactPrimary(false);
    setAddContactFlyout(false);
  };

  const editContacts = () => {
    props.setDataMode(DataMode.Edit);
  };
  const setDeleteContacts = (ids: string[]) => {
    setDeleteIds(ids);
    setShowDeleteModal(true);
  };

  const handleDeleteContacts = async () => {
    try {
      return await Promise.all(
        deleteIds.map((id: string) => {
          return contactsClient.patchContact(id, { isActive: false });
        })
      ).then(() => {
        setToastError(false);
        (tableRef?.current as any)?.softRefresh();
      });
    } catch (error: unknown) {
      setToastError(true);
    } finally {
      setShowDeleteModal(false);
      setToast(true);
    }
  };

  async function handleChangePrimary(id: string) {
    await companiesClient.patchCompany(customerId, { primaryContactId: id });
  }

  async function postNewContact() {
    try {
      await contactsClient
        .createContact([
          {
            companyId: customerId,
            contactName: newContactName,
            roleCode: newContactRole,
            emailAddress: newContactEmail,
            phone: newContactPhone,
          },
        ])
        .then((data) => {
          if (newContactPrimary) {
            handleChangePrimary(data.contactId);
          }
        });
    } catch (error: any) {
      setAddToastError(true);
      console.log(error);
    }
  }

  const handleAddContact = () => {
    //TODO wire up posting a new contact
    let valid = true;
    if (!newContactName) {
      valid = false;
      setAddContactNameError("NO NAME GIVEN");
    } else {
      setAddContactNameError("");
    }
    if (!newContactEmail) {
      valid = false;
      setAddContactEmailError("NO EMAIL GIVEN");
    } else if (!Utils.validEmail(newContactEmail)) {
      valid = false;
      setAddContactEmailError("INVALID EMAIL GIVEN");
    } else if (props.tableData.some((elem) => elem.email === newContactEmail)) {
      valid = false;
      setAddContactEmailError("THERE IS A CONTACT WITH THIS EMAIL ADDRESS");
    } else {
      setAddContactEmailError("");
    }
    if (valid) {
      //TODO: make the post request for a new contact
      handleCloseFlyout();
      postNewContact();
      setAddToastError(false);
      setAddToast(true);
      (tableRef?.current as any)?.softRefresh();
    }
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    const path = history.location.pathname;
    history.push(`${path.substr(0, path.lastIndexOf("/contacts"))}/contactProfile/${row.original.id ?? ""}`);
  }

  return (
    <>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Contacts",
            displayName: "Contacts",
            data: {
              tableData: props.tableData,
              setTableData: props.setTableData,
              fetchCall: contactsClient.queryContacts,
              fetchParser: props.fetchParser,
            },
            columns: COLUMNS,
            handleRowClick: handleRowClick,
            defaultSort: "CONTACTNAME",
            defaultSortToken: "",
            predefinedFilters: [
              {
                route: "contacts",
                searchlightFilter: `(companyId eq '${customerId}') and (isActive eq true)`,
              },
            ],
          },
        ]}
        states={{
          isLoading: props.isLoading,
          setLoading: props.setLoading,
          isError: props.isError,
          setError: props.setError,
          errorMessage: props.errorMessage,
          setErrorMessage: props.setErrorMessage,
        }}
        pagination={{
          pageCount: props.pageCount,
          setPageCount: props.setPageCount,
          pageNumber: props.pageNumber,
          setPageNumber: props.setPageNumber,
          totalCount: props.totalCount,
          setTotalCount: props.setTotalCount,
        }}
        toggles={{
          showSearchbar: false,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
        }}
        headerBtns={[
          {
            component: (
              <Button icon={<PlusSign />} alignIcon="left" onClick={() => setAddContactFlyout((prev: boolean) => !prev)}>
                Add Contact
              </Button>
            ),
            hideOnRowSelect: false,
          },
          {
            component: (
              <Button icon={<Email />} variant="secondary" alignIcon="left" onClick={() => editContacts()}>
                Edit
              </Button>
            ),
            hideOnRowSelect: false,
          },
        ]}
        rowSelectBtns={[
          {
            icon: <Delete />,
            variant: "secondary",
            alignIcon: "left",
            children: "Delete",
            callback: (ids) => setDeleteContacts(ids),
          },
        ]}
      />
      <Drawer anchor={"right"} open={addContactFlyout} onClose={handleCloseFlyout}>
        <div className={"invite-flyout-wrapper"}>
          <pre className={"header"}>{"Add Contact"}</pre>
          <Input
            className="sb-wrapper bottom"
            value={newContactName}
            label={{ regularText: "Name", extraText: "" }}
            placeholder=""
            onChange={(val: string) => setNewContactName(val)}
            errorMessage={addContactNameError}
          />
          <Dropdown
            header={"Role"}
            options={[
              { value: "AP Specialist", onClickValue: "AP Specialist", id: "1" },
              { value: "AP Manager", onClickValue: "AP Manager", id: "2" },
              { value: "AP Sales Representative", onClickValue: "AP Sales Representative", id: "3" },
              { value: "AR Specialist", onClickValue: "AR Specialist", id: "4" },
              { value: "AR Manager", onClickValue: "AR Manager", id: "5" },
              { value: "CFO", onClickValue: "CFO", id: "6" },
              { value: "CEO", onClickValue: "CEO", id: "7" },
              { value: "Other", onClickValue: "Other", id: "8" },
              { value: "Unknown", onClickValue: "Unknown", id: "9" },
            ]}
            displayValue={newContactRole}
            icon={<DropdownArrow />}
            onClick={(onClickValue: string) => setNewContactRole(onClickValue)}
          />
          <Input
            className="sb-wrapper bottom"
            label={{ regularText: "Email", extraText: "" }}
            placeholder=""
            value={newContactEmail}
            onChange={(val: string) => setNewContactEmail(val.trim())}
            errorMessage={addContactEmailError}
          />
          <Input
            className="sb-wrapper bottom"
            label={{ regularText: "Phone Number ", extraText: "(Optional)" }}
            placeholder=""
            value={newContactPhone}
            onChange={(val: string) => setNewContactPhone(val.trim())}
          />
          <div className="checkbox-wrapper">
            <React.Fragment>
              <input
                type="checkbox"
                className="is-primary-checkbox"
                checked={newContactPrimary}
                onChange={() => setNewContactPrimary(!newContactPrimary)}
              />
            </React.Fragment>
            <span className="contact-text">Make Primary Contact</span>
          </div>
          <div className={"add-contact-btn-grp"}>
            <Button className={"invite-btn"} variant="secondary" onClick={() => handleCloseFlyout()}>
              Cancel
            </Button>
            <Button className={"invite-btn"} onClick={() => handleAddContact()}>
              Add Contact
            </Button>
          </div>
        </div>
      </Drawer>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={addToast} onClose={() => setAddToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setAddToast(false)}
          severity={addToastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setAddToast(false)}>
              CLOSE
            </p>
          }
        >
          {addToastError ? "Failed" : "Contact Added"}
        </Alert>
      </Snackbar>
      {showDeleteModal && (
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <div className="as-modal">
            <h3>Delete Contacts</h3>
            <p>
              Are you sure you want to delete {deleteIds.length} contact{deleteIds.length === 1 ? "" : "s"}
            </p>
            <div className="btn-grp">
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="error" onClick={() => handleDeleteContacts()}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={toast} onClose={() => setToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "Deletion Failed" : deleteIds.length > 1 ? "Contacts Deleted" : "Contact Deleted"}
        </Alert>
      </Snackbar>
    </>
  );
}
