import React from "react";
import FlyoutButton from "../FlyoutButton/FlyoutButton";
import { Attachment, EmailSettings, UserPic } from "../../library/Icons/Icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./TeamSettings.scss";

export default function TeamSettings(): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  return (
    <div className={"teamSettings-wrapper"}>
      {/* Account Software Settings */}
      <FlyoutButton
        icon={<Attachment />}
        header="Accounting Software Settings"
        subHeader="Manage connections to your accounting software."
        onClick={() => history.push(`${path.substr(0, path.lastIndexOf("/"))}/accountingSoftware`)}
      />
      {/* Email Settings */}
      <FlyoutButton
        icon={<EmailSettings />}
        header="Email Settings"
        subHeader="Manage email setup and delivery for your workspaces."
        onClick={() => history.push(`${path.substr(0, path.lastIndexOf("/"))}/emailSettings`)}
      />
      {/* Users and Permissions */}
      <FlyoutButton
        icon={<UserPic />}
        header="Users and Permissions"
        subHeader="Add or remove team members from Lockstep and manage user permissions."
        onClick={() => history.push(`${path.substr(0, path.lastIndexOf("/"))}/userPermissions`)}
      />
    </div>
  );
}
