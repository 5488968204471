import React, { useState, useEffect } from "react";
import Arrow from "../../../library/Arrow/Arrow";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import "./DaysSalesOutstanding.scss";
import NoData from "../../../library/NoData/NoData";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";

/**
 * * Defines the Days Sales Outstanding Widget
 */
export default function DaysSalesOutstanding(): React.ReactElement {
  const { getDSOData } = React.useContext(DashboardContext) as DashboardType;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<GraphData[]>([]);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Reports/dailysalesoutstanding
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getDSOData();
      res.forEach((value) => {
        value.id = value.id.substr(0, 3);
      });
      if (res && res.some((elem) => elem.value !== 0)) {
        setData(res);
      } else {
        setData([]);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    // API call finalized
    setLoading(false);
  };

  const handleOnClick = (id: string) => {
    const d = new Date();
    const year = d.getFullYear();
    const route = id === "" ? "" : `/${id.toLowerCase()}_${year}`;
    history.push(`${path}/days_sales_outstanding${route}?sort=DSO+DESC%2C+COMPANYNAME+DESC`);
  };

  // On mount, fetch DSO Data
  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className={`dso-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`dso-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === 0 ? (
    <div className={`dso-wrapper`}>
      <div className={`dso-top`}>
        <p className={`title`}>Days Sales Outstanding (DSO)</p>
        <Tooltip title="The average number of days that it takes for an invoice to be fully paid." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`dso-wrapper`}>
      <div className={`dso-top`}>
        <p className={`title`} onClick={() => handleOnClick("")}>
          Days Sales Outstanding (DSO)
        </p>
        <Tooltip title="The average number of days that it takes for an invoice to be fully paid." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={`dso-mid`}>
        <p className={`caption-sm`} style={{ visibility: "hidden" }}>
          Placeholder
        </p>
        <div>
          <p onClick={() => handleOnClick("")} className={`amount`}>
            {data && data.length !== 0 ? `${new Intl.NumberFormat().format(data[data.length - 1].value ?? 0)} Days` : 0}{" "}
          </p>
        </div>
        <div className={`mid-row`}>
          <p className={`caption-sm`}>30 DAY HISTORICAL</p>
          <div className={`arrow-wrapper`}>
            <Arrow
              direction={
                data && data.length >= 2
                  ? (data[data.length - 1].value ?? 0) - (data[data.length - 2].value ?? 0) < 0
                    ? "down"
                    : (data[data.length - 1].value ?? 0) - (data[data.length - 2].value ?? 0) > 0
                    ? "up"
                    : "center"
                  : "up"
              }
            />
            <p className={`arrow-text`}>
              {data && data.length >= 2 ? (data[data.length - 1].value ?? 0) - (data[data.length - 2].value ?? 0) : 0} DAYS
            </p>
          </div>
        </div>
      </div>
      <div className={`dso-bot`}>
        <BarChart
          hoverSquare={false}
          data={data}
          // TODO: Add back bar onClicks after historical DSO data is figured out by Shane and team
          // onClick={(id) => handleOnClick(id)}
          onClick={() => handleOnClick("")}
          padding={45}
          margin={
            data.length === 1
              ? {
                  top: 0,
                  right: 16,
                  bottom: 85,
                  left: calculateLeftMargin(data),
                }
              : data.length === 2
              ? {
                  top: 0,
                  right: 16,
                  bottom: 50,
                  left: calculateLeftMargin(data),
                }
              : data.length === 3
              ? {
                  top: 0,
                  right: 16,
                  bottom: 16,
                  left: calculateLeftMargin(data),
                }
              : undefined
          }
          hoverFormat="days"
        />
      </div>
    </div>
  );
}
