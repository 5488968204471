import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";

import Button from "../../library/Button/Button";
import { Input } from "../../library/Input/Input";
import { BackArrow } from "../../library/Icons/Icons";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { DataMode, OnboardStep } from "../../../types/enums";
import { countries } from "../../../Countries";
import Autocomplete from "../../library/Autocomplete/Autocomplete";

type EditCompanyInfoProps = {
  showCancel: boolean;
};

function EditCompanyInfo(props: EditCompanyInfoProps): React.ReactElement {
  const { setOnboardStep, setDataMode } = React.useContext(OnboardContext) as OnboardType;
  const { setOnboardData, onboardData } = React.useContext(AppContext) as AppType;
  const [countryList] = useState<string[]>(countries.map((country) => country.label));
  const companyNameRef = useRef<HTMLInputElement>();

  const [company, setCompany] = useState<Partial<CompanyModel>>({
    companyName: onboardData.company.companyName || "",
    phoneNumber: onboardData.company.phoneNumber || "",
    faxNumber: onboardData.company.faxNumber || "",
    address1: onboardData.company.address1 || "",
    address2: onboardData.company.address2 || "",
    postalCode: onboardData.company.postalCode || "",
    city: onboardData.company.city || "",
    stateRegion: onboardData.company.stateRegion || "",
    country: onboardData.company.country || "",
  });

  useEffect(() => {
    if (companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, []);

  const disableSave = () => {
    return !(
      company.companyName &&
      company.phoneNumber &&
      company.address1 &&
      company.city &&
      company.stateRegion &&
      company.postalCode &&
      company.country
    );
  };

  const handleSave = () => {
    setOnboardData((prev: OnboardDataModel) => {
      return {
        ...prev,
        company: company,
      };
    });
    setDataMode(DataMode.View);
  };

  const handleCancel = () => {
    setDataMode(DataMode.View);
  };

  const handleBack = () => {
    setDataMode(DataMode.View);
    setOnboardStep(OnboardStep.PersonalInfo);
  };

  return (
    <>
      <Button
        className="back-arrow-button"
        variant="transparent"
        icon={<BackArrow />}
        alignIcon="left"
        onClick={handleBack}
        data-testid="EditYourPersonalInfoBackButton"
      >
        Your Personal Info
      </Button>
      <h2>Enter your company's information.</h2>

      <form onSubmit={(e) => e.preventDefault()} className="data-container" id="EditCompanyInfoForm">
        <div className="form-row">
          <Input
            label={"Company Name"}
            value={company.companyName ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, companyName: value };
              })
            }
            className="form-field companyName-field"
            placeholder="Boone Times"
            data-testid="EditCompanyInfoName"
            inputRef={companyNameRef}
            maxLength={100}
            required
          />
          <Input
            label={"Company Phone Number"}
            value={company.phoneNumber ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, phoneNumber: value };
              })
            }
            placeholder="+1 123.456.7890"
            className="form-field phone-field"
            autocomplete="tel"
            data-testid="EditCompanyInfoPhone"
            type="tel"
            maxLength={20}
            required
          />
          <Input
            label={{ regularText: "Company Fax ", extraText: "(Optional)" }}
            value={company.faxNumber ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, faxNumber: value };
              })
            }
            placeholder="+1 456.789.0123"
            className="form-field fax-field"
            autocomplete="tel"
            data-testid="EditCompanyInfoFax"
            type="tel"
            maxLength={20}
          />
        </div>

        <hr />

        <div className="form-row">
          <Input
            label={"Company Street Address"}
            value={company.address1 ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, address1: value };
              })
            }
            placeholder="122 Main Street"
            className="form-field street-field"
            autocomplete="street-address"
            data-testid="EditCompanyInfoAddressLine1"
            maxLength={80}
            required
          />
          <Input
            label={{ regularText: "Unit or Suite ", extraText: "(Optional)" }}
            value={company.address2 ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, address2: value };
              })
            }
            placeholder="Suite 100"
            className="form-field unit-field"
            data-testid="EditCompanyInfoAddressLine2"
            maxLength={80}
          />
        </div>
        <div className="form-row">
          <Input
            label={"Postal Code"}
            value={company.postalCode ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, postalCode: value };
              })
            }
            placeholder="94016"
            className={"form-field zipcode-field"}
            autocomplete="postal-code"
            data-testid="EditCompanyInfoPostalCode"
            maxLength={10}
            required
          />
          <Input
            label={"City"}
            value={company.city ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, city: value };
              })
            }
            placeholder="San Francisco"
            className={"form-field city-field"}
            autocomplete="address-level2"
            data-testid="EditCompanyInfoCity"
            maxLength={100}
            required
          />
          <Input
            label={"State/Region"}
            value={company.stateRegion ?? undefined}
            onChange={(value) =>
              setCompany((prev: Partial<CompanyModel>) => {
                return { ...prev, stateRegion: value };
              })
            }
            placeholder="CA"
            className={"form-field state-field"}
            autocomplete="address-level1"
            data-testid="EditCompanyInfoState"
            maxLength={20}
            required
          />
        </div>
        <div className="form-row">
          <div>
            <label htmlFor="DefaultCountryInput">Country</label>
            <Autocomplete
              id="DefaultCountryInput"
              data-testid="EditCompanyInfoCountry"
              options={countryList}
              className="form-field country-field"
              onChange={(_event, value: string) => {
                setCompany((prev: Partial<CompanyModel>) => {
                  return { ...prev, country: value };
                });
              }}
              value={company.country}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    autoComplete: "country",
                  }}
                />
              )}
              isOptionEqualToValue={(option: string, value: string) => option === value}
            />
          </div>
        </div>

        <div className="button-row">
          <Button data-testid="EditCompanyInfoSaveButton" disabled={disableSave()} onClick={() => handleSave()}>
            Save Changes
          </Button>

          {props.showCancel && (
            <Button variant={"secondary"} data-testid="EditCompanyInfoCancelButton" onClick={() => handleCancel()}>
              Cancel
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default EditCompanyInfo;
