import React from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";

interface ActivityTitleProps {
    title: string;
    handleTitleSelect: (e: any) => void;
}

export default function ActivityTitle(props: ActivityTitleProps): React.ReactElement {
    const TextFieldTitle = styled(TextField)`
        & .MuiFormControl-root {
            height: 40px;
            margin: 0 !important;
            width: 504px;
        }

        & .MuiFilledInput-root {
            height: 40px;
            background-color: #F7F7FB;
            border-radius: 2px;
            font-weight: 400;
            font-family: Work Sans;
            color: #143B52;
            font-size: 14px;
            line-height: 20px;
            input {
                border: 1px solid transparent;
                border-radius: 2px;
                padding: 10px 12px;
                &::placeholder {
                    color: #8592AD;
                }
                &:hover {
                    background-color: #f2f3f8;
                }
                &:focus {
                    border: 1px solid #A1ADC4;
                }
            }
        }
    `

    return (
        <TextFieldTitle
            onBlur={props.handleTitleSelect}
            defaultValue={props.title}
            style={{ 
                margin: 0,
                width: "504px", 
            }}
            InputProps={{
                disableUnderline: true,
            }}
            margin="normal"
            placeholder={"Title"}
            variant="filled"
        />
    );
}