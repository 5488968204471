import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Switch, useRouteMatch, Route, useParams, useLocation } from "react-router-dom";
import { CustomerContext } from "../../contexts/CustomerContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CustomerDetail from "./CustomerDetail/CustomerDetail";
import CustomerTable from "./CustomerTable/CustomerTable";
import Navbar from "../library/Navbar/Navbar";
import "./Customers.scss";
import ActivityDetail from "../Activities/ActivityDetail/ActivityDetail";

export default function Customers(): React.ReactElement {
  const location = useLocation();
  const { path } = useRouteMatch();
  const { company } = React.useContext(CustomerContext) as CustomerType;
  const { inboxContacts, inboxDocuments } = useFlags();

  return (
    <div className={`customers-wrapper`}>
      <div className={`head`}>
        <Switch>
          <Route
            exact
            path={[`${path}`]}
            render={() => {
              return (
                <Navbar
                  tabs={[
                    {
                      displayName: "All Customers",
                      routeName: "?sort=AMOUNTPASTDUE+DESC%2C+COMPANYNAME+DESC",
                    },
                  ]}
                  rootPath={path}
                />
              );
            }}
          />
          <Route
            path={`${path}/:customerId/contactProfile/:contactId`}
            component={() => {
              const { customerId, contactId } = useParams<{ customerId: string; contactId: string }>();
              return (
                <Navbar
                  tabs={[{ displayName: "Profile", routeName: "" }]}
                  rootPath={`${path}/${customerId}/contactProfile/${contactId}`}
                  backDisplayName="Contacts"
                  backRoute={path + `/${customerId}/contacts/?sort=CONTACTNAME` ?? path}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/:customerId/activities/:activityId`}
            component={() => {
              const { customerId } = useParams<{ customerId: string }>();
              return (
                <Navbar
                  tabs={[
                    { displayName: "Activities", routeName: "" },
                    { displayName: "Customer Profile", routeName: "profile" },
                    {
                      displayName: "Open Invoices",
                      routeName: "open_invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                    {
                      displayName: "Closed Invoices",
                      routeName: "closed_invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                    {
                      displayName: "Payments",
                      routeName: "payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
                    },
                    {
                      displayName: inboxContacts ? "Contacts" : "",
                      routeName: "contacts",
                    },
                    {
                      displayName: inboxDocuments ? "Documents" : "",
                      routeName: "documents",
                    },
                  ]}
                  rootPath={`${path}/${customerId}`}
                  dropdown={[{ displayName: company?.name ?? "N/A", routeName: "" }]}
                  dropdownDefault={company?.name ?? "N/A"}
                  hideDropdown={true}
                  backDisplayName={company?.name ?? undefined}
                  backRoute={`${path}/${customerId}?sort=DATERECEIVED+DESC`}
                />
              );
            }}
          />
          <Route
            path={`${path}/:customerId`}
            component={() => {
              const { customerId } = useParams<{ customerId: string }>();
              return (
                <Navbar
                  tabs={[
                    { displayName: "Activities", routeName: "" },
                    { displayName: "Customer Profile", routeName: "profile" },
                    {
                      displayName: "Open Invoices",
                      routeName: "open_invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                    {
                      displayName: "Closed Invoices",
                      routeName: "closed_invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                    {
                      displayName: "Payments",
                      routeName: "payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
                    },
                    {
                      displayName: inboxContacts ? "Contacts" : "",
                      routeName: "contacts",
                    },
                    {
                      displayName: inboxDocuments ? "Documents" : "",
                      routeName: "documents",
                    },
                  ]}
                  rootPath={`${path}/${customerId}`}
                  dropdown={[{ displayName: company?.name ?? "N/A", routeName: "" }]}
                  dropdownDefault={company?.name ?? "N/A"}
                  hideDropdown={true}
                  backDisplayName="Customers"
                  backRoute={path.substr(0, 3) + "/customers?sort=AMOUNTPASTDUE+DESC%2C+COMPANYNAME+DESC" ?? path}
                />
              );
            }}
          />
        </Switch>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true }}>
        <div className={`body`}>
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={() => {
                return <CustomerTable />;
              }}
            />
            <Route
              exact
              path={`${path}/:customerId/activities/:activityId`}
              render={() => {
                return <ActivityDetail />;
              }}
            />
            <Route
              path={`${path}/:customerId`}
              render={() => {
                return <CustomerDetail key={location.pathname.split("/")[3]} />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
