import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useState } from "react";
import { formatDate } from "../../../../db/utils/date";
import Button from "../../../library/Button/Button";
import Chip from "../../../library/Chip/Chip";
import { Reply, ReplyFilled, Note, Phone, Forward, MarkAsUnread, UserPic } from "../../../library/Icons/Icons";
import { default as AddNote } from "./ActivityFeedActions/ActivityAddNote";
import { default as AddPhoneCall } from "./ActivityFeedActions/ActivityAddPhone";
import "./ActivityFeed.scss";
import { default as Reassign } from "./ActivityFeedActions/ActivityReassign";
import { default as Move } from "./ActivityFeedActions/ActivityMove";
import ActivityClose from "./ActivityFeedActions/ActivityClose";
import ActivityArchive from "./ActivityFeedActions/ActivityArchive";
import { useHistory, useParams } from "react-router";
import { activitiesClient } from "../../../../db/accessor";
import { ActivityStatus } from "../../../../types/enums";
import NewActivity from "../../ActivitiesTable/NewActivity/NewActivity";

// eslint-disable-next-line @typescript-eslint/ban-types
type FeedProps = {
  header: string;
  to: string;
  date?: string;
  content: string;
  isNew: boolean;
  variant: "phone" | "note" | "reply" | "message";
  icon?: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Feed(props: FeedProps): React.ReactElement {
  return (
    <div className="feed-wrapper">
      <div className="head">
        <div className="icon">
          {props.icon ??
            (props.variant === "phone" ? (
              <Phone />
            ) : props.variant === "note" ? (
              <Note />
            ) : props.variant === "reply" ? (
              <ReplyFilled />
            ) : props.variant === "message" ? (
              <UserPic />
            ) : (
              ""
            ))}
        </div>
      </div>
      <div className="body">
        <div className="header">
          <p className="body2">{props.header}</p>
          {props.isNew && <Chip text="NEW" variant="late1" />}
        </div>
        <p className="subheader caption">{`To ${props.to}${props.date ? ` • ${formatDate(props.date)}` : ""}`}</p>
        <div className="content body1">{props.content}</div>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityFeedProps = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityFeed(props: ActivityFeedProps): React.ReactElement {
  const [addNoteValue, setAddNoteValue] = useState<string>("");

  const [addPhoneRecipient, setAddPhoneRecipent] = useState<string>("");
  const [addPhoneNoteValue, setAddPhoneNoteValue] = useState<string>("");

  const [reassignNoteValue, setReassignNoteValue] = useState<string>("");

  const [closeNoteValue, setCloseNoteValue] = useState<string>("");
  const [closeReason, setCloseReason] = useState<string>("");

  const [archiveNoteValue, setArchiveNoteValue] = useState<string>("");
  const [archiveReason, setArchiveReason] = useState<string>("");

  const { customerId, activityId } = useParams<{ customerId: string; activityId: string }>();
  const history = useHistory();

  const [showReply, setShowReply] = useState<boolean>(false);
  const [showForward, setShowForward] = useState<boolean>(false);

  const handleReply = () => {
    if (showForward) {
      setShowForward(false);
    }
    setShowReply(true);
  };
  const handleForward = () => {
    if (showReply) {
      setShowReply(false);
    }
    setShowForward(true);
  };

  const handleUnread = async () => {
    try {
      await activitiesClient.patch(activityId, {
        activityStatus: ActivityStatus.Active,
        isOpen: true,
        isArchived: false,
        isUnread: true,
      });

      if (customerId) {
        // We visited by way of the contact detail page
        history.push(`/AR/customers/${customerId}`);
      } else {
        // We came from an activity detail go up one level
        history.push(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <div className="activity-feed-wrapper">
      <div className="head">
        <div className="head-grp">
          <Button size="sm" icon={<Reply />} alignIcon="right" onClick={() => handleReply()}>
            Reply
          </Button>
          <AddNote activityId={activityId} noteValue={addNoteValue} setNoteValue={setAddNoteValue} />
          <AddPhoneCall
            activityId={activityId}
            noteValue={addPhoneNoteValue}
            setNoteValue={setAddPhoneNoteValue}
            recipientValue={addPhoneRecipient}
            setRecipientValue={setAddPhoneRecipent}
          />
        </div>
        <div className="head-grp">
          <Button
            icon={<Forward />}
            onClick={() => handleForward()}
            variant={"solo-icon"}
            alignIcon={"left"}
            tooltip={"Forward"}
            className={"detail-icon-btn"}
          />

          <Reassign activityId={activityId} noteValue={reassignNoteValue} setNoteValue={setReassignNoteValue} />
          <Move activityId={activityId} noteValue={reassignNoteValue} setNoteValue={setReassignNoteValue} />

          <ActivityArchive
            activityId={activityId}
            noteValue={archiveNoteValue}
            setNoteValue={setArchiveNoteValue}
            reason={archiveReason}
            setReason={setArchiveReason}
          />
          <Button
            icon={<MarkAsUnread />}
            onClick={() => handleUnread()}
            variant={"solo-icon"}
            alignIcon={"left"}
            tooltip={"Mark as unread"}
            className={"detail-icon-btn"}
          />
          <ActivityClose
            activityId={activityId}
            noteValue={closeNoteValue}
            setNoteValue={setCloseNoteValue}
            reason={closeReason}
            setReason={setCloseReason}
          />
        </div>
      </div>
      <div className="body">
        <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
          <div>
            <Feed header="Phone Call" to="Raychel Green" date="2021-04-05" content="Raychel was called, no answer" isNew={true} variant="phone" />
            <Feed header="Add Note" to="Me" date="2021-04-04" content="It's time to call Raychel." isNew={true} variant="note" />
            <Feed header="Blue Bottle Coffee AR" to="Raychel Green" date="2021-04-04" content="AR Reply to AP" variant="message" isNew={true} />
            <Feed
              header="Raychel Replied to you"
              to="Me"
              date="2021-04-01"
              content="ipsum quia dolor sit amet, consectetur"
              variant="reply"
              isNew={false}
            />
            <Feed
              header="Blue Bottle Coffee AR"
              to="Raychel Green"
              date="2021-04-01"
              content="Hi Kennedy Corporation, Blushing Blooms uses Lockstep to onboard new customer, you should have recieved an invoice from Lockstep Self Serve containing your invoice. "
              variant="message"
              isNew={false}
            />
            <Feed header="Phone Call" to="Raychel Green" date="2021-04-05" content="Raychel was called, no answer" isNew={false} variant="phone" />
            <Feed header="Add Note" to="Me" date="2021-04-04" content="It's time to call Raychel." isNew={true} variant="note" />
            <Feed header="Blue Bottle Coffee AR" to="Raychel Green" date="2021-04-04" content="AR Reply to AP" variant="message" isNew={false} />
            <Feed
              header="Raychel Replied to you"
              to="Me"
              date="2021-04-01"
              content="ipsum quia dolor sit amet, consectetur"
              variant="reply"
              isNew={false}
            />
            <Feed
              header="Blue Bottle Coffee AR"
              to="Raychel Green"
              date="2021-04-01"
              content="Hi Kennedy Corporation, Blushing Blooms uses Lockstep to onboard new customer, you should have recieved an invoice from Lockstep Self Serve containing your invoice. "
              variant="message"
              isNew={false}
            />
          </div>
        </OverlayScrollbarsComponent>
      </div>
        <NewActivity 
          // TODO: wireup title
          title={"Payment Due For Invoice #123"} 
          open={showReply}
          // TODO: wire up options
          contactOptions={[
            { id: "1", label: "Raychel Green" }, 
            { id: "2", label: "Jenny Wilson" }, 
            { id: "3", label: "Robert Fox" }, 
            { id: "4", label: "Cameron Williamson" }, 
            { id: "5", label: "Jerome Bell" }
          ]} 
          defaultTo={[{ id: "1", label: "Raychel Green"}]}
          replyOptions={{ 
            replyButton: true, 
            start: "Reply", 
            // TODO: wire up reply/reply all options
            reply: [{ id: "1", label: "Raychel Green"}], 
            replyAll: [
              { id: "1", label: "Raychel Green" }, 
              { id: "2", label: "Jenny Wilson" }, 
              { id: "3", label: "Robert Fox" }, 
              { id: "4", label: "Cameron Williamson" }, 
              { id: "5", label: "Jerome Bell" }
            ]
          }}
          // TODO: wire up send/ send mark as closed
          handleSend={() => 0} 
          handleSendMarkClosed={() => 0}
          onClose={() => setShowReply(false)} 
        />
        <NewActivity 
          // TODO: wireup title
          title={"Forward: Payment Due For Invoice #123"} 
          open={showForward}
          //TODO: wireup information (to, title, body, files) from previous activity
          defaultTo={[{ id: "1", label: "Raychel Green"}]}
          defaultTitle={"Forward: Title"}
          defaultBody={
          `Hey,
            This is an activity!
                      Thanks!
          `}
          defaultFiles={[]}
          // TODO: wire up options
          contactOptions={[
            { id: "1", label: "Raychel Green" }, 
            { id: "2", label: "Jenny Wilson" }, 
            { id: "3", label: "Robert Fox" }, 
            { id: "4", label: "Cameron Williamson" }, 
            { id: "5", label: "Jerome Bell" }
          ]}
          // TODO: wire up send/ send mark as closed
          handleSend={() => 0} 
          handleSendMarkClosed={() => 0}
          onClose={() => setShowForward(false)} 
        />
    </div>
  );
}
