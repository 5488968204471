import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-common";

import {msalInstance} from "../index";
import {loginRequest, silentRequest, silentRequestForgotPassword} from "../auth/authConfig";

const singletonEnforcer = Symbol("APIClientSingletonEnforcer");

type ParamsWithNoData = [url: string, config?: AxiosRequestConfig, overrideMSAL?: boolean];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParamsWithData = [url: string, data?: any, config?: AxiosRequestConfig, overrideMSAL?: boolean];

class APIClient {
  private static clientInstance: APIClient;

  private readonly session: AxiosInstance;

  constructor(enforcer: symbol) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    const axiosConfig = {
      baseURL: process.env.REACT_APP_API_ENDPOINT + "/",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "-1",
      }
    }

    this.session = axios.create(axiosConfig);
  }

  static get instance() {
    if (!this.clientInstance) {
      this.clientInstance = new APIClient(singletonEnforcer);
    }

    return this.clientInstance;
  }

  private async getToken() {
    const account = msalInstance.getActiveAccount();

    return await msalInstance.acquireTokenSilent({
        // Change silent request depending on the account's policy
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((account?.idTokenClaims as any)?.acr === "b2c_1a_passwordreset" ? silentRequestForgotPassword : silentRequest), account: account ?? undefined
      })
      .then((res: AuthenticationResult) => {
        return res?.accessToken ?? "";
      })
      // Silent request fails, default to interactive login
      .catch((error) => {
        console.log(error);

        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(loginRequest).catch((acquireTokenError) => {
            console.log(acquireTokenError);
          });
        }
      });
  }

  private async getConfig(config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    // We set some config defaults and then let the caller provide additional ones or override those defaults
    let requestConfig: AxiosRequestConfig = {
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json"
      }
    };

    if (config) {
      requestConfig = { ...requestConfig, ...config };
    }

    if (overrideMSAL === undefined || !overrideMSAL) {
      requestConfig.headers["Authorization"] = `Bearer ${await this.getToken()}`;
    }

    return requestConfig;
  }

  private async makeGetRequest(url: string, config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    const requestConfig = await this.getConfig(config, overrideMSAL);
    return this.session.get(url, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePostRequest(url: string, data: any, config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    const requestConfig = await this.getConfig(config, overrideMSAL);
    return this.session.post(url, data, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePutRequest(url: string, data: any, config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    const requestConfig = await this.getConfig(config, overrideMSAL);
    return this.session.put(url, data, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePatchRequest(url: string, data: any, config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    const requestConfig = await this.getConfig(config, overrideMSAL);
    return this.session.patch(url, data, requestConfig);
  }

  private async makeDeleteRequest(url: string, config?: AxiosRequestConfig, overrideMSAL?: boolean) {
    const requestConfig = await this.getConfig(config, overrideMSAL);
    return this.session.delete(url, requestConfig);
  }

  get = (...params: ParamsWithNoData) => this.makeGetRequest(...params);
  post = (...params: ParamsWithData) => this.makePostRequest(...params);
  put = (...params: ParamsWithData) => this.makePutRequest(...params);
  patch = (...params: ParamsWithData) => this.makePatchRequest(...params);
  delete = (...params: ParamsWithNoData) => this.makeDeleteRequest(...params);
}

export default APIClient.instance;
