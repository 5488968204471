/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState, Dispatch, FormEvent, useRef } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Modal, TextField } from "@mui/material";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../library/Button/Button";
import { Edit as EditSVG, Email, Fax, Location, Notes, Phone, Position, Save, Upload } from "../../../library/Icons/Icons";
import { DataMode } from "../../../../types/enums";
import { Input } from "../../../library/Input/Input";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { countries } from "../../../../Countries";
import { AppContext } from "../../../../contexts/AppContext";
import { userAccountsClient } from "../../../../db/accessor";
import { formatDate } from "../../../../db/utils/date";

type EditMyProfileProps = {
  setDataMode: Dispatch<DataMode>;
  defaultData: UserAccountModel;
  setDefaultData: Dispatch<UserAccountModel>;
  setShowToast: Dispatch<boolean>;
  setToastError: Dispatch<boolean>;
  codeDefinitions: CodeDefinitionModel[];
};

export default function EditMyProfile(props: EditMyProfileProps): React.ReactElement {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [myProfileInfo, setMyProfileInfo] = useState<UserAccountModel>(props.defaultData);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [saveLoad, setSaveLoad] = useState<boolean>(false);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const handleSave = async () => {
    const {
      userId,
      groupKey,
      status,
      created,
      createdUserId,
      modified,
      modifiedUserId,
      modifiedUserName,
      b2CUserId,
      userRole,
      inviteSent,
      notes,
      attachments,
      customFieldDefinitions,
      customFieldValues,
      accountingRoleCodeDefinition,
      // TODO: Remove imageURL from non-editables after API allows uploading images
      imageURL,
      ...editableProfileFields
    } = myProfileInfo;
    try {
      setSaveLoad(true);
      await userAccountsClient.patchUserAccount(userId, editableProfileFields);
      props.setDefaultData(myProfileInfo);
      props.setToastError(false);
    } catch (e) {
      props.setToastError(true);
    }
    props.setDataMode(DataMode.View);
    props.setShowToast(true);
    setSaveLoad(false);
    return false;
  };

  const handleUpload = () => {
    console.log("handleUpload");
  };

  return (
    <div className="edit-profile">
      <div className="profile-edit-bar">
        <div className="btn-grp">
          <Button icon={<Save />} variant="primary" size="lg" alignIcon={"left"} onClick={() => submitBtnRef.current?.click()} loading={saveLoad}>
            Save
          </Button>
          <Button variant="secondary" size="lg" onClick={() => setShowCancel(true)} disabled={saveLoad}>
            Cancel
          </Button>
          {/* TODO: Move to reusable component */}
          <Modal open={showCancel} onClose={() => setShowCancel(false)}>
            <div className="as-modal">
              <h3>Cancel Changes?</h3>
              <p>Are you sure you want to cancel your changes?</p>
              <div className="btn-grp">
                <Button variant="secondary" onClick={() => setShowCancel(false)}>
                  Close
                </Button>
                <Button
                  variant="error"
                  onClick={() => {
                    setMyProfileInfo(props.defaultData);
                    props.setDataMode(DataMode.View);
                  }}
                >
                  Yes, Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="last-updated">
          <p>Updated {formatDate(myProfileInfo?.modified)}</p>
          <p>by {myProfileInfo?.modifiedUserId === userStatus.userId ? "You" : myProfileInfo?.modifiedUserName}</p>
        </div>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
        <form
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            handleSave();
            return false;
          }}
          className="container"
          id="EditMyProfileForm"
        >
          <div className="name-row">
            <Userpic id="user-pic" />
            <Button
              icon={<Upload />}
              variant="secondary"
              size="lg"
              alignIcon={"left"}
              className="upload-btn"
              onClick={() => handleUpload()}
              type="button"
            >
              Upload Photo
            </Button>
          </div>
          <div className="form-row">
            <div className="tag">
              <EditSVG />
            </div>
            <Input
              label={"Name (Required)"}
              defaultValue={myProfileInfo.userName}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, userName: value };
                })
              }
              className={"field-md"}
              placeholder="Chelsea Henley"
              data-testid="EditMyName"
              required
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Position />
            </div>
            <div className="sb-wrapper">
              <span className="sb-label-container">
                <label className="sb-label">Role</label>
              </span>
              <Autocomplete
                className="field-md"
                options={props.codeDefinitions.map((codeDef: CodeDefinitionModel) => {
                  return {
                    id: codeDef.codeDefinitionId,
                    label: codeDef.codeDescription,
                  };
                })}
                onChange={(_: any, value: { id: string; label: string }) => {
                  setMyProfileInfo((prev: UserAccountModel) => {
                    return {
                      ...prev,
                      accountingRoleCodeDefId: value.id,
                      accountingRoleCodeDefinition: props.codeDefinitions.find((elem) => elem.codeDefinitionId === value.id) ?? null,
                    };
                  });
                }}
                value={myProfileInfo.accountingRoleCodeDefinition?.codeDescription || null}
                disableClearable
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.label === (value.label || value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </div>
            <Input
              label={"Title"}
              defaultValue={myProfileInfo.title}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, title: value };
                })
              }
              className={"title-field"}
              placeholder="AR Associate"
              data-testid="EditTitleName"
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Email />
            </div>
            <Input
              label={"Email"}
              defaultValue={myProfileInfo.email}
              onChange={() => null}
              className={"field-md"}
              placeholder="ap@gekko.com"
              data-testid="EditEmail"
              disabled
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Phone />
            </div>
            <Input
              label={"Phone"}
              defaultValue={myProfileInfo.phoneNumber}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, phoneNumber: value };
                })
              }
              className={"field-md"}
              placeholder="(123) 456-7890"
              data-testid="EditPhone"
              maxLength={20}
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Fax />
            </div>
            <Input
              label={"Fax"}
              defaultValue={myProfileInfo.faxNumber}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, faxNumber: value };
                })
              }
              className={"field-md"}
              placeholder="(123) 456-7890"
              data-testid="EditFax"
              maxLength={20}
            />
          </div>
          <div className="form-row">
            <div className="tag">
              <Location />
            </div>
            <Input
              label={"Street Address"}
              defaultValue={myProfileInfo.address1}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, address1: value };
                })
              }
              className={"street-field"}
              placeholder="123 Main Street"
              data-testid="EditStreetAddr"
            />
            <Input
              label={"Unit or Suite"}
              defaultValue={myProfileInfo.address2}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, address2: value };
                })
              }
              className={"field-sm addr-field"}
              placeholder="2"
              data-testid="EditStreetAddr"
            />
          </div>
          <div className="form-row address-row">
            <Input
              label={"Postal Code"}
              defaultValue={myProfileInfo.postalCode}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, postalCode: value };
                })
              }
              className={"field-sm no-icon-field"}
              placeholder="94016"
              data-testid="EditZipcode"
              maxLength={10}
            />
            <Input
              label={"City"}
              defaultValue={myProfileInfo.city}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, city: value };
                })
              }
              className={"city-field addr-field"}
              placeholder="San Fransisco"
              data-testid="EditCity"
            />
            <Input
              label={"State/Region"}
              defaultValue={myProfileInfo.stateRegion}
              onChange={(value) =>
                setMyProfileInfo((prev: UserAccountModel) => {
                  return { ...prev, stateRegion: value };
                })
              }
              className={"field-sm addr-field"}
              placeholder="CA"
              data-testid="EditState"
            />
          </div>
          <div className="form-row address-row">
            <div className="sb-wrapper no-icon-field">
              <div className="sb-label-container">
                <label className="sb-label" htmlFor="DefaultCountryInput">
                  Country
                </label>
              </div>
              <Autocomplete
                options={countries}
                className="country-field"
                onChange={(_: any, value: { id: string; label: string }) => {
                  if (value) {
                    setMyProfileInfo((prev: UserAccountModel) => {
                      return { ...prev, country: value.label };
                    });
                  } else {
                    setMyProfileInfo((prev: UserAccountModel) => {
                      return { ...prev, country: "United States" };
                    });
                  }
                  ``;
                }}
                value={myProfileInfo.country}
                disableClearable
                isOptionEqualToValue={(option: any, value: any) => option.label === value}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      autoComplete: "country",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="tag">
              <Notes />
            </div>
            <div className="sb-wrapper notes-field">
              <span className="sb-label-container">
                <label className="sb-label">Description</label>
              </span>
              <textarea
                className="sb-input notes-field"
                placeholder={"Enter notes"}
                defaultValue={myProfileInfo.description ?? ""}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setMyProfileInfo((prev: UserAccountModel) => {
                    return { ...prev, description: e.target.value };
                  });
                }}
              ></textarea>
            </div>
          </div>
          <button ref={submitBtnRef} type="submit" style={{ display: "none" }}>
            submit
          </button>
        </form>
      </OverlayScrollbarsComponent>
    </div>
  );
}
