import React, { useState } from "react";

// TODO : Timezone moved to GA
// import React, { useState } from "react";
import Flyout from "../Flyout/Flyout";
// import Dropdown from "../../library/Dropdown/Dropdown";
// import { Lock, Clock } from "../../../components/library/Icons/Icons";
import { msalInstance } from "../../..";
import FlyoutButton from "../FlyoutButton/FlyoutButton";
import { Lock } from "../../library/Icons/Icons";
import "./MySettings.scss";
import { Alert, Snackbar } from "@mui/material";

export default function MySettings(): React.ReactElement {
  const [passwordFlyout, setPasswordFlyout] = useState<boolean>(false);
  const [showPasswordSuccess, setPasswordSuccess] = useState<boolean>(false);

  // TODO: Timezone moved to GA
  // const [timezoneFlyout, setTimezoneFlyout] = useState<boolean>(false);

  window.onmessage = function (event) {
    if (event?.data?.type === "cancel_password_reset") {
      setPasswordFlyout(false);
    } else if (event?.data?.type === "forgot_success") {
      setPasswordFlyout(false);
      setPasswordSuccess(true);
    }
  };

  const changePasswordInstructions = `The email address registered with\nyour account is ${msalInstance.getActiveAccount()?.username}.`;
  // TODO: When the profile features are ready, include the text below at the end of the instructions
  // \nTo update your email address go to\nMy Profile.

  return (
    <div className={"mySettings-wrapper"}>
      <FlyoutButton
        header="Sign-in and security"
        subHeader="Change your password"
        icon={<Lock />}
        onClick={() => setPasswordFlyout((prev: boolean) => !prev)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showPasswordSuccess}
        onClose={() => setPasswordSuccess(false)}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setPasswordSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setPasswordSuccess(false)}>
              CLOSE
            </p>
          }
        >
          Password Changed
        </Alert>
      </Snackbar>
      <Flyout isOpen={passwordFlyout} onClose={() => setPasswordFlyout(false)}>
        <div className={"timezone-flyout-wrapper"}>
          <h3 className={"header"}>Change Your Password</h3>

          <pre className={"description"}>
            {changePasswordInstructions}
          </pre>

          <iframe
            frameBorder="0"
            width="100%"
            height="100%"
            src={`https://${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${
              process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY
            }/oauth2/v2.0/authorize?p=B2C_1A_EMBEDDEDPASSWORDRESET&client_id=${
              process.env.REACT_APP_AZURE_CLIENT_ID
            }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
              window.location.origin + "/signupSuccess"
            )}&scope=openid&response_type=id_token&prompt=login&response_mode=query&login_hint=${msalInstance.getActiveAccount()?.username}`}
          />
        </div>
      </Flyout>

      {/* TODO: Timezone Flyout */}
      {/* <FlyoutButton
        icon={<Clock />}
        header="Timezone"
        subHeader="Use the browser default or select a specific timezone that you work in."
        onClick={() => setTimezoneFlyout((prev: boolean) => !prev)}
      />
      <Flyout isOpen={timezoneFlyout} onClose={() => setTimezoneFlyout(false)}>
        <div className={"timezone-flyout-wrapper"}>
          <h3 className={"header"}>Set Your Timezone</h3>
          <pre className={"description"}>
            {"Use the dropdown below to select\nyour preferred timezone."}
          </pre>
          <Dropdown
            className={"timezone-dropdown"}
            header="Your Timezone"
            options={[
              { value: "System default", id: "OS" }
            ]}
          />
          <div className={"timezone-btn-grp"}>
            <Button
              className={"timezone-btn"}
              variant="secondary"
              onClick={() => setTimezoneFlyout(false)}
            >
              Cancel
            </Button>
            <Button disabled className={"timezone-btn"}>
              Save
            </Button>
          </div>
        </div>
      </Flyout> */}
    </div>
  );
}
