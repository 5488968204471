import APIClient from "../APIClient";

export class UserRolesClient {
  /**
   * * GET on /api/v1/UserRoles/{id}
   * @param id - Database ID associated with a user
   * @param include
   * @returns Promise of type UserRoleModel or Exception
   */
  get(id: string, include?: string): Promise<UserRoleModel> {
    const url = `api/v1/UserRoles/${id}`;

    const options = {
      params: {
        include,
      },
    };

    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * GET on /api/v1/UserRoles/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type UserRoleModelFetchResult or Exception
   */
  query(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<UserRoleModelFetchResult> {
    const url = "api/v1/UserRoles/query";

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((res) => res.data);
  }
}
