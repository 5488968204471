import React, { useState } from "react";
import { Copy as CopySVG, QuestionMark as QuestionMarkSVG } from "../../library/Icons/Icons";
import Utils from "../../../utils/utils";
import "./Detail.scss";
import { Tooltip, IconButton, Skeleton } from "@mui/material";

type column = {
  title?: string;
  value: string | null;
  format?: "tel" | "email" | "link";
  copyToClipboard?: boolean;
  toolTip?: string;
};

type DetailProps = {
  className?: string;
  icon?: React.ReactNode;
  columns?: column | column[];
  isLoading?: boolean;
};

export default function Detail(props: DetailProps): React.ReactElement {
  const [textFade, setTextFade] = useState("fade-out");

  // Copies email to clipboard and fades in and out the copied text
  function onCopyToClipBoard(email?: string) {
    if (email) {
      navigator.clipboard.writeText(email ?? "N/A");
    } else {
      navigator.clipboard.writeText((props.columns as column).value ?? "N/A");
    }
    setTextFade(() => "fade-in");
    setTimeout(() => {
      setTextFade(() => "fade-out");
    }, 2600);
  }

  if (props.isLoading) {
    return (
      <div className={`detail-wrapper ${props.className ?? ""}`}>
        <div className="tag">
          <Skeleton variant="rectangular" width={40} height={40} animation="wave" />
        </div>
        <div className="col-grp">
          <div className="col" key="title">
            <p className="title">
              <Skeleton variant="text" width={80} height={16} animation="wave" />
            </p>
            <div className="text-wrapper">
              <Skeleton variant="text" width={144} height={20} animation="wave" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render Columns Array
  if (props.columns && Array.isArray(props.columns)) {
    // All values are null
    if (props.columns.every((column: column) => column.value === null)) {
      return <></>;
    }
    // At least one value is not null
    return (
      <div className={`detail-wrapper ${props.className ?? ""}`}>
        <div className="tag">{props.icon}</div>
        <div className="col-grp">
          {props.columns.map((column: column, index: number) => {
            return column.value ? (
              <div className="col" key={index} style={{ gap: column.title ? "4px" : "0px" }}>
                {column.title && (
                  <p className="title">
                    {column.title}
                    {column.toolTip && (
                      <Tooltip title={column.toolTip} placement="right" arrow>
                        <IconButton size="small">
                          <QuestionMarkSVG />
                        </IconButton>
                      </Tooltip>
                    )}
                  </p>
                )}
                <div className="text-wrapper">
                  {column.format === "email" ? (
                    <a className="link" href={`mailto:${column?.value}`}>
                      {column?.value}
                    </a>
                  ) : column.format == "link" ? (
                    <a className="link" href={`https://${column?.value}`}>
                      {column?.value}
                    </a>
                  ) : column.format === "tel" ? (
                    <p className="text">{Utils.formatPhoneNumber(column?.value ?? "N/A")}</p>
                  ) : (
                    <p className="text">{column?.value}</p>
                  )}
                  {column.copyToClipboard && (
                    <>
                      <CopySVG onClick={() => onCopyToClipBoard(column?.value ?? "N/A")} />
                      <div className={textFade}>Copied</div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  }

  // Render Columns object
  if (props.columns && !Array.isArray(props.columns)) {
    return props.columns?.value ? (
      <div className={`detail-wrapper ${props.className ?? ""}`}>
        {props.icon ? <div className="tag">{props.icon}</div> : <div className="no-tag"></div>}
        <div className="col-grp">
          <div className="col" key="title" style={{ gap: props.columns.title ? "4px" : "0px" }}>
            {props.columns.title && (
              <p className="title">
                {props.columns.title}
                {props.columns.toolTip && (
                  <Tooltip title={props.columns.toolTip} placement="right" arrow>
                    <IconButton size="small">
                      <QuestionMarkSVG />
                    </IconButton>
                  </Tooltip>
                )}
              </p>
            )}
            <div className="text-wrapper">
              {props.columns.format === "email" ? (
                <a className="link" href={`mailto:${props.columns?.value}`}>
                  {props.columns?.value}
                </a>
              ) : props.columns.format == "link" ? (
                <a className="link" href={`https://${props.columns?.value}`}>
                  {props.columns?.value}
                </a>
              ) : props.columns.format === "tel" ? (
                <p className="text">{Utils.formatPhoneNumber(props.columns?.value)}</p>
              ) : (
                <p className="text">{props.columns?.value}</p>
              )}
              {props.columns.copyToClipboard && (
                <>
                  <CopySVG onClick={() => onCopyToClipBoard()} />
                  <div className={textFade}>Copied</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }

  // Return fragment
  return <></>;
}
