import React from "react";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { Chip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ActivityToProps {
    options: To[];
    to: To[];
    setTo: (e: any, value: any) => void;
}

export default function ActivityTo(props: ActivityToProps): React.ReactElement {
    return (
        <Autocomplete
            multiple
            id="tags-filled"
            options={props.options}
            freeSolo
            value={props.to}
            disablePortal
            onChange={props.setTo}
            getOptionLabel={(val: To) => val.label}
            style={{ "border": "none" }}
            renderTags={(value: To[], getTagProps: any) => {
                return value.map((option: any, index: any) => (
                    <div key={index}>
                        <Chip 
                            variant="outlined" 
                            label={option.label}
                            deleteIcon={<CloseIcon />}
                            className={"na-body-to-chip"}
                            {...getTagProps({ index })}
                        />
                    </div>
                ))}
            }
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            ...params.inputProps.style,
                            "padding": 0,
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                    variant="filled"
                    autoComplete
                />
                )}
            forcePopupIcon={false}
        />
    );
}