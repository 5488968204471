/* eslint-disable react/no-children-prop */
import React from "react";
import { NavLink, Route, RouteComponentProps, withRouter } from "react-router-dom";

// Defines Nav Link with Router Props
interface PropsInterface extends RouteComponentProps {
  exact?: boolean | undefined;
  strict?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: any;
  children?: string | undefined | React.ReactNode;
  className?: string | undefined;
  activeClassName?: string | undefined;
}

/**
 * * Defines a custom NavLink deriving from react-router-dom
 * * Used to handle NavLink active states
 */
const NavLinkWithRouter: React.FC<PropsInterface> = (props: PropsInterface) => {
  return (
    <Route
      path={typeof props.to === "object" ? props.to.pathname : props.to.replace(/\?.+$/, "")}
      exact={props.exact}
      strict={props.strict}
      children={({ match }) => {
        const isActive = !!match;
        return (
          <NavLink className={isActive ? [props.className, props.activeClassName].filter((i) => i).join(" ") : props.className} to={typeof props.to === "object" ? {pathname: props.to.pathname, search: props.to.search} : props.to}>
            {typeof props.children === "function" ? props.children(isActive) : props.children}
          </NavLink>
        );
      }}
    />
  );
};

export default withRouter(NavLinkWithRouter);
