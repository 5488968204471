import React, { useState, useEffect } from "react";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import NoData from "../../../library/NoData/NoData";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./ReceivablesRisk.scss";
import { DateTime } from "luxon";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";

/**
 * * Defines the Risk Rate Widget
 */
export default function ReceivableRisk(): React.ReactElement {
  // Get GET Method for DSO Report from Dashboard Context
  const { getRiskRateData } = React.useContext(DashboardContext) as DashboardType;
  // Loading state from GET API Call for RiskRate Report
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error Message from GET API Call for RiskRate Report
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Data from GET API Call for RiskRate Report
  const [data, setData] = useState<RiskRateModel[]>([]);
  // Data parsed into Graph Data from API Call
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  // The latest month's atRiskAmount
  const [riskAmount, setRiskAmount] = useState<number>(0);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Reports/riskrates
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getRiskRateData();
      // Set data from API
      setData(res);
      // Parse and set data from API into Graph Data
      setGraphData(() => {
        return res.map((val: RiskRateModel, index: number) => {
          return {
            id: val?.invoiceMonthName?.toUpperCase().substr(0, 3) ?? "N/A",
            color: index === res.length - 1 ? "#4B5DF8" : "#C1CEFF",
            value: val.atRiskPercentage ?? 0,
          };
        });
      });
      setRiskAmount(res && res.length >= 1 ? res[res.length - 1].atRiskAmount : 0);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleOnClick = (id: string) => {
    const route =
      id === ""
        ? ""
        : id === DateTime.now().toFormat("MMM").toUpperCase()
        ? ""
        : `/${id.charAt(0) + id.slice(1).toLowerCase()}_${DateTime.now().toFormat("yy")}`;
    history.push(`${path}/receivables_risk${route}?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC`);
  };

  // On mount, call API to get data for widget
  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className={`cr-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`cr-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === 0 ? (
    <div className={`cr-wrapper`}>
      <div className={`cr-top`}>
        <p className={`title`}>Receivables Risk</p>
        <Tooltip title="The percentage of total AR balance left unpaid after 90 days." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`cr-wrapper`}>
      <div className={`cr-top`}>
        <p className={`title`} onClick={() => handleOnClick("")}>
          Receivables Risk
        </p>
        <Tooltip title="The percentage of total AR balance left unpaid after 90 days." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={`cr-mid`}>
        <p className={`caption-sm`} style={{ visibility: "hidden" }}>
          Placeholder
        </p>
        <div className={`riskrate-wrapper`}>
          <p onClick={() => handleOnClick("")} className={`riskrate`}>
            {data && data.length >= 1 && data[data.length - 1]?.atRiskPercentage?.toPrecision(3)}
          </p>
          <p className={`riskrate-percent`}>%</p>
        </div>
        <div className={`improvement-wrapper`}>
          <div className={`improvement-amount-wrapper`}>
            <p className={`improvement-amount`}>{shortenCurrency(riskAmount, true)}</p>
            <p className={`improvement-currency`}>
              ($
              {getCurrencySuffix(riskAmount)})
            </p>
          </div>
          <p className={`improvement-percentage`}>
            {data && data.length >= 2 ? (data[data.length - 1].atRiskPercentage - data[data.length - 2]?.atRiskPercentage)?.toFixed(2) ?? 0 : 0}%
            MONTHLY IMPROVEMENT
          </p>
        </div>
      </div>
      <div className={`cr-bot`}>
        <BarChart
          hoverSquare={false}
          data={graphData}
          hoverFormat="percent"
          onClick={(id) => handleOnClick(id)}
          padding={45}
          margin={
            graphData.length === 1
              ? {
                  top: 0,
                  right: 16,
                  bottom: 85,
                  left: calculateLeftMargin(graphData),
                }
              : graphData.length === 2
              ? {
                  top: 0,
                  right: 16,
                  bottom: 50,
                  left: calculateLeftMargin(graphData),
                }
              : graphData.length === 3
              ? {
                  top: 0,
                  right: 16,
                  bottom: 16,
                  left: calculateLeftMargin(graphData),
                }
              : undefined
          }
        />
      </div>
    </div>
  );
}
