import React, { Dispatch, useState } from "react";
import { Alert, Drawer, Snackbar } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { InputWithIcon } from "../../../../library/Input/Input";
import { UserPic } from "../../../../library/Icons/Icons";
import { notesClient } from "../../../../../db/accessor";
import { NoteType } from "../../../../../types/enums";

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityAddPhoneProps = {
  activityId: string;
  recipientValue: string;
  setRecipientValue: Dispatch<string>;
  noteValue: string;
  setNoteValue: Dispatch<string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityAddPhone(props: ActivityAddPhoneProps): React.ReactElement {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);

  const handleAddPhone = async () => {
    try {
      await notesClient.post([
        {
          tableKey: "Activity",
          objectKey: props.activityId,
          noteText: `(Phone Call) To: ${props.recipientValue} Note: ${props.noteValue}`,
          noteType: NoteType.PhoneCall,
        },
      ]);
    } catch (e: unknown) {
      setToastError(true);
    } finally {
      props.setNoteValue("");
      props.setRecipientValue("");
      setShowToast(true);
      setOpen(false);
    }
  };

  return (
    <div className="activity-add-phone">
      <Button size="sm" variant="secondary" onClick={() => setOpen(true)}>
        Add Phone
      </Button>

      <Drawer anchor="right" open={isOpen} onClose={() => setOpen(false)} disablePortal>
        <div className="activity-flyout">
          <h3 className="header">Add a Phone Call</h3>
          <p className="subheader body1">This phone call will only be visible to team members within Lockstep.</p>
          <InputWithIcon
            placeholder="Who are you calling?"
            icon={<UserPic />}
            onChange={props.setRecipientValue}
            defaultValue={props.recipientValue}
          />
          <TextArea
            label={{ regularText: "Note", extraText: " (optional)" }}
            placeholder={""}
            onChange={props.setNoteValue}
            defaultValue={props.noteValue}
          />
          <div className="btn-grp">
            <Button variant="secondary" size="lg" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button size="lg" onClick={() => handleAddPhone()}>
              Add
            </Button>
          </div>
          <p className="recap-header caption">Latest Phone Calls</p>
          <ul className="recap-list">
            <li className="recap-item">
              <div className="head">
                <p className="body2">By You</p>
                <p className="caption">Apr 4, 2021</p>
              </div>
              <p className="body1">Raychel was called, no answer.</p>
            </li>
            <li className="recap-item">
              <div className="head">
                <p className="body2">By Jane Foe</p>
                <p className="caption">Apr 2, 2021</p>
              </div>
              <p className="body1">A previous call.</p>
            </li>
          </ul>
        </div>
      </Drawer>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "An unknown error occurred" : "Phone Call Added Successfully"}
        </Alert>
      </Snackbar>
    </div>
  );
}
