import React from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import Button from "../../library/Button/Button";
import { forgotPasswordRedirectRequest } from "../../../auth/authConfig";
import "./ExisitingAccount.scss";

function ExistingAccount(): React.ReactElement {
  const history = useHistory();
  const { instance } = useMsal();

  return (
    <div className="existing-account-wrapper">
      <h1>We found your account!</h1>
      <p className="p-body desc">Great news, you’re already a member of Lockstep. All you need to do is sign in and you’re ready to go.</p>

      <div className="button-row">
        <Button onClick={() => history.push("/")}>Sign In </Button>
        <Button onClick={() => instance.loginRedirect(forgotPasswordRedirectRequest)} variant="secondary">
          Reset Password{" "}
        </Button>
      </div>
    </div>
  );
}

export default ExistingAccount;
