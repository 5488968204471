import React, { useState, useRef, MouseEvent } from "react";
import Table from "../../library/Table/Table";
import { useEffect } from "react";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import { activitiesClient, notesClient, userAccountsClient, companiesClient, statusClient } from "../../../db/accessor";
import Button from "../../library/Button/Button";
import { Email, MarkAsUnread, MoveTo, AssignTo, Archive, Forward, UserPic } from "../../library/Icons/Icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Drawer, TextField, Snackbar, Alert } from "@mui/material";
import { TextArea } from "../../library/TextArea/TextArea";
import { AppContext } from "../../../contexts/AppContext";
import NewActivity from "./NewActivity/NewActivity";
import Autocomplete from "../../library/Autocomplete/Autocomplete";
import { NoteType } from "../../../types/enums";

type TableProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any[];
  predefinedFilter: { route: string; searchlightFilter: string }[];
};

export default function ActivitiesTable(props: TableProps): React.ReactElement {
  const { path } = useRouteMatch();
  const { userStatus } = React.useContext(AppContext) as AppType;
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [toggleUnread, setToggleUnread] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");
  const [newActivity, setNewActivity] = useState<boolean>(false);

  const [reassignOpen, setReassignOpen] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>("");
  const [reassignIds, setReassignIds] = useState<string[]>([]);
  const [reassignTo, setReassignTo] = useState<{ id: string | null; label: string } | null>(null);
  const [reassignOptions, setReassignOptions] = useState<{ id: string | null; label: string }[]>([]);

  const [moveOpen, setMoveOpen] = useState<boolean>(false);
  const [moveIds, setMoveIds] = useState<string[]>([]);
  const [moveActivityTo, setMoveActivityTo] = useState<{ id: string; label: string } | null>(null);
  const [moveOptions, setMoveOptions] = useState<{ id: string; label: string }[]>([]);

  const fetchParser = (fetchResult: ActivityModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: ActivityModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.activityId,
          }),
          ...((variant === "export" || variant === "all") && {
            // TODO: Link up with Shane to figure out if createdUserId == from
            from: record.createdUserId,
            activity: {
              subject: record.activityName,
              body: record.activityDescription,
            },
            has_attachment: record?.attachments?.length ?? false,
            status: record.activityStatus,
            assigned_to: record.userAssignedTo ?? "Unassigned",
            // TODO: Link up with Shane to figure out if dateAssigned == date_received
            date_received: record.dateAssigned ?? record.created,
          }),
          ...(variant === "all" && {
            disableCheckbox: false,
            isUnread: record.isUnread,
          }),
        };
      }) ?? []
    );
  };

  async function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string; isUnread?: boolean } }) {
    if (path.includes("all")) {
      history.push(`/AR/activities/all/${row.original.id}`);
    } else if (path.includes("mine")) {
      history.push(`/AR/activities/mine/${row.original.id}`);
    } else if (path.includes("unassigned")) {
      history.push(`/AR/activities/unassigned/${row.original.id}`);
    }

    if (row.original.isUnread) {
      try {
        await activitiesClient.patch(row.original.id ?? "", {
          isUnread: false,
        });
      } catch (error: unknown) {
        console.log(error);
      }
    }
  }

  async function updateActivities(ids: string[], updates: Partial<ActivityModel>, action: string) {
    await Promise.all(
      ids.map((id) => {
        return activitiesClient.patch(id, updates).catch((err) => err);
      })
    ).then((values) => {
      if (values.every((value: any) => !(value instanceof Error))) {
        setToastError(false);
        if (values.length > 1) {
          setToastText(`${ids.length} Activities ${action}`);
        } else {
          setToastText(`Activity ${action}`);
        }
      } else {
        setToastError(true);
        if (values.length > 1) {
          if (values.reduce((prev, curr) => prev + (curr instanceof Error ? 1 : 0), 0) > 1) {
            setToastText(`${values.filter((value: any) => value instanceof Error).length ?? ids.length} Activities were not ${action.toLowerCase()}`);
          } else {
            setToastText(`1 Activity was not ${action.toLowerCase()}`);
          }
        } else {
          setToastText(`Activity was not ${action.toLowerCase()}`);
        }
      }
      (tableRef?.current as any)?.softRefresh();
      setShowToast(true);
    });
  }

  const onRowSelect = (val: ActivitiesGridData[]) => {
    setToggleUnread(true);
    val.forEach((row: ActivitiesGridData) => {
      if (row.isUnread) {
        setToggleUnread(false);
      }
    });
  };

  const handleGroupClose = async (ids: string[]) => {
    await updateActivities(ids, { isOpen: false, isArchived: false, activityStatus: "Closed" }, "Closed");
  };

  const handleGroupReply = (ids: string[]) => {
    console.log(ids);
  };
  const handleGroupReassign = async (ids: string[]) => {
    setReassignIds(ids);
    setReassignOpen(true);
    try {
      const predefinedFilter = `(STATUS EQ 'Active')`;
      await userAccountsClient.queryUserAccounts(predefinedFilter).then((data) => {
        setReassignOptions(
          data.records?.map((row: UserAccountModel) => {
            const user = {
              id: row.userId,
              label: row.userId === userStatus.userId ? `${row.userName} (You)` : row.userName ? row.userName : "N/A",
            };
            return user;
          }) ?? []
        );
      });
      setReassignOptions((prev: { id: string | null; label: string }[]) => {
        return [...prev, { id: null, label: "Unassigned" }];
      });
    } catch (error: any) {
      setError(true);
      setErrorMessage(() => {
        return APIClientUtils.buildErrorMessage(error);
      });
    }
  };

  const reassignActivity = async () => {
    if (reassignTo) {
      await updateActivities(reassignIds, { userAssignedTo: reassignTo.id }, "Reassigned");
      await Promise.all(
        reassignIds.map((id) => {
          try {
            notesClient.post([
              {
                objectKey: id,
                tableKey: "Activity",
                noteText: `(Reassigned) To User: ${reassignTo?.label} by ${userStatus.userName} Note: ${noteValue}`,
                noteType: NoteType.Note,
              },
            ]);
          } catch (error: any) {
            setError(true);
            setErrorMessage(() => {
              return APIClientUtils.buildErrorMessage(error);
            });
          }
        })
      );
      setReassignOpen(false);
      setNoteValue("");
      setShowToast(true);
      setReassignTo(null);
    }
  };

  const handleGroupToggleRead = async (ids: string[]) => {
    updateActivities(ids, { isUnread: toggleUnread }, `${toggleUnread ? "Unread" : "Read"}`);
  };

  const handleGroupArchive = async (ids: string[]) => {
    return updateActivities(ids, { isArchived: true, activityStatus: "Archived" }, "Archived");
  };
  const handleGroupMove = async (ids: string[]) => {
    setMoveIds(ids);
    setMoveOpen(true);
    try {
      const predefinedFilter = "(ISACTIVE EQ true) AND (COMPANYTYPE EQ 'Customer')";
      await companiesClient.getCompanies(predefinedFilter).then((data) => {
        setMoveOptions(
          data.records?.map((row: CompanyModel) => {
            const user = {
              id: row.companyId,
              label: row.companyName ?? "N/A",
            };
            return user;
          }) ?? []
        );
      });
    } catch (error: any) {
      setError(true);
      setErrorMessage(() => {
        return APIClientUtils.buildErrorMessage(error);
      });
    }
  };

  const moveActivity = async () => {
    if (moveActivityTo?.id) {
      await updateActivities(moveIds, { companyId: moveActivityTo.id }, "Moved");
      await Promise.all(
        moveIds.map((id) => {
          try {
            notesClient.post([
              {
                objectKey: id,
                tableKey: "Activity",
                noteText: `(Moved) To Connection: ${moveActivityTo?.label} by ${userStatus.userName} Note: ${noteValue}`,
                noteType: NoteType.Note,
              },
            ]);
          } catch (error: any) {
            setError(true);
            setErrorMessage(() => {
              return APIClientUtils.buildErrorMessage(error);
            });
          }
        })
      );
      setMoveOpen(false);
      setNoteValue("");
      setMoveActivityTo(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSend = async (to: To[], title: string, body: string) => {
    await statusClient.getStatus().then(async (results: StatusModel) => {
      // TODO: wireup sending message to toList
      // TODO: wireup adding attachments to activity
      await activitiesClient.post([{
        userAssignedTo: results?.userId,
        activityTypeCode: "string",
        activityName: title,
        activityDescription: body,
        activityStatus: "Active",
        priority: "string",
        isOpen: true,
        isUnread: false,
        isArchived: false
      }]).then(() => {
        (tableRef?.current as any)?.hardRefresh();
        setToastText("Messages Sent");
        setShowToast(true);
      });
    });
  }

  useEffect(() => {
    return () => {
      setPageNumber(0);
      setPageCount(0);
      setTotalCount(0);
    };
  }, []);

  return (
    <>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Activities",
            displayName: "Activities",
            rowSelectToken: "activityId",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: activitiesClient.query,
              fetchParser: fetchParser,
              selectAllFilter: `(USERID NE '${userStatus.userId}')`,
              includeOption: "attachments",
            },
            columns: props.columns,
            defaultSort: path.includes("unassigned") ? "CREATED" : "DATEASSIGNED",
            defaultSortToken: "DESC",
            handleRowClick: handleRowClick,
            predefinedFilters: props.predefinedFilter,
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
        }}
        toggles={{
          showSearchbar: true,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
        }}
        email={true}
        hoverBar={true}
        onRowSelect={onRowSelect}
        headerBtns={[
          {
            component: (
              <Button icon={<Email />} onClick={() => setNewActivity(true)} alignIcon="left">
                New Activity
              </Button>
            ),
          },
        ]}
        rowSelectBtns={[
          {
            variant: "secondary",
            children: "Close",
            callback: (ids) => handleGroupClose(ids),
          },
          "divider",
          {
            icon: <Forward />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Forward",
            callback: (ids) => handleGroupReply(ids),
          },
          {
            icon: <AssignTo />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Reassign",
            callback: (ids) => handleGroupReassign(ids),
          },
          {
            icon: <MoveTo />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Move",
            callback: (ids) => handleGroupMove(ids),
          },
          {
            icon: <Archive />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: "Archive",
            callback: (ids) => handleGroupArchive(ids),
          },

          {
            icon: <MarkAsUnread />,
            variant: "solo-icon",
            alignIcon: "left",
            tooltip: `Mark as ${toggleUnread ? "unread" : "read"}`,
            callback: (ids) => handleGroupToggleRead(ids),
          },
        ]}
      />
      <Drawer
        anchor="right"
        open={reassignOpen}
        onClose={() => {
          setReassignOpen(false);
          setNoteValue("");
          setReassignTo(null);
        }}
        disablePortal
      >
        <div className="activity-flyout">
          <h3 className="header">Change Assignment Owner</h3>
          <p className="subheader body1">Reassign this assignment to another team member within Lockstep.</p>
          <label htmlFor={"string"}>{"Team Member"}</label>
          <Autocomplete
            options={reassignOptions}
            onChange={(_event, selection: { id: string | null; label: string }) => setReassignTo({ id: selection.id, label: selection.label })}
            value={reassignTo}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth={true}
            popupIcon={<UserPic />}
            staticIcon={true}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
          />
          <TextArea label={{ regularText: "Note", extraText: " (optional)" }} placeholder={""} onChange={setNoteValue} defaultValue={noteValue} />
          <div className="btn-grp">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                setReassignOpen(false);
                setReassignTo(null);
                setNoteValue("");
              }}
            >
              Cancel
            </Button>
            <Button size="lg" onClick={() => reassignActivity()}>
              Reassign
            </Button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={moveOpen}
        onClose={() => {
          setMoveOpen(false);
          setNoteValue("");
          setMoveActivityTo(null);
        }}
        disablePortal
      >
        <div className="activity-flyout">
          <h3 className="header">Move Assignmnet</h3>
          <p className="subheader body1">Move this assignment to another connction.</p>
          <label htmlFor={"string"}>{"Connection"}</label>
          <Autocomplete
            options={moveOptions}
            onChange={(_event, selection: { id: string; label: string }) => setMoveActivityTo({ id: selection.id, label: selection.label })}
            value={moveActivityTo}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth={true}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
          />
          <TextArea label={{ regularText: "Note", extraText: " (optional)" }} placeholder={""} onChange={setNoteValue} defaultValue={noteValue} />
          <div className="btn-grp">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                setMoveOpen(false);
                setMoveActivityTo(null);
                setNoteValue("");
              }}
            >
              Cancel
            </Button>
            <Button size="lg" onClick={() => moveActivity()}>
              Move
            </Button>
          </div>
        </div>
      </Drawer>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "An unknown error occurred" : toastText}
        </Alert>
      </Snackbar>
      {/* TODO: wireup options (will have to do this with every instance of new activity(reply, replyall) */ }
      <NewActivity 
        open={newActivity}
        onClose={() => setNewActivity(false)}
        title={"New Activity"}
        contactOptions={[{ id: "1", label: "Raychel Green"}]}
        handleSend={handleSend}
      />
    </>
  );
}
