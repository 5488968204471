import React, { Dispatch, useState } from "react";
import { Alert, Drawer, Snackbar } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { notesClient } from "../../../../../db/accessor";
import { NoteType } from "../../../../../types/enums";

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityAddNoteProps = {
  activityId: string;
  noteValue: string;
  setNoteValue: Dispatch<string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityAddNote(props: ActivityAddNoteProps): React.ReactElement {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);

  const handleAddNote = async () => {
    try {
      await notesClient.post([
        {
          tableKey: "Activity",
          objectKey: props.activityId,
          noteText: props.noteValue,
          noteType: NoteType.Note,
        },
      ]);
    } catch (e: unknown) {
      setToastError(true);
    } finally {
      props.setNoteValue("");
      setShowToast(true);
      setOpen(false);
    }
  };

  return (
    <div className="activity-add-note">
      <Button size="sm" variant="secondary" onClick={() => setOpen(true)}>
        Add Note
      </Button>

      <Drawer anchor="right" open={isOpen} onClose={() => setOpen(false)} disablePortal>
        <div className="activity-flyout">
          <h3 className="header">Add a Note</h3>
          <p className="subheader body1">This note will only be visible to team members within Lockstep.</p>
          <TextArea label={"Note"} placeholder={""} onChange={props.setNoteValue} defaultValue={props.noteValue} />
          <div className="btn-grp">
            <Button variant="secondary" size="lg" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button size="lg" onClick={() => handleAddNote()}>
              Add
            </Button>
          </div>
          <p className="recap-header caption">Latest Notes</p>
          <ul className="recap-list">
            <li className="recap-item">
              <div className="head">
                <p className="body2">By Jane Foe</p>
                <p className="caption">Apr 4, 2021</p>
              </div>
              <p className="body1">It's time to call Raychel.</p>
            </li>
          </ul>
        </div>
      </Drawer>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "An unknown error occurred" : "Note Added Successfully"}
        </Alert>
      </Snackbar>
    </div>
  );
}
