import React from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material";
import { UseAutocompleteProps as MuiUseAutocompleteProps } from "@mui/core";
import { Autocomplete as MUIAutocomplete } from "@mui/material";
import { DropdownArrow } from "../Icons/Icons";
import "./Autocomplete.scss";

type AutocompleteProps<T> = MuiAutocompleteProps<T, boolean, boolean, boolean> &
  MuiUseAutocompleteProps<T, boolean, boolean, boolean> & {
    className?: string;
    staticIcon?: boolean; // Disable icon flip
  };

export default function Autocomplete({ className, staticIcon, ...rest }: AutocompleteProps<any>): React.ReactElement {
  return <MUIAutocomplete className={`${className ?? ""} ${staticIcon ? "Autocomplete-static-icon" : ""}`} {...rest} />;
}

Autocomplete.defaultProps = {
  forcePopupIcon: true,
  popupIcon: <DropdownArrow />,
};
