import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { AppContext } from "../../../../contexts/AppContext";
import { DateTime } from "luxon";
import "./Header.scss";
import Utils from "../../../../utils/utils";

function formatCurrency(value: number, label: string): JSX.Element {
  return (
    <div>
      <span className={`balance-amount-dark`}>
        {new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "currency",
          currency: "USD",
        }).format(value)}
      </span>
      <span className={`balance-amount-light`}> {label}</span>
    </div>
  );
}

function formatCustomerInfo(value: number, label: string): React.ReactElement {
  const displayLabel = Utils.formatLabelPluralization(value, label);

  return (
    <div>
      <span className={`balance-amount-dark`}>{value} </span>
      <span className={`balance-amount-light`}>{displayLabel}</span>
    </div>
  );
}

export default function Header(): React.ReactElement {
  const { path } = useRouteMatch();
  const [ARHeader, setARHeader] = useState<ArHeaderInfoModel>({} as ArHeaderInfoModel);
  const [documentsHeader, setDocumentsHeader] = useState<AttachmentHeaderInfoModel>({} as AttachmentHeaderInfoModel);
  const { company } = React.useContext(CustomerContext) as CustomerType;
  const { getARHeader, getDocumentsHeader } = React.useContext(AppContext) as AppType;
  const { customerId } = useParams<{ customerId: string }>();

  useEffect(() => {
    try {
      getARHeader(DateTime.now().toFormat("yyyy-MM-dd"), customerId).then((data) => {
        setARHeader(data);
      });
    } catch (error: unknown) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDocumentsHeader(customerId)
      .then((data) => {
        setDocumentsHeader(data);
      })
      .catch((e) => console.error("Failed to retrieve documents header info.", e));
  }, []);

  return (
    <>
      <div className={`tag`}>{company?.name?.charAt(0) ?? ""}</div>
      <p className={`customerName`}>{company?.name ?? ""}</p>
      <div className={`amounts-wrapper`}>
        <Switch>
          {/*TODO: enable when activites tab is ready */}
          {/* <Route
            exact
            path={`${path}`}
            render={() => {
              return (
                <>
                  TODO: wire this to real data (add activities to ar-header report?)
                  {formatCustomerInfo(0, "Active Activity")}
                  {formatCustomerInfo(0, "Not Started Activity")}
                  {formatCustomerInfo(0, "Total Activity")}
                </>
              );
            }}
          /> */}
          <Route
            exact
            path={`${path}/open_invoices`}
            render={() => {
              return (
                <>
                  {formatCustomerInfo((ARHeader?.totalInvoices ?? 0) - (ARHeader?.totalInvoicesPaid ?? 0), "Open Invoice")}
                  {formatCustomerInfo(ARHeader?.totalInvoicesPastDue ?? 0, "Past Due Invoice")}
                  {formatCurrency(ARHeader?.totalArAmount ?? 0, "Total Outstanding Balance")}
                </>
              );
            }}
          />
          <Route
            exact
            path={`${path}/closed_invoices`}
            render={() => {
              return (
                <>
                  {formatCustomerInfo(ARHeader?.totalInvoicesPaid ?? 0, "Closed Invoice")}
                  {formatCurrency(ARHeader?.totalInvoiceAmountPreviousYear ?? 0, "Total Invoiced in 2020")}
                  {formatCurrency(ARHeader?.totalInvoiceAmountCurrentYear ?? 0, "Total Invoiced in 2021")}
                </>
              );
            }}
          />
          <Route
            exact
            path={`${path}/payments`}
            render={() => {
              return (
                <>
                  {formatCurrency(ARHeader.totalPaymentAmountCurrentYear ?? 0, `Total Paid in ${new Date().getFullYear()}`)}
                  {formatCurrency(ARHeader.totalUnappliedPayments ?? 0, "Total Unapplied Payment Amount")}
                </>
              );
            }}
          />
          <Route
            exact
            path={`${path}/contacts`}
            render={() => {
              return (
                <>
                  {/* TODO: wire to real data when it is avaliable in the API*/}
                  {formatCustomerInfo(0, `Total Contact`)}
                </>
              );
            }}
          />
          <Route
            exact
            path={`${path}/documents`}
            render={() => {
              return (
                <>
                  {formatCustomerInfo(documentsHeader.totalActive ?? 0, `Active Document`)}
                  {formatCustomerInfo(documentsHeader.totalArchived ?? 0, `Archived Document`)}
                  {formatCustomerInfo(documentsHeader.totalAttachments ?? 0, `Total Document`)}
                </>
              );
            }}
          />
        </Switch>
      </div>
    </>
  );
}
