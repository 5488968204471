export enum OnboardStep {
  SelectERP,
  OtherERP,
  CreateAccount,
  CreateInvitedAccount,
  ExistingAccount,
  PersonalInfo,
  CompanyInfo,
  ConnectEmail,
}

export enum DataMode {
  View,
  Edit,
}

export enum InvoiceStatusType {
  "OPEN" = -180,
  "PAST DUE 1-30" = 0,
  "PAST DUE 31-60" = 30,
  "PAST DUE 61-90" = 60,
  "PAST DUE 91+" = 90,
  "CLOSED" = -1,
}

export enum EntityClassification {
  corporation = "Corporation",
  partnership = "Partnership",
  "sole-proprietor" = "Sole-proprietor",
}

/**
 * Contains the possible roles that a user can have.
 */
export enum UserRoles {
  GroupOwner = "Group Owner",
  GroupAdmin = "Group Admin",
  Member = "Member",
  ReadOnly = "Read-Only",
}

/**
 * Holds all actions that will be only allowed for some users
 */
export enum UserActions {
  AddNote = "addActivity",
  InvitationsRemind = "invRemind",
  InvitationsRemove = "invRemove",
  InvitationsCheckbox = "invCheckbox",
  RemovedMembersInvite = "rmvedMembersInv",
  RemovedMembersCheckbox = "rmvCheckbox",
  InviteMembers = "invMembers",
  InvitationsChangeRole = "invChangeRoles",
  ActiveMembersCheckbox = "actvCheckbox",
  ActiveMembersRemove = "actvRemove",
  ChangeRoles = "changeRoles",
  EditMyProfile = "editMyProfile",
  EditMyCompany = "editMyCompany",
  AddEmailClient = "addEmailClient",
  RunSync = "runSync",
  EditAccountingSoftware = "editAccountingSoftware",
}

/**
 * The possible statuses an activity can have.
 */
export enum ActivityStatus {
  NotStarted = "Not Started",
  Active = "Active",
  Closed = "Closed",
  Archived = "Archived",
}

/**
 * Types of notes that can be created.
 */
export enum NoteType {
  Note = "Note",
  PhoneCall = "Phone Call",
}
