import React, { useState, MouseEvent } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Table from "../../../../library/Table/Table";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { InvoiceStatusType } from "../../../../../types/enums";
import { companiesClient, invoicesClient } from "../../../../../db/accessor";

const CUSTOMERSCOLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "22.5%",
    showFilter: true,
    popupPlaceholder: "ex. Blushing Blooms",
    showSort: true,
    searchlightField: "COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Primary Contact",
    accessor: "primary_contact",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "PRIMARYCONTACT",
    sortField: "PRIMARYCONTACT, COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Total Amount Due",
    accessor: "total_amount_due",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "OUTSTANDINGAMOUNT",
    sortField: "OUTSTANDINGAMOUNT, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, true),
  },
  {
    Header: "Open Invoices",
    accessor: "open_invoices",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "OUTSTANDINGINVOICES",
    sortField: "OUTSTANDINGINVOICES, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value, true),
  },
  {
    Header: "Last Activity",
    accessor: "last_activity",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "NEWESTACTIVITY",
    sortField: "NEWESTACTIVITY, COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
  },
];

const INVOICESCOLUMNS = [
  {
    Header: "Invoice Number",
    accessor: "invoice_number",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "CUSTOMERNAME",
    sortField: "CUSTOMERNAME, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. Open, Closed, Past Due",
    showSort: true,
    searchlightField: "DAYSPASTDUE",
    sortField: "STATUS, DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "lt",
    upperBoundToken: "ge",
    dropdownOptions: [
      { altDisplay: "OPEN", value: InvoiceStatusType["OPEN"], id: "open" },
      { altDisplay: "PAST DUE 1-30", value: "0", id: "late1" },
      { altDisplay: "PAST DUE 31-60", value: InvoiceStatusType["PAST DUE 31-60"], id: "late31" },
      { altDisplay: "PAST DUE 61-90", value: InvoiceStatusType["PAST DUE 61-90"], id: "late61" },
      { altDisplay: "PAST DUE 91+", value: InvoiceStatusType["PAST DUE 91+"], id: "late91" },
      { altDisplay: "CLOSED", value: InvoiceStatusType["CLOSED"], id: "closed" },
    ],
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { due_date: string | null } } }) =>
      TableUtils.formatStatus(props.row.original.due_date, props.value, false),
  },
  {
    Header: "Due Date",
    accessor: "due_date",
    width: "12.5%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDUEDATE",
    sortField: "PAYMENTDUEDATE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
  },
  {
    Header: "Invoice Amount",
    accessor: "invoice_amount",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICEAMOUNT",
    sortField: "INVOICEAMOUNT, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Last Activity",
    accessor: "last_activity",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "NEWESTACTIVITY",
    sortField: "NEWESTACTIVITY, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
  },
];

export default function Invoiced(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const customerFetchParser = (fetchResult: CustomerSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.companyId,
          }),
          ...((variant === "export" || variant === "all") && {
            customer_name: record?.companyName || "N/A",
            primary_contact: record?.primaryContact || "N/A",
            total_amount_due: record?.outstandingAmount,
            open_invoices: record?.outstandingInvoices,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/receivables_summary"))}/customers/${row.original.id ?? ""}`);
  }

  const invoiceFetchParser = (fetchResult: InvoiceSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.invoiceId,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record?.invoiceNumber,
            customer_name: record?.customerName || "N/A",
            status: record?.status,
            due_date: record?.paymentDueDate,
            invoice_amount: record?.invoiceAmount,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/receivables_summary"))}/invoices/${row.original.id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Customers",
          displayName: "By Customers",
          rowSelectToken: "companyId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: companiesClient.getCustomerSummaries,
            fetchParser: customerFetchParser,
          },
          columns: CUSTOMERSCOLUMNS,
          export: {
            exportFileName: "Receivables Summary Drilldown - Invoiced - By Customers",
          },
          defaultSort: "OUTSTANDINGAMOUNT, COMPANYNAME",
          defaultSortToken: "DESC",
          handleRowClick: handleCustomerRowClick,
          predefinedFilters: [
            {
              route: "invoiced",
              searchlightFilter: "(OUTSTANDINGAMOUNT NE 0)",
            },
          ],
        },
        {
          id: "By Invoices",
          displayName: "By Invoices",
          rowSelectToken: "invoiceId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: invoicesClient.getInvoiceSummaries,
            fetchParser: invoiceFetchParser,
          },
          columns: INVOICESCOLUMNS,
          export: {
            exportFileName: "Receivables Summary Drilldown - Invoiced - By Invoices",
          },
          defaultSort: "PAYMENTDUEDATE, INVOICENUMBER",
          defaultSortToken: "DESC",
          handleRowClick: handleInvoiceRowClick,
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
