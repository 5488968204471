import React, { Dispatch, SetStateAction } from "react";
import { Editor } from "react-draft-wysiwyg";
import Undo from "../../../../../assets/Undo.svg";
import Redo from "../../../../../assets/Redo.svg";
import Bold from "../../../../../assets/Bold.svg";
import Italic from "../../../../../assets/Italic.svg";
import Underline from "../../../../../assets/Underline.svg";
import Strikethrough from "../../../../../assets/Strikethrough.svg";
import Attachment from "./CustomButtons/Attachment";
import FileAttachment from "../../../../library/FileAttachment/FileAttachment";

import "./ActivityBody.scss"
import { EditorState } from "draft-js";

interface ActivityBodyProps {
    editorState: EditorState;
    setEditorState: Dispatch<SetStateAction<EditorState>>;
    files: File[];
    handleFileSelect: (newFiles: FileList) => void;
    filesSelected: boolean;
    handleFileRemove: (key: number) => void;
}

export default function ActivityBody(props: ActivityBodyProps): React.ReactElement {
    return (
        <div className={"na-body-wrapper"}>
            <Editor
                editorState={props.editorState}
                onEditorStateChange={props.setEditorState}
                wrapperClassName={"na-body-editor-wrapper"}
                toolbarClassName={"na-body-editor-toolbar"}
                editorClassName={"na-body-editor"}
                toolbar={{
                    options: ['history', 'list', 'inline'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                        bold: { icon: Bold, className: undefined },
                        italic: { icon: Italic, className: undefined },
                        underline: { icon: Underline, className: undefined },
                        strikethrough: { icon: Strikethrough, className: undefined }
                    },
                    list: { // spacing bar component
                        options: [],
                    },
                    history: {
                        options: ['undo', 'redo'],
                        undo: { icon: Undo, className: undefined },
                        redo: { icon: Redo, className: undefined }
                    }
                }}
                toolbarCustomButtons={
                    [
                        <Attachment key={0}  handleFileSelect={props.handleFileSelect} fileSelected={props.filesSelected} />
                    ]
                }
            />  
            <div className={"na-body-attachments"}>
                {props.files.map((value, index) => (
                    <FileAttachment 
                        key={index}
                        label={value.name}
                        size={value.size}
                        handleClick={() => props.handleFileRemove(index)}
                    />
                ))}
            </div>
        </div>
    );
}