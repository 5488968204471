export default class APIClientUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static buildErrorMessage(error: any):string {
    let msg = "An error has occurred, please retry";

    if (error) {
      if (error.response) {
        if (error.response.data && Array.isArray(error.response.data) && error.response.data.length > 0) {
          if (error.response.data[0].FieldValue) {
            msg = `Sorry, we could not find results for\n"${error.response.data[0].FieldValue}"`;
          } else if (error.response.data[0].ErrorName) {
            msg = `Sorry, the following error occurred:\n${error.response.data[0].ErrorName}`;
          }
        } else {
          switch (error.response.status) {
            case 400:
              msg = "A bad request was made";
              break;
            case 401:
              msg = "You are not unauthorized";
              break;
            case 403:
              msg = "You are not allowed to perform the operation";
              break;
            case 404:
              msg = "A resource could not be found";
              break;
            case 500:
              msg = "An unexpected server error occurred";
              break;
            case 503:
              msg = "A service is currently unavailable";
              break;
          }
        }
      } else if (error.message) {
        msg = `Error: ${error.message}`;
      }
    }

    return msg;
  }
}
