import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import Button from "../../library/Button/Button";
import { Input } from "../../library/Input/Input";
import Autocomplete from "../../library/Autocomplete/Autocomplete";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { DataMode } from "../../../types/enums";

type EditPersonalInfoProps = {
  supportedCurrencies: { id: string; label: string }[];
  showCancel: boolean;
};

function EditPersonalInfo(props: EditPersonalInfoProps): React.ReactElement {
  const { setDataMode } = React.useContext(OnboardContext) as OnboardType;
  const { setOnboardData, onboardData } = React.useContext(AppContext) as AppType;
  const nameRef = useRef<HTMLInputElement>();
  const [name, setName] = useState<string>(onboardData.fullName || "");
  const [currency, setCurrency] = useState<{ id: string; label: string }>(
    props.supportedCurrencies.find((val) => val.id === onboardData.defaultCurrency) ?? props.supportedCurrencies[0]
  );

  const handleSave = () => {
    setOnboardData((prev: OnboardDataModel) => {
      return {
        ...prev,
        fullName: name,
        defaultCurrency: currency.id,
        company: { ...prev.company, defaultCurrencyCode: currency.id },
      };
    });
    setDataMode(DataMode.View);
  };

  const handleCancel = () => {
    setDataMode(DataMode.View);
  };

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  return (
    <>
      <h2>Enter your personal information.</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-row">
          <Input
            inputRef={nameRef}
            label="Your Name"
            value={name}
            onChange={(val: string) => setName(val)}
            placeholder="Salvatore Boone"
            className="form-field"
            autocomplete="name"
            data-testid="EditPersonalInfoName"
          />
          <span>
            <Input
              label="Email Address"
              defaultValue={onboardData.email || ""}
              placeholder="sboone@boone.com"
              className="form-field"
              autocomplete="email"
              data-testid="EditPersonalInfoEmail"
              disabled
            />
          </span>
        </div>
        <hr />
        <div className="form-row">
          <div>
            <label htmlFor="DefaultCurrencyInput">Primary Currency</label>
            <Autocomplete
              id="DefaultCurrencyInput"
              data-testid="EditUserInfoCurrency"
              options={props.supportedCurrencies}
              className="form-field"
              value={currency}
              onChange={(event, value: { id: string; label: string }) => {
                setCurrency(value);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        </div>
        <div className="button-row">
          <Button data-testid="PersonalInfoSaveChangesButton" disabled={!name} onClick={() => handleSave()}>
            Save Changes
          </Button>
          {props.showCancel && (
            <Button data-testid="PersonalInfoCancelChangesButton" variant="secondary" onClick={() => handleCancel()}>
              Cancel
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default EditPersonalInfo;
