import React, { useState } from "react";
import { DataMode, OnboardStep } from "../types/enums";

export const OnboardContext = React.createContext<OnboardType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const OnboardProvider: React.FC<Props> = ({ children }: Props) => {
  const [onboardStep, setOnboardStep] = useState<OnboardStep>(OnboardStep.SelectERP);
  const [dataMode, setDataMode] = useState<DataMode>(DataMode.Edit);
  const [bearerToken, setBearerToken] = useState<string>("");
  const [isInvited, setIsInvited] = useState<boolean>(false);

  return (
    <OnboardContext.Provider
      value={{
        setOnboardStep,
        setDataMode,
        onboardStep,
        dataMode,
        bearerToken,
        setBearerToken,
        isInvited,
        setIsInvited,
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
};

export default OnboardProvider;
