export const shortenCurrency = (amount: number, decimal?: boolean): string => {
  const stringifiedAmount = String(Math.abs(Math.floor(amount)));
  if (decimal) {
    if (stringifiedAmount.length <= 6) {
      return (amount / 1000).toPrecision(4);
    } else if (stringifiedAmount.length <= 9) {
      return (amount / 1000000).toPrecision(4);
    } else if (stringifiedAmount.length <= 12) {
      return (amount / 1000000000).toPrecision(4);
    } else {
      return (amount / 1000000000000).toPrecision(4);
    }
  } else {
    if (stringifiedAmount.length <= 3) {
      return String(Math.round(amount));
    } else if (stringifiedAmount.length <= 6) {
      return String(Math.round(amount / 1000));
    } else if (stringifiedAmount.length <= 9) {
      return String(Math.round(amount / 1000000));
    } else if (stringifiedAmount.length <= 12) {
      return String(Math.round(amount / 1000000000));
    } else {
      return String(Math.round(amount / 1000000000000));
    }
  }
};

export const getCurrencySuffix = (amount: number): string => {
  const stringifiedAmountLength = String(Math.abs(Math.floor(amount))).length;
  if (stringifiedAmountLength <= 3) {
    return "";
  } else if (stringifiedAmountLength <= 6) {
    return "k";
  } else if (stringifiedAmountLength <= 9) {
    return "m";
  } else if (stringifiedAmountLength <= 12) {
    return "b";
  } else {
    return "t";
  }
};
