import React, { useState } from "react";

import { invoicesClient } from "../db/accessor";

export const InvoiceContext = React.createContext<InvoiceType | null>(null);

export interface InvoiceProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const InvoiceProvider: React.FC<InvoiceProviderProps> = ({ children }: InvoiceProviderProps) => {
  // Current Invoice customer name (Shown on invoice navbar)
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceModel>({} as InvoiceModel);
  const [lastFilterItem, setLastFilterItem] = useState<string>("");

  async function getInvoiceSummaryExportData(): Promise<Omit<InvoicesGridData, "id">[]> {
    return await invoicesClient.getInvoiceSummaries().then((data) => {
      return (
        data.records?.map((val: InvoiceSummaryModel) => {
          return {
            invoice_number: val.invoiceNumber,
            customer_name: val.customerName,
            status: val.status,
            due_date: val.paymentDueDate,
            outstanding_amount: val.outstandingBalance,
          };
        }) ?? []
      );
    });
  }

  // GET on /api/v1/Invoices/{id}
  async function get(id: string): Promise<InvoiceModel> {
    return invoicesClient.getInvoice(id, "Company,Customer,Payments,CreditMemos").then((data) => {
      setCustomerName(data?.company?.companyName ?? "N/A");
      return data;
    });
  }

  return (
    <InvoiceContext.Provider
      value={{
        get,
        getInvoiceSummaryExportData,
        customerName,
        setInvoiceData,
        invoiceData,
        lastFilterItem,
        setLastFilterItem,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
