/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports from react
import React, { useMemo, useEffect, MouseEvent, useState, useRef, forwardRef, useImperativeHandle, SetStateAction, Dispatch } from "react";
// Imports from react-table
import { useTable, usePagination, useRowSelect, TableInstance, HeaderProps, TableState } from "react-table";
// Import reusable styled-components
import { InputWithIcon } from "../Input/Input";
import IndeterminateCheckbox from "./Checkbox/Checkbox";
import { FilterButton } from "./FilterButton/FilterButton";
import { SortButton } from "./SortButton/SortButton";
import { ExportButton } from "./ExportButton/ExportButton";
import Loading from "../Loading/Loading";
import { DropdownArrow, TooltipIcon, LeftArrow, RightArrow } from "../Icons/Icons";
// Imports from react-router-dom
import { useHistory, useRouteMatch } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import NoData from "../NoData/NoData";
import Button, { ButtonProps } from "../Button/Button";
import SoftAlert from "../SoftAlert/SoftAlert";
import { useContext } from "react";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { InvoiceStatusType } from "../../../types/enums";
import { Tooltip, IconButton } from "@mui/material";
import { DateTime } from "luxon";
import HoverBar from "../../Activities/Mine/HoverBar/Hoverbar";
import { getConditionalAllRowsSelectedProps } from "./getConditionalAllRowsSelectedProps";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import "./Table.scss";

const INTERVAL_SIZE = 40;
const DROPDOWNOPTIONS = [
  { onClickValue: 25, value: "25 items per page", id: "25" },
  { onClickValue: 50, value: "50 items per page", id: "50" },
  { onClickValue: 100, value: "100 items per page", id: "100" },
  { onClickValue: 150, value: "150 items per page", id: "150" },
];

// Defines a Type for Column Headers
interface ColumnHeaderType {
  Header: string;
  accessor: string;
  searchlightField?: string;
  searchlightToken?: string;
  sortField?: string;
  upperBoundToken?: string;
}

interface HeaderButton {
  component: React.ReactElement;
  hideOnRowSelect?: boolean;
}

interface RowSelectButton extends Omit<ButtonProps, "onClick"> {
  callback: (ids: string[]) => void;
}

type dataSet = {
  id: string;
  displayName: string;
  rowSelectToken?: string;
  data: {
    tableData: TableData[];
    setTableData: Dispatch<SetStateAction<TableData[]>>;
    fetchCall: (filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number) => Promise<FetchResult>;
    fetchParser: (response: FetchResult, variant?: FetchVariant) => Partial<TableData>[];
    includeOption?: string;
    selectAllFilter?: string;
  };
  columns: ColumnHeaderType[] | null;
  columnDeps?: any;
  hiddenColumns?: string[];
  export?: {
    exportFileName: string;
  };
  defaultSort?: string;
  defaultSortToken?: string;
  handleRowClick: (e: MouseEvent<HTMLTableRowElement>, row: { original: { [key: string]: unknown } }) => void;
  predefinedFilters?: tabFilter[];
};

const defaultProps = {
  toggles: {
    showCaption: true as boolean,
    showRowSelect: true as boolean,
    showNavigation: true as boolean,
    showExportBtn: true as boolean,
    showSearchbar: true as boolean,
  },
};

// Defines the Table Props
type Props = {
  title?: string;
  dataSets: dataSet[];
  states: {
    isLoading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    isError: boolean;
    setError: Dispatch<SetStateAction<boolean>>;
    errorMessage: string;
    setErrorMessage: Dispatch<SetStateAction<string>>;
  };
  pagination: {
    pageCount: number;
    setPageCount: Dispatch<SetStateAction<number>>;
    pageNumber: number;
    setPageNumber: Dispatch<SetStateAction<number>>;
    totalCount: number;
    setTotalCount: Dispatch<SetStateAction<number>>;
  };
  toggles?: {
    showCaption?: boolean;
    showNavigation?: boolean;
    showRowSelect?: boolean;
    showExportBtn?: boolean;
    showSearchbar?: boolean;
  };
  hoverBar?: boolean;
  email?: boolean;
  headerBtns?: HeaderButton[];
  rowSelectBtns?: (RowSelectButton | "divider")[];
  onRowSelect?: (val: any) => void;
} & Partial<typeof defaultProps>;

/**
 * * Defines a reusable Table from react-table
 */
// eslint-disable-next-line react/display-name
const Table = forwardRef<any, Props>((props: Props, ref) => {
  const params = new URLSearchParams(window.location.search);
  const { url } = useRouteMatch();
  const history = useHistory();
  const [pageSize, setPageSize] = useState<number>(25);
  const { lastFilterItem, setLastFilterItem } = useContext(InvoiceContext) as InvoiceType;
  const [dataSetIndex, setDataSetIndex] = useState<number>(() => {
    const dataSetIndexQueryString = parseInt(params.get("dataSetIndex") ?? "0", 10);
    if (isNaN(dataSetIndexQueryString)) {
      return 0;
    } else {
      return dataSetIndexQueryString <= 0
        ? 0
        : dataSetIndexQueryString >= props.dataSets.length - 1
        ? props.dataSets.length - 1
        : dataSetIndexQueryString;
    }
  });
  const data = useMemo<TableData[]>(() => props.dataSets[dataSetIndex].data.tableData, [props.dataSets[dataSetIndex].data.tableData]);
  const columns = useMemo<any>(
    () => props.dataSets[dataSetIndex].columns,
    [dataSetIndex, props.pagination.totalCount, ...(props?.dataSets[dataSetIndex]?.columnDeps ? props?.dataSets[dataSetIndex]?.columnDeps : [])]
  );
  // Row-select states
  const [currentSelectedRows, setCurrentSelectedRows] = useState({});
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
  const [showSelectAll, setShowSelectAll] = useState<boolean>(false);

  const addInvoiceDaysPastDueFilters = (
    value: string | null,
    searchlightField: string | null,
    searchlightToken: string | null,
    filters: any[],
    upperBoundToken?: string | null
  ) => {
    const valueInt = parseInt(value ?? "0");
    const status = InvoiceStatusType[valueInt];
    const today = DateTime.utc(DateTime.utc().year, DateTime.utc().month, DateTime.utc().day);

    if (!value || !searchlightField || !searchlightToken) {
      return;
    }

    if (status === "CLOSED") {
      filters.push({ field: "STATUS", token: "eq", value: "CLOSED" });
    } else if (status === "OPEN") {
      filters.push({ field: "STATUS", token: "eq", value: "OPEN" });
    } else {
      filters.push({ field: "STATUS", token: "ne", value: "CLOSED" });
      filters.push({
        field: "PAYMENTDUEDATE",
        token: searchlightToken,
        value: today.minus({ days: valueInt }),
      });
    }

    if (upperBoundToken && valueInt >= 0 && valueInt < 90) {
      filters.push({
        field: "PAYMENTDUEDATE",
        token: upperBoundToken,
        value: today.minus({ days: valueInt + 30 }),
      });
    }
  };

  // * Get Searchlight formatted order from URL Query param 'sort'
  function getSortFromURL(): string | undefined {
    return params.get("sort") ?? undefined;
  }

  // * Get Searchlight formatted filters from URL Query params (Defined in Columns)
  function getFilterFromURL(): string {
    const ret: string[] = [];
    // Handle user-defined filters
    const filters: any[] = [];

    columns.map((column: ColumnHeaderType) => {
      const value = params.get(column.accessor)?.replaceAll(/'/g, "''") ?? null;
      const searchlightField = column.searchlightField;

      if (value && searchlightField === "DAYSPASTDUE") {
        addInvoiceDaysPastDueFilters(value, searchlightField, column.searchlightToken ?? null, filters, column.upperBoundToken ?? null);
      } else {
        filters.push({ field: column.searchlightField ?? null, token: column.searchlightToken ?? null, value: value ?? null });
      }
    });
    filters.forEach((filter: { field: string; token: string; value: string | null }) => {
      if (filter.value !== null) {
        ret.push(`(${filter.field} ${filter.token} '${filter.value}')`);
      }
    });

    // Handle pre-defined
    if (props?.dataSets[dataSetIndex].predefinedFilters) {
      const childPath = url.slice(url.lastIndexOf("/") + 1);
      props?.dataSets[dataSetIndex].predefinedFilters?.forEach((filter: tabFilter) => {
        if (filter.route === childPath) {
          ret.push(filter.searchlightFilter);
        }
      });
    }
    return ret.join(" AND ");
  }

  /**
   * * Helper function for current data set's fetch call. Calls to fetchParser (on onlyIds = true).
   * @param onlyIds - If true, return only ids. Otherwise, returns parsed Table Data Array.
   * @returns Generic Fetch result with records replaced by parsed Table Data Array
   */
  const fetch = async (variant?: FetchVariant, additionalFilters?: string): Promise<FetchResult> => {
    return await props.dataSets[dataSetIndex].data
      .fetchCall(
        [getFilterFromURL(), additionalFilters].filter((x) => !!x).join(" AND "),
        variant === "id" ? undefined : props.dataSets[dataSetIndex].data.includeOption,
        getSortFromURL(),
        variant === "id" || variant === "export" ? 10000 : pageSize,
        variant === "id" || variant === "export" ? 0 : props.pagination.pageNumber
      )
      .then((fetchResult: FetchResult) => {
        return { ...fetchResult, records: props.dataSets[dataSetIndex].data.fetchParser(fetchResult, variant) };
      });
  };

  /**
   * * Fetches parsed Table Data and sets loading, error, and pagintion states
   * @param disableLoad - If true, disables loading animation. Otherwise, show loading animation.
   */
  const fetchTableData = async (disableLoad?: boolean) => {
    props.states.setError(false);
    props.states.setErrorMessage("");
    props.states.setLoading(!disableLoad);
    try {
      const fetchResult = await fetch("all");
      props.dataSets[dataSetIndex].data.setTableData(fetchResult?.records ?? []);
      props.pagination.setPageCount(Math.ceil((fetchResult.totalCount ?? 0) / (fetchResult.pageSize ?? 12)));
      props.pagination.setTotalCount(fetchResult.totalCount ?? 0);
    } catch (err: any) {
      props.states.setError(true);
      props.states.setErrorMessage(APIClientUtils.buildErrorMessage(err));
    } finally {
      props.states.setLoading(false);
    }
  };

  /**
   * * Fetches all ids of rows, excluding those which are disabled
   * @returns Array of Table Data consisting only of ids
   */
  const fetchAllRowIds = async (additionalFilters?: string): Promise<TableData[]> => {
    try {
      return (await fetch("id", additionalFilters))?.records ?? [];
    } catch (err: any) {
      props.states.setError(true);
      props.states.setErrorMessage(APIClientUtils.buildErrorMessage(err));
      return [];
    }
  };

  const { dispatch, getTableBodyProps, headerGroups, prepareRow, page, visibleColumns, state } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: props.pagination.pageNumber ?? 0,
        pageSize: pageSize,
        hiddenColumns: props.dataSets[dataSetIndex].hiddenColumns ?? [],
        selectedRowIds: currentSelectedRows,
      },
      autoResetSelectedRows: false,
      stateReducer: (newState, action, prevState) => {
        newState = { ...newState, totalCount: props.pagination.totalCount } as TableState;
        if (action.type === "toggleAllRowsAllPagesSelected") {
          setCurrentSelectedRows(action.payload.currentSelectedRows);
          setAllRowsSelected(action.payload.allRowsSelected);
          return {
            ...newState,
            selectedRowIds: action.payload.currentSelectedRows,
          };
        } else if (action.type === "clearRowSelection") {
          setCurrentSelectedRows({});
          setShowSelectAll(false);
          setAllRowsSelected(false);
          return { ...newState, selectedRowIds: {} };
        } else if (action.type === "toggleRowSelected") {
          setCurrentSelectedRows(newState.selectedRowIds);
          setAllRowsSelected(Object.keys(newState.selectedRowIds).length === props.pagination.totalCount);
        }
        return newState;
      },
      manualPagination: true,
      pageCount: props.pagination.pageCount,
      getRowId: (row) => row.id,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      props.toggles?.showRowSelect
        ? hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              // eslint-disable-next-line react/display-name
              Header: (headerProps: HeaderProps<{ totalCount: number }>) => {
                const checkboxProps = getConditionalAllRowsSelectedProps({
                  headerProps: { ...headerProps, totalCount: props.pagination.totalCount },
                  isRowSelectable: (row) => !row.original.disableCheckbox,
                });

                useEffect(() => {
                  if (checkboxProps.indeterminate ?? false) {
                    setShowSelectAll(true);
                  } else if ((checkboxProps.indeterminate ?? false) === false && checkboxProps.checked === false) {
                    setShowSelectAll(false);
                  }
                }, [checkboxProps.indeterminate, checkboxProps.checked]);

                return (
                  <div className={`selection-wrapper`}>
                    <IndeterminateCheckbox {...checkboxProps} />
                  </div>
                );
              },
              // eslint-disable-next-line react/display-name
              Cell: (props: TableInstance) => {
                return (
                  <div className={`selection-wrapper`}>
                    {/*
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore */}
                    <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} disabled={props.row.original.disableCheckbox} />
                  </div>
                );
              },
            },
            ...columns,
          ])
        : null;
    }
  );

  function handleOnChangeDataSetIndex(index: number) {
    params.forEach((_, key: string) => {
      if (key !== "dataSetIndex") {
        params.delete(key);
      }
    });
    params.set("dataSetIndex", String(index));
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  const handleDropdownSelect = (value: string) => {
    const size = parseInt(value);
    if (props.pagination.totalCount < size * ((props.pagination.pageNumber ?? 0) + 1) - (size - 1) && props.pagination.setPageNumber) {
      props.pagination.setPageNumber(0);
    }
    setPageSize(size);
  };

  const handleRowRead = (row: any) => {
    return row.isUnread ? "" : " read";
  };

  /**
   * * Exposes useful functions to parent components via refs
   */
  useImperativeHandle(ref, () => ({
    hardRefresh() {
      fetchTableData();
      dispatch({ type: "clearRowSelection" });
    },
    softRefresh() {
      fetchTableData(true);
      dispatch({ type: "clearRowSelection" });
    },
    getCurrentSelectedRows() {
      const selectedRows = Object.keys(currentSelectedRows) as Array<string>;
      return props.dataSets[dataSetIndex].data.tableData.filter((val) => selectedRows.includes(val.id));
    },
  }));

  const handleClearSelection = () => {
    dispatch({ type: "clearRowSelection" });
  };

  const handleSelectAllPages = () => {
    fetchAllRowIds(props.dataSets[dataSetIndex].data.selectAllFilter).then((data: any) => {
      const dataFormatted = data.reduce((obj: any, item: any) => Object.assign(obj, { [item.id as string]: true }), {});
      setShowSelectAll(true);
      dispatch({
        type: "toggleAllRowsAllPagesSelected",
        payload: {
          currentSelectedRows: dataFormatted,
          allRowsSelected: true,
        },
      });
    });
  };

  /**
   * * Handles exporting selected row data in batches, returning an array of Table Data elements
   * @returns Table Data Array
   */
  const handleExport = async (): Promise<{ [key: string]: any }[]> => {
    let idList: string[] = [];
    // If no rows selected, then export all rows
    if (Object.keys(currentSelectedRows).length === 0) {
      await fetchAllRowIds().then((data: TableData[]) => {
        idList = data.map((elem: TableData) => elem.id);
      });
    }
    // Otherwise, export selected rows
    else {
      idList = Object.keys(state.selectedRowIds) as Array<string>;
    } 
    // Handle fetching row data for idList in batches
    const promises: Promise<FetchResult>[] = [];
    for (let i = 0; i < Math.ceil(idList.length / INTERVAL_SIZE); i++) {
      const idListBatch = idList.slice(i * INTERVAL_SIZE, i * INTERVAL_SIZE + INTERVAL_SIZE);
      promises.push(fetch("export", `${props.dataSets[dataSetIndex].rowSelectToken} in (${idListBatch.join(", ")})`));
    }
    // Flatten FetchResult[] to string[] of ids
    return await Promise.all(promises).then((values) =>
      values.reduce((prev: { [key: string]: any }[], curr: FetchResult) => {
        return [...prev, ...(curr?.records ?? [])];
      }, [])
    );
  };

  /**
   * * Removes filter causing Searchlight Error
   */
  const removeBadFilter = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete(lastFilterItem);
    history.push({ search: params.toString().replace(/[?&]$/, "") });
    setLastFilterItem("");
  };

  // * On mount, handle default sort and fetching table data
  useEffect(() => {
    // Handle default sort
    if (props?.dataSets[dataSetIndex]?.defaultSort && params.get("sort") === null) {
      params.delete("sort");
      const parsedDefaultSort =
        props?.dataSets[dataSetIndex]?.defaultSort
          ?.split(", ")
          .map(
            (field: string) => field + (props?.dataSets[dataSetIndex]?.defaultSortToken ? ` ${props?.dataSets[dataSetIndex]?.defaultSortToken}` : "")
          )
          .join(", ") ?? "";
      params.append("sort", parsedDefaultSort);
      history.push({ search: params.toString().replace(/[?&]$/, "") });
    }
    // Handle fetch table data
    fetchTableData();
  }, [props.pagination.pageNumber, pageSize, dataSetIndex]);

  // * Handle onRowSelect callback
  useEffect(() => {
    if (props.onRowSelect) {
      const selectedRowIds = Object.keys(currentSelectedRows) as Array<string>;
      props.onRowSelect(props.dataSets[dataSetIndex].data.tableData.filter((val) => selectedRowIds.includes(val.id)));
    }
  }, [currentSelectedRows]);

  return (
    <div
      className={`main-table-wrapper`}
      ref={ref}
      style={{
        gridTemplateRows:
          props.toggles?.showCaption && props.toggles?.showNavigation
            ? "5.625rem auto 3rem"
            : props.toggles?.showCaption
            ? "5.625rem auto"
            : props.toggles?.showNavigation
            ? "auto 3rem"
            : "auto",
      }}
    >
      {/* Table Caption */}
      {props.toggles?.showCaption && (
        <div className={`table-caption ${!props.title ? "table-caption-no-title" : ""}`}>
          {props.title && <p className={`title`}>{props.title}</p>}
          {props.toggles?.showExportBtn && (
            <ExportButton
              getExportData={() => handleExport()}
              exportFileName={props.dataSets[dataSetIndex].export?.exportFileName ?? ""}
              alignIcon="left"
              disabled={props.states.isLoading || props.states.isError || props.dataSets[dataSetIndex].data.tableData.length <= 0}
            />
          )}
          {props.headerBtns &&
            props.headerBtns.map((headerBtn: HeaderButton) => {
              return <>{(Object.keys(currentSelectedRows).length === 0 || !headerBtn.hideOnRowSelect) && headerBtn.component}</>;
            })}
          {props.rowSelectBtns &&
            Object.keys(currentSelectedRows).length !== 0 &&
            props.rowSelectBtns.map((elem, index: number) => {
              if (elem === "divider") {
                return <div className="table-button-divider">|</div>;
              } else {
                return (
                  <Button
                    key={`rowSelectButton-${index}`}
                    {...elem}
                    onClick={() => elem.callback(Object.keys(currentSelectedRows) as Array<string>)}
                  />
                );
              }
            })}
          <div className="input-dropdown-wrapper">
            {props.dataSets.length > 1 && (
              <Dropdown
                icon={<DropdownArrow />}
                defaultValue={props.dataSets[dataSetIndex].displayName}
                options={
                  props.dataSets.map((dataSet: dataSet, index: number) => {
                    return {
                      id: dataSet.id,
                      value: dataSet.displayName,
                      onClickValue: index,
                    };
                  }) ?? []
                }
                onClick={handleOnChangeDataSetIndex}
              />
            )}
            {/* TODO: Add onChange handler for Filtering by Input */}
            {props.toggles?.showSearchbar ? <InputWithIcon onChange={(val: string) => null} /> : null}
          </div>
        </div>
      )}
      <OverlayScrollbarsComponent
        className={`os-root ${showSelectAll ? "os-root-select-all" : ""}`}
        options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: false }}
      >
        <div
          className={`table-grid table-grid-${showSelectAll ? "select-all" : "default"} ${
            props.states.isLoading || props.states.isError || !page?.length ? "table-grid-full-height" : ""
          }`}
        >
          {/* Table Head */}
          <div className="table-head">
            {headerGroups.map((headerGroup, index) => (
              <div className={`row`} {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <div
                      className={`head-col ${
                        index === 0 && props.toggles?.showRowSelect
                          ? "head-col-first"
                          : index === headerGroup.headers.length - 1
                          ? "head-col-last"
                          : index === 0
                          ? "head-col-no-checkbox-first"
                          : ""
                      }`}
                      {...column.getHeaderProps({
                        style: { width: column.width },
                      })}
                      key={`column-${index}`}
                    >
                      <div className={`cell cell-align-${(headerGroup.headers[index] as any)?.alignment ?? "center"}`}>
                        {/* Column Content */}
                        {index == 0 && props.toggles?.showRowSelect ? (
                          column.render("Header")
                        ) : (
                          <>
                            <SortButton
                              headerProps={headerGroup.headers[index]}
                              label={column.render("Header")}
                              disabled={props.states.isLoading || (column.Header === "Status" && url.includes("closed"))}
                              isDefault={
                                (headerGroup.headers[index] as any).sortField === props.dataSets[dataSetIndex].defaultSort ||
                                (headerGroup.headers[index] as any).searchlightField === props.dataSets[dataSetIndex].defaultSort
                              }
                            />
                            {!url.includes("closed") || (url.includes("closed") && column.Header !== "Status") ? (
                              <FilterButton
                                variant="transparent"
                                size="sm"
                                anchor={index !== headerGroup.headers.length - 1 ? "center" : "left"}
                                disabled={props.states.isLoading}
                                headerProps={headerGroup.headers[index]}
                              />
                            ) : (
                              <></>
                            )}
                            {(headerGroup.headers[index] as any).HeaderTooltip && (
                              <Tooltip
                                title={(headerGroup.headers[index] as any).HeaderTooltip}
                                placement={(headerGroup.headers[index] as any).HeaderTooltipDirection ?? "top-start"}
                              >
                                <IconButton size="small">
                                  <TooltipIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
            {showSelectAll ? (
              <div className={`row row-no-hover`} key={`headerGroup-select-all`}>
                <div className={"table-select-all"}>
                  {allRowsSelected ? (
                    <p>
                      All <span>{Object.keys(currentSelectedRows).length}</span> items have been selected.
                    </p>
                  ) : (
                    <p>
                      All <span>{Object.keys(currentSelectedRows).length}</span> items on this page have been selected.
                    </p>
                  )}
                  <p className={"select-all-button"} onClick={allRowsSelected ? handleClearSelection : handleSelectAllPages}>
                    {allRowsSelected ? "Clear Selection" : `Select all ${props.pagination.totalCount} items`}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Table Body */}
          <div className="table-body" {...getTableBodyProps()} style={{ position: "relative" }}>
            {props.states.isLoading ? (
              <Loading isRelative />
            ) : props.states.isError ? (
              <SoftAlert
                alertMessage={props.states.errorMessage}
                onClick={() => removeBadFilter()}
                buttonText={"Clear Filter"}
                badParam={lastFilterItem}
              ></SoftAlert>
            ) : page?.length ? (
              page.map((row, index) => {
                prepareRow(row);

                return (
                  <div
                    className={`row${handleRowRead(props.dataSets[dataSetIndex].data.tableData[index])}`}
                    {...row.getRowProps()}
                    key={`row-${index}`}
                    onClick={(e: MouseEvent<HTMLTableRowElement>) => props.dataSets[dataSetIndex].handleRowClick(e, row)}
                  >
                    {row.cells.map((cell, index) => {
                      const sortIsActive = (visibleColumns[index] as any)?.sortField
                        ? params.get("sort") === (visibleColumns[index] as any)?.sortField ||
                          params.get("sort") ===
                            (visibleColumns[index] as any)?.sortField
                              .split(", ")
                              .map((field: string) => field + " DESC")
                              .join(", ")
                        : params.get("sort")?.includes((visibleColumns[index] as any)?.searchlightField);
                      return (
                        <div
                          style={{
                            width: props.toggles?.showRowSelect && index == 0 ? "auto" : visibleColumns[index]?.width,
                          }}
                          className={`col col-align-${(visibleColumns[index] as any)?.alignment ?? "center"} ${
                            index === row.cells.length - 1
                              ? "col-last "
                              : index === 0 && props.toggles?.showRowSelect
                              ? "col-first"
                              : index === 0
                              ? "col-no-checkbox-first"
                              : "" 
                            }`}
                          {...cell.getCellProps()}
                          key={`cell-${index}`}
                        >
                          {cell.render("Cell")}
                          {index !== 0 && sortIsActive && <div className="sort-placeholder"></div>}
                          {index !== 0 && (visibleColumns[index] as any)?.showFilter && <div className="filter-placeholder"></div>}
                        </div>
                      );
                    })}
                    {props.hoverBar && <HoverBar barriers={false} />}
                  </div>
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </OverlayScrollbarsComponent>
      {props.pagination.totalCount !== 0 && props.toggles?.showNavigation && (
        <div className={`table-navigation`}>
          <Dropdown
            iconAlign={"none"}
            className={`table-dropdown ${props.states.isLoading || props.pagination.totalCount <= 25 ? "disabled" : ""}`}
            defaultValue={"25 items per page"}
            onClick={(onClickValue: string) => handleDropdownSelect(onClickValue)}
            options={DROPDOWNOPTIONS}
            displayValue={`${pageSize * (props.pagination.pageNumber ?? 0) + 1}-${Math.min(
              pageSize * ((props.pagination.pageNumber ?? 0) + 1),
              props.pagination.totalCount
            )}`}
          />
          <p>of {props.pagination.totalCount}</p>
          <div className={`navigation-btn-grp`}>
            <button
              className={`navigation-btn`}
              onClick={() =>
                props.pagination.setPageNumber && props.pagination.setPageNumber((currPage: number) => (currPage === 0 ? 0 : currPage - 1))
              }
              disabled={props.states.isLoading || props.pagination.totalCount <= 25}
            >
              <LeftArrow />
            </button>
            <button
              className={`navigation-btn`}
              onClick={() =>
                props.pagination.setPageNumber &&
                props.pagination.setPageNumber((currPage: number) =>
                  (currPage + 1) * pageSize >= props.pagination.totalCount ? currPage : currPage + 1
                )
              }
              disabled={props.states.isLoading || props.pagination.totalCount <= 25}
            >
              <RightArrow />
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Table.defaultProps = defaultProps;

export default Table;
