import React from "react";
import "./Arrow.scss";

// Define default Button Props
export interface Props {
  className?: string;
  isFlipped?: boolean;
  borderWidth?: number;
  direction: "right" | "left" | "center" | "up" | "down";
}

// Define Button with icon React Element
export default function Arrow(props: Props): React.ReactElement {
  return (
    <div
      style={{ borderWidth: `${props.borderWidth}px` ?? "8px" }}
      className={`arrow-${props.direction}${
        props.isFlipped ? "-flipped" : ""
      } ${props.className ?? ""}`}
    ></div>
  );
}
