import React, { useState } from "react";
import { DataMode } from "../../../types/enums";
import { default as Edit } from "./EditCompanyInfo";
import { default as View } from "./ViewCompanyInfo";
import { OnboardContext } from "../../../contexts/OnboardContext";
import "./CompanyInfo.scss";

function CompanyInfo(): React.ReactElement {
  const { dataMode } = React.useContext(OnboardContext) as OnboardType;
  const [showCancel, setShowCancel] = useState<boolean>(false);

  return (
    <div className="company-info-wrapper">
      {dataMode === DataMode.Edit && <Edit showCancel={showCancel} />}
      {dataMode === DataMode.View && <View setShowCancel={setShowCancel} />}
    </div>
  );
}

export default CompanyInfo;
