import React from "react";
import Button from "../Button/Button";
import "./CenterPopup.scss";

interface CenterPopupProps {
  count?: number;
  leftBtnText?: string;
  middleRightBtnText?: string;
  rightBtnText?: string;
  headerText?: string;
  mainDisplay?: { Cell(): JSX.Element };
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickMiddleRightBtn?: React.MouseEventHandler<HTMLButtonElement>;
  onClickRightBtn?: React.MouseEventHandler<HTMLButtonElement>;
}

const displayMembers = (count: number): string => {
  if (count === 1) {
    return "Remove Team Member";
  } else {
    return `Remove ${count} Team Members`;
  }
};

const CenterPopup = (props: CenterPopupProps): React.ReactElement => {
  return (
    <div className="centerpopup-background">
      <div className="centerpopup-wrapper">
        <div className="centerpopup-container">
          <p className="centerpopup-header">{props.headerText ? props.headerText : displayMembers(props?.count ?? 0)}</p>
          <p className="centerpopup-text">{props.mainDisplay?.Cell()}</p>
          <div className="centerpopup-btn-grp">
            <div className="centerpopup-btn-left">
              <Button variant="grey" onClick={props.onClickCancel}>
                {props.leftBtnText ? props.leftBtnText : `Cancel`}
              </Button>
            </div>
            <div className="centerpopup-btn-right">
              <Button onClick={props.onClickRightBtn}>{props.rightBtnText ? props.rightBtnText : `Remove`}</Button>
            </div>
            { props?.middleRightBtnText && (
              <div className="centerpopup-btn-middle-right">
                <Button onClick={props.onClickMiddleRightBtn}>{props.middleRightBtnText ?? `Remove`}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterPopup;
