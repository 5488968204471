import React from "react";
import "./Legend.scss";

type LegendData = {
  color: string;
  id: string;
  value: number;
  onClick: (id: string) => void;
};

type LegendProps = {
  className?: string;
  data: LegendData[];
};

export default function Legend(props: LegendProps): React.ReactElement {
  return (
    <div className={`${props.className ?? ""} legend`}>
      {props.data.map((val: LegendData) => {
        return (
          <React.Fragment key={val.id}>
            <div className={`legend-dot`} style={{ backgroundColor: val.color }} onClick={() => val.onClick(val.id)} />

            <p className={`legend-value`} onClick={() => val.onClick(val.id)}>
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(val.value)}
            </p>

            <p className={`legend-label`} onClick={() => val.onClick(val.id)}>
              {val.id}
            </p>
          </React.Fragment>
        );
      })}
    </div>
  );
}
