import React, { useRef } from "react";
import { EmailAttachment } from "../../../../../library/Icons/Icons";

interface AttachmentProps  {
    key: number;
    handleFileSelect: (files: FileList) => void;
    fileSelected: boolean;
}

export default function Attachment(props: AttachmentProps): React.ReactElement {
    const inputFile = useRef<HTMLInputElement | null>(null);

    const imageClick = () => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleFileSelect(e.target.files ?? new FileList());
    }

    return (
        <div key={props.key} className={"rdw-custom-wrapper"} >
            <div className={"rdw-option-wrapper rdw-custom-option-wrapper"} style={{ backgroundColor: props.fileSelected ? "#EEF0F6" : "none" }}>
                <input 
                    className={"na-body-message-attachment-input"}
                    type="file"
                    ref={inputFile}
                    accept=".jpg, .png, .pdf, .doc, .docx, .xlsx, .csv"
                    onChange={handleOnChange}
                    multiple
                />
                <div onClick={imageClick} className={`${props.fileSelected ? "rdw-custom-option-file-selected" : "rdw-custom-option-file"}`}>
                    <EmailAttachment />
                </div>
            </div>
        </div>
    )
}