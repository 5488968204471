import React, { useEffect, useState, MouseEvent } from "react";
import LoadingDots from "../../library/Loading/LoadingDots";
import Button from "../../library/Button/Button";
import { BackArrow } from "../../library/Icons/Icons";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { buildAuthRedirectURL } from "../../../auth/authConfig";
import Utils from "../../../utils/utils";
import { OnboardStep } from "../../../types/enums";
import QuickbooksLogo from "../../../assets/qb-logo.svg";
import XeroLogo from "../../../assets/XeroLogo.svg";
import "./ERPSelect.scss";

const erpList: ERPModel[] = [
  {
    name: "Quickbooks Online",
    appId: "00000000-0000-0000-0000-000000000001",
    iconUrl: QuickbooksLogo,
    width: "171px",
  },
  {
    name: "Xero",
    appId: "00000000-0000-0000-0000-000000000002",
    iconUrl: XeroLogo,
    width: "100px",
  },
  {
    name: "Other",
    appId: "",
    iconUrl: "",
    width: "",
  },
];

function ERPSelect(): React.ReactElement {
  const { setOnboardStep } = React.useContext(OnboardContext) as OnboardType;
  const { selectedERP, setSelectedERP, setOnboardData } = React.useContext(AppContext) as AppType;
  const [showBackButton, setShowBackButton] = useState(false);

  const handleOpenPopup = (e: MouseEvent, erp: ERPModel) => {
    e.preventDefault();

    setOnboardData((prev: OnboardDataModel) => {
      return { ...prev, erp: { name: erp.name, appId: erp.appId, data: { authCode: "", realmId: "" } } };
    });
    setSelectedERP(erp);
    setShowBackButton(true);

    const url = buildAuthRedirectURL(erp.name, erp.appId);
    Utils.openPopup(url, "", window, 900, 800);
  };

  const handleGoBack = () => {
    setOnboardData((prev: OnboardDataModel) => {
      return { ...prev, erp: { name: "", appId: "", data: { authCode: "", realmId: "" } } };
    });
    setSelectedERP(null);
    setShowBackButton(false);
  };

  // Handle Authorization Code message from ERP Connector
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === window.location.origin && event.data.type === "auth_code") {
        setOnboardData((prev: OnboardDataModel) => {
          return {
            ...prev,
            erp: { name: event.data.erp, appId: event.data.appId, data: { authCode: event.data.code, realmId: event.data.realmId } },
          };
        });
        setOnboardStep(OnboardStep.CreateAccount);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="erp-select-wrapper">
      {showBackButton && (
        <Button
          className="back-arrow-button"
          variant="transparent"
          icon={<BackArrow />}
          alignIcon="left"
          onClick={() => handleGoBack()}
          data-testid="SelectERPGoBack"
        >
          Select Your Accounting System
        </Button>
      )}

      {!selectedERP ? (
        <>
          <h1>Let's get started.</h1>
          <p className="p-body desc">
            In order to show you the best insights about your accounts receivable team, we will need to connect to your team's accounting system.
            Please select one of the options below to get started.
          </p>
          <div className="options-container">
            {erpList.map((option) => {
              const isOther = option.name === "Other";
              return (
                <div
                  key={option.name}
                  className={`erp-option ${isOther ? "erp-option-other" : ""}`}
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    if (isOther) {
                      setOnboardStep(OnboardStep.OtherERP);
                      setSelectedERP(null);
                    } else {
                      handleOpenPopup(e, option);
                    }
                  }}
                  data-testid={`SelectERP${option.name}Button`}
                >
                  {isOther ? <>{option.name}</> : <img src={option?.iconUrl} style={{ width: option.width ?? "auto" }} alt={option.name} />}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <h1>Connecting...</h1>
          <p className="p-body desc">
            Please wait while we establish a connection with <strong>{selectedERP.name}</strong>. In a few moments you will see a pop-up window open
            requesting you to enter your {selectedERP.name} information. This process is totally secure and will link your accounts with Lockstep
            quickly and easily.
          </p>
          <p className="p-body desc">
            <a
              data-testid="SelectERPConnectingClickHereLink"
              href="#"
              rel="noreferrer"
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                handleOpenPopup(e, selectedERP);
              }}
            >
              Click here
            </a>{" "}
            if your window does not open in 5 seconds. Make sure you have pop-up blockers turned off.
          </p>
          <LoadingDots className="loading-dots" />
        </>
      )}
    </div>
  );
}

export default ERPSelect;
