/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, Dispatch, FormEvent, useRef, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Modal, TextField } from "@mui/material";
import { Briefcase, Save, Company, Email, Fax, Globe, Notes, Location, Phone, Upload } from "../../../library/Icons/Icons";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../library/Button/Button";
import { Input } from "../../../library/Input/Input";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { DataMode } from "../../../../types/enums";
import { countries } from "../../../../Countries";
import { formatDate } from "../../../../db/utils/date";
import { AppContext } from "../../../../contexts/AppContext";
import { companiesClient } from "../../../../db/accessor";

type EditCompanyProfileProps = {
  setDataMode: Dispatch<DataMode>;
  setShowToast: Dispatch<boolean>;
  setToastError: Dispatch<boolean>;
  defaultData: CompanyModel;
  setDefaultData: Dispatch<CompanyModel>;
  codeDefinitions: CodeDefinitionModel[];
};

export default function EditCompanyProfile(props: EditCompanyProfileProps): React.ReactElement {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [companyInfo, setCompanyInfo] = useState<CompanyModel>(props.defaultData);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [saveLoad, setSaveLoad] = useState<boolean>(false);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const handleSave = async () => {
    const editableFields: Partial<CompanyModel> = {
      companyName: companyInfo.companyName,
      arEmailAddress: companyInfo.arEmailAddress,
      phoneNumber: companyInfo.phoneNumber,
      faxNumber: companyInfo.faxNumber,
      address1: companyInfo.address1,
      address2: companyInfo.address2,
      city: companyInfo.city,
      stateRegion: companyInfo.stateRegion,
      postalCode: companyInfo.postalCode,
      country: companyInfo.country,
      taxId: companyInfo.taxId,
      companyClassificationCodeDefId: companyInfo.companyClassificationCodeDefId,
      website: companyInfo.website,
      description: companyInfo.description,
    };
    try {
      setSaveLoad(true);
      await companiesClient.patchCompany(companyInfo.companyId, { ...editableFields });
      props.setDefaultData(companyInfo);
      props.setToastError(false);
    } catch (e) {
      props.setToastError(true);
    }

    props.setDataMode(DataMode.View);
    props.setShowToast(true);
    setSaveLoad(false);
    return false;
  };

  const handleUpload = () => {
    console.log("uploading pic");
  };

  return (
    <div className="profile-detail">
      <div className="profile-edit-bar">
        <div className="btn-grp">
          <Button
            icon={<Save />}
            data-testid="CompanyProfileSaveButton"
            variant="primary"
            onClick={() => submitBtnRef.current?.click()}
            alignIcon="left"
            loading={saveLoad}
          >
            Save
          </Button>
          <Button data-testid="CompanyProfileCancelButton" variant="secondary" onClick={() => setShowCancel(true)} disabled={saveLoad}>
            Cancel
          </Button>
          {/* TODO: Move to reusable component */}
          <Modal open={showCancel} onClose={() => setShowCancel(false)}>
            <div className="as-modal">
              <h3>Cancel Changes?</h3>
              <p>Are you sure you want to cancel your changes?</p>
              <div className="btn-grp">
                <Button variant="secondary" onClick={() => setShowCancel(false)}>
                  Close
                </Button>
                <Button
                  variant="error"
                  onClick={() => {
                    setCompanyInfo(props.defaultData);
                    props.setDataMode(DataMode.View);
                  }}
                >
                  Yes, Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="last-updated">
          <p>Updated {formatDate(companyInfo?.modified)}</p>
          <p>by {companyInfo?.modifiedUserId === userStatus.userId ? "You" : companyInfo?.modifiedUserName}</p>
        </div>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
        <div className="container">
          <form
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              handleSave();
              return false;
            }}
            className="data-container"
            id="EditCompanyInfoForm"
          >
            <div className="name-row">
              <Userpic id="user-pic" />
              <Button
                icon={<Upload />}
                alignIcon="left"
                data-testid="CompanyProfileCancelButton"
                variant="secondary"
                onClick={handleUpload}
                className="upload-btn"
              >
                Upload Logo
              </Button>
              <p className="upload-description">Maximum 2MB file size</p>
            </div>
            <div className="form-row">
              <div className="tag">
                <Company />
              </div>
              <Input
                label={"Company Name (Required)"}
                defaultValue={companyInfo.companyName}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, companyName: value };
                  })
                }
                className={"field-md"}
                placeholder="Blushing Blooms"
                data-testid="EditCompanyInfoName"
                required
              />
            </div>
            <div className="form-row">
              <div className="tag">
                <Email />
              </div>
              <Input
                label={"Email"}
                defaultValue={companyInfo.arEmailAddress}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, arEmailAddress: value };
                  })
                }
                placeholder="ar@blushing.com"
                className={"field-md"}
                autocomplete="email"
                data-testid="EditCompanyInfoEmail"
                type="email"
              />
            </div>
            <div className="form-row">
              <div className="tag">
                <Phone />
              </div>
              <Input
                label={"Phone"}
                defaultValue={companyInfo.phoneNumber}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, phoneNumber: value };
                  })
                }
                placeholder="+1 123.456.7890"
                className={"field-md"}
                autocomplete="tel"
                data-testid="EditCompanyInfoPhone"
                type="tel"
                maxLength={20}
              />
            </div>
            <div className="form-row">
              <div className="tag">
                <Fax />
              </div>
              <Input
                label={"Fax"}
                defaultValue={companyInfo.faxNumber}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, faxNumber: value };
                  })
                }
                placeholder="+1 456.789.0123"
                className={"field-md"}
                autocomplete="tel"
                data-testid="EditCompanyInfoFax"
                type="tel"
                maxLength={20}
              />
            </div>
            <div className="form-row">
              <div className="tag">
                <Location />
              </div>
              <Input
                label={"Company Street Address"}
                defaultValue={companyInfo.address1}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, address1: value };
                  })
                }
                placeholder="122 Main Street"
                className={"street-field"}
                autocomplete="street-address"
                data-testid="EditCompanyInfoAddressLine1"
              />
              <Input
                label={{ regularText: "Unit or Suite ", extraText: "(Optional)" }}
                defaultValue={companyInfo.address2}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, address2: value };
                  })
                }
                placeholder="Suite 100"
                className={"field-sm addr-field"}
                data-testid="EditCompanyInfoAddressLine2"
              />
            </div>
            <div className="form-row address-row">
              <Input
                label={"Postal Code"}
                defaultValue={companyInfo.postalCode}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, postalCode: value };
                  })
                }
                placeholder="94016"
                className={"field-sm no-icon-field"}
                autocomplete="postal-code"
                data-testid="EditCompanyInfoPostalCode"
                maxLength={10}
              />
              <Input
                label={"City"}
                defaultValue={companyInfo.city}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, city: value };
                  })
                }
                placeholder="San Francisco"
                className={"city-field addr-field"}
                autocomplete="address-level2"
                data-testid="EditCompanyInfoCity"
              />
              <Input
                label={"State/Region"}
                defaultValue={companyInfo.stateRegion}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, stateRegion: value };
                  })
                }
                placeholder="CA"
                className={"field-sm addr-field"}
                autocomplete="address-level1"
                data-testid="EditCompanyInfoState"
              />
            </div>
            <div className="form-row address-row">
              <div className="sb-wrapper no-icon-field">
                <div className="sb-label-container">
                  <label className="sb-label" htmlFor="DefaultCountryInput">
                    Country
                  </label>
                </div>
                <Autocomplete
                  options={countries}
                  className="country-field"
                  onChange={(_: any, value: { id: string; label: string }) => {
                    if (value) {
                      setCompanyInfo((prev: CompanyModel) => {
                        return { ...prev, country: value.label };
                      });
                    } else {
                      setCompanyInfo((prev: CompanyModel) => {
                        return { ...prev, country: "United States" };
                      });
                    }
                  }}
                  value={companyInfo.country}
                  disableClearable
                  isOptionEqualToValue={(option: any, value: any) => option.label === value}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        autoComplete: "country",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="tag">
                <Briefcase />
              </div>
              <Input
                label={"EIN"}
                value={companyInfo.taxId ?? undefined}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, taxId: value };
                  })
                }
                placeholder="12-3456789"
                className={"field-md"}
                data-testid="EditCompanyInfoPhone"
                maxLength={20}
              />
            </div>
            <div className="form-row">
              <div className="no-tag"></div>
              <div className="sb-wrapper">
                <span className="sb-label-container">
                  <label className="sb-label">Entity Classification</label>
                </span>
                <Autocomplete
                  className="field-md"
                  options={props.codeDefinitions.map((codeDef: CodeDefinitionModel) => {
                    return {
                      id: codeDef.codeDefinitionId,
                      label: codeDef.codeDescription,
                    };
                  })}
                  onChange={(_: any, value: { id: string; label: string }) => {
                    setCompanyInfo((prev: CompanyModel) => {
                      return {
                        ...prev,
                        companyClassificationCodeDefId: value.id,
                        companyClassificationCodeDefinition:
                          props.codeDefinitions.find((elem) => elem.codeDefinitionId === value.id) ?? ({} as CodeDefinitionModel),
                      };
                    });
                  }}
                  value={companyInfo.companyClassificationCodeDefinition?.codeDescription || null}
                  disableClearable
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.label === (value.label || value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="tag">
                <Globe />
              </div>
              <Input
                label={"Company Website"}
                defaultValue={companyInfo.website}
                onChange={(value) =>
                  setCompanyInfo((prev: CompanyModel) => {
                    return { ...prev, website: value };
                  })
                }
                placeholder="www.bluebottle.com"
                className={"field-md"}
                data-testid="EditCompanyInfoWebsite"
              />
            </div>
            <div className="form-row">
              <div className="tag">
                <Notes />
              </div>
              <div className="sb-wrapper notes-field">
                <span className="sb-label-container">
                  <label className="sb-label">Description</label>
                </span>
                <textarea
                  className="sb-input notes-field"
                  placeholder={"Enter description"}
                  defaultValue={companyInfo?.description ?? ""}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setCompanyInfo((prev: CompanyModel) => {
                      return { ...prev, description: e.target.value };
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <button ref={submitBtnRef} type="submit" style={{ display: "none" }}>
              submit
            </button>
          </form>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
