import { Tooltip } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Ellipses } from "../Icons/Icons";
import "./Dropdown.scss";

interface DropdownProps {
  className?: string;
  options: { value: string; onClickValue?: string | number; id: string; altDisplay?: string }[];
  defaultValue?: string | null;
  errorMessage?: string;
  header?: string;
  onSelect?: (value: string) => void;
  iconAlign?: "left" | "right" | "none";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  showEllipses?: boolean;
  displayValue?: string;
  icon?: React.ReactElement;
  iconOnly?: boolean;
  tooltip?: string;
}

export default function Dropdown(props: DropdownProps): React.ReactElement {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.defaultValue ? props.defaultValue : props.options.length > 0 ? props.options[0].value : "");

  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as unknown as Node)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  
  return (
    <div className={`dropdown-wrapper ${props.className ?? ""}`}>
      {props?.header && <p className={"header"}>{props.header}</p>}

      <div ref={dropdownRef}>
        <Tooltip
          title={props.tooltip ?? ""}
          placement="bottom-start"
          open={showTooltip && !isMenuOpen}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <div
            className={`dropdown ${props.errorMessage ? "dropdown-error" : ""} ${isMenuOpen ? "dropdown-open" : ""}`}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              if (!props.className?.includes("disabled")) {
                setMenuOpen((prev: boolean) => !prev);
              }
            }}
          >
            {props.showEllipses ? (
              <Ellipses />
            ) : props.iconOnly ? (
              <>{props.icon}</>
            ) : (
              <>
                {props.iconAlign === "left" && props.icon}
                {props.displayValue ? props.displayValue : value}
                {props.iconAlign === "right" && props.icon}
              </>
            )}
          </div>
        </Tooltip>
        {isMenuOpen && (
          <div className={"dropdown-options"}>
            {props.options.map((obj) => {
              return (
                <div
                  key={obj.id}
                  onClick={() => {
                    setMenuOpen(false);
                    setValue(obj.value);
                    if (props.onClick) {
                      props.onClick(obj.onClickValue);
                    }
                    if (props.onSelect) {
                      props.onSelect(obj.value);
                    }
                  }}
                >
                  {obj.altDisplay ? obj.altDisplay : obj.value}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {props?.errorMessage && <p className={"error-message"}>{props.errorMessage}</p>}
    </div>
  );
}

Dropdown.defaultProps = {
  iconAlign: "right",
};
