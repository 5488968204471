import APIClient from "../APIClient";

export class UserAccountsClient {
  /**
   * * GET on /api/v1/UserAccounts/{id}
   * @param id - Database ID associated with a user
   * @param include
   * @returns Promise of type UserAccountModel or Exception
   */
  getUserAccount(id: string, include?: string): Promise<UserAccountModel> {
    const url = `api/v1/UserAccounts/${id}`;

    const options = {
      params: {
        include,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  /**
   * * PATCH on /api/v1/UserAccounts/{id}
   * @param id - Database ID associated with a user
   * @param body: The fields to change
   * @returns Promise of type UserAccountModel or Exception
   */
  patchUserAccount(id: string, body: Partial<UserAccountModel>): Promise<UserAccountModel> {
    const url = `api/v1/UserAccounts/${id}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }

  /**
   * * DELETE on /api/v1/UserAccounts/{id}
   * @param id - Database ID associated with a user
   * @returns Promise of type ActionResultModel or Exception
   */
  deleteUserAccount(id: string): Promise<ActionResultModel> {
    const url = `api/v1/UserAccounts/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }

  /**
   * * POST on /api/v1/UserAccounts/reenable
   * @param id: The unique Lockstep Platform ID number of this User
   * @returns Promise of type InviteModel[] or Exception
   */
  postReenable(id: string): Promise<ActionResultModel> {
    const url = "api/v1/UserAccounts/reenable";
    const options = {
      params: {
        id,
      },
    };
    return APIClient.post(url, undefined, options).then((res) => res.data);
  }

  /**
   * * POST on /api/v1/UserAccounts/invite
   * @param body: List of emails to send invites to
   * @returns Promise of type InviteModel[] or Exception
   */
  postInvite(body: { email: string }[]): Promise<InviteModel[]> {
    const url = "api/v1/UserAccounts/invite";
    return APIClient.post(url, body).then((res) => res.data);
  }

  /**
   * * GET on /api/v1/UserAccounts/invite
   * @param code: The code of the invite
   * @returns Promise of type InviteDataModel or Exception
   */
  getInvite(code: string): Promise<InviteDataModel> {
    const url = "api/v1/UserAccounts/invite";
    const options = {
      params: {
        code,
      },
    };
    return APIClient.get(url, options, true).then((res) => res.data);
  }

  /**
   * * Post on /api/v1/UserAccounts/transfer-ownership
   * @param id - Database ID associated the target user
   * @param
   * @returns Promise of type UserAccountModel or Exception
   */
  transferOwnership(id?: string): Promise<UserAccountModel> {
    const url = `api/v1/UserAccounts/transfer-owner`;
    if (id) {
      return APIClient.post(url, { targetUserId: id }).then((res) => res.data);
    } else {
      throw new Error("The parameter 'id' must be defined.");
    }
  }

  /**
   * * GET on /api/v1/UserAccounts
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type UserAccountModelFetchResult or Exception
   */
  queryUserAccounts(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<UserAccountModelFetchResult> {
    const url = "api/v1/UserAccounts/query";

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }
}
