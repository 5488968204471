import APIClient from "../APIClient";

export class ContactsClient {
  /**
   * * POST on /api/v1/Contacts
   * @param body: The fields to create the contact with
   * @returns Promise of type ContactModel or Exception
   */
  createContact(
    body: {
      companyId: string;
      contactName: string;
      roleCode: string;
      emailAddress: string;
      phone: string;
    }[]
  ): Promise<ContactModel> {
    const url = `api/v1/Contacts`;
    return APIClient.post(url, body).then((res) => {
      return res.data[0];
    });
  }

  /**
   * * GET on /api/v1/Contacts/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type ContactModelFetchResult or Exception
   */
  queryContacts(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<ContactModelFetchResult> {
    const url = "api/v1/Contacts/query";

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }

  /**
   * * DELETE on /api/v1/Contacts/{id}
   * @param id - Database ID associated the target contact
   * @returns Promise of type ActionResultModel or Exception
   */
  disableContact(id: string): Promise<ActionResultModel> {
    const url = `api/v1/Contacts/${id}`;
    return APIClient.delete(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Contacts/
   * @param id The id of the contact retrieving
   * @param include Specify options
   * @returns Promis of type Contact Model or Exception
   */
  getContact(id: string, include?: string): Promise<ContactModel> {
    const url = `api/v1/Contacts/${id}`;
    const config = { params: { include } };
    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }

  /**
   * * PATCH on /api/v1/Contacts/{id}
   * @param id - Database ID associated with a contact
   * @param body: The fields to change
   * @returns Promise of type ContactModel or Exception
   */
  patchContact(id: string, body: Partial<ContactModel>): Promise<ContactModel> {
    const url = `api/v1/Contacts/${id}`;
    return APIClient.patch(url, body).then((res) => res.data);
  }
}
