import APIClient from "../APIClient";

export class CodeDefinitionsClient {
  public getCodeDefinition = (id: string, include?: string): Promise<CodeDefinitionModel> => {
    const url = `api/v1/CodeDefinitions/${id}`;

    const options = {
      params: {
        include,
      },
    };

    return APIClient.get(url, options).then((res) => res.data);
  };

  public queryCodeDefinitions = (
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<CodeDefinitionModelFetchResult> => {
    const url = "api/v1/CodeDefinitions/query";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  };
}
