import React from "react";
import { useRouteMatch, Switch, Route } from "react-router";
import ActiveMembers from "./ActiveMembers/ActiveMembers";
import Invitations from "./Invitations/Invitations";
import RemovedMembers from "./RemovedMembers/RemovedMembers";
import "./UserPermissions.scss";

export default function UserPermissions(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          return <ActiveMembers />;
        }}
      />
      <Route
        path={`${path}/invitations`}
        render={() => {
          return <Invitations />;
        }}
      />
      <Route
        path={`${path}/removed`}
        render={() => {
          return <RemovedMembers />;
        }}
      />
    </Switch>
  );
}
