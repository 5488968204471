import React, { useState, MouseEvent } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Table from "../../../../library/Table/Table";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { InvoiceStatusType } from "../../../../../types/enums";
import { companiesClient, invoicesClient, paymentsClient } from "../../../../../db/accessor";

const CUSTOMERSCOLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "19.5%",
    showFilter: true,
    popupPlaceholder: "ex. Blushing Blooms",
    showSort: true,
    searchlightField: "COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Primary Contact",
    accessor: "primary_contact",
    width: "18.75%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "PRIMARYCONTACT",
    sortField: "PRIMARYCONTACT, COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Amount Collected",
    accessor: "amount_collected",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "AMOUNTCOLLECTED",
    sortField: "AMOUNTCOLLECTED, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, true),
  },
  {
    Header: "Unapplied Amount",
    accessor: "unapplied_amount",
    width: "18.75%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "UNAPPLIEDPAYMENTS",
    sortField: "UNAPPLIEDPAYMENTS, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, true),
  },
  {
    Header: "Invoices Paid",
    accessor: "invoices_paid",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "CLOSEDINVOICES",
    sortField: "CLOSEDINVOICES, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, true),
  },
  {
    Header: "Last Activity",
    accessor: "last_activity",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "NEWESTACTIVITY",
    sortField: "NEWESTACTIVITY, COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
  },
];

const PAYMENTSCOLUMNS = [
  {
    Header: "Payment Number",
    accessor: "payment_number",
    width: "22.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "CUSTOMERNAME",
    sortField: "CUSTOMERNAME, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Type",
    accessor: "type",
    width: "12.5%",
    showFilter: true,
    popupPlaceholder: "ex. Check, Cash, Credit",
    showSort: true,
    searchlightField: "PAYMENTTYPE",
    sortField: "PAYMENTTYPE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, true),
  },
  {
    Header: "Payment Date",
    accessor: "payment_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDATE",
    sortField: "PAYMENTDATE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
  },
  {
    Header: "Payment Amount",
    accessor: "payment_amount",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "PAYMENTAMOUNT",
    sortField: "PAYMENTAMOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Invoices",
    accessor: "invoices",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    // TODO: Change this to the appropriate field, Link up with API team
    searchlightField: "INVOICECOUNT",
    sortField: "INVOICECOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "left",
    Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
  },
];

const INVOICESCOLUMNS = [
  {
    Header: "Invoice Number",
    accessor: "invoice_number",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "CUSTOMERNAME",
    sortField: "CUSTOMERNAME, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. Open, Closed, Past Due",
    showSort: true,
    searchlightField: "DAYSPASTDUE",
    sortField: "STATUS, DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "lt",
    upperBoundToken: "ge",
    dropdownOptions: [
      { altDisplay: "OPEN", value: InvoiceStatusType["OPEN"], id: "open" },
      { altDisplay: "PAST DUE 1-30", value: "0", id: "late1" },
      { altDisplay: "PAST DUE 31-60", value: InvoiceStatusType["PAST DUE 31-60"], id: "late31" },
      { altDisplay: "PAST DUE 61-90", value: InvoiceStatusType["PAST DUE 61-90"], id: "late61" },
      { altDisplay: "PAST DUE 91+", value: InvoiceStatusType["PAST DUE 91+"], id: "late91" },
      { altDisplay: "CLOSED", value: InvoiceStatusType["CLOSED"], id: "closed" },
    ],
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { due_date: string | null } } }) =>
      TableUtils.formatStatus(props.row.original.due_date, props.value, false),
  },
  {
    Header: "Due Date",
    accessor: "due_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDUEDATE",
    sortField: "PAYMENTDUEDATE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
  },
  {
    Header: "Invoice Amount",
    accessor: "invoice_amount",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "INVOICEAMOUNT",
    sortField: "INVOICEAMOUNT, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Payments",
    accessor: "payments",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    // TODO: Ask API team for an appropriate sort Field, placeholder
    searchlightField: "DAYSPASTDUE",
    sortField: "DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "left",
    Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
  },
];

export default function Collected(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const customerFetchParser = (fetchResult: CustomerSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.companyId,
          }),
          ...((variant === "export" || variant === "all") && {
            customer_name: record?.companyName || "N/A",
            primary_contact: record?.primaryContact || "N/A",
            amount_collected: record?.amountCollected,
            unapplied_amount: record?.unappliedPayments,
            invoices_paid: record?.closedInvoices,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/receivables_summary"))}/customers/${row.original.id ?? ""}`);
  }

  const invoiceFetchParser = (fetchResult: InvoiceSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.invoiceId,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record?.invoiceNumber,
            customer_name: record?.customerName || "N/A",
            status: record?.status,
            due_date: record?.paymentDueDate,
            invoice_amount: record?.invoiceAmount,
            payments: record?.paymentNumbers,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/receivables_summary"))}/invoices/${row.original.id ?? ""}`);
  }

  const paymentFetchParser = (fetchResult: PaymentSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.paymentId,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_number: record?.referenceCode,
            customer_name: record?.customerName || "N/A",
            type: record?.paymentType || "N/A",
            payment_date: record?.paymentDate,
            payment_amount: record?.paymentAmount,
            invoices: record?.invoiceList,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handlePaymentRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/receivables_summary"))}/payments/${row.original.id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Customers",
          displayName: "By Customers",
          rowSelectToken: "companyId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: companiesClient.getCustomerSummaries,
            fetchParser: customerFetchParser,
          },
          columns: CUSTOMERSCOLUMNS,
          export: {
            exportFileName: "Receivables Summary Drilldown - Collected - By Customers",
          },
          defaultSort: "AMOUNTCOLLECTED, COMPANYNAME",
          defaultSortToken: "DESC",
          handleRowClick: handleCustomerRowClick,
          predefinedFilters: [
            {
              route: "receivables_summary",
              searchlightFilter: "(AMOUNTCOLLECTED NE 0)",
            },
          ],
        },
        {
          id: "By Payments",
          displayName: "By Payments",
          rowSelectToken: "paymentId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: paymentsClient.querySummaries,
            fetchParser: paymentFetchParser,
          },
          columns: PAYMENTSCOLUMNS,
          export: {
            exportFileName: "Receivables Summary Drilldown - Collected - By Payments",
          },
          defaultSort: "PAYMENTDATE, REFERENCECODE",
          defaultSortToken: "DESC",
          handleRowClick: handlePaymentRowClick,
        },
        {
          id: "By Invoices",
          displayName: "By Invoices",
          rowSelectToken: "invoiceId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: invoicesClient.getInvoiceSummaries,
            fetchParser: invoiceFetchParser,
          },
          columns: INVOICESCOLUMNS,
          export: {
            exportFileName: "Receivables Summary Drilldown - Collected - By Invoices",
          },
          defaultSort: "PAYMENTDUEDATE, INVOICENUMBER",
          defaultSortToken: "DESC",
          handleRowClick: handleInvoiceRowClick,
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
