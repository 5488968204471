import React from "react";
import LoadingImg from "../../../assets/Loading.svg";
import "./Loading.scss";

// Define Loading Props
interface Props {
  isTableCell?: boolean;
  isRelative?: boolean;
  imgHeight?: string;
  imgWidth?: string;
}

// Defines a wrapper for Loading animations
export default function Loading(props: Props): React.ReactElement {
  return props.isTableCell ? (
    <tr>
      <td className={`loading-cell`}>
        <div
          className={`loading-wrapper loading-wrapper${
            props.isRelative ? "-relative" : ""
          }`}
        >
          <img src={LoadingImg} alt="loading" />
        </div>
      </td>
    </tr>
  ) : (
    <div
      className={`loading-wrapper loading-wrapper${
        props.isRelative ? "-relative" : ""
      }`}
    >
      <img
        src={LoadingImg}
        alt="loading"
        height={props.imgHeight ?? "auto"}
        width={props.imgWidth ?? "auto"}
      />
    </div>
  );
}
