import React, { useState, MouseEvent } from "react";
import { Snackbar, Alert, Modal } from "@mui/material";
import Table from "../../../library/Table/Table";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import Utils from "../../../../utils/utils";
import { Download, Archive } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { documentsClient } from "../../../../db/accessor";

const COLUMNS = [
  {
    Header: "Document Name",
    accessor: "document_name",
    width: "40%",
    showFilter: true,
    showSort: true,
    sortField: "DOCUMENTNAME",
    searchlightField: "DOCUMENTNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
  },
  {
    Header: "Owner",
    accessor: "owner",
    width: "18%",
    showFilter: true,
    showSort: true,
    searchlightField: "OWNER",
    sortField: "OWNER, DATERECEIVED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
  },
  {
    Header: "File Type",
    accessor: "file_type",
    width: "12.5%",
    showFilter: true,
    showSort: true,
    searchlightField: "FILETYPE",
    sortField: "FILETYPE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false, "contact-bold"),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "12.5%",
    showFilter: true,
    showSort: true,
    searchlightField: "STATUS",
    sortField: "STATUS",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDocumentStatus(props.value),
  },
  {
    Header: "Date Received",
    accessor: "date_received",
    width: "17%",
    showSort: true,
    showFilter: true,
    searchlightField: "DATERECEIVED",
    sortField: "DATERECEIVED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, false, "contact-bold"),
  },
];

const documentNames = ["Long Document Name can truncate at 42 characters", "Document Name"];

/**
 * * Define the Document Tab of the Customer Detail's page
 */
export default function Documents(): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [archiveIds, setArchiveIds] = useState<string[]>([]);
  const [toast, setToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateMockData = (filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<FetchResult> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          totalCount: 2,
          pageSize: 25,
          pageNumber: 0,
          records: [
            {
              id: "ab3c3fce-9b04-46ef-bcf7-1095727acaf3",
              document_name: documentNames[0].length > 42 ? `${documentNames[0].substr(0, 42)}...` : documentNames[0],
              owner: "Sakae Watanabe",
              file_type: "JPG",
              status: "active",
              date_received: "2021-10-28T11:40:59.479Z",
            },
            {
              id: "68014940-5781-4c0b-8f01-f54d09476584",
              document_name: documentNames[1].length > 42 ? `${documentNames[1].substr(0, 42)}...` : documentNames[1],
              owner: "Stephen Kim",
              file_type: "CSV",
              status: "archived",
              date_received: "2021-10-27T11:40:59.479Z",
            },
          ],
        });
      }, 500);
    });
  };

  const fetchParser = (fetchResult: any, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: any) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.id,
          }),
          ...((variant === "export" || variant === "all") && {
            document_name: record.document_name,
            owner: record.owner,
            file_type: record.file_type,
            status: record.status,
            date_received: record.date_received,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const handleDownloadDocument = async (ids: string[]) => {
    return await Promise.all(
      ids.map((id: string) => {
        return documentsClient.download(id);
      })
    ).then(async (data) => {
      for (const ele of data) {
        await Utils.downloadItem(ele);
      }
    });
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { original: { id?: string } }) {
    handleDownloadDocument([row.original.id ?? ""]);
  }

  const setArchiveDocument = (ids: string[]) => {
    setArchiveIds(ids);
    setShowArchiveModal(true);
  };

  const handleArchiveDocument = async () => {
    return await Promise.all(
      archiveIds.map((id: string) => {
        return documentsClient.archive(id);
      })
    ).then(() => {
      setShowArchiveModal(false);
      setToast(true);
      setToastError(false);
    });
  };

  return (
    <div className="documents-wrapper">
      <Table
        dataSets={[
          {
            id: "Documents",
            displayName: "Documents",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: generateMockData,
              fetchParser: fetchParser,
            },
            columns: COLUMNS,
            handleRowClick: handleRowClick,
            defaultSort: "DATERECEIVED",
            defaultSortToken: "DESC",
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
        }}
        toggles={{
          showSearchbar: true,
          showExportBtn: false,
          showCaption: true,
          showRowSelect: true,
          showNavigation: true,
        }}
        rowSelectBtns={[
          {
            icon: <Download />,
            variant: "secondary",
            alignIcon: "left",
            children: "Download",
            callback: (ids) => handleDownloadDocument(ids),
          },
          {
            icon: <Archive />,
            variant: "secondary",
            alignIcon: "left",
            children: "Archive",
            callback: (ids) => {
              setArchiveDocument(ids);
            },
          },
        ]}
      />

      {showArchiveModal && (
        <Modal open={showArchiveModal} onClose={() => setShowArchiveModal(false)}>
          <div className="as-modal">
            <h3>Archive Documents</h3>
            <p>
              Are you sure you want to archive {archiveIds.length} document{archiveIds.length === 1 ? "" : "s"}
            </p>
            <div className="btn-grp">
              <Button variant="secondary" onClick={() => setShowArchiveModal(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleArchiveDocument()}>Archive</Button>
            </div>
          </div>
        </Modal>
      )}

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={toast} onClose={() => setToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "Error Message" : archiveIds.length > 1 ? "Documents Archived" : "Document Archived"}
        </Alert>
      </Snackbar>
    </div>
  );
}
