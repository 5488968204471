import React from "react";
import "./NoData.scss";

// Defines Error Props
interface Props {
  children?: React.ReactNode;
  isTableCell?: boolean;
  isRelative?: boolean;
}

// Defines a wrapper for Errors
export default function NoData(props: Props): React.ReactElement {
  return props.isTableCell ? (
    <tr>
      <td className={`noData-cell`}>
        <div className={`noData-wrapper noData-wrapper${props.isRelative ? "-relative" : ""}`}>
          {props?.children ? (
            <p>{props.children}</p>
          ) : (
            // TODO: Add this back once you've confirmed with Gibby the text
            // <p>
            //   Sorry, we could not find your data. We've notified our team about
            //   the issue, but if you'd like to speak with us please contact our
            //   support team at{" "}
            //   <a href="mailto:support@lockstep.io">support@lockstep.io</a>
            // </p>
            <p>No Data Found</p>
          )}
        </div>
      </td>
    </tr>
  ) : (
    <div className={`noData-wrapper noData-wrapper${props.isRelative ? "-relative" : ""}`}>
      {props?.children ? (
        <p>{props.children}</p>
      ) : (
        // TODO: Add this back once you've confirmed with Gibby the text
        // <p>
        //   Sorry, we could not find your data. We've notified our team about the
        //   issue, but if you'd like to speak with us please contact our support
        //   team at <a href="mailto:support@lockstep.io">support@lockstep.io</a>
        // </p>
        <p>No Data Found</p>
      )}
    </div>
  );
}
