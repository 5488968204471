import React, { ReactElement, useState, useEffect } from "react";
import { Caution } from "../../library/Icons/Icons";
import { applicationsClient } from "../../../db/accessor";
import { enrollmentsClient } from "../../../db/accessor";
import { getIconURL } from "../../../utils/IconUtils/IconUtils";
import { buildAuthRedirectURL } from "../../../auth/authConfig";
import Utils from "../../../utils/utils";
import { AppContext } from "../../../contexts/AppContext";
import { UserActions } from "../../../types/enums";
import NoEmailConnected from "../../../assets/NoEmailConnected.svg";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GoogleLogin } from "react-google-login";

interface EditEmailSettingsProps {
  onResponseSuccess: () => void;
  setToast: (change: ToastData) => void;
}

export default function EditEmailSettings(props: EditEmailSettingsProps): ReactElement {
  const { hasPermission } = React.useContext(AppContext) as AppType;
  const [emailApplications, setEmailApplications] = useState<EmailApplicationsGridData[]>([]);

  async function getEmailConnections() {
    const emailFilter = "appType contains email";
    try {
      return await applicationsClient.getApplications(emailFilter).then((data: AppModelFetchResult) => {
        setEmailApplications(
          data.records?.map((emailConnection: AppModel) => {
            return {
              id: emailConnection?.appId ?? "",
              name: emailConnection?.name ?? "",
              iconURL: getIconURL(emailConnection?.iconUrl) ?? "",
            } as EmailApplicationsGridData;
          }) ?? []
        );
      });
    } catch (error: any) {
      console.log(error);
    }
  }

  async function createEmailEnrollment(id: string, code: string, redirectUri: string) {
    const body: Partial<AppEnrollmentModel>[] = [
      {
        appId: id,
        isActive: true,
        syncScheduleIsActive: true,
        connectorInfo: {
          authCode: code,
          email: null,
          redirectUri: redirectUri,
          realmId: null,
        },
      },
    ];

    try {
      return await enrollmentsClient.createEnrollment(body);
    } catch (error: any) {
      console.log(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const gmailResponseSuccess = (id: string) => {
    createEmailEnrollment(id, "", "").then(() => {
      props.onResponseSuccess();
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const gmailResponseFailure = () => {
    props.setToast({ show: true, severity: "error", text: "Email Connection Unsuccessful" });
  };

  const handlePopup = (emailApp: EmailApplicationsGridData) => {
    const url = buildAuthRedirectURL(emailApp.name, emailApp.id, "EmailAuthState");
    Utils.openPopup(url, "", window, 900, 800);
  };

  useEffect(() => {
    getEmailConnections();

    const handleMessage = (event: MessageEvent) => {
      if (event.origin === window.location.origin && event.data.type === "auth_code") {
        const redirectURL = window.location.origin;
        const id = event.data["appId"];
        const code = event.data["code"];

        createEmailEnrollment(id, code, redirectURL).then(() => {
          props.onResponseSuccess();
        });
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (!hasPermission(UserActions.AddEmailClient)) {
    return (
      <div className="emailSettings-no-permission-wrapper">
        <div className="emailSettings-no-permission-icon">
          <img src={NoEmailConnected} />
        </div>
        <div className="emailSettings-no-permission-text-wrapper">
          <p className="emailSettings-no-permission-text">
            Your team doesn't have an email connected to Lockstep. Contact your team's Owner or an Admin to get your email connected.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="emailSettings-header">
        <p className="emailSettings-header-text">
          Connect your team's shared email client to manage your workflow with Lockstep Activities. Once you connect an email account, your whole team
          will see the emails appear in their Lockstep inboxes.
        </p>
      </div>
      <div className="emailSettings-warning">
        <div className="emailSettings-warning-icon">
          <Caution />
        </div>
        <p className="emailSettings-header-text">We recommend using your team's shared email alias during your setup. For example: ar@company.com</p>
      </div>
      <div className="emailSettings-email-list">
        {(emailApplications ?? []).map((element: EmailApplicationsGridData) => {
          if (element.name === "Microsoft Outlook") {
            return (
              <div className="emailSettings-connector" key={element.name} onClick={() => handlePopup(element)}>
                <div className="emailSettings-connector-logo-wrapper">
                  <img src={element.iconURL} className="emailSettings-connector-logo" alt={element.name} />
                </div>
              </div>
            );
          }
          // else if (element.name === "Gmail") {
          //   return (
          //     <GoogleLogin
          //       key={element.name}
          //       clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
          //       render={(renderProps) => (
          //         <div className="emailSettings-connector" onClick={renderProps.onClick}>
          //           <div className="emailSettings-connector-logo-wrapper">
          //             <img src={element.iconURL} className="emailSettings-connector-logo" alt={element.name} />
          //           </div>
          //         </div>
          //       )}
          //       onSuccess={() => gmailResponseSuccess(element.id)}
          //       onFailure={() => gmailResponseFailure()}
          //     />
          //   );
          // }
        })}
      </div>
    </>
  );
}
