import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Navbar from "../../library/Navbar/Navbar";
import CompanyProfile from "./CompanyProfile/CompanyProfile";
import MyProfile from "./MyProfile/MyProfile";

export default function Profiles(): React.ReactElement {
  const { path } = useRouteMatch();
  
  return (
    <div className="profile-wrapper">
      <div className="profile-head">
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "My Profile", routeName: "" },
                    { displayName: "Company Profile", routeName: "company" },
                  ]}
                  rootPath={path}
                  dropdown={[
                    { displayName: "My Profile", routeName: "" },
                    { displayName: "Company Profile", routeName: "company" },
                  ]}
                  dropdownDefault={"My Profile"}
                  backDisplayName="Dashboard"
                  backRoute={path.substr(0, 3) + "/dashboard"}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/company`}
            render={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "My Profile", routeName: "" },
                    { displayName: "Company Profile", routeName: "company" },
                  ]}
                  rootPath={path}
                  dropdown={[
                    { displayName: "My Profile", routeName: "" },
                    { displayName: "Company Profile", routeName: "company" },
                  ]}
                  dropdownDefault={"Company Profile"}
                  backDisplayName="Dashboard"
                  backRoute={path.substr(0, 3) + "/dashboard"}
                />
              );
            }}
          />
        </Switch>
      </div>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => {
            return <MyProfile />;
          }}
        />
        <Route
          exact
          path={`${path}/company`}
          render={() => {
            return <CompanyProfile />;
          }}
        />
      </Switch>
    </div>
  );
}
