import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../library/Table/Table";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { paymentsClient } from "../../../db/accessor";

const COLUMNS = [
  {
    Header: "Payment Number",
    accessor: "payment_number",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "20%",
    showFilter: true,
    popupPlaceholder: "ex. Joe Doe Co.",
    showSort: true,
    searchlightField: "CUSTOMERNAME",
    sortField: "CUSTOMERNAME, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Type",
    accessor: "type",
    width: "12.5%",
    showFilter: true,
    popupPlaceholder: "ex. Check, Cash, Credit",
    showSort: true,
    searchlightField: "PAYMENTTYPE",
    sortField: "PAYMENTTYPE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatPaymentType(props.value, false),
  },
  {
    Header: "Payment Date",
    accessor: "payment_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "PAYMENTDATE",
    sortField: "PAYMENTDATE, REFERENCECODE",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
  },
  {
    Header: "Payment Amount",
    accessor: "payment_amount",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "PAYMENTAMOUNT",
    sortField: "PAYMENTAMOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Invoices",
    accessor: "invoices",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 10",
    showSort: true,
    // TODO: Change this to the appropriate field, Link up with API team
    searchlightField: "INVOICECOUNT",
    sortField: "INVOICECOUNT, REFERENCECODE",
    searchlightToken: "EQ",
    alignment: "left",
    Cell: (props: { value: string[] | null }) => TableUtils.formatList(props.value, false),
  },
];

export default function AllPayments(): React.ReactElement {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tableData, setTableData] = useState<TableData[]>([]);

  const fetchParser = (fetchResult: PaymentSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.paymentId,
          }),
          ...((variant === "export" || variant === "all") && {
            payment_number: record.referenceCode,
            customer_name: record.customerName,
            type: record.paymentType,
            payment_date: record.paymentDate,
            payment_amount: record.paymentAmount,
            invoices: record.invoiceList,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  // Handles redirect to Payment Details page on row click of the Data Grid
  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`/AR/payments/${row.original.id ?? ""}`);
  };

  return (
    <Table
      key="All Payments"
      dataSets={[
        {
          id: "Payments",
          displayName: "Payments",
          rowSelectToken: "paymentId",
          columns: COLUMNS,
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: paymentsClient.querySummaries,
            fetchParser: fetchParser,
          },
          export: {
            exportFileName: "All Payments",
          },
          defaultSort: "PAYMENTDATE, REFERENCECODE",
          defaultSortToken: "DESC",
          handleRowClick: handleRowClick,
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showSearchbar: false,
        showExportBtn: true,
        showCaption: true,
        showRowSelect: true,
        showNavigation: true,
      }}
    />
  );
}
