import React, { useEffect, useState } from "react";
import { DataMode, UserActions } from "../../../../types/enums";
import { default as Edit } from "./EditCompanyProfile";
import { default as View } from "./ViewCompanyProfile";
import { Snackbar, Alert } from "@mui/material";
import { AppContext } from "../../../../contexts/AppContext";
import { codeDefinitionsClient, companiesClient } from "../../../../db/accessor";
import "../Profiles.scss";

export default function CompanyProfile(): React.ReactElement {
  const { userStatus, hasPermission } = React.useContext(AppContext) as AppType;
  const [dataMode, setDataMode] = useState<DataMode>(DataMode.View);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const [codeDefinitions, setCodeDefinitions] = useState<CodeDefinitionModel[]>([]);

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      try {
        if (userStatus.accountCompanyId) {
          await companiesClient.getCompany(userStatus.accountCompanyId, "Classification").then((data: CompanyModel) => {
            setCompanyData(data);
          });
        }

        await codeDefinitionsClient
          .queryCodeDefinitions("CODETYPE EQ 'CompanyClassification'", undefined, "codeDefinitionId")
          .then((data: CodeDefinitionModelFetchResult) => {
            setCodeDefinitions(data?.records ?? []);
          });
      } catch (e: unknown) {
        setShowToast(true);
        setToastError(true);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {dataMode === DataMode.View && (
        <View setDataMode={setDataMode} data={companyData} hasEditPermission={hasPermission(UserActions.EditMyCompany)} isLoading={isLoading} />
      )}
      {dataMode === DataMode.Edit && (
        <Edit
          setDataMode={setDataMode}
          defaultData={companyData}
          setDefaultData={setCompanyData}
          setShowToast={setShowToast}
          setToastError={setToastError}
          codeDefinitions={codeDefinitions}
        />
      )}

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "An unknown error occurred" : "Profile Saved"}
        </Alert>
      </Snackbar>
    </>
  );
}
