/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import Activity, { ActivityVariants } from "../../library/Sidebar/Activity/Activity";
import NavLinkWrapper from "../../library/NavLink";
import AccordionNav from "../../library/AccordionNav/AccordionNav";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { AppContext } from "../../../contexts/AppContext";
import { Union, SortDown, SortUp } from "../../library/Icons/Icons";
import Dropdown from "../../library/Dropdown/Dropdown";
import { InputWithIcon } from "../../library/Input/Input";
import { companiesClient } from "../../../db/accessor";

const routes = ["dashboard"];

type Customer = {
  name: string;
  id: string;
};

export default function Sidebar(): React.ReactElement {
  const { url } = useRouteMatch();
  const { syncError } = React.useContext(AppContext) as AppType;
  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState<Customer[]>([]);
  const [accountingExpanded, setAccountingExpanded] = useState<boolean>(true);
  const [activitiesExpanded, setActivitiesExpanded] = useState<boolean>(true);
  const [networkExpanded, setNetworkExpanded] = useState<boolean>(true);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [sortDisplay, setSortDisplay] = useState<string>("Sort");
  const [sortIcon, setSortIcon] = useState<React.ReactElement>(Union);
  const [searchString, setSearchString] = useState<string>("");

  const dropOptions = [
    { onClickValue: "nameAsc", value: "A to Z", id: "nameAsc" },
    { onClickValue: "nameDesc", value: "Z to A", id: "nameDesc" },
    // { onClickValue: "outstandingDesc", value: "Largest Balance", id: "outstandingDesc" },
    // { onClickValue: "outstandingAsc", value: "Smallest Balance", id: "outstandingAsc" },
    // { onClickValue: "LargestActivityCount", value: "Largest Activity Count", id: "LargestActivityCount" },
    // { onClickValue: "SmallestActivityCount", value: "Smallest Activity Count", id: "SmallestActivityCount" },
  ];

  const filterList = () => {
    return customerList.filter((cust) => cust.name.toLowerCase().includes(searchString.toLowerCase()));
  };

  const getCustomerList = async (order: string) => {
    try {
      await companiesClient
        .getCompanies("(ISACTIVE EQ true) AND (COMPANYTYPE EQ 'Customer')", undefined, order, 1000, 0)
        .then((data: CompanyModelFetchResult) => {
          if (data.records) {
            const custList = data.records.map((record: CompanyModel) => {
              return {
                name: record.companyName ? record.companyName : "",
                id: record.companyId,
              };
            });

            setCustomerList(custList);
            setFilteredCustomerList(custList);
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  function handleResize() {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    getCustomerList("COMPANYNAME");
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setDrop = (sort: string) => {
    let icon = SortDown;
    if (sort.startsWith("Z") || sort.startsWith("Largest")) {
      icon = SortUp;
    }
    setSortDisplay(sort);

    if (sort.endsWith("Balance")) {
      setSortDisplay("Balance");
    }

    setSortIcon(icon);
  };

  const searchList = (value: string) => {
    setSearchString(value);

    if (value) {
      setFilteredCustomerList(filterList());
    } else {
      setFilteredCustomerList(customerList);
    }
  };

  function calculateMaxHeight() {
    // TODO Remove this block when activities is shown!
    // if (networkExpanded && accountingExpanded) {
    //   return `calc(${windowHeight}px - 339px)`;
    // } else if (networkExpanded) {
    //   return `calc(${windowHeight}px - 259px)`;
    // } else {
    //   return "none";
    // }

    if (networkExpanded && accountingExpanded && activitiesExpanded) {
      return `calc(${windowHeight}px - 509px)`;
    }
    if (networkExpanded && accountingExpanded) {
      return `calc(${windowHeight}px - 389px)`;
    }
    if (networkExpanded && activitiesExpanded) {
      return `calc(${windowHeight}px - 429px)`;
    }
    if (networkExpanded) {
      return `calc(${windowHeight}px - 309px)`;
    }
    return "none";
  }

  const getSortedList = (sortAction: string) => {
    let sortBehavior: (a: Customer, b: Customer) => number;

    switch (sortAction) {
      case "nameAsc":
        sortBehavior = (a, b) => a.name.localeCompare(b.name, "en", { numeric: true });
        break;
      case "nameDesc":
        sortBehavior = (a, b) => b.name.localeCompare(a.name, "en", { numeric: true });
        break;
      // case "outstandingAsc":
      //   sortBehavior = (a, b) => a.outstandingAmount - b.outstandingAmount;
      //   break;
      // case "outstandingDesc":
      //   sortBehavior = (a, b) => b.outstandingAmount - a.outstandingAmount;
      //   break;
      default:
        sortBehavior = (a, b) => a.name.localeCompare(b.name, "en", { numeric: true });
        break;
    }

    customerList.sort(sortBehavior);
    return filteredCustomerList.sort(sortBehavior);
  };

  useEffect(() => {
    searchList(searchString);
  }, [searchString, customerList]);

  const sortClass = sortDisplay !== "Sort" ? "sorted" : "";

  return (
    <>
      {routes.map((val) => {
        return (
          <NavLinkWrapper className={`navlink`} exact={false} strict={false} key={val} to={`${url}/${val}`}>
            {(isActive: boolean) => (
              <Activity variant={`${val}` as ActivityVariants} isActive={isActive}>
                {val.charAt(0).toUpperCase() + val.slice(1)}
              </Activity>
            )}
          </NavLinkWrapper>
        );
      })}

      {!syncError && (
        <>
          <div className="accordion-list">
            <OverlayScrollbarsComponent options={{ autoUpdate: true, paddingAbsolute: true }}>
              <AccordionNav
                className="activity-nav-item"
                navTitle="Activities"
                navList={[
                  // TODO: wire count to API when it is ready
                  { name: "mine", id: "mine", route: "activities/mine?sort=DATEASSIGNED+DESC", count: 1 },
                  { name: "unassigned", id: "unassigned", route: "activities/unassigned?sort=CREATED+DESC", count: 1 },
                  { name: "all", id: "all", route: "activities/all?sort=DATEASSIGNED+DESC", count: 1 },
                ]}
                expanded={activitiesExpanded}
                setExpanded={setActivitiesExpanded}
              />

              <AccordionNav
                className="accounting-nav-item"
                navTitle="Accounting"
                navList={[
                  { name: "invoices", id: "invoices", route: "invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC" },
                  { name: "payments", id: "payments", route: "payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC" },
                ]}
                expanded={accountingExpanded}
                setExpanded={setAccountingExpanded}
              />

              <AccordionNav
                className="network-nav-item"
                navTitle="My Network"
                bodyMaxHeight={calculateMaxHeight()}
                navList={filteredCustomerList}
                expanded={networkExpanded}
                setExpanded={setNetworkExpanded}
              />
            </OverlayScrollbarsComponent>
          </div>

          <div className="left-footer">
            <div className="left-search">
              <InputWithIcon
                onChange={(value) => {
                  if (!networkExpanded) {
                    setNetworkExpanded(true);
                  }

                  setSearchString(value);
                }}
              />
            </div>

            <div className="left-sort">
              <Dropdown
                iconAlign="right"
                icon={sortIcon}
                className={`sort-dropdown ${sortClass}`}
                defaultValue=""
                onClick={(onClickValue: string) => setFilteredCustomerList(getSortedList(onClickValue))}
                onSelect={(onSelectValue: string) => setDrop(onSelectValue)}
                options={dropOptions}
                displayValue={sortDisplay}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
