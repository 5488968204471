import React, { useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Navbar from "../../../library/Navbar/Navbar";
import ReceivablesRiskTable from "./ReceivablesRiskTable/ReceivablesRiskTable";
import { DateTime } from "luxon";
import "../Drilldowns.scss";
import ARHeader from "../../../library/ARHeader/ARHeader";

const routes = Array.from(Array(3).keys()).map((index: number) => {
  const lastOfMonth = DateTime.now().minus({ months: index }).endOf("month");
  return {
    displayName: `${lastOfMonth.toFormat("MMM")} ${lastOfMonth.toFormat("yy")}`,
    routeName: index === 0 ? "" : `${lastOfMonth.toFormat("MMM")}_${lastOfMonth.toFormat("yy")}?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC`,
  };
});

export default function ReceivablesRisk(): React.ReactElement {
  const { path } = useRouteMatch();
  const reportDate = useMemo(() => {
    const childPath = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
    let reportDate = DateTime.now().toFormat("yyyy-MM-dd");
    if (childPath !== "receivables_risk") {
      const dateArr = childPath.split("_");
      reportDate = DateTime.fromFormat(`${dateArr[1]} ${dateArr[0]} 01`, "yy MMM dd").endOf("month").toFormat("yyyy-MM-dd");
    }
    return reportDate;
  }, [location.pathname]);

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(0, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "Current AR Aging", routeName: "aging?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "DSO", routeName: "days_sales_outstanding?sort=DSO+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Receivables Risk", routeName: "receivables_risk?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "Receivables Summary", routeName: "receivables_summary?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Top Customers", routeName: "top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC" },
          ]}
          dropdownDefault={"Receivables Risk"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(0, path.lastIndexOf("/"))}`}
          tooltip="The percentage of total AR balance left unpaid after 90 days."
        />
      </div>
      <div className="body">
        <div className="content">
          <ARHeader
            list={[
              { type: "totalCustomers" },
              { type: "totalInvoices90DaysPastDue" },
              { type: "totalPastDueAmount90Days" },
              { type: "percentageOfTotalAr90DaysPastDue" },
            ]}
            reportDate={reportDate}
          />
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route: { displayName: string; routeName: string }) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <ReceivablesRiskTable />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
