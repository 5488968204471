import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import "../Drilldowns.scss";
import Collected from "./Collected/Collected";
import Invoiced from "./Invoiced/Invoiced";
import Navbar from "../../../library/Navbar/Navbar";
import ARHeader from "../../../library/ARHeader/ARHeader";
import { DateTime } from "luxon";

const routes = [
  {
    displayName: "Collected",
    routeName: "?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC",
    component: <Collected />,
  },
  {
    displayName: "Invoiced",
    routeName: "invoiced?sort=OUTSTANDINGAMOUNT+DESC%2C+COMPANYNAME+DESC",
    component: <Invoiced />,
  },
];

export default function ReceivablesSummary(): React.ReactElement {
  const { path } = useRouteMatch();
  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(0, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "Current AR Aging", routeName: "aging?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "DSO", routeName: "days_sales_outstanding?sort=DSO+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Receivables Risk", routeName: "receivables_risk?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "Receivables Summary", routeName: "receivables_summary?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Top Customers", routeName: "top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC" },
          ]}
          dropdownDefault={"Receivables Summary"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(0, path.lastIndexOf("/"))}`}
          tooltip="Rolling collected amount verses invoiced amount."
        />
      </div>
      <div className="body">
        <div className="content">
          <Switch>
            <Route
              exact
              path={`${path}`}
              render={() => {
                return (
                  <ARHeader
                    key={path}
                    list={[
                      { type: "totalCustomers" },
                      { type: "totalCollected" },
                      { type: "totalUnappliedPayments", customLabel: "Unapplied Amount" },
                      { type: "totalInvoicesPaid" },
                    ]}
                    reportDate={DateTime.now().toFormat("yyyy-MM-dd")}
                  />
                );
              }}
            />
            <Route
              exact
              path={`${path}/invoiced`}
              render={() => {
                return (
                  <ARHeader
                    key={path}
                    list={[
                      { type: "totalCustomers" },
                      { type: "totalInvoicedAmount" },
                      { type: "totalPastDueAmount" },
                      { type: "totalInvoicesOpen" },
                    ]}
                    reportDate={DateTime.now().toFormat("yyyy-MM-dd")}
                  />
                );
              }}
            />
          </Switch>

          <div className="content-table">
            <Switch>
              {routes &&
                routes.map((val) => {
                  return (
                    <Route
                      exact
                      path={`${path}/${val.routeName.replace(/\?.+$/, "")}`}
                      key={`${val.displayName}`}
                      component={() => {
                        return val.component;
                      }}
                    />
                  );
                })}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
