import React, { Dispatch, useEffect, useState } from "react";
import { Alert, Drawer, Snackbar, TextField } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { AssignTo, UserPic } from "../../../../library/Icons/Icons";
import { AppContext } from "../../../../../contexts/AppContext";
import { activitiesClient, notesClient, userAccountsClient } from "../../../../../db/accessor";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { NoteType } from "../../../../../types/enums";

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityReassignProps = {
  activityId: string;
  noteValue: string;
  setNoteValue: Dispatch<string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityReassign(props: ActivityReassignProps): React.ReactElement {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [memberListOpen, setMemberListOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>("");
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [teamMemberList, setTeamMemberList] = useState<Partial<UserAccountModel>[]>([]);
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccountModel> | null>(null);

  const fetchUserList = async () => {
    try {
      const predefinedFilter = `(STATUS eq ACTIVE)`;
      const order = "USERNAME ASC";
      await userAccountsClient.queryUserAccounts(predefinedFilter, order).then((data) => {
        setTeamMemberList(
          data.records?.map((user: UserAccountModel) => {
            return {
              userId: user.userId,
              userName: user.userName,
            };
          }) ?? []
        );
      });
      const unassignedUser = { userId: "", userName: "Unassigned" };
      setTeamMemberList((prev: Partial<UserAccountModel>[]) => {
        return [...prev, unassignedUser];
      });
      setLoading(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  // Load list of user choices for re-assignment
  useEffect(() => {
    if (!isLoading) {
      return undefined;
    }
    fetchUserList();
  }, [isLoading]);

  const handleReassign = async () => {
    try {
      await activitiesClient.patch(props.activityId, {
        userAssignedTo: selectedUser?.userId ?? null,
      });

      await notesClient.post([
        {
          tableKey: "Activity",
          objectKey: props.activityId,
          noteText: `(Reassigned) To User: ${selectedUser?.userName} by ${userStatus.userName} Note: ${props.noteValue}`,
          noteType: NoteType.Note,
        },
      ]);
    } catch (e: unknown) {
      setToastMsg("Activity was not reassigned");
      setToastError(true);
    } finally {
      props.setNoteValue(props.noteValue);
      setShowToast(true);
      setOpen(false);
    }
  };

  return (
    <div className="activity-reassign">
      <Button
        icon={<AssignTo />}
        onClick={() => setOpen(true)}
        variant={"solo-icon"}
        alignIcon={"left"}
        tooltip={"Reassign"}
        className={"detail-icon-btn"}
      />

      <Drawer anchor="right" open={isOpen} onClose={() => setOpen(false)} disablePortal>
        <div className="activity-flyout">
          <h3 className="header">Change Assignment Owner</h3>
          <p className="subheader body1">Reassign this assignment to another team member within Lockstep.</p>
          <label htmlFor={"string"}>{"Team Member"}</label>
          <Autocomplete
            getOptionLabel={(option: Partial<UserAccountModel>) => option.userName ?? "N/A"}
            options={teamMemberList}
            loading={isLoading}
            open={memberListOpen}
            onOpen={() => {
              setMemberListOpen(true);
            }}
            onClose={() => {
              props.setNoteValue("");
              setMemberListOpen(false);
            }}
            onChange={async (_event, value: Partial<UserAccountModel>) => {
              setSelectedUser(value);
              setToastMsg(value.userName ?? "");
            }}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            popupIcon={<UserPic />}
            staticIcon={true}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Team Member",
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option: Partial<UserAccountModel>, value: string) => {
              return option.userId === value;
            }}
          />
          <TextArea
            label={{ regularText: "Note", extraText: " (optional)" }}
            placeholder={""}
            onChange={props.setNoteValue}
            defaultValue={props.noteValue}
          />
          <div className="btn-grp">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                setOpen(false);
                props.setNoteValue("");
              }}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              onClick={() => {
                handleReassign();
                props.setNoteValue("");
              }}
            >
              Reassign
            </Button>
          </div>
          <p className="recap-header caption">Owner History</p>
          <ul className="recap-list">
            <li className="recap-item">
              <div className="head">
                <p className="body2">Team Member</p>
                <p className="caption">Apr 1, 2021</p>
              </div>
              <p className="body1">You were made Activity Owner on April 1, 2021.</p>
            </li>
          </ul>
        </div>
      </Drawer>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? (
            toastMsg
          ) : (
            <p>
              Successfully changed owner to <span style={{ fontWeight: 700 }}>{toastMsg}</span>
            </p>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}
