import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./FileAttachment.scss"

interface FileAttachmentProps {
    label: string;
    size: number;
    handleClick: () => void;
}

export default function FileAttachment(props: FileAttachmentProps): React.ReactElement {
    return (
        <div className={"fa-wrapper"}>
            <div className={"fa-name"}>
                <p className={"fa-name-label"}>{props.label}</p>
                <p className={"fa-name-size"}>{`(${props.size})`}</p>
            </div>
            <div className={"fa-close"} onClick={props.handleClick}>
                <CloseIcon />
            </div>
        </div>
    )
}