import React, { useState } from "react";
import Button from "../../library/Button/Button";
import { Edit } from "../../library/Icons/Icons";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { DataMode, OnboardStep } from "../../../types/enums";
import { provisioningClient } from "../../../db/accessor";
import { useHistory } from "react-router-dom";

type ViewPersonalInfoProps = {
  setShowCancel: (prev: boolean) => void;
  supportedCurrencies: { id: string; label: string }[];
};

function ViewPersonalInfo(props: ViewPersonalInfoProps): React.ReactElement {
  const history = useHistory();
  const { setOnboardStep, setDataMode, isInvited, bearerToken } = React.useContext(OnboardContext) as OnboardType;
  const { onboardData } = React.useContext(AppContext) as AppType;
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    // Invite flow (Early Exit)
    if (isInvited) {
      setLoading(true);
      const body = {
        fullName: onboardData.fullName,
        defaultCurrency: onboardData.defaultCurrency,
        timeZone: onboardData.timeZone,
      } as OnboardDataModel;
      await provisioningClient.finalize(bearerToken, body).then(() => {
        history.push("/");
      });
      setLoading(false);
    }
    // Default flow
    else {
      setOnboardStep(OnboardStep.CompanyInfo);
      setDataMode(DataMode.Edit);
    }
  };

  const handleEdit = () => {
    setDataMode(DataMode.Edit);
    props.setShowCancel(true);
  };
  return (
    <>
      <h1>Looking good!</h1>

      <p className="p-body">Take a moment to make sure your personal information is correct.</p>

      <hr />

      <div className="data-container">
        <div className="data-row">
          <div className="data-col">
            <div className="view-mode-label">Your Name</div>
            <div className="value" data-testid="ReadonlyPersonalInfoName">
              {onboardData.fullName}
            </div>
          </div>

          <div className="data-col">
            <div className="view-mode-label">Email Address</div>
            <div className="value" data-testid="ReadonlyPersonalInfoEmail">
              {onboardData.email}
            </div>
          </div>
        </div>

        <div className="data-row">
          <div className="data-col">
            <div className="view-mode-label">Primary Currency</div>
            <div className="value" data-testid="ReadonlyPersonalInfoCurrency">
              {props.supportedCurrencies.find((val: { id: string; label: string }) => val.id === onboardData.defaultCurrency)?.label ?? ""}
            </div>
          </div>
        </div>
      </div>

      <div className="button-row">
        <Button data-testid="PersonalInfoLooksGreatButton" onClick={handleSubmit} loading={isLoading}>
          Looks great!
        </Button>

        <Button data-testid="PersonalInfoEditButton" icon={<Edit />} variant="secondary" onClick={handleEdit} alignIcon="right" loading={isLoading}>
          I need to make an edit.
        </Button>
      </div>
    </>
  );
}

export default ViewPersonalInfo;
