import { DateTime } from "luxon";

/**
 * * Returns the number of days between @param date and current date. If @param date is before
 * * current date, return a positive integer. If @param date is after current date, return a
 * * negative integer. Otherwise, return 0.
 * @param date - YYYY-MM-DD date string
 * @returns number or null
 */
export const calcDaysBetween = (date: string | null): number | null => {
  if (!date) {
    return null;
  }
  const parsedDate = DateTime.fromFormat(date, "yyyy-MM-dd");
  if (!parsedDate.isValid) {
    return null;
  } else {
    return Math.floor(-parsedDate.diffNow("days").days);
  }
};

/**
 * * Converts Javascript Date Object to <Long month> <Numeric day>, <Numeric year> string
 * @param value - Javascript Date Object
 * @returns <Long month> <Numeric day>, <Numeric year>
 */
export const formatDate = (value: string | null): string => {
  if (!value) {
    return "N/A";
  }
  return DateTime.fromFormat(value.split("T")[0], "yyyy-MM-dd").toFormat("MMM dd, yyyy");
};

/**
 * Converts Javascript Date Object to <Hours>:<Minutes> string
 * @param value Javascript Date Object
 * @returns <Hours>:<Minutes> <AM/PM>
 */
export const formatTime = (value: string | null): string => {
  if (!value) {
    return "N/A";
  }
  return DateTime.fromSQL((value + "UTC").replace("T", " ").replace("Z", ""))
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(" ", "");
};

export const formatTimeZone = () => {
  return new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
};

/**
 * Conversts javascript date object to time elapsed
 * @param date Javascript Date object
 * @returns ** minutes/hours/days/weeks
 */
export const formatTimeElapsed = (date: string | null): string | null => {
  if (!date) {
    return null;
  }

  const parsedDate = DateTime.fromSQL(date.replace("T", " ").replace("Z", ""), { zone: "UTC" });
  if (!parsedDate.isValid) {
    return null;
  } else {
    const timeElapsed = Math.floor(-parsedDate.diffNow("minutes").minutes);
    if (timeElapsed < 60) {
      return `${Math.ceil(timeElapsed)} minutes`;
    }
    if (timeElapsed < 60 * 24) {
      return `${Math.ceil(timeElapsed / 60)} hours`;
    }
    if (timeElapsed < 60 * 24 * 7) {
      return `${Math.ceil(timeElapsed / (60 * 24))} days`;
    }
    return `${Math.ceil(timeElapsed / (60 * 24 * 7))} weeks`;
  }
};
