// Imports from react
import React, { useState, useEffect } from "react";
import BarChart, { calculateLeftMargin } from "../../../library/Charts/BarChart";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
// Import Context
import { DashboardContext } from "../../../../contexts/DashboardContext";
import "./ReceivablesSummary.scss";
import NoData from "../../../library/NoData/NoData";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";

/**
 * * Defines the Collection Summary Widget
 */
export default function ReceivablesSummary(): React.ReactElement {
  // Get GET method for Cashflow Report from Dashboard Context
  const { getCashflowData } = React.useContext(DashboardContext) as DashboardType;
  // Loading state from GET API Call for Cashflow Report
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error message from GET API Call for Cashflow Report
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Data from GET API Call for Cashflow Report
  const [data, setData] = useState<GraphData[]>([]);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Reports/cashflow
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getCashflowData();
      setData(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 404) {
        setData([]);
      } else {
        setErrorMessage(error.message);
      }
    }

    // API call finalized
    setLoading(false);
  };

  const handleOnClick = (value: string) => {
    let route = "?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC";
    if (value === "INVOICED") {
      route = "/invoiced?sort=OUTSTANDINGAMOUNT+DESC%2C+COMPANYNAME+DESC";
    }
    history.push(`${path}/receivables_summary${route}`);
  };

  // On mount, fetch Collection Summary data
  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className={`cs-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`cs-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : data.length === 0 ? (
    <div className={`cs-wrapper`}>
      <div className={`cs-top`}>
        <p className={`title`}>Receivables Summary</p>
        <Tooltip title="Rolling 30 day collected amount verses invoiced amount." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <NoData />
    </div>
  ) : (
    <div className={`cs-wrapper`}>
      <div className={`cs-top`}>
        <p className={`title`} onClick={() => handleOnClick("")}>
          Receivables Summary
        </p>
        <Tooltip title="Rolling 30 day collected amount verses invoiced amount." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={`cs-mid`}>
        <p className={`caption-sm`}>{"COLLECTED"}</p>
        <p onClick={() => handleOnClick("")} className={`large-number`}>
          {data[1]?.value &&
            new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: "USD",
            }).format(data[1].value)}
        </p>
        <p className={`caption-sm`}>{"30 DAY HISTORICAL"}</p>
      </div>
      <div className={`cs-bot`}>
        <BarChart
          hoverSquare={false}
          data={data}
          hoverFormat="currency"
          margin={
            data.length === 1
              ? {
                  top: 0,
                  right: 16,
                  bottom: 85,
                  left: calculateLeftMargin(data),
                }
              : data.length === 2
              ? {
                  top: 0,
                  right: 16,
                  bottom: 50,
                  left: calculateLeftMargin(data),
                }
              : data.length === 3
              ? {
                  top: 0,
                  right: 16,
                  bottom: 16,
                  left: calculateLeftMargin(data),
                }
              : undefined
          }
          onClick={(id) => handleOnClick(id)}
        />
      </div>
    </div>
  );
}
