import APIClient from "../APIClient";

export class DocumentsClient {
  /**
   * GET on /api/v1/Attachments/{id}/download
   * @param id - The unique ID number of the Attachment whose URI will be returned
   * @returns - a download link
   */
  download(id: string): Promise<UriModel> {
    const url = `api/v1/Attachments/${id}/download`;
    return APIClient.get(url).then((res) => res.data);
  }
  /**
   * DELETE on /api/v1/Attachments/{id}
   * @param id - The unique ID number of the Attachment being marked as archived.
   * @returns - a download link
   */
  archive(id: string): Promise<ActionResultModel> {
    const url = `api/v1/Attachments/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }
}
