import React from "react";
import { RequestApproval, AssignTo, MarkAsUnread, MoveTo, Snoozed, Closed, Spam } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
// import Tooltip from "../../../library/Tooltip/Tooltip";
import "./Hoverbar.scss"
// import { Tooltip } from "@mui/material";

type hoverBarProps = {
  barriers?: boolean
}

export default function HoverBar(props: hoverBarProps): React.ReactElement {
  const handleClick = (type: string) => {
    switch(type) {
      case "Request Approval":
        return console.info(type);
      case "Assign To":
        return console.info(type);
      case "Mark as Unread": 
        return console.info(type);
      case "Move To": 
        return console.info(type);
      case "Snoozed": 
        return console.info(type);
      case "Closed": 
        return console.info(type);
      case "Spam": 
        return console.info(type);
      default:
        console.log("Handle default behavior")  
    }
  }

  return (
    <div className={props.barriers ? "hoverbar-container" : "hoverbar-container in-table" } style={{ width: props?.barriers ? "360px" : "370px"}}>
      <Button icon={<RequestApproval />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Request Approval")} className="btn-icon-only"/>
      <Button icon={<AssignTo />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Assign To")} className="btn-icon-only"/> 
      {props.barriers ? <div className="barrier">|</div> : null }
      <Button icon={<MarkAsUnread />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Mark as Unread")} className="btn-icon-only"/> 
      <Button icon={<MoveTo />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Move To")} className="btn-icon-only"/> 
      <Button icon={<Snoozed />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Snoozed")} className="btn-icon-only"/> 
      {props.barriers ? <div className="barrier">|</div> : null }
      <Button icon={<Closed />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Closed")} className="btn-icon-only"/> 
      <Button icon={<Spam />} variant="transparent" alignIcon="left" onClick={ () => handleClick("Spam")} className="btn-icon-only"/> 
    </div>
  )
}