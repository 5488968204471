import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";

// Define BarChart Props
interface Props {
  hoverSquare: boolean;
  data: GraphData[];
  margin?: { top: number; right: number; bottom: number; left: number };
  hoverFormat?: "currency" | "days" | "percent";
  padding?: number;
  onClick: (id: string) => void;
}

interface monthHash {
  [month: string]: number;
}

const marginCalc: monthHash = {
  JAN: 53,
  FEB: 52.5,
  MAR: 55.5,
  APR: 53.5,
  MAY: 54.5,
  JUN: 55.5,
  JUL: 52.5,
  AUG: 54.5,
  SEP: 55.5,
  OCT: 54.5,
  NOV: 55.5,
  DEC: 53.5,
  COLLECTED: 60.5,
  INVOICED: 49.5,
};

/**
 * * Calculate left margin of Bar Graph based on @param id month values
 * @param data - Bar Graph Data object array
 *    @param id - Represents id for a specific bar of the bar graph
 *    @param color - Represents the color for the specific bar @param id
 *    @param value - Represents the value for the specific bar @param id
 * @returns Numeric value representing the left margin of the Bar Graph
 */
export function calculateLeftMargin(data: GraphData[]): number {
  let max = 0;
  data.forEach((val) => {
    max = Math.max(max, marginCalc[val.id]);
  });
  // 16 represents left-padding of cards to account for
  // 12 represents spacing between legend text and progress bar to account for
  // max represents the suggested spacing for a given month id
  return 16 + 12 + max;
}

// Define Wrapper for Nivo's Responsive Bar
export default function BarChart(props: Props): React.ReactElement {
  const [borderRadius, setBorderRadius] = useState(10);
  const [data, setData] = useState<GraphData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isZero, setZero] = useState<boolean[]>(new Array(props.data.length).fill(false));

  function handleResize() {
    if (window.innerHeight <= 1000) setBorderRadius(10);
    else setBorderRadius(15);
  }

  useEffect(() => {
    // Set Border Radius on resize
    window.addEventListener("resize", handleResize);
    // Set Graph Data
    const min = props.data.every((val: GraphData) => val.value === 0)
      ? 100
      : props.data.reduce((a: number, b: GraphData) => {
          if (b.value === 0) return a;
          else return Math.min(a, b.value);
        }, Number.MAX_SAFE_INTEGER);
    setData(
      props.data.map((val, index: number) => {
        if (val.value === 0) {
          setZero((prev) => {
            prev[index] = true;
            return prev;
          });
        }
        return {
          ...val,
          value: val.value === 0 ? min / 50 : val.value,
        };
      })
    );
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ResponsiveBar
      data={data}
      theme={{ fontSize: 10, fontFamily: "Work Sans" }}
      keys={["value"]}
      groupMode="grouped"
      margin={props.margin ?? { top: 0, right: 16, bottom: 50, left: 95 }}
      padding={0.5}
      borderRadius={borderRadius}
      layout="horizontal"
      colors={{ datum: "data.color" }}
      axisLeft={{
        tickSize: 0,
        tickPadding: props?.padding ?? 12,
      }}
      axisBottom={null}
      enableLabel={false}
      enableGridY={false}
      enableGridX={false}
      // @ts-expect-error: Change later
      onClick={(ev: unknown) => props.onClick(ev.indexValue)}
      motionConfig="slow"
      maxValue={isZero.every((val: boolean) => val === true) ? 30 : "auto"}
      tooltip={(input) => {
        return (
          <div className={`hover-wrapper`}>
            {props.hoverSquare && <div className={`hover-square`} style={{ backgroundColor: input.color ?? "black" }} />}
            <p className={`hover-text`}>
              {input.indexValue}:{" "}
              {props.hoverFormat === "currency" ? (
                <span>
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(isZero[input.index] ? 0 : input.value)}
                </span>
              ) : props.hoverFormat === "days" ? (
                <span>{isZero[input.index] ? "0" : input.value} Days</span>
              ) : props.hoverFormat === "percent" ? (
                <span>{isZero[input.index] ? "0" : input.value.toFixed(2)}%</span>
              ) : (
                <span>{isZero[input.index] ? "0" : input.value}</span>
              )}
            </p>
          </div>
        );
      }}
    />
  );
}
