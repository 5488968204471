import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Button from "../../../../library/Button/Button";
import Detail from "../../../../library/Detail/Detail";
import { DropdownArrow, Email, Phone, UserPic } from "../../../../library/Icons/Icons";
import "./ActivityProfileTab.scss";

export default function ProfileTab(): React.ReactElement {
  const handleShowMore = () => {
    console.info("handleShowMore");
  };
  return (
    <div className="activity-profile-tab">
      <Accordion defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<DropdownArrow />}>
          <p className="caption-med">GENERAL INFO</p>
        </AccordionSummary>
        <AccordionDetails>
          <Detail icon={<Phone />} columns={{ value: "(480) 555-0103", format: "tel" }} />
          <Detail icon={<Email />} columns={{ value: "ar.dept@abbieblue.com", format: "email" }} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<DropdownArrow />}>
          <p className="caption-med">CONTACTS</p>
        </AccordionSummary>
        <AccordionDetails>
          <Detail icon={<UserPic />} columns={{ title: "Raychel Green", value: "AP Specialist" }} />
          <Detail icon={<UserPic />} columns={{ title: "Albert Flores", value: "CEO" }} />
          <Button size="sm" variant="secondary" onClick={() => handleShowMore()}>
            Show Other 6 Persons
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
