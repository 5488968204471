import React, { MouseEvent, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { companiesClient } from "../../../../../db/accessor";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import Table from "../../../../library/Table/Table";

const CUSTOMERSCOLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "21.25%",
    showFilter: true,
    popupPlaceholder: "ex. Blushing Blooms",
    showSort: true,
    searchlightField: "COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Primary Contact",
    accessor: "primary_contact",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. John Doe",
    showSort: true,
    searchlightField: "PRIMARYCONTACT",
    sortField: "PRIMARYCONTACT, COMPANYNAME",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Past Due Invoices",
    accessor: "past_due_invoices",
    width: "16.25%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "TOTALINVOICESPASTDUE",
    sortField: "TOTALINVOICESPASTDUE, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value, true),
  },
  {
    Header: "Past Due Amount",
    accessor: "past_due_amount",
    width: "17.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "OUTSTANDINGINVOICES",
    sortField: "OUTSTANDINGINVOICES, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, true),
  },
  {
    Header: "DSO",
    accessor: "dso",
    width: "12.5%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "DSO",
    sortField: "DSO, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatNumber(props.value !== null ? Math.round(props.value) : 0, true),
  },
  {
    Header: "Last Activity",
    accessor: "last_activity",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. yyyy-mm-dd",
    showSort: true,
    searchlightField: "NEWESTACTIVITY",
    sortField: "NEWESTACTIVITY, COMPANYNAME",
    searchlightToken: "EQ",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
  },
];

export default function DaysSalesOutstandingTable(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchParser = (fetchResult: CustomerSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.companyId,
          }),
          ...((variant === "export" || variant === "all") && {
            customer_name: record?.companyName || "N/A",
            primary_contact: record?.primaryContact || "N/A",
            past_due_invoices: record?.totalInvoicesPastDue,
            past_due_amount: record?.amountPastDue,
            dso: record?.dso,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleCustomerRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/days_sales_outstanding"))}/customers/${row.original.id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Customers",
          displayName: "By Customers",
          rowSelectToken: "companyId",
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: companiesClient.getCustomerSummaries,
            fetchParser: fetchParser,
          },
          columns: CUSTOMERSCOLUMNS,
          export: {
            exportFileName: "Days Sales Outstanding Drilldown - By Customers",
          },
          defaultSort: "DSO, COMPANYNAME",
          defaultSortToken: "DESC",
          handleRowClick: handleCustomerRowClick,
          predefinedFilters: [
            {
              route: "days_sales_outstanding",
              searchlightFilter: "(OUTSTANDINGINVOICES > 0) AND (CLOSEDINVOICES > 0)",
            },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
