import React from "react";
import { Route, useRouteMatch, Switch } from "react-router";
import Navbar from "../library/Navbar/Navbar";
import Mine from "./Mine/Mine";
import Unassigned from "./Unassigned/Unassigned";
import All from "./AllActivities/AllActivities";
import ActivityDetail from "./ActivityDetail/ActivityDetail";
import "./Activities.scss";

//TODO: wire up the mine activites table and other page information here
export default function Activities(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <div className={"activities-wrapper"}>
      <div className={"head"}>
        <Switch>
          <Route
            exact
            path={[`${path}/mine`, `${path}/mine/notStarted`, `${path}/mine/closed`, `${path}/mine/archived`]}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Active", routeName: "?sort=DATEASSIGNED+DESC" },
                    { displayName: "Not Started", routeName: "notStarted?sort=DATEASSIGNED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=DATEASSIGNED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=DATEASSIGNED+DESC" },
                  ]}
                  rootPath={`${path}/mine`}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/mine/:activityId`}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Active", routeName: "?sort=DATEASSIGNED+DESC" },
                    { displayName: "Not Started", routeName: "notStarted?sort=DATEASSIGNED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=DATEASSIGNED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=DATEASSIGNED+DESC" },
                  ]}
                  backDisplayName="Mine Activities"
                  backRoute={`${path}/mine`}
                  rootPath={`${path}/mine`}
                />
              );
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned`, `${path}/unassigned/closed`, `${path}/unassigned/archived`]}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Not Started", routeName: "?sort=CREATED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=CREATED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=CREATED+DESC" },
                  ]}
                  rootPath={`${path}/unassigned`}
                />
              );
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned/:activityId`]}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Not Started", routeName: "?sort=CREATED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=CREATED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=CREATED+DESC" },
                  ]}
                  backDisplayName="Unassigned Activities"
                  backRoute={`${path}/unassigned`}
                  rootPath={`${path}/unassigned`}
                />
              );
            }}
          />
          <Route
            exact
            path={[`${path}/all`, `${path}/all/notStarted`, `${path}/all/closed`, `${path}/all/archived`]}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Active", routeName: "?sort=DATEASSIGNED+DESC" },
                    { displayName: "Not Started", routeName: "notStarted?sort=DATEASSIGNED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=DATEASSIGNED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=DATEASSIGNED+DESC" },
                  ]}
                  rootPath={`${path}/all`}
                />
              );
            }}
          />
          <Route
            exact
            path={[`${path}/all/:activityId`]}
            component={() => {
              return (
                <Navbar
                  tabs={[
                    { displayName: "Active", routeName: "?sort=DATEASSIGNED+DESC" },
                    { displayName: "Not Started", routeName: "notStarted?sort=DATEASSIGNED+DESC" },
                    { displayName: "Closed", routeName: "closed?sort=DATEASSIGNED+DESC" },
                    { displayName: "Archived", routeName: "archived?sort=DATEASSIGNED+DESC" },
                  ]}
                  backDisplayName="All Activities"
                  backRoute={`${path}/all`}
                  rootPath={`${path}/all`}
                />
              );
            }}
          />
        </Switch>
      </div>
      <div className={"body"}>
        <Switch>
          <Route
            exact
            path={[`${path}/mine`, `${path}/mine/notStarted`, `${path}/mine/closed`, `${path}/mine/archived`]}
            component={() => {
              return <Mine />;
            }}
          />
          <Route
            exact
            path={[`${path}/mine/:activityId`]}
            component={() => {
              return <ActivityDetail />;
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned`, `${path}/unassigned/closed`, `${path}/unassigned/archived`]}
            component={() => {
              return <Unassigned />;
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned/:activityId`]}
            component={() => {
              return <ActivityDetail />;
            }}
          />
          <Route
            exact
            path={[`${path}/all`, `${path}/all/notStarted`, `${path}/all/closed`, `${path}/all/archived`]}
            component={() => {
              return <All />;
            }}
          />
          <Route
            exact
            path={[`${path}/all/:activityId`]}
            component={() => {
              return <ActivityDetail />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
}
