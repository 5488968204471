import React, { useRef, useEffect, useState } from "react";
import NavLinkWrapper from "../NavLink/index";
import ContentNavLink from "../ContentNavLink/ContentNavLink";
import { BackArrowNav } from "../Icons/Icons";
import Dropdown from "../Dropdown/Dropdown";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownArrow } from "../Icons/Icons";
import "./Navbar.scss";
import { Skeleton } from "@mui/material";

type Tab = {
  displayName: string;
  routeName: string;
};

interface NavbarProps {
  tabs: Tab[];
  dropdown?: Tab[];
  dropdownDefault?: string;
  backDisplayName?: string;
  backRoute?: string;
  rootPath: string;
  dropdownRootPath?: string;
  hideDropdown?: boolean;
  persistQueryParams?: boolean;
  tooltip?: string;
}

export default function Navbar(props: NavbarProps): React.ReactElement {
  const location = useLocation();
  const tabWrapperRef = useRef<HTMLDivElement>(null);
  const [tabBreakpoint, setTabBreakpoint] = useState<number>(props.tabs.length);
  const [tabWidth, setTabWidth] = useState<number>(0);
  const history = useHistory();

  function handleResize() {
    const tabWrapperWidth = tabWrapperRef.current?.offsetWidth ?? 0;
    const tabChildren = tabWrapperRef.current?.children;
    let runningWidth = 0;
    if (tabChildren) {
      for (let i = 0; i < tabChildren.length; i++) {
        runningWidth += (tabChildren[i] as HTMLElement).offsetWidth + (i !== tabChildren.length - 1 ? 24 : 0);
        if (runningWidth > tabWrapperWidth + 1) {
          setTabBreakpoint(i);
          setTabWidth(runningWidth - (tabChildren[i] as HTMLElement).offsetWidth - (i !== tabChildren.length - 1 ? 24 : 0));
          return;
        }
      }
    }
    setTabBreakpoint(props.tabs.length);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="navbar-wrapper">
      <div className="navbar-tabs">
        <div className="navbar-visible-tabs" ref={tabWrapperRef}>
          {props.tabs &&
            props.tabs.map((tab: Tab, index: number) => {
              return (
                <NavLinkWrapper
                  className={`navbar-navlink ${index >= tabBreakpoint ? "navbar-navlink-hidden" : ""}`}
                  exact
                  key={tab.displayName}
                  to={
                    props.persistQueryParams
                      ? tab.routeName && !tab.routeName.startsWith("?")
                        ? { pathname: `${props.rootPath}/${tab.routeName.replace(/\?.+$/, "")}`, search: location.search }
                        : { pathname: `${props.rootPath}`, search: location.search }
                      : `${props.rootPath}${tab.routeName && !tab.routeName.startsWith("?") ? `/${tab.routeName}` : tab.routeName}`
                  }
                >
                  {(isActive: boolean) => (
                    <ContentNavLink isActive={isActive} noMargin>
                      <>{tab.displayName.charAt(0).toUpperCase() + tab.displayName.slice(1)}</>
                    </ContentNavLink>
                  )}
                </NavLinkWrapper>
              );
            })}
        </div>
        {props.tabs && tabBreakpoint !== props.tabs.length && (
          <div className="overflow-dropdown" style={{ left: tabWidth }}>
            <Dropdown
              showEllipses
              onClick={(onClickValue: string) => history.push(`${props.rootPath}/${onClickValue}`)}
              options={props.tabs.slice(tabBreakpoint).map((tab: Tab, index: number, arr: Tab[]) => {
                return {
                  value: arr?.[index]?.displayName ?? "",
                  onClickValue: arr?.[index]?.routeName ?? "",
                  id: arr?.[index]?.displayName ?? "",
                };
              })}
            />
          </div>
        )}
      </div>
      <div className="navbar-back">
        {!props.hideDropdown && props.dropdown && (
          <Dropdown
            icon={<DropdownArrow />}
            iconAlign="left"
            defaultValue={props.dropdownDefault}
            onClick={(onClickValue: string) => history.push(`${props.dropdownRootPath ?? props.rootPath}/${onClickValue}`)}
            tooltip={props.tooltip}
            options={props.dropdown.map((tab: Tab) => {
              return {
                value: tab.displayName,
                onClickValue: tab.routeName,
                id: tab.displayName,
              };
            })}
          />
        )}
        {props.dropdown && props.backRoute && !props.hideDropdown && <div className="divider"></div>}
        {props.backRoute && (
          <NavLinkWrapper className="navbar-navlink-back" key="navlink-back" to={props.backRoute}>
            {() => {
              return (
                <div className={`back-btn`}>
                  {props.backDisplayName ? (
                    <>
                      <BackArrowNav />
                      <p>{props.backDisplayName}</p>
                    </>
                  ) : (
                    <Skeleton variant="text" width={96} height={24} animation="wave" />
                  )}
                </div>
              );
            }}
          </NavLinkWrapper>
        )}
      </div>
    </div>
  );
}
