import React from 'react';

interface LoadingDotsProps {
  className?: string;
}

const LoadingDots = (props: LoadingDotsProps):JSX.Element => {
  return (
    <div className={`dot-container ${props.className ?? ""}`}>
      <div className='dot-div' />
      <div className='dot-div' />
      <div className='dot-div' />
      <div className='dot-div' />
    </div>
  )
}

export default LoadingDots;