import Gmail from "../../assets/GmailLogo.svg"
import Outlook from "../../assets/OutlookLogo.svg"

const iconMap = new Map<string, string>();
iconMap.set("GmailLogo", Gmail);
iconMap.set("OutlookLogo", Outlook);

export const getIconURL = (icon: string): string => {
    return iconMap.get(icon) ?? "";
}
