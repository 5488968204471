import React, { useRef, useEffect } from "react";
import CloseImg from "../../../assets/Close.svg";
import "./Flyout.scss";

interface FlyoutProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function Flyout(props: FlyoutProps): React.ReactElement {
  const popupRef = useRef<HTMLDivElement>(null);

  // Handles closing popup when clicking outside
  useEffect(() => {
    // Toggle off popup if clicking outside of the div
    function handleClickOutside(e: Event) {
      if (
        popupRef.current &&
        !popupRef.current.contains(e.target as unknown as Node)
      ) {
        props.onClose();
      }
    }
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <div className={`flyout-wrapper ${props.isOpen ? "flyout-expand" : ""}`} ref={popupRef}>
      <div className={"flyout-content"}>
        <button className={"flyout-close"} onClick={props.onClose}>
          <img src={CloseImg} alt="close popup" />
        </button>
        {props.children}
      </div>
    </div>
  );
}
