import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { default as View } from "./ViewMyProfile";
import { default as Edit } from "./EditMyProfile";
import { DataMode } from "../../../../types/enums";
import { Snackbar, Alert } from "@mui/material";
import { AppContext } from "../../../../contexts/AppContext";
import { UserActions } from "../../../../types/enums";
import { codeDefinitionsClient, userAccountsClient } from "../../../../db/accessor";
import "../Profiles.scss";

export default function MyProfile(): React.ReactElement {
  const { userStatus, hasPermission } = React.useContext(AppContext) as AppType;
  const [dataMode, setDataMode] = React.useState<DataMode>(DataMode.View);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const { customerId } = useParams<{ customerId: string }>();
  const [profileData, setProfileData] = useState<UserAccountModel>({} as UserAccountModel);
  const [codeDefinitions, setCodeDefinitions] = useState<CodeDefinitionModel[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      await userAccountsClient.getUserAccount(customerId ?? userStatus.userId, "AccountingRole").then((data: UserAccountModel) => {
        setProfileData(data);
      });
      await codeDefinitionsClient
        .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
        .then((data: CodeDefinitionModelFetchResult) => {
          setCodeDefinitions(data?.records ?? []);
        });
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {dataMode === DataMode.View && (
        <View
          setDataMode={setDataMode}
          data={profileData}
          hasEditPermission={hasPermission(UserActions.EditMyProfile)}
          isSelf={!customerId || userStatus.userId === customerId}
          isLoading={isLoading}
        />
      )}
      {dataMode === DataMode.Edit && (
        <Edit
          setDataMode={setDataMode}
          defaultData={profileData}
          setDefaultData={setProfileData}
          setShowToast={setShowToast}
          setToastError={setToastError}
          codeDefinitions={codeDefinitions}
        />
      )}

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "An unknown error occurred" : "Profile Saved"}
        </Alert>
      </Snackbar>
    </>
  );
}
