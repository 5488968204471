import React, { useState, ReactElement } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import { default as Edit } from "./EditEmailSettings";
import { default as View } from "./ViewEmailSettings";
import { AppContext } from "../../../contexts/AppContext";
import "./EmailSettings.scss";

export default function EmailSettings(): ReactElement {
  const { initialLoad, userStatus } = React.useContext(AppContext) as AppType;
  const [toast, setToast] = useState<ToastData>({} as ToastData);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);

  // Hack to call child method from ViewEmailSettings to softRefresh table
  const [onResponseSuccessCallback, setResponseSucessCallback] = useState<number>(0);
  const onResponseSuccess = () => {
    setResponseSucessCallback((prev) => prev + 1);
  };

  return (
    <div className={`emailSettings-wrapper ${initialLoad || userStatus.roles.length === 0 ? "d-none" : ""}`}>
      <div className={`emailSettings-connected ${totalCount === 0 ? "d-none" : ""}`}>
        <View
          setToast={setToast}
          isLoading={isLoading}
          setLoading={setLoading}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          onResponseSuccessCallback={onResponseSuccessCallback}
        />
      </div>

      {!isLoading && totalCount === 0 && <Edit onResponseSuccess={onResponseSuccess} setToast={setToast} />}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.show}
        onClose={() => setToast({} as ToastData)}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setToast({} as ToastData)}
          severity={toast.severity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToast({} as ToastData)}>
              CLOSE
            </p>
          }
        >
          {toast.text}
        </Alert>
      </Snackbar>
    </div>
  );
}
