import React, { useMemo } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import DaysSalesOutstandingTable from "./DaysSalesOutstandingTable/DaysSalesOutstandingTable";
import Navbar from "../../../library/Navbar/Navbar";
import { DateTime } from "luxon";
import "../Drilldowns.scss";
import ARHeader from "../../../library/ARHeader/ARHeader";

// TODO: Post-EA cleanup, change Array(1) to Array(12) after historical DSO logic is figured out by Shane and team.
const routes = Array.from(Array(1).keys()).map((index: number) => {
  const lastOfMonth = DateTime.now().minus({ months: index }).endOf("month");
  return {
    displayName: `${lastOfMonth.toFormat("MMM")} ${lastOfMonth.toFormat("yy")}`,
    routeName: index === 0 ? "" : `${lastOfMonth.toFormat("MMM")}_${lastOfMonth.toFormat("yy")}?sort=DSO+DESC%2C+COMPANYNAME+DESC`,
  };
});

export default function DaysSalesOutstanding(): React.ReactElement {
  const location = useLocation();
  const { path } = useRouteMatch();
  const reportDate = useMemo(() => {
    const childPath = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
    let reportDate = DateTime.now().toFormat("yyyy-MM-dd");
    if (childPath !== "days_sales_outstanding") {
      const dateArr = childPath.split("_");
      reportDate = DateTime.fromFormat(`${dateArr[1]} ${dateArr[0]} 01`, "yy MMM dd").endOf("month").toFormat("yyyy-MM-dd");
    }
    return reportDate;
  }, [location.pathname]);

  return (
    <div className="drilldown-wrapper">
      <div className="head">
        <Navbar
          tabs={routes}
          rootPath={path}
          dropdownRootPath={path.substring(0, path.lastIndexOf("/"))}
          dropdown={[
            { displayName: "Current AR Aging", routeName: "aging?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "DSO", routeName: "days_sales_outstanding?sort=DSO+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Receivables Risk", routeName: "receivables_risk?sort=OUTSTANDINGBALANCE+DESC%2C+INVOICENUMBER+DESC" },
            { displayName: "Receivables Summary", routeName: "receivables_summary?sort=AMOUNTCOLLECTED+DESC%2C+COMPANYNAME+DESC" },
            { displayName: "Top Customers", routeName: "top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC" },
          ]}
          dropdownDefault={"DSO"}
          backDisplayName="Dashboard"
          backRoute={`${path.slice(0, path.lastIndexOf("/"))}`}
          tooltip="The average number of days that it takes for an invoice to be fully paid."
        />
      </div>
      <div className="body">
        <div className="content">
          <ARHeader
            list={[{ type: "totalCustomers" }, { type: "totalInvoicesPastDue" }, { type: "totalPastDueAmount" }, { type: "dso" }]}
            reportDate={reportDate}
          />
          <div className="content-table">
            <Switch>
              <Route
                exact
                path={routes.map((route: any) => {
                  return `${path}/${route.routeName.replace(/\?.+$/, "")}`;
                })}
                component={() => {
                  return <DaysSalesOutstandingTable />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
