import React, { useState } from "react";
import { Input } from "../../../library/Input/Input";
import Button from "../../../library/Button/Button";
import { PlusSign } from "../../../library/Icons/Icons";
import { Drawer } from "@mui/material";
import { userAccountsClient } from "../../../../db/accessor";
import Utils from "../../../../utils/utils";

type InviteMembersFlyoutButtonProps = {
  refreshTable: () => void;
  setShowToast: (prev: boolean) => void;
  setToastSeverity: (prev: string) => void;
  setToastMsg: (prev: string) => void;
};

export function InviteMembersFlyoutButton(props: InviteMembersFlyoutButtonProps): React.ReactElement {
  const [inviteFlyout, setInviteFlyout] = useState<boolean>(false);
  const [inviteEmails, setInviteEmails] = useState<string>("");
  const [inviteErrorMessage, setInviteErrorMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleInvite = async () => {
    if (!inviteEmails) {
      setInviteErrorMessage("[NO EMAIL GIVEN]");
      return;
    }

    const emails = inviteEmails.split(",").map((email) => email.trim());
    const isValid = emails.every((email: string) => Utils.validEmail(email));

    if (!isValid) {
      setInviteErrorMessage("[INVALID EMAIL(S) GIVEN]");
      return;
    }

    try {
      setLoading(true);

      await userAccountsClient
        .postInvite(
          emails.map((email: string) => {
            return {
              email: email,
            };
          })
        )
        .then((data: InviteModel[]) => {
          if (data.length > 1) {
            if (data.every((invite: InviteModel) => invite.success === true)) {
              props.setToastSeverity("success");
              props.setToastMsg(`${data.length} Invitations Sent`);
            } else {
              props.setToastSeverity("error");
              props.setToastMsg(`${data.filter((invite) => !invite.success).length ?? data.length} Invitations were not sent`);
            }
          } else {
            if (data[0].success) {
              props.setToastSeverity("success");
              props.setToastMsg("Invitation Sent");
            } else {
              props.setToastSeverity("error");
              props.setToastMsg("Invitation was not sent");
            }
          }
          props.refreshTable();
        });
    } catch (e) {
      props.setToastSeverity("error");
      if(emails.length > 1) {
        props.setToastMsg(`${emails.length} Invitations were not sent`);
      }
      else {
        props.setToastMsg("Invitation was not sent");
      }
    }

    setInviteErrorMessage("");
    setInviteFlyout(false);
    props.setShowToast(true);
    setLoading(false);
  };

  return (
    <>
      <Button icon={<PlusSign />} alignIcon="left" onClick={() => setInviteFlyout((prev: boolean) => !prev)}>
        Invite Members
      </Button>

      <Drawer anchor="right" open={inviteFlyout} onClose={() => setInviteFlyout(false)}>
        <div className={"invite-flyout-wrapper"}>
          <pre className={"header"}>{"Invite Members To\nLockstep"}</pre>
          <Input
            label={{ regularText: "Invite by Email", extraText: " (Comma Separated)" }}
            placeholder="abbie@example.com"
            onChange={(val: string) => {
              setInviteEmails(val.trim());
              setInviteErrorMessage("");
            }}
            errorMessage={inviteErrorMessage}
          />
          <div className={"invite-btn-grp"}>
            <Button className={"invite-btn"} variant="secondary" onClick={() => setInviteFlyout(false)}>
              Cancel
            </Button>
            <Button className={"invite-btn"} onClick={() => handleInvite()} loading={isLoading}>
              Send Invite
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
