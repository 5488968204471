import React, { useEffect, ChangeEvent, KeyboardEvent } from "react";
import OverlayScrollbars from "overlayscrollbars";
import "./TextArea.scss";

type LabelObject = {
  regularText: string;
  extraText: string;
};

interface TextAreaProps {
  placeholder?: string;
  label?: string | LabelObject;
  defaultValue?: string;
  onChange: (val: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  className?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
}

export function TextArea(props: TextAreaProps): React.ReactElement {
  const isLabelObject = (x: string | LabelObject): x is LabelObject => true;
  const inputId = typeof props?.label === "string" ? props?.label : props?.label?.regularText;
  useEffect(() => {
    OverlayScrollbars(document.querySelectorAll("textarea"), {
      className: "os-theme-dark textarea_wrapper",
      textarea: { dynWidth: false, dynHeight: true },
    });
  }, []);

  return (
    <div className={`ta-wrapper ${props.className ?? ""}`}>
      {props?.label &&
        (typeof props?.label === "string" ? (
          <label htmlFor={inputId} className="ta-label">
            {props?.label ? props.label : ""}
          </label>
        ) : isLabelObject(props?.label) ? (
          <span className="ta-label-container">
            <label htmlFor={inputId} className="ta-label">
              {props.label?.regularText}

              <span className="ta-label-optional">{props?.label?.extraText}</span>
            </label>
          </span>
        ) : (
          <></>
        ))}
      <textarea
        className={`ta-input ${props.className ?? ""}`}
        placeholder={props.placeholder ?? "Enter text"}
        defaultValue={props.defaultValue}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          props.onChange(e.target.value);
        }}
        onKeyDown={props.onKeyDown}
        ref={props.inputRef ?? undefined}
      />
    </div>
  );
}
