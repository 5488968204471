import APIClient from "../APIClient";

export class SyncClient {
  startSync(body: SyncModel, token?: string): Promise<SyncRequestModel> {
    const url = "api/v1/Sync";

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return APIClient.post(url, body, token !== undefined ? options : undefined, token !== undefined ? true : false).then((response) => {
      return response.data;
    });
  }

  getSyncStatus(syncRequestId: string, includeDetails?: boolean): Promise<SyncRequestModel> {
    const url = `api/v1/Sync/${syncRequestId}`;

    const options = {
      params: {
        includeDetails: !!includeDetails,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  // eslint-disable-next-line
  querySync(pageSize?: number, filter?: string, include?: string, order?: string, pageNumber?: number): Promise<SyncRequestModelFetchResult> {
    const url = "api/v1/Sync/query";
    const options = {
      params: {
        filter,
        pageSize,
        order,
        include,
        pageNumber,
      },
    };
    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }
}
