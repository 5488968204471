import React, { useState, useEffect } from "react";
import { default as View } from "./ViewContactProfile";
import { default as Edit } from "./EditContactProfile";
import { DataMode } from "../../../../../types/enums";
import { Snackbar, Alert } from "@mui/material";
import { useParams } from "react-router";
import { contactsClient, companiesClient } from "../../../../../db/accessor";

export default function ContactProfile(): React.ReactElement {
  const [dataMode, setDataMode] = React.useState<DataMode>(DataMode.View);
  const [showEditSuccess, setShowEditSuccess] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [contactData, setContactData] = useState<Partial<ContactModel>>({} as ContactModel);
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { contactId } = useParams<{ contactId: string }>();
  const fetchData = async () => {
    try {
      const contactRes = await contactsClient.getContact(contactId);
      setContactData(contactRes);
      const companyRes = await companiesClient.getCompany(contactRes.companyId, "contacts");
      setCompanyData(companyRes);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {dataMode === DataMode.View && (
        <View setDataMode={setDataMode} data={contactData} companyData={companyData} erpContact={contactData.appEnrollmentId ? true : false} />
      )}
      {dataMode === DataMode.Edit && (
        <Edit
          setDataMode={setDataMode}
          defaultData={contactData}
          companyData={companyData}
          setToastError={setToastError}
          setShowEditSuccess={setShowEditSuccess}
          setContactData={setContactData}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showEditSuccess}
        onClose={() => setShowEditSuccess(false)}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setShowEditSuccess(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowEditSuccess(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "Failed" : "Profile Saved"}
        </Alert>
      </Snackbar>
    </>
  );
}
