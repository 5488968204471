/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { MouseEvent, useEffect, useState } from "react";
import OutlookLogo from "../../../assets/ConnectOutlookLogo.svg";
import GmailLogo from "../../../assets/ConnectGmailLogo.svg";
import Button from "../../library/Button/Button";
import { provisioningClient, syncClient } from "../../../db/accessor";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { useHistory } from "react-router-dom";
import "./ConnectEmail.scss";
import { log } from "react-fullstory";
import OnboardError from "../OnboardError/OnboardError";

const emailList: ERPModel[] = [
  {
    name: "Outlook 365",
    appId: "00000000-0000-0000-0000-000000000001",
    iconUrl: OutlookLogo,
    width: "150px",
  },
  {
    name: "Gmail",
    appId: "00000000-0000-0000-0000-000000000002",
    iconUrl: GmailLogo,
    width: "150px",
  },
];

function ConnectEmail(): React.ReactElement {
  const history = useHistory();
  const { bearerToken } = React.useContext(OnboardContext) as OnboardType;
  const { onboardData } = React.useContext(AppContext) as AppType;
  const [loading, setLoading] = useState<boolean>(false);

  const [apiError, setAPIError] = useState<boolean>(false);
  const [apiRetry, setAPIRetry] = useState<boolean>(false);

  // TODO: Wire-up to API. Requirements: Open Popup, retrieve authCode, save to onboardData, go to OnboardStep.Finalize
  const handleConnect = (e: MouseEvent, email: ERPModel) => {
    if (loading) return;
    e.preventDefault();
    console.info(email);
  };

  const handleSync = async () => {
    // TODO: Remove localStorage hack for AppEnrollmentId once Finalize calls Sync automatically
    const appEnrollmentId = localStorage.getItem(`SyncRequestAppEnrollmentId-${onboardData.email}`);
    if (appEnrollmentId) {
      await syncClient.startSync({ appEnrollmentId: appEnrollmentId }, bearerToken).then((res: SyncRequestModel) => {
        localStorage.removeItem(`SyncRequestAppEnrollmentId-${onboardData.email}`);
        localStorage.setItem(`SyncRequest-${onboardData.email}`, res.syncRequestId);
        history.push("/");
      });
    }
  };

  const handleSkip = async () => {
    try {
      setLoading(true);
      await provisioningClient.finalize(bearerToken, onboardData);
      await handleSync();
    } catch (err: any) {
      console.info(err);
      log("warn", `Error calling the Finalize Provisioning endpoint: ${err.toString()}`);
      setAPIError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTryAgain = async () => {
    setAPIRetry(true);
    await handleSync();
    setAPIRetry(false);
  };

  return !apiError ? (
    <div className="connect-email-wrapper">
      <h1>Connect Your Email</h1>
      <p className="p-body desc">
        To get all of the Lockstep Inbox features, including automatic activity management, team communication, and customer communication we will
        need to connect to your email system.
      </p>
      <div className="emails-container">
        {emailList.map((option) => {
          return (
            <div
              key={option.name}
              className="email-option"
              onClick={(e: MouseEvent<HTMLDivElement>) => handleConnect(e, option)}
              data-testid={`SelectERP${option.name}Button`}
            >
              <img src={option?.iconUrl} style={{ width: option.width ?? "auto" }} alt={option.name} />
            </div>
          );
        })}
      </div>
      <div className="button-row">
        <Button variant="secondary" onClick={() => handleSkip()} loading={loading}>
          No thanks, I'll connect my email later
        </Button>
      </div>
    </div>
  ) : !apiRetry ? (
    <div className="error-message-wrapper">
      <OnboardError onTryAgain={() => handleTryAgain()} />
    </div>
  ) : (
    <></>
  );
}

export default ConnectEmail;
