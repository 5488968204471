import React from "react";
import Chip from "../../../library/Chip/Chip";
import "./Header.scss";

const DUMMYNAME = "Abbie Blue";

export default function Header(): React.ReactElement {
  return (
    <div className="activity-detail-head">
      <div className={`tag`}>{DUMMYNAME?.charAt(0) ?? ""}</div>
      <p className={`customerName`}>{DUMMYNAME ?? ""}</p>
      <div className={`amounts-wrapper`}>
        <div>
          <span className="dark">Payment Due For Invoice #123 </span>
          <span className="light">Subject</span>
        </div>
        <div>
          <span className="dark">April 1, 2020 </span>
          <span className="light">Activity Started</span>
        </div>
        <div>
          <Chip text="ACTIVE" variant="open" />
        </div>
      </div>
    </div>
  );
}
