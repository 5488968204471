import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { IntercomProvider } from "react-use-intercom";

import Onboard from "./routes/Onboard/Onboard";
import Home from "./routes/Home/Home";
import FourOFourError from "./routes/FourOFourError/FourOFourError";
import AppProvider from "./contexts/AppContext";
import OnboardProvider from "./contexts/OnboardContext";
import { CustomNavigationClient } from "./auth/NavigationClient";
import PrivateRoute from "./auth/PrivateRoute";
import { reactPlugin } from "./AppInsights";
import Utils from "./utils/utils";

interface Props {
  pca: IPublicClientApplication;
}

function App({ pca }: Props): React.ReactElement {
  const history = useHistory();
  const location = useLocation();
  const navigationClient = new CustomNavigationClient(history);

  pca.setNavigationClient(navigationClient);

  const queryParams = new URLSearchParams(location.search);

  // Here we handle an in-progress OAuth flow where a given ERP redirects back to us with an auth code. If a new window was opened, We need to
  // signal the main window with the code then close this one.
  if (queryParams.has("code") && queryParams.has("state")) {
    const state = queryParams.get("state")?.split("|") || [];

    // Are we dealing with an erp or email connector?
    const storageKeys = [ "ERPAuthState", "EmailAuthState" ];
    let localStorageKey;
    for(const key of storageKeys) {
      const storageItem = localStorage.getItem(key);
      if(state[state.length - 1] === storageItem) 
      {
        localStorageKey = key;
        break;
      }
    }

    // Only proceed if the redirect URL includes a state value and the ID from it matches the one we originally sent
    if (localStorageKey !== undefined) {
      localStorage.removeItem(localStorageKey);

      let realmId = "";

      if (queryParams.has("realmId")) {
        realmId = queryParams.get("realmId") || "";

        if (realmId === "null") {
          realmId = "";
        }
      }

      const messageBody = {
        type: "auth_code",
        code: queryParams.get("code"),
        erp: state[0],
        appId: state[1],
        realmId,
      };

      if (window.opener) {
        window.opener.postMessage(messageBody, window.location.origin);
        window.close();
      } else {
        // TODO: Add code to handle the case where the redirect is happening in the same browser window
      }
    }
    // Handle OAuth Error flow
  } else if (queryParams.has("error") && queryParams.has("error_description") && queryParams.has("state") && window.opener) {
    window.opener.postMessage({ type: "erp_error", description: queryParams.get("error_description") }, window.location.origin);
    window.close();
  }

  const sendToken = (queryParams: URLSearchParams) => {
    if (queryParams.has("id_token")) {
      window?.top?.postMessage({ type: "id_token", code: queryParams.get("id_token") }, window.location.origin);

      // We remove the token parameter to prevent a duplicate call to the provisioning API
      queryParams.delete("id_token");
    }
  };

  return (
    <MsalProvider instance={pca}>
      <IntercomProvider appId={Utils.getIntercomAppId()} shouldInitialize={Utils.shouldEnableIntercom()}>
        <AppProvider>
          <Switch>
            <Route
              exact
              path="/signupSuccess"
              component={() => {
                sendToken(queryParams);

                return <div />;
              }}
            />
            <Route
              exact
              path="/onboard"
              component={() => {
                return (
                  <OnboardProvider>
                    <Onboard />
                  </OnboardProvider>
                );
              }}
            />
            {/* This route exists solely for Azure B2C to have a stable redirect location, meaning one that doesn't itself redirect or modify the
                browser URL. The path is defined in our B2C policies. */}
            <Route exact path="/start" component={FourOFourError} />
            <PrivateRoute path="/" component={Home} />
            <Route component={FourOFourError} />
          </Switch>
        </AppProvider>
      </IntercomProvider>
    </MsalProvider>
  );
}

export default withAITracking(reactPlugin, App);
