import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { formatDate } from "../../../../db/utils/date";
import NoteStream from "../../../library/NoteStream/NoteStream";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import { PaymentContext } from "../../../../contexts/PaymentContext";
import NotesProvider from "../../../../contexts/NoteContext";
import { User as UserSVG, Payments as PaymentsSVG, Date as DateSVG } from "../../../library/Icons/Icons";
import Detail from "../../../library/Detail/Detail";
import "./Details.scss";

export default function Details(): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const {
    paymentDetailViewData,
    setPaymentDetailViewData,
    getDetailView,
    getDetailViewInvoices,
    setPaymentDetailViewInvoices,
    paymentDetailViewInvoices,
  } = React.useContext(PaymentContext) as PaymentType;
  const { paymentId } = useParams<{ paymentId: string }>();
  const { path } = useRouteMatch();
  const history = useHistory();
  const tooltipRef = useRef<HTMLDivElement>(null);

  // GET on /api/v1/Payments/views/detail
  const fetchPaymentsDetailViewData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const response = await getDetailView(`PaymentId eq ${paymentId}`);
      setPaymentDetailViewData(response);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  // GET on /api/v1/PaymentApplications/query
  const fetchInvoices = async () => {
    try {
      const response = await getDetailViewInvoices(`PaymentId eq ${paymentId}`, "Invoice");
      const mappedResponse = response.map((element) => {
        return {
          invoiceId: element.invoiceId,
          referenceCode: element.invoice.referenceCode,
          paymentAppliedAmount: element.paymentAppliedAmount,
          outstandingBalanceAmount: element.invoice.outstandingBalanceAmount,
          invoiceStatusCode: element.invoice.invoiceStatusCode,
        };
      });
      setPaymentDetailViewInvoices(mappedResponse);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (Object.keys(paymentDetailViewData).length === 0) {
      fetchPaymentsDetailViewData();
    } else {
      setLoading(false);
      setErrorMessage("");
    }
    fetchInvoices();
  }, []);

  const handleNameClick = (id: string) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substr(0, 3)}/customers/${id ?? ""}`);
  };

  const handleInvoiceClick = (id: string) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substr(0, path.lastIndexOf("/payments"))}/invoices/${id ?? ""}`);
  };

  const handleMouseEvent = (e: MouseEvent, invoiceId: string) => {
    if (e.type === "mouseleave") {
      if (tooltipRef.current) {
        tooltipRef.current.innerHTML = "";
        tooltipRef.current.style.display = "none";
      }
    }
    if (e.type === "mouseenter" && tooltipRef.current) {
      tooltipRef.current.innerHTML = invoiceId;
      //checks for ellipsis to decide if hover state should be displayed
      if ((e.currentTarget as HTMLElement).offsetWidth < (e.currentTarget as HTMLElement).scrollWidth) {
        tooltipRef.current.style.display = "inline";
        const top = 540;
        const left = e.clientX - 340;
        tooltipRef.current.style.top = `${top}px`;
        tooltipRef.current.style.left = `${left}px`;
      }
    }
  };

  return isLoading ? (
    <div className={"id-wrapper"}>
      <Loading isRelative />
    </div>
  ) : errorMessage ? (
    <div className={"id-wrapper"}>
      <Error isRelative>{errorMessage}</Error>
    </div>
  ) : (
    <div className="id-wrapper">
      <div className={"header"}>
        <div className={"tag"}>{paymentDetailViewData && (paymentDetailViewData?.customerName ?? "N/A").charAt(0)}</div>
        <p className={"name"} onClick={() => handleNameClick(paymentDetailViewData.customerId)}>
          {paymentDetailViewData && (paymentDetailViewData?.customerName ?? "N/A")}
        </p>
        <div className={"due-wrapper"}>
          <p className={"due-amount"}>
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: "USD",
            }).format(paymentDetailViewData?.paymentAmount ?? 0)}
          </p>
          <p className={"due-text"}>Total Payment Amount </p>
        </div>
      </div>

      {/* Body of Details*/}
      <div className={"body"}>
        {/* Payment Details */}
        <div className={"payment"}>
          <div className={"payment-contact-wrapper"}>
            <div className={"head"}>
              <p className={"title"}>{`Payment ${paymentDetailViewData?.referenceCode ?? "N/A"}`}</p>
            </div>
            <div className="body">
              <Detail
                icon={<UserSVG />}
                columns={[
                  {
                    title: "Primary Contact",
                    value: paymentDetailViewData?.primaryContact ?? null,
                  },
                  {
                    title: "Email",
                    value: paymentDetailViewData?.email ?? null,
                    format: "email",
                    copyToClipboard: true,
                  },
                ]}
              />
              <Detail
                icon={<PaymentsSVG />}
                columns={[
                  {
                    title: "Total Payment Amount",
                    value: paymentDetailViewData?.paymentAmount
                      ? new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }).format(paymentDetailViewData.paymentAmount)
                      : null,
                  },
                  {
                    title: "Total Unapplied Amount",
                    value: new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    }).format(paymentDetailViewData.unappliedAmount),
                  },
                  {
                    title: "Payment Type",
                    value: paymentDetailViewData?.paymentType ?? null,
                  },
                ]}
              />
              <Detail
                icon={<DateSVG />}
                columns={[
                  {
                    title: "Payment Date",
                    value: formatDate(paymentDetailViewData?.paymentDate ?? null),
                  },
                  {
                    title: "Post Date",
                    value: formatDate(paymentDetailViewData?.postDate),
                  },
                ]}
              />
            </div>
          </div>

          {paymentDetailViewInvoices.length > 0 ? (
            <div className={"invoice-wrapper"}>
              <div className={"title"}>Invoices Applied</div>
              <div className="row row-header">
                <p className="header header-left">Invoice Number</p>
                <p className="header header-left">Status</p>
                <p className="header header-right">Payment Amount</p>
                <p className="header header-right">Outstanding Amount</p>
              </div>
              {paymentDetailViewInvoices &&
                paymentDetailViewInvoices.map((invoice) => {
                  return (
                    <div onClick={() => handleInvoiceClick(invoice.invoiceId)} className="row" key={invoice.invoiceId}>
                      <div
                        onMouseEnter={(event) => handleMouseEvent(event, invoice.invoiceId)}
                        onMouseLeave={(event) => handleMouseEvent(event, invoice.invoiceId)}
                        className={"invoiceNumber"}
                      >{`${invoice.referenceCode}`}</div>
                      <div className={"status"}>{invoice.invoiceStatusCode ?? "N/A"}</div>
                      <div className={"paymentAmount"}>
                        {invoice?.paymentAppliedAmount !== null &&
                          new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          }).format(invoice.paymentAppliedAmount)}
                      </div>
                      <div className={"outstandingAmount"}>
                        {invoice?.outstandingBalanceAmount !== null &&
                          new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          }).format(invoice.outstandingBalanceAmount)}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Note Stream */}
        <NotesProvider>
          <NoteStream title="Notes" tableKey="payment" objectKey={paymentId} />
        </NotesProvider>
      </div>
      <div id="tooltip-container" style={{ display: "none" }} ref={tooltipRef} />
    </div>
  );
}
