import React from "react";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";

import "./Settings.scss";
import MySettings from "./MySettings/MySettings";
import TeamSettings from "./TeamSettings/TeamSettings";
import UserPermissions from "./UserPermissions/UserPermissions";
import AccountingSoftware from "./AccountingSoftware/AccountingSoftware";
import Navbar from "../library/Navbar/Navbar";
import MyProfile from "../Dashboard/Profiles/MyProfile/MyProfile";
import { AppContext } from "../../contexts/AppContext";
import EmailSettings from "./EmailSettings/EmailSettings";
import { SettingsContext } from "../../contexts/SettingsContext";

const mainRoutes = [
  {
    displayName: "My Settings",
    routeName: "",
  },
  {
    displayName: "Team Settings",
    routeName: "teamSettings",
  },
];

const userPermissionsRoutes = [
  {
    displayName: "Active Members",
    routeName: "userPermissions?sort=USERNAME+DESC",
  },
  {
    displayName: "Invitations",
    routeName: "userPermissions/invitations?sort=EMAIL+DESC",
  },
  {
    displayName: "Removed Members",
    routeName: "userPermissions/removed?sort=USERNAME+DESC",
  },
];

const accountingSoftwareRoutes = [
  {
    displayName: "Accounting Software Settings",
    routeName: "accountingSoftware",
  },
];

const emailRoutes = [
  {
    displayName: "Email Settings",
    routeName: "emailSettings",
  },
];

export default function Settings(): React.ReactElement {
  const { path } = useRouteMatch();
  return (
    <div className={"settings-wrapper"}>
      <div className={"head"}>
        <Route
          exact
          path={mainRoutes.map((val) => `${path}/${val.routeName}`)}
          component={() => {
            return <Navbar tabs={mainRoutes} rootPath={path} />;
          }}
        />
        <Route
          exact
          path={userPermissionsRoutes.map((val) => `${path}/${val.routeName.split("?")[0]}`)}
          component={() => {
            return (
              <Navbar
                tabs={userPermissionsRoutes}
                rootPath={`${path}`}
                dropdown={[
                  {
                    displayName: "Users and Permissions",
                    routeName: "userPermissions?sort=USERNAME+DESC",
                  },
                  {
                    displayName: "Accounting Software Settings",
                    routeName: "accountingSoftware",
                  },
                  {
                    displayName: "Email Settings",
                    routeName: "emailSettings",
                  },
                ]}
                dropdownRootPath={path}
                backDisplayName="Team Settings"
                backRoute={`${path}/teamSettings`}
                tooltip="Settings to manage the users on Insights."
              />
            );
          }}
        />
        <Route
          exact
          path={accountingSoftwareRoutes.map((val) => `${path}/${val.routeName}`)}
          component={() => {
            return (
              <Navbar
                tabs={accountingSoftwareRoutes}
                rootPath={`${path}`}
                // TODO: Unhide after Users and Permissions & Email Settings are functioning
                // dropdown={[
                //   {
                //     displayName: "Users and Permissions",
                //     routeName: "userPermissions?sort=USERNAME+DESC",
                //   },
                //   {
                //     displayName: "Email Settings",
                //     routeName: "emailSettings",
                //   },
                // ]}
                // dropdownDefault="Accounting Software Settings"
                backDisplayName="Team Settings"
                backRoute={`${path}/teamSettings`}
              />
            );
          }}
        />
        <Route
          exact
          path={emailRoutes.map((val) => `${path}/${val.routeName}`)}
          component={() => {
            return (
              <Navbar
                tabs={emailRoutes}
                rootPath={`${path}`}
                dropdown={[
                  {
                    displayName: "Accounting Software Settings",
                    routeName: "accountingSoftware",
                  },
                  {
                    displayName: "Users and Permissions",
                    routeName: "userPermissions?sort=USERNAME+DESC",
                  },
                ]}
                dropdownDefault="Email Settings"
                backDisplayName="Team Settings"
                backRoute={`${path}/teamSettings`}
              />
            );
          }}
        />
        <Route
          exact
          path={[`${path}/profile`, `${path}/profile/:customerId`]}
          component={() => {
            const { customerId } = useParams<{ customerId: string }>();
            const { userStatus } = React.useContext(AppContext) as AppType;
            const { tableData } = React.useContext(SettingsContext) as SettingsType;
            // @typescript-eslint/no-explicit-any
            const dropdown = tableData.map((ele: any) => {
              return {
                displayName: ele.name,
                routeName: ele.id !== userStatus.userId ? ele.id : "",
                id: ele.id,
              };
            });
            const isCurrentUser = !customerId || userStatus.userId === customerId;
            return (
              <Navbar
                tabs={[{ displayName: isCurrentUser ? "My Profile" : "Profile", routeName: isCurrentUser ? "" : customerId }]}
                dropdown={dropdown}
                dropdownDefault={dropdown.filter((ele) => ele.id === customerId)[0]?.displayName ?? userStatus.userName}
                rootPath={`${path}/profile`}
                backDisplayName="Active Members"
                backRoute={`${path}/userPermissions?sort=USERNAME+DESC`}
              />
            );
          }}
        />
      </div>
      <div className={"body"}>
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => {
              return <MySettings />;
            }}
          />
          <Route
            exact
            path={`${path}/teamSettings`}
            render={() => {
              return <TeamSettings />;
            }}
          />
          <Route
            path={`${path}/userPermissions`}
            render={() => {
              return <UserPermissions />;
            }}
          />
          <Route
            path={`${path}/accountingSoftware`}
            render={() => {
              return <AccountingSoftware />;
            }}
          />
          <Route
            path={`${path}/emailSettings`}
            render={() => {
              return <EmailSettings />;
            }}
          />
          <Route
            exact
            path={[`${path}/profile`, `${path}/profile/:customerId`]}
            render={() => {
              return <MyProfile />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
}
