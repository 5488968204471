import React, { useState } from "react";
import { paymentsApplicationsClient, paymentsClient } from "../db/accessor";

// Create Context
export const PaymentContext = React.createContext<PaymentType | null>(null);

// Define PaymentProvider Props
export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const PaymentProvider: React.FC<Props> = ({ children }: Props) => {
  const [paymentDetailViewData, setPaymentDetailViewData] = useState<PaymentDetailModel>({} as PaymentDetailModel);
  const [paymentDetailViewInvoices, setPaymentDetailViewInvoices] = useState<PaymentDetailViewInvoice[]>({} as PaymentDetailViewInvoice[]);

  async function getDetailView(filter?: string): Promise<PaymentDetailModel> {
    return await paymentsClient.getDetailView(filter);
  }

  async function getDetailViewInvoices(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<PaymentDetailViewResponse[]> {
    return await paymentsApplicationsClient.getDetailViewInvoices(filter, include, order, pageSize, pageNumber);
  }

  return (
    <PaymentContext.Provider
      value={{
        getDetailView,
        setPaymentDetailViewData,
        paymentDetailViewData,
        getDetailViewInvoices,
        setPaymentDetailViewInvoices,
        paymentDetailViewInvoices,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
