import React from "react";
import { FullStoryAPI } from "react-fullstory";
import {useLDClient} from "launchdarkly-react-client-sdk";
import Utils from "../../utils/utils";

type UserInfo = {
  id: string;
  username: string;
  custom: {
    groupKey: string;
    accountName: string;
  };
};

interface TrackingProps {
  identity: UserInfo;
}

const FullStoryHandler = (props: TrackingProps) => {
  if (Utils.getFullStoryConfig().forceEnableFullStory || Utils.isProduction()) {
    FullStoryAPI("identify", props.identity.id, {
      username: props.identity.username,
      ...props.identity.custom,
      isDeveloper: Utils.isLocal(),
    });
  }
  return null;
};

const LaunchDarklyHandler = (props: TrackingProps) => {
  const ldClient = useLDClient();
  if (ldClient && props.identity.id && props.identity.custom.groupKey) {
    ldClient.identify({
      key: props.identity.id,
      name: props.identity.username,
      custom: props.identity.custom
    });
  }
  return null;
};


const Tracking = (props: TrackingProps): React.ReactElement => {
  return (
    <React.Fragment>
      <FullStoryHandler identity={props.identity} />
      <LaunchDarklyHandler identity={props.identity} />
    </React.Fragment>
  );
};

export default Tracking;
