import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../db/utils/date";
import NoteStream from "../../../library/NoteStream/NoteStream";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import Button from "../../../library/Button/Button";
import { Download, User as UserSVG, Currency as CurrencySVG, Date as DateSVG } from "../../../library/Icons/Icons";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";
import NotesProvider from "../../../../contexts/NoteContext";
import { useHistory, useRouteMatch } from "react-router-dom";
import Detail from "../../../library/Detail/Detail";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import "./Details.scss";

type PaymentsAppliedData = {
  id: string;
  referenceCode: string | null;
  appliedAmount: number;
  applyToInvoiceDate: string | null;
  type: string;
  handleClick: ((id: string) => void) | (() => null);
};

export default function InvoiceDetail(): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { get, setInvoiceData, invoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const { path } = useRouteMatch();
  const history = useHistory();
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Calculates the payments applied amount for a given invoice
  const calculatedPaymentsApplied = useMemo(
    () => (invoiceData?.payments ? invoiceData?.payments.reduce((a, b) => a + b.paymentAppliedAmount, 0) : 0),
    [invoiceData]
  );

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await get(invoiceId);
      setInvoiceData(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(invoiceData).length === 0) {
      fetchData();
    }
  }, []);

  const handleNameClick = (id: string) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substr(0, path.lastIndexOf("/invoices"))}/customers/${id ?? ""}`);
  };
  const handlePaymentClick = (id: string) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substr(0, path.lastIndexOf("/invoices"))}/payments/${id ?? ""}`);
  };

  const hasPayments = !(invoiceData.payments == null || invoiceData.payments.length < 1);
  const hasCreditMemos = !(invoiceData.creditMemos == null || invoiceData.creditMemos.length < 1);
  const paymentsApplied: PaymentsAppliedData[] = [
    ...(hasPayments ? (invoiceData.payments as InvoicePaymentDetailModel[]) : []).map((element: InvoicePaymentDetailModel) => {
      return {
        id: element.paymentId,
        referenceCode: element.referenceCode,
        appliedAmount: element.paymentAppliedAmount,
        applyToInvoiceDate: element.applyToInvoiceDate,
        type: "Payment",
        handleClick: (id: string) => handlePaymentClick(id),
      };
    }),
    ...(hasCreditMemos ? (invoiceData.creditMemos as CreditMemoInvoiceModel[]) : []).map((element: CreditMemoInvoiceModel) => {
      return {
        id: element.creditMemoInvoiceId,
        referenceCode: element.referenceCode,
        appliedAmount: element.creditMemoAppliedAmount,
        applyToInvoiceDate: element.applyToInvoiceDate,
        type: "Credit Memo",
        // TODO: Add onclick handler to route to Credit Memo Details Page (Invoice Details Page)
        handleClick: () => null,
      };
    }),
  ].sort((first, second) => ((first?.applyToInvoiceDate ?? "") < (second?.applyToInvoiceDate ?? "") ? 1 : -1));

  return isLoading ? (
    <div className={`id-wrapper`}>
      <Loading isRelative />
    </div>
  ) : errorMessage ? (
    <div className={`id-wrapper`}>
      <Error isRelative>{errorMessage}</Error>
    </div>
  ) : (
    <div className={`id-wrapper`}>
      <div className={`header`}>
        <div className={`tag`}>{invoiceData && (invoiceData?.customer?.companyName ?? "N/A").charAt(0)}</div>
        <p className={`name`} onClick={() => handleNameClick(invoiceData.customerId)}>
          {invoiceData && (invoiceData?.customer?.companyName ?? "N/A")}
        </p>
        <div className={`due-wrapper`}>
          <p className={`due-amount`}>
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: "USD",
            }).format(invoiceData?.outstandingBalanceAmount ?? 0)}
          </p>
          <p className={`due-text`}>Total Due </p>
        </div>
        {TableUtils.formatStatus(invoiceData.paymentDueDate, invoiceData.invoiceStatusCode, false)}
      </div>
      {/* Body of Details*/}
      <div className={`body`}>
        {/* Invoice Details */}
        <div className={`invoice`}>
          <div className={`invoice-contact-wrapper`}>
            <div className={`head`}>
              <p className={`title`}>{`Invoice ${invoiceData?.referenceCode ?? "N/A"}`}</p>
              {invoiceData?.attachments && invoiceData?.attachments.length > 0 ? (
                <Button className={`download-btn`} icon={<Download />} variant="secondary">
                  Download Invoice
                </Button>
              ) : null}
            </div>
            <div className="body">
              <Detail
                icon={<UserSVG />}
                columns={[
                  {
                    title: "Primary Contact",
                    value: invoiceData?.customerPrimaryContact?.contactName ?? invoiceData?.customer?.companyName ?? null,
                  },
                  {
                    title: "Email",
                    value: invoiceData?.customerPrimaryContact?.emailAddress ?? invoiceData?.customer?.arEmailAddress ?? null,
                    format: "email",
                    copyToClipboard: true,
                  },
                ]}
              />
              <Detail
                icon={<CurrencySVG />}
                columns={[
                  {
                    title: "Total Invoice Amount",
                    value:
                      invoiceData.totalAmount !== null
                        ? new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          }).format(invoiceData.totalAmount)
                        : null,
                  },
                  {
                    title: "Payments Applied",
                    value: new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    }).format(calculatedPaymentsApplied),
                  },
                  {
                    title: "Outstanding Amount Owed",
                    value:
                      invoiceData?.outstandingBalanceAmount !== null
                        ? new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          }).format(invoiceData.outstandingBalanceAmount)
                        : null,
                  },
                ]}
              />

              {/* If the invoice is closed we don't show the expected payment date value since it wouldn't make much sense */}
              <Detail
                icon={<DateSVG />}
                columns={[
                  {
                    title: "Invoice Date",
                    value: formatDate(invoiceData?.invoiceDate ?? null),
                  },
                  {
                    title: "Due Date",
                    value: formatDate(invoiceData?.paymentDueDate ?? null),
                  },
                  {
                    title: "Expected Payment Date",
                    value: invoiceData?.invoiceClosedDate ? null : "N/A",
                    toolTip: "Expected payment date based on prior payments made.",
                  },
                  {
                    title: "Closed Date",
                    value: invoiceData?.invoiceClosedDate ? formatDate(invoiceData?.invoiceClosedDate ?? null) : null,
                  },
                ]}
              />
            </div>
          </div>

          {(hasPayments || hasCreditMemos) && (
            <div className={`payment-wrapper`}>
              <div className={`title`}>Payments Applied</div>
              <div className="row row-header">
                <p className="header header-left">Payment Number</p>
                <p className="header header-left">Type</p>
                <p className="header header-right">Payment Date</p>
                <p className="header header-right">Payment Amount</p>
              </div>
              {paymentsApplied &&
                paymentsApplied?.map((val: PaymentsAppliedData) => {
                  return (
                    <div className="row" onClick={() => val.handleClick(val.id)} key={val.id}>
                      <p className="id">{`${val?.referenceCode || " N/A"}`}</p>
                      <p className={`type`}>
                        {val.appliedAmount == invoiceData.totalAmount ? `Full ${val.type} Applied` : `Partial ${val.type} Applied`}
                      </p>
                      <p className={`date`}>{formatDate(val.applyToInvoiceDate)}</p>
                      <p className={`amount`}>
                        {val.appliedAmount !== undefined &&
                          new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                          }).format(val.appliedAmount)}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {/* Note Stream */}
        <NotesProvider>
          <NoteStream title="Notes" tableKey="invoice" objectKey={invoiceId} />
        </NotesProvider>
      </div>
      <div id="tooltip-container" style={{ display: "none" }} ref={tooltipRef} />
    </div>
  );
}
