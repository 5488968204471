import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import NavLinkWrapper from "../../components/library/NavLink";
import UserContent from "../../components/UserContent/UserContent";
import Activity, { ActivityVariants } from "../../components/library/Sidebar/Activity/Activity";
import FourOFourError from "../FourOFourError/FourOFourError";
import WaitScreen from "../../components/Onboard/WaitScreen/WaitScreen";
import CustomerProvider from "../../contexts/CustomerContext";
import { AppContext } from "../../contexts/AppContext";
import { LockstepLogo } from "../../components/library/Icons/Icons";
import "./Home.scss";

const Home: React.FC = () => {
  const { getSyncStatus, setInitialSyncInProgress, initialSyncInProgress, setSyncError } = React.useContext(AppContext) as AppType;
  const history = useHistory();
  const { instance } = useMsal();

  const checkSyncStatus = async () => {
    const syncRequestKey = `SyncRequest-${instance.getActiveAccount()?.username}`;
    const syncRequestId = localStorage.getItem(syncRequestKey) || "";

    try {
      if (syncRequestId) {
        const response = await getSyncStatus(syncRequestId);

        switch (response.statusCode) {
          case "Connector":
          case "Ready":
          case "In Progress":
            setInitialSyncInProgress(true);
            break;
          case "Success":
          case "Cancelled":
          case "Failed":
            setInitialSyncInProgress(false);
            localStorage.removeItem(syncRequestKey);

            if (response.statusCode === "Failed") {
              setSyncError(true);
            } else {
              setSyncError(false);
            }

            break;
          default:
            break;
        }
      } else {
        setInitialSyncInProgress(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkSyncStatus();
  }, []);

  // We periodically check the sync status so we can show the dashboard when it's completed. We should eventually replace this polling with a
  // push mechanism of some kind.
  useEffect(() => {
    const interval = setInterval(() => {
      checkSyncStatus();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className={"home-wrapper"}>
      {initialSyncInProgress ? (
        <WaitScreen />
      ) : (
        <>
          <div className={"sidebar"}>
            <LockstepLogo className="sidebar-logo" onClick={() => history.push("/")} />
            <NavLinkWrapper className={"navlink"} exact={false} strict={false} key={"settings-nav"} to={"/AR/settings"}>
              {(isActive: boolean) => <Activity className={"settings-btn"} variant={"settings" as ActivityVariants} isActive={isActive} />}
            </NavLinkWrapper>
          </div>
          <Switch>
            <Redirect exact from={"/"} to={"/AR"} />
            <CustomerProvider>
              <Route path={"/AR"} component={UserContent} />
            </CustomerProvider>
            <Route component={FourOFourError} />
          </Switch>
        </>
      )}
    </div>
  );
};

export default Home;
