import React, { useEffect } from "react";
import { Switch, useRouteMatch, Route, useParams } from "react-router-dom";
import { default as CustomerProfile } from "../../Customers/CustomerDetail/Profile/Profile";
import { default as CustomerHeader } from "../../Customers/CustomerDetail/Header/Header";
import Details from "./Detail/Details";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { CustomerContext } from "../../../contexts/CustomerContext";

export default function PaymentDetail(): React.ReactElement {
  const { paymentId } = useParams<{ paymentId: string }>();
  const { path } = useRouteMatch();
  const { setPaymentDetailViewData, getDetailView } = React.useContext(PaymentContext) as PaymentType;
  const { getCustomerDetails } = React.useContext(CustomerContext) as CustomerType;

  const fetchPaymentsDetailViewData = async () => {
    try {
      const response = await getDetailView(`PaymentId eq ${paymentId}`);
      getCustomerDetails(response.customerId);
      setPaymentDetailViewData(response);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPaymentsDetailViewData();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          return <Details />;
        }}
      />
      <Route
        path={`${path}/customer_profile/:customerId`}
        render={() => {
          return (
            <div className="customer-detail-wrapper">
              <div className="head">
                <CustomerHeader />
              </div>
              <div className="body">
                <CustomerProfile />
              </div>
            </div>
          );
        }}
      />
    </Switch>
  );
}
