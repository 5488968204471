import React, { useEffect } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { default as CustomerProfile } from "../../Customers/CustomerDetail/Profile/Profile";
import { default as CustomerHeader } from "../../Customers/CustomerDetail/Header/Header";
import Details from "./Details/Details";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { CustomerContext } from "../../../contexts/CustomerContext";

/**
 * * Defines the Invoices Details - Default Page
 */
export default function InvoiceDetail(): React.ReactElement {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { path } = useRouteMatch();
  const { get, setInvoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const { getCustomerDetails } = React.useContext(CustomerContext) as CustomerType;

  const fetchData = async () => {
    try {
      const res = await get(invoiceId);
      getCustomerDetails(res.customerId);
      setInvoiceData(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => {
          return <Details />;
        }}
      />
      <Route
        exact
        path={`${path}/customer_profile/:customerId`}
        render={() => {
          return (
            <div className="customer-detail-wrapper">
              <div className="head">
                <CustomerHeader />
              </div>
              <div className="body">
                <CustomerProfile />
              </div>
            </div>
          );
        }}
      />
    </Switch>
  );
}
