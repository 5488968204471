import React from "react";

import { reportsClient, companiesClient } from "../db/accessor";

export const DashboardContext = React.createContext<DashboardType | null>(null);

export interface DashboardProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const barColors = { dark: "#4B5DF8", light: "#C1CEFF" };

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }: DashboardProviderProps) => {
  // GET on /api/v1/ar-aging-header
  async function getAgingData(): Promise<GraphData[]> {
    return reportsClient.getAging().then((data: ArAgingHeaderInfoModel[]) => {
      if (data.length === 0) {
        return [];
      }
      // future due -> 1-30 -> ... -> 91+
      data.sort((left: ArAgingHeaderInfoModel, right: ArAgingHeaderInfoModel) => ((left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? -1 : 1));
      return [
        {
          id: "LATE 91+ DAYS",
          value: data[4].totalOutstandingAmount ?? 0,
          color: "#EF476F",
        },
        {
          id: "LATE 61-90 DAYS",
          value: data[3].totalOutstandingAmount ?? 0,
          color: "#FFD166",
        },
        {
          id: "LATE 31-60 DAYS",
          value: data[2].totalOutstandingAmount ?? 0,
          color: "#046180",
        },
        {
          id: "LATE 1-30 DAYS",
          value: data[1].totalOutstandingAmount ?? 0,
          color: "#118AB2",
        },
        {
          id: "FUTURE DUE",
          value: data[0].totalOutstandingAmount ?? 0,
          color: "#06D6A0",
        },
      ];
    });
  }

  // GET on /api/v1/Reports/cashflow
  async function getCashflowData(): Promise<GraphData[]> {
    return reportsClient.getCashflow(undefined).then((data) => {
      return [
        {
          id: "INVOICED",
          color: barColors.light,
          value: data.invoicesBilled,
        },
        {
          id: "COLLECTED",
          color: barColors.dark,
          value: data.paymentsCollected,
        },
      ];
    });
  }

  // GET on /api/v1/Reports/dailysalesoutstanding
  async function getDSOData(): Promise<GraphData[]> {
    return reportsClient.getDSO().then((data) => {
      return (
        data
          // Sort data points in ascending order
          .sort((a: { timeframe: string }, b: { timeframe: string }) => new Date(a.timeframe).getMonth() - new Date(b.timeframe).getMonth())
          // Map data points to readable barChart data
          .map((val: DailySalesOutstandingReportModel, index: number, arr: DailySalesOutstandingReportModel[]) => {
            // Change in DSO from last month to this month

            return {
              id: new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(val.timeframe)).toUpperCase(),
              // Set top bar to dark color
              color: index === arr.length - 1 ? barColors.dark : barColors.light,
              value: Math.ceil(val.dailySalesOutstanding),
            };
          })
      );
    });
  }

  // GET on /api/v1/Companies/views/customer-summary
  async function getTopRisksData(): Promise<CustomerSummaryModel[] | null> {
    return companiesClient.getCustomerSummaries(undefined, undefined, "AMOUNTPASTDUE DESC, TOTALINVOICESPASTDUE DESC", 5, 0).then((data) => {
      return data.records;
    });
  }

  // GET on /api/v1/Reports/riskrates
  async function getRiskRateData(): Promise<RiskRateModel[]> {
    return reportsClient.getRiskRate().then((data) => {
      return data;
    });
  }

  return (
    <DashboardContext.Provider
      value={{
        getAgingData,
        getCashflowData,
        getDSOData,
        getTopRisksData,
        getRiskRateData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
