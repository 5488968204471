import React, { useState, useEffect } from "react";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
import { IconButton, Tooltip } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import DonutChart from "../../../library/Charts/DonutChart";
import NoData from "../../../library/NoData/NoData";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { useRouteMatch, useHistory } from "react-router-dom";
import Legend from "../../../library/Charts/Legend/Legend";
import "./Aging.scss";

const marginTop = 16;
const marginRight = 0;
const marginBottom = 16;
const marginLeft = 0;

/**
 * * Defines the Aging Widget
 */
export default function Aging(): React.ReactElement {
  // Get GET method of Aging Report from Dashboard Context
  const { getAgingData } = React.useContext(DashboardContext) as DashboardType;
  // Loading State of API Call to GET Aging Data
  const [isLoading, setLoading] = useState<boolean>(false);
  // Error Message of API Call to GET Aging Data
  const [errorMessage, setErrorMessage] = useState<string>("");
  // Aging Data from API Call to GET Aging Data
  const [data, setData] = useState<GraphData[]>([]);
  // Total amount outstanding from Aging Data
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const history = useHistory();
  const { path } = useRouteMatch();

  // GET on /api/v1/Aging
  const fetchData = async () => {
    // Default error message
    setErrorMessage("");
    // API call loading...
    setLoading(true);
    try {
      // On success, set data
      const res = await getAgingData();
      setData(res);
      setTotalAmount(res ? res.reduce((a: number, b: GraphData) => a + b.value, 0) : 0);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    // API call finalized
    setLoading(false);
  };

  const handleOnClick = (id: string) => {
    let route;
    switch (id) {
      case "FUTURE DUE":
        route = "/future_due";
        break;
      case "LATE 1-30 DAYS":
        route = "/late_1_30";
        break;
      case "LATE 31-60 DAYS":
        route = "/late_31_60";
        break;
      case "LATE 61-90 DAYS":
        route = "/late_61_90";
        break;
      default:
        route = "";
        break;
    }
    history.push(`${path}/aging${route}?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC`);
  };

  // On mount, fetch Aging data
  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className={`aging-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`aging-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : (
    <div className={`aging-wrapper`}>
      <div className={`aging-head`}>
        <p className={`aging-title`} onClick={() => handleOnClick("")}>
          Current AR Aging
        </p>
        <Tooltip
          title="The total open invoice balance, and the duration for which invoices have been outstanding."
          placement="right"
          arrow
          disableInteractive
        >
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      {data.length === 0 ? (
        <NoData />
      ) : (
        <div className={`aging-body`}>
          <div className={`aging-pie-wrapper`}>
            <DonutChart
              hoverSquare={false}
              graphData={data}
              graphColors={data.map((d) => d.color)}
              marginTop={marginTop}
              marginRight={marginRight}
              marginBottom={marginBottom}
              marginLeft={marginLeft}
              handleClick={(id) => {
                handleOnClick(id);
              }}
            />
            <div
              className={`pie-total-wrapper`}
              style={{
                marginRight: marginRight,
                marginLeft: marginLeft,
                right: marginRight,
                left: marginLeft,
              }}
            >
              <span className={`pie-total-span`}>
                <span className={`pie-total`}>{shortenCurrency(totalAmount, true)}</span> ($
                {getCurrencySuffix(totalAmount)})
              </span>
            </div>
          </div>
          <Legend
            className="aging-legend"
            data={data.map((val: GraphData) => {
              return {
                color: val.color,
                id: val.id,
                value: val.value,
                onClick: handleOnClick,
              };
            })}
          />
        </div>
      )}
    </div>
  );
}
