import React, { useState } from "react";
import { Search, Reply, DropdownArrow } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import ActivityTo from "./ActivityTo/ActivityTo";
import ActivityTitle from "./ActivityTitle/ActivityTitle";
import ActivityBody from "./ActivityBody/ActivityBody";
import CloseIcon from "@mui/icons-material/Close";
import "./NewActivity.scss";
import { ContentState, EditorState } from "draft-js";

interface NewActivityProps {
    title: string;
    open: boolean;
    defaultTo?: To[];
    defaultTitle?: string;
    defaultBody?: string;
    defaultFiles?: File[];
    replyOptions?: { replyButton: boolean, start: string, reply: To[], replyAll: To[] };
    handleSend: (to: To[], title: string, body: string) => void;
    handleSendMarkClosed?: () => void;
    onClose: () => void;
    contactOptions: To[];
}

export default function NewActivity(props: NewActivityProps): React.ReactElement {
    const newActivityTypings = new Map();
    newActivityTypings.set("Reply", props?.replyOptions?.reply);
    newActivityTypings.set("Reply All", props?.replyOptions?.replyAll);

    const replyButtonStateMappings = new Map();
    replyButtonStateMappings.set("Reply", "Reply All");
    replyButtonStateMappings.set("Reply All", "Reply");

    const [to, setTo] = useState<To[]>(props.defaultTo ?? [])
    const [showSendClose, setShowSendClose] = useState<boolean>(false);
    const [toOption, setToOption] = useState<string>(replyButtonStateMappings.get(props?.replyOptions?.start) ?? "");
    const [title, setTitle] = useState<string>(props.defaultTitle ?? "");
    const [editorState, setEditorState] = useState<EditorState>(() => {
        if (props.defaultBody) {
            return EditorState.createWithContent(ContentState.createFromText(props.defaultBody));
        }
        return EditorState.createEmpty()
    });
    const [files, setFiles] = useState<File[]>(props.defaultFiles ?? []);
    const [filesSelected, setFilesSelected] = useState<boolean>(false);

    const handleSetTo = (e: any, value: To[]) => {
        value.forEach((val: string | To, index, value) => {
            if (typeof val === "string") {
                value[index] = { id: "", label: val }
            }
        });
        setTo(value)
    }

    const handleTitleSelect = (e: any) => {
        setTitle((e.target as HTMLInputElement).value);
    }

    const handleFileSelect = (newFiles: FileList) => {
        setFiles(Array.from(newFiles));
        setFilesSelected(newFiles.length > 0);
    }

    const handleFileRemove = (index: number) => {
        const newFiles = files;
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    const handleReplyButtonClick = () => {
        setTo(newActivityTypings.get(toOption) ?? []);
        setToOption(replyButtonStateMappings.get(toOption) ?? "");
    }

    return (
        <div className={`na-wrapper${props.open ? "" : "-closed"}`}>
            <div className={"na-header"}>
                <p className={"na-header-title-text"}>
                    {props.title}
                </p>
                <div className={"na-header-close-wrapper"} onClick={props.onClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className={"na-body"}>
                <div className={"na-body-to na-body-child"} >
                    <div className={"na-body-toline"}>
                        <div className={"na-body-to-To"}>
                            <p>To</p>
                        </div>
                            <ActivityTo 
                                options={props.contactOptions}
                                to={to}
                                setTo={handleSetTo} 
                            />
                        <div className={"na-body-to-search"}>
                            <Search />
                        </div>
                    </div>
                    {props?.replyOptions?.replyButton && (<div className={"na-body-toline-reply"}>
                        <Button
                            icon={<Reply />}
                            onClick={handleReplyButtonClick}
                            alignIcon={"right"}
                            variant={"secondary"}
                        >
                            {toOption}
                        </Button>
                    </div>
                    )}
                </div>
                <div className={"na-body-title na-body-child"}>
                    <ActivityTitle 
                        title={title ?? ""} 
                        handleTitleSelect={handleTitleSelect} 
                    />
                </div>
                <div className={"na-body-message na-body-child"}>
                    <ActivityBody 
                        editorState={editorState}
                        setEditorState={setEditorState}
                        files={files} 
                        handleFileSelect={handleFileSelect} 
                        filesSelected={filesSelected} 
                        handleFileRemove={handleFileRemove}
                    />
                    <div className={"na-body-message-send"}>
                        <div className={"na-body-message-send-button"}>
                            <Button 
                                variant={"primary"}
                                disabled={false}
                                onClick={() => {
                                    props.handleSend(to, title, editorState.getCurrentContent().getPlainText())
                                    setTo([]);
                                    setTitle("");
                                    setEditorState(EditorState.createEmpty());
                                    props.onClose();
                                }}
                            >
                                Send
                            </Button>
                        </div>
                        {props.handleSendMarkClosed && (
                            <div className={"na-body-message-send-closed"}>
                                <Button 
                                    variant={"primary"}
                                    icon={<DropdownArrow />}
                                    alignIcon={"right"}
                                    onClick={() => setShowSendClose(!showSendClose)}
                                    disabled={false}
                                />
                                { showSendClose && (
                                    <div className={"send-closed-popup"} onClick={props.handleSendMarkClosed}>
                                        <p>Send and Mark as Closed</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}