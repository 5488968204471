class Utils {
  public static validEmail = (email: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return !!email.match(mailformat);
  };

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
  public static openPopup = (url: string, windowName: string, window: any, width: number, height: number): void => {
    const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
    const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;

    window.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  public static getFullStoryConfig(): { fullStoryId: string; forceEnableFullStory: boolean } {
    return {
      fullStoryId: "G2QXT",
      forceEnableFullStory: false,
    };
  }

  public static getIntercomAppId(): string {
    return "a6npq4d0";
  }

  public static shouldEnableIntercom(): boolean {
    return this.isSandbox() || this.isProduction();
  }

  public static isLocal(): boolean {
    return process.env.REACT_APP_ENV === "local";
  }

  public static isDev(): boolean {
    return process.env.REACT_APP_ENV === "dev";
  }

  public static isDevQA(): boolean {
    return process.env.REACT_APP_ENV === "devqa";
  }

  public static isQA(): boolean {
    return process.env.REACT_APP_ENV === "qa";
  }

  public static isSandbox(): boolean {
    return process.env.REACT_APP_ENV === "sbx";
  }

  public static isProduction(): boolean {
    return process.env.REACT_APP_ENV === "prod";
  }

  public static formatPhoneNumber(number: string): string {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      const intlCode = match[1] ? "+1 " : "";

      return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return number;
  }

  /**
   * Returns proper pluralization for the supplied label based on the amount.
   *
   * @param amount Number of items being counted
   * @param label The label for a single unit of the supplied list.
   *
   * e.x. - formatLabelPluralization(2, Company) should return Companies
   */
  public static formatLabelPluralization(amount: number, label: string): string {
    // Singular
    if (amount === 1) {
      return label;
    }

    // Pluralize
    const lastChar = label.length - 1;

    if (label.charAt(lastChar) !== "y") {
      return `${label}s`;
    } else {
      return `${label.substring(0, lastChar)}ies`;
    }
  }

  public static testSanityCheck() {
    return true;
  }

  public static delay(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * This function is used to consume a direct download link and trigger this in the browser.
   * @param uri direct download link object from api
   */
  public static downloadItem = async (uri: any) => {
    const url = uri.downloadLink;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.txt");
    document.body.appendChild(link);
    await Utils.delay(500);
    link.click();
    document.body.removeChild(link);
  };
}

export default Utils;
