import React from "react";
import { Modal } from "@mui/material";
import Button from "../../../library/Button/Button";
import { enrollmentsClient } from "../../../../db/accessor";
import "./AccountingSoftwareModal.scss";

type AccountingSoftwareModalProps = {
  show: boolean;
  setShow: (prev: boolean) => void;
  isActive: boolean;
  erpName: string;
  appId: string;
  refresh: () => void;
  setShowToast: (prev: boolean) => void;
  setToastMessage: (prev: string) => void;
  setToastSeverity: (prev: string) => void;
};

export default function AccountingSoftwareModal(props: AccountingSoftwareModalProps): React.ReactElement {
  function handleAction() {
    enrollmentsClient.changeEnrollment(props.appId, { isActive: !props.isActive }).then(() => {
      props.refresh();
    });
    props.setShow(false);
    props.setShowToast(true);
    props.setToastMessage(`${props.erpName} ${props.isActive ? "disconnected" : "connected"}`);
    props.setToastSeverity("success");
  }

  return (
    <Modal open={props.show} onClose={() => props.setShow(false)} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5);" }}>
      <div className="as-modal">
        <h3>{`${props.isActive ? "Disconnect" : "Reconnect"} ${props.erpName}`}</h3>
        <p>
          {props.isActive
            ? `Disconnecting your accounting software will impact your Lockstep Insights experience. Are you sure you want to disconnect ${props.erpName}`
            : `Are you sure you want to reconnect ${props.erpName}`}
        </p>
        <div className="btn-grp">
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Cancel
          </Button>
          <Button className={props.isActive ? "btn-danger" : ""} onClick={() => handleAction()}>
            {props.isActive ? "Disconnect" : "Reconnect"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
