import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Detail from "../../../library/Detail/Detail";
import { Briefcase, Edit, Email, Fax, Globe, Notes, Location, Phone } from "../../../library/Icons/Icons";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../library/Button/Button";
import { DataMode } from "../../../../types/enums";
import { formatAddress } from "../../../../db/utils/address";
import { AppContext } from "../../../../contexts/AppContext";
import { Skeleton } from "@mui/material";
import { formatDate } from "../../../../db/utils/date";

type ViewCompanyProfileProps = {
  setDataMode: (prev: DataMode) => void;
  data: CompanyModel;
  hasEditPermission: boolean;
  isLoading: boolean;
};

export default function ViewCompanyProfile(props: ViewCompanyProfileProps): React.ReactElement {
  const { userStatus, initialLoad } = React.useContext(AppContext) as AppType;
  return (
    <div className="profile-detail">
      <div className="profile-edit-bar">
        {props.hasEditPermission && !props.isLoading && (
          <Button icon={<Edit />} variant="primary" size="lg" alignIcon={"left"} onClick={() => props.setDataMode(DataMode.Edit)}>
            Edit Profile
          </Button>
        )}
        <div className={props.hasEditPermission && !props.isLoading ? "last-updated" : "last-updated no-button"}>
          {initialLoad || props.isLoading ? (
            <>
              <Skeleton variant="text" width={96} height={24} animation="wave" />
              <Skeleton variant="text" width={96} height={24} animation="wave" />
            </>
          ) : (
            <>
              <p>Updated {formatDate(props?.data?.modified)}</p>
              <p>by {props.data?.modifiedUserId === userStatus.userId ? "You" : props.data?.modifiedUserName}</p>
            </>
          )}
        </div>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
        <div className="container">
          <div>
            <div className="name-row">
              {initialLoad || props.isLoading ? (
                <>
                  <Skeleton variant="circular" width={48} height={48} animation="wave" />
                  <Skeleton variant="text" width={96} height={24} animation="wave" />
                </>
              ) : (
                <>
                  <Userpic />
                  <h4 className="profile-name">{props.data.companyName}</h4>
                </>
              )}
            </div>
            <Detail
              icon={<Email />}
              columns={{ title: "Email", value: props.data.arEmailAddress, format: "email" }}
              isLoading={initialLoad || props.isLoading}
            />
            <Detail
              icon={<Phone />}
              columns={{ title: "Phone", value: props.data.phoneNumber, format: "tel" }}
              isLoading={initialLoad || props.isLoading}
            />
            <Detail
              icon={<Fax />}
              columns={{ title: "Fax", value: props.data.faxNumber, format: "tel" }}
              isLoading={initialLoad || props.isLoading}
            />
            <Detail
              icon={<Location />}
              columns={{
                title: "Company Address",
                value: formatAddress(
                  props.data?.address1 ?? "",
                  props.data?.address2 ?? "",
                  "",
                  props.data?.city ?? "",
                  props.data?.stateRegion ?? "",
                  props.data?.postalCode ?? "",
                  props.data?.country ?? ""
                ),
              }}
              isLoading={initialLoad || props.isLoading}
            />
            <Detail icon={<Briefcase />} columns={{ title: "EIN", value: props.data.taxId }} isLoading={initialLoad || props.isLoading} />
            <Detail
              columns={{ title: "Entity Classification", value: props.data.companyClassificationCodeDefinition?.codeDescription }}
              isLoading={initialLoad || props.isLoading}
            />
            <Detail icon={<Globe />} columns={{ title: "Company Website", value: props.data.website }} isLoading={initialLoad || props.isLoading} />
            <Detail icon={<Notes />} columns={{ title: "Description", value: props.data.description }} isLoading={initialLoad || props.isLoading} />
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
