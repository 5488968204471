import React, { useState } from "react";

export const SettingsContext = React.createContext<SettingsType | null>(null);

export interface SettingsProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }: SettingsProviderProps) => {
  // State needed for Settings pages, hoisted up to pass to the header.
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [syncHasUpdated, setSyncHasUpdated] = useState<number>(0);
  const [lastSync, setLastSync] = useState<SyncRequestModel>({
    syncRequestId: "",
    groupKey: "",
    statusCode: "",
    processResultMessage: "",
    appEnrollmentId: "",
    created: "",
    modified: "",
    modifiedUserId: "",
    details: null,
  });

  return (
    <SettingsContext.Provider
      value={{
        tableData,
        setTableData,
        setSyncHasUpdated,
        syncHasUpdated,
        setLastSync,
        lastSync,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
