import React from "react";
import { Switch, useRouteMatch, Route, useParams } from "react-router-dom";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import { PaymentContext } from "../../contexts/PaymentContext";
import Navbar from "../library/Navbar/Navbar";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import UnappliedPayments from "./UnappliedPayments/UnappliedPayments";
import AllPayments from "./AllPayments/AllPayments";
import "./Payments.scss";

// Define PredefinedFilter routes for the Payments Page
const routes = [
  {
    displayName: "All Payments",
    routeName: "?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
  },
  {
    displayName: "Unapplied Payments",
    routeName: "unappliedPayments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
  },
];

/**
 * * Defines the Payments Tab (Top-level route)
 */
export default function Payments(): React.ReactElement {
  const { paymentDetailViewData } = React.useContext(PaymentContext) as PaymentType;
  const { path } = useRouteMatch();

  return (
    <div className="payments-wrapper">
      <div className="head">
        <Switch>
          <Route
            exact
            path={[`${path}`, `${path}/unappliedPayments`]}
            component={() => {
              return <Navbar tabs={routes} rootPath={path} />;
            }}
          />
          <Route
            path={`${path}/:paymentId`}
            component={() => {
              const { paymentId } = useParams<{ paymentId: string }>();
              const customerName = paymentDetailViewData?.customerName;
              const displayName = customerName ? (customerName.length >= 15 ? customerName.slice(0, 15) + "..." : customerName) : "";
              return (
                <Navbar
                  tabs={[
                    { displayName: "Payment Details", routeName: paymentId },
                    { displayName: "Customer Profile", routeName: `${paymentId}/customer_profile/${paymentDetailViewData?.customerId}` },
                  ]}
                  dropdown={[
                    {
                      displayName: `${displayName ? displayName : "All"} Payments`,
                      routeName: customerName
                        ? `payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC&customer_name=${customerName}`
                        : "payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
                    },
                    {
                      displayName: `${displayName ? displayName : "All"} Invoices`,
                      routeName: customerName
                        ? `invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC&customer_name=${customerName}`
                        : "invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                  ]}
                  dropdownRootPath={path.substr(0, path.lastIndexOf("/"))}
                  dropdownDefault={`Payment ${paymentDetailViewData?.referenceCode ?? "N/A"}`}
                  rootPath={path}
                  backRoute={path.substr(0, 3) + "/payments/" ?? path}
                  backDisplayName="All Payments"
                />
              );
            }}
          />
        </Switch>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
        <div className={`body`}>
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={() => {
                return <AllPayments />;
              }}
            />
            <Route
              exact
              path={`${path}/unappliedPayments`}
              component={() => {
                return <UnappliedPayments />;
              }}
            />
            <Route
              path={`${path}/:paymentId`}
              render={() => {
                return <PaymentDetail />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
