import React, { useState, useEffect } from "react";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { DataMode } from "../../../types/enums";
import { default as Edit } from "./EditPersonalInfo";
import { default as View } from "./ViewPersonalInfo";
import { definitionsClient } from "../../../db/accessor";
import "./PersonalInfo.scss";

// TODO: The currency API currently includes certain values that aren't likely to be selected as a default currency, so we won't show them. See
// TODO: work item 8199 for modifying the API to exclude these values if possible.
const filteredValues: { [key: string]: string } = {
  XBA: "Bond Markets Unit European Composite Unit (EURCO)",
  XBB: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
  XBC: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
  XBD: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
  XTS: "Codes specifically reserved for testing purposes",
  XXX: "The codes assigned for transactions where no currency is involved",
};

function PersonalInfo(): React.ReactElement {
  const { dataMode } = React.useContext(OnboardContext) as OnboardType;
  const [supportedCurrencies, setSupportedCurrencies] = useState<{ id: string; label: string }[]>([]);
  const [showCancel, setShowCancel] = useState<boolean>(false);

  useEffect(() => {
    (async function getCurrencies() {
      setSupportedCurrencies(
        (await definitionsClient.getCurrencies())
          .filter((currency: CurrencyModel) => {
            return !filteredValues[currency.alphaCode];
          })
          .map((currency: CurrencyModel) => {
            return {
              id: currency.alphaCode,
              label: `${currency.currencyName}${currency.symbol ? ` (${currency.symbol})` : ""}`,
            };
          })
          .sort((lVal, rVal) => {
            return lVal.label.localeCompare(rVal.label);
          })
      );
    })();
  }, []);

  return (
    <div className="personal-info-wrapper">
      {supportedCurrencies.length ? (
        <>
          {dataMode === DataMode.Edit && <Edit supportedCurrencies={supportedCurrencies} showCancel={showCancel} />}
          {dataMode === DataMode.View && <View supportedCurrencies={supportedCurrencies} setShowCancel={setShowCancel} />}
        </>
      ) : <></>}
    </div>
  );
}

export default PersonalInfo;
