/* eslint-disable @typescript-eslint/no-explicit-any */
import { HeaderProps, Row, TableToggleAllRowsSelectedProps } from "react-table";

interface GetConditionalAllRowsSelectedProps {
  headerProps: React.PropsWithChildren<HeaderProps<any>>;
  isRowSelectable: (row: Row<any>) => boolean;
  shouldSelectPage?: boolean;
}

interface CheckboxProps extends TableToggleAllRowsSelectedProps {
  disabled: boolean;
}

/**
 * A convenience method for react-table headers for allowing conditional select
 * @param headerProps react-table's header props
 * @param isRowSelectable A predicate - based on your business logic - to determine whether a given row should be selectable
 * @param setShowSelectAll React setter for showing select all menu
 * @param shouldSelectPage Whether to allow page selection. Default: true
 * @returns Modified `checkboxProps` to enforce the conditional select
 */
export const getConditionalAllRowsSelectedProps = ({
  headerProps,
  isRowSelectable,
  shouldSelectPage = true,
}: GetConditionalAllRowsSelectedProps): CheckboxProps => {
  const checkAllRowsSelected = (rows: Row<any>[]) => rows.filter(isRowSelectable).every((row) => row.isSelected);
  const isSelectPage = shouldSelectPage && headerProps.page.filter(isRowSelectable).some((row) => !row.isSelected);

  // Number of rows selected
  const selectedCount = Object.keys(headerProps.state.selectedRowIds).length;
  // Nuber of total rows
  const totalCount = (headerProps.state as any).totalCount;

  const checkboxProps = isSelectPage ? headerProps.getToggleAllPageRowsSelectedProps() : headerProps.getToggleAllRowsSelectedProps();

  const disabled = headerProps.rows.filter(isRowSelectable).length === 0;
  const checked = !disabled && checkAllRowsSelected(headerProps.rows) && selectedCount === totalCount;
  const indeterminate = !checked && selectedCount > 0;

  const onChange = () => {
    // If we're in Select All and all selectable rows are already selected: deselect all rows
    if (!isSelectPage && checkAllRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      // Otherwise:
      // First, define the rows to work with: if we're in Select Page, use `headerProps.page`, otherwise (Select All) use headerProps.rows
      const rows = isSelectPage ? headerProps.page : headerProps.rows;
      // Then select every selectable row
      rows.forEach((row) => {
        const checked = isRowSelectable(row);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
  };

  return {
    ...checkboxProps,
    onChange,
    checked,
    indeterminate,
    disabled,
  };
};
