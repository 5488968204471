/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Dropdown from "../../../library/Dropdown/Dropdown";
import { DropdownArrow } from "../../../library/Icons/Icons";
import { default as ProfileTab } from "./ActivityProfileTab/ActivityProfileTab";
import { default as AttachmentTab } from "./ActivityAttachmentTab/ActivityAttachmentTab";

import "./ActivityTab.scss";

enum Tabs {
  Profile,
  Attachments,
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityTabProps = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityTab(props: ActivityTabProps): React.ReactElement {
  const [tab, setTab] = useState<Tabs>(Tabs.Profile);

  return (
    <div className="activity-tab-wrapper">
      <div className="head">
        <div className="tabs-wrapper">
          <p className={`tab ${tab === Tabs.Profile ? "tab-active" : ""} body2`} onClick={() => setTab(Tabs.Profile)}>
            Profile
          </p>
          <p className={`tab ${tab === Tabs.Attachments ? "tab-active" : ""} body2`} onClick={() => setTab(Tabs.Attachments)}>
            Attachments
          </p>
        </div>
        <div className="jump-wrapper">
          <Dropdown
            icon={<DropdownArrow />}
            iconAlign="left"
            defaultValue={"Jump To"}
            onClick={(onClickValue: string) => console.info(onClickValue)}
            options={[
              {
                value: "Invoices",
                id: "Invoices",
                onClickValue: "Invoices",
              },
              {
                value: "Payments",
                id: "Payments",
                onClickValue: "Payments",
              },
            ]}
          />
        </div>
      </div>
      <div className="body">
        {tab === Tabs.Profile && <ProfileTab />}
        {tab === Tabs.Attachments && <AttachmentTab />}
      </div>
    </div>
  );
}
