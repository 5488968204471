import React, { useState, MouseEvent } from "react";
import { useHistory, useRouteMatch } from "react-router";
import Table from "../../../../library/Table/Table";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { invoicesClient } from "../../../../../db/accessor";

const INVOICESCOLUMNS = [
  {
    Header: "Invoice Number",
    accessor: "invoice_number",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. Blushing Blooms",
    showSort: true,
    searchlightField: "INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "CUSTOMERNAME",
    sortField: "CUSTOMERNAME, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatString(props.value, false),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. Open, Closed, Past Due",
    showSort: true,
    searchlightField: "STATUS",
    sortField: "STATUS, DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { due_date: string | null } } }) =>
      TableUtils.formatStatus(props.row.original.due_date, props.value, false),
  },
  {
    Header: "Due Date",
    accessor: "due_date",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "PAYMENTDUEDATE",
    sortField: "PAYMENTDUEDATE, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatDate(props.value, true),
  },
  {
    Header: "Days Late",
    accessor: "days_late",
    width: "10%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "DAYSPASTDUE",
    sortField: "DAYSPASTDUE, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatDaysLate(props.value, true),
  },
  {
    Header: "Outstanding Amount",
    accessor: "outstanding_amount",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "OUTSTANDINGBALANCE",
    sortField: "OUTSTANDINGBALANCE, INVOICENUMBER",
    searchlightToken: "EQ",
    alignment: "right",
    Cell: (props: { value: number | null }) => TableUtils.formatCurrency(props.value, false),
  },
  {
    Header: "Last Activity",
    accessor: "last_activity",
    width: "15%",
    showFilter: true,
    popupPlaceholder: "ex. 100",
    showSort: true,
    searchlightField: "NEWESTACTIVITY",
    sortField: "NEWESTACTIVITY, INVOICENUMBER",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatLastActivity(props.value, false),
  },
];

export default function AgingTable(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const invoiceFetchParser = (fetchResult: InvoiceSummaryModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceSummaryModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.invoiceId,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record?.invoiceNumber,
            customer_name: record?.customerName || "N/A",
            status: record?.status,
            due_date: record?.paymentDueDate,
            days_late: record?.daysPastDue,
            outstanding_amount: record?.outstandingBalance,
            last_activity: record?.newestActivity,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  function handleInvoiceRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${path.substring(0, path.lastIndexOf("/dashboard/aging"))}/invoices/${row.original.id ?? ""}`);
  }

  return (
    <Table
      dataSets={[
        {
          id: "By Invoices",
          displayName: "By Invoices",
          rowSelectToken: "invoiceId",
          columns: INVOICESCOLUMNS,
          data: {
            tableData: tableData,
            setTableData: setTableData,
            fetchCall: invoicesClient.getInvoiceSummaries,
            fetchParser: invoiceFetchParser,
          },
          export: {
            exportFileName: "Aging Drilldown - By Invoices",
          },
          defaultSort: "PAYMENTDUEDATE, INVOICENUMBER",
          defaultSortToken: "DESC",
          handleRowClick: handleInvoiceRowClick,
          predefinedFilters: [
            {
              route: "aging",
              searchlightFilter: `(DAYSPASTDUE > 90) AND (STATUS eq 'Past Due')`,
            },
            {
              route: "late_61_90",
              searchlightFilter: "(DAYSPASTDUE >= 61) AND (DAYSPASTDUE <= 90) AND (STATUS eq 'Past Due')",
            },
            {
              route: "late_31_60",
              searchlightFilter: "(DAYSPASTDUE >= 31) AND (DAYSPASTDUE <= 60) AND (STATUS eq 'Past Due')",
            },
            {
              route: "late_1_30",
              searchlightFilter: "(DAYSPASTDUE >= 1) AND (DAYSPASTDUE <= 30) AND (STATUS eq 'Past Due')",
            },
            {
              route: "future_due",
              searchlightFilter: "(STATUS eq 'Open')",
            },
          ],
        },
      ]}
      states={{
        isLoading: isLoading,
        setLoading: setLoading,
        isError: isError,
        setError: setError,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      }}
      pagination={{
        pageCount: pageCount,
        setPageCount: setPageCount,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
      }}
      toggles={{
        showRowSelect: true,
        showExportBtn: true,
        showCaption: true,
        showNavigation: true,
        showSearchbar: false,
      }}
    />
  );
}
