import APIClient from "../APIClient";

export class StatusClient {
  /**
   * * GET on /api/v1/Status
   * @returns Promise of type StatusModel or Exception
   */
  getStatus(): Promise<StatusModel> {
    const url = "api/v1/Status";

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }
}
