import React, { useState } from "react";
import { CookiesProvider } from "react-cookie";

import { msalInstance } from "..";
import { statusClient, leadsClient, reportsClient, syncClient, userRolesClient } from "../db/accessor";
import { appInsights } from "../AppInsights";
import { UserRoles, UserActions } from "../types/enums";

export const AppContext = React.createContext<AppType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const [selectedERP, setSelectedERP] = useState<ERPModel | null>(null);
  const [syncError, setSyncError] = useState<boolean>(false);
  const [erpConnectionError, setERPConnectionError] = useState<boolean>(false);
  const [initialSyncInProgress, setInitialSyncInProgress] = useState<boolean>(true);
  const [userStatus, setUserStatus] = useState<StatusModel>({
    userName: "",
    accountName: "",
    accountCompanyId: "",
    userId: "",
    groupKey: "",
    loggedIn: false,
    errorMessage: "",
    roles: [],
    lastLoggedIn: null,
    dependencies: null,
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [userAccountName, setUserAccountName] = useState<string>("");
  const [userRoles, setUserRoles] = useState<UserRoleModel[]>([]);

  const [onboardData, setOnboardData] = useState<OnboardDataModel>({
    fullName: "",
    timeZone: "",
    defaultCurrency: "USD",
    erp: {
      name: "",
      appId: "",
      data: {
        authCode: "",
        realmId: "",
      },
    },
    company: {
      defaultCurrencyCode: "USD",
      country: "United States",
    },
  });

  const permissionMap = new Map();
  permissionMap.set(UserActions.AddNote, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.InvitationsCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemind, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InviteMembers, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsChangeRole, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersInvite, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ChangeRoles, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditMyProfile, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.EditMyCompany, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.AddEmailClient, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditAccountingSoftware, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RunSync, [UserRoles.Member, UserRoles.GroupOwner, UserRoles.GroupAdmin]);

  async function getARHeader(reportDate: string, companyId?: string): Promise<ArHeaderInfoModel> {
    return reportsClient.getARHeader(reportDate, companyId).then((data: ArHeaderInfoModel) => {
      return data;
    });
  }

  async function getDocumentsHeader(companyId?: string): Promise<AttachmentHeaderInfoModel> {
    return reportsClient.getAttachmentsHeader(companyId).then((data: AttachmentHeaderInfoModel) => {
      return data;
    });
  }

  // GET on /api/v1/Reports/ar-aging-header
  async function getArAgingHeader(): Promise<ArAgingHeaderInfoModel[]> {
    return reportsClient.getAging().then((data: ArAgingHeaderInfoModel[]) => {
      // 91+ -> ... -> Future Due
      data.sort((left: ArAgingHeaderInfoModel, right: ArAgingHeaderInfoModel) => ((left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? 1 : -1));
      return data;
    });
  }

  // Clears the user's session and redirects back to login
  function logout(): void {
    const currentAccount = msalInstance.getActiveAccount();

    msalInstance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: window.location.origin,
    });

    appInsights?.clearAuthenticatedUserContext();
  }

  function getTokenFromQueryString(): string | null {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("id_token")) {
      return null;
    } else {
      return String(urlParams.get("id_token"));
    }
  }

  async function getStatus(): Promise<StatusModel> {
    const response = await statusClient.getStatus().then((data: StatusModel) => {
      setUserStatus(data);
      setUserAccountName(data?.accountName ?? "");
      return data;
    });
    await userRolesClient
      .query()
      .then((data: UserRoleModelFetchResult) => {
        setUserRoles(data?.records?.sort((a: UserRoleModel, b: UserRoleModel) => (a.userRoleId < b.userRoleId ? -1 : 1)) ?? []);
      })
      .catch(() => {
        setUserRoles([]);
      });
    setInitialLoad(false);
    return response;
  }

  function hasPermission(action: string) {
    if (action) {
      return permissionMap.get(action).some((role: string) => userStatus.roles?.includes(role));
    } else {
      return false;
    }
  }

  async function createLead(leadData: LeadsModel[]): Promise<LeadsModel[]> {
    return leadsClient.createLead(leadData);
  }

  async function getSyncStatus(syncRequestId: string, includeDetails?: boolean): Promise<SyncRequestModel> {
    return syncClient.getSyncStatus(syncRequestId, includeDetails);
  }

  return (
    <AppContext.Provider
      value={{
        logout,
        getTokenFromQueryString,
        getStatus,
        createLead,
        getSyncStatus,
        setSelectedERP,
        setInitialSyncInProgress,
        selectedERP,
        onboardData,
        setOnboardData,
        initialSyncInProgress,
        getARHeader,
        getDocumentsHeader,
        getArAgingHeader,
        erpConnectionError,
        setERPConnectionError,
        syncError,
        setSyncError,
        userStatus,
        userAccountName,
        hasPermission,
        initialLoad,
        userRoles,
      }}
    >
      <CookiesProvider>{children}</CookiesProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
