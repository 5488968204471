import React, { useState } from "react";
import { ButtonProps } from "../../Button/Button";
import { useHistory } from "react-router-dom";
import { Ascending, Descending } from "../../Icons/Icons";
import "./SortButton.scss";

// Defines Sort Button Props
interface SortButtonProps extends ButtonProps {
  // TODO: Find type for headerProps

  //@typescript-eslint/ban-ts-comment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerProps: any;
  label: React.ReactNode;
  isDefault?: boolean;
}

/**
 * * Defines the Sort Button on a Table
 */
export function SortButton(props: SortButtonProps): React.ReactElement {
  // Get History handler from react-router-dom
  const history = useHistory();
  // Represents whether the Sort is Active on a column of the Table
  const [isActive, setIsActive] = useState<boolean>(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!props.headerProps?.showSort) return false;
    const params = new URLSearchParams(window.location.search);
    const value = params.get("sort");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (props.headerProps?.sortField) {
      const sortFieldDesc = props.headerProps.sortField
        .split(", ")
        .map((field: string) => field + " DESC")
        .join(", ");
      return (value === props.headerProps?.sortField || value == sortFieldDesc) ?? false;
    } else {
      return (value === props.headerProps?.searchlightField || value === props.headerProps?.searchlightField + " DESC") ?? false;
    }
  });
  // Represents whether the Sort is descending on a column of the Table
  const [isDescending, setDescending] = useState(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!props.headerProps?.showSort) return false;
    const params = new URLSearchParams(window.location.search);
    const value = params.get("sort");
    if (
      isActive &&
      value?.includes(
        props.headerProps?.sortField
          ? props.headerProps?.sortField
              .split(", ")
              .map((field: string) => field + " DESC")
              .join(", ")
          : props.headerProps?.searchlightField + " DESC"
      )
    ) {
      return true;
    } else {
      return false;
    }
  });

  // Handles on click events for the Sort Button
  function handleChange() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!props.headerProps?.showSort) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    params.delete("sort");
    if (!isActive) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.append("sort", props.headerProps?.sortField ?? props.headerProps?.searchlightField);
      setIsActive(true);
    } else {
      if (!isDescending) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.append(
          "sort",
          `${
            props.headerProps?.sortField
              ? props.headerProps?.sortField
                  .split(", ")
                  .map((field: string) => field + " DESC")
                  .join(", ")
              : props.headerProps?.searchlightField + " DESC"
          }`
        );
        setDescending(true);
      } else {
        params.delete("sort");
        setDescending(false);
        setIsActive(false);
      }
    }
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  function handleChangeDefault() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!props.headerProps?.showSort) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    params.delete("sort");
    if (!isDescending) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.append(
        "sort",
        `${
          props.headerProps?.sortField
            ? props.headerProps?.sortField
                .split(", ")
                .map((field: string) => field + " DESC")
                .join(", ")
            : props.headerProps?.searchlightField + " DESC"
        }`
      );
      setDescending(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.append("sort", `${props.headerProps?.sortField ?? props.headerProps?.searchlightField}`);
      setDescending(false);
    }
    history.push({ search: params.toString().replace(/[?&]$/, "") });
  }

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      className={`sort-btn-wrapper ${props.headerProps?.showSort ? "" : "sort-btn-wrapper-disabled"}`}
      onClick={() => (!props.disabled ? (props.isDefault ? handleChangeDefault() : handleChange()) : null)}
    >
      <p className={`sort-btn-label`}>{props.label}</p>
      {/*// eslint-disable-next-line @typescript-eslint/no-explicit-unknown
       */}

      {props.headerProps?.showSort &&
        (isDescending ? (
          <Descending className={`sort-btn-icon ${isActive ? "sort-btn-icon-active" : ""}`} />
        ) : !isActive ? (
          props.isDefault ? (
            <Descending className={`sort-btn-icon ${isActive ? "sort-btn-icon-active" : ""}`} />
          ) : (
            <Ascending className={`sort-btn-icon ${isActive ? "sort-btn-icon-active" : ""}`} />
          )
        ) : (
          <Ascending className={`sort-btn-icon ${isActive ? "sort-btn-icon-active" : ""}`} />
        ))}
    </div>
  );
}
