import React from "react";
import { ButtonProps } from "../../../components/library/Button/Button";
import "./FlyoutButton.scss";

interface FlyoutButtonProps extends Omit<ButtonProps, "variant"> {
  header: string;
  subHeader: string;
  icon?: React.ReactNode;
}

export default function FlyoutButton(
  props: FlyoutButtonProps
): React.ReactElement {
  return (
    <button className={"flyout-btn"} onClick={props.onClick}>
      <div className={"icon-wrapper"}>
        <div className={"icon"}>{props?.icon ? props.icon : null}</div>
      </div>
      <div className={"text-wrapper"}>
        <h3 className={"header"}>{props.header}</h3>
        <p className={"subHeader"}>{props.subHeader}</p>
      </div>
    </button>
  );
}
