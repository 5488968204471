import React from "react";
import { CaretUp } from "../Icons/Icons";
import "./AccordionNav.scss";
import NavLinkWrapper from "../NavLink";
import Activity, { ActivityVariants } from "../Sidebar/Activity/Activity";
import { useRouteMatch } from "react-router-dom";

interface AccordionProps {
  className: string;
  navTitle: string;
  navList: { name: string; id: string; route?: string; count?: number }[];
  expanded?: boolean; // Escape hatch for expanded state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExpanded?: any;
  bodyMaxHeight?: string;
}
export default function AccordionNav(props: AccordionProps): React.ReactElement {
  const { url } = useRouteMatch();
  const navUrl = props.className === "network-nav-item" ? `/AR/customers` : `${url}`;

  return (
    <div className="accordion-wrapper">
      <div className={"nav-title-wrapper"} onClick={() => props.setExpanded((prevState: boolean) => !prevState)}>
        <div className={`icon icon-${props.expanded ? "expanded" : "closed"}`}>
          <CaretUp />
        </div>
        <div className={"nav-title"}>{props.navTitle.toUpperCase()}</div>
      </div>
      <div className={"nav-body"} style={{ maxHeight: props.bodyMaxHeight ?? "auto" }}>
        {props.expanded && props.className === "network-nav-item" && props.navList.length > 1 && (
          <NavLinkWrapper
            className={props.className}
            exact={true}
            strict={false}
            key={"customers"}
            to={`${url}/customers?sort=AMOUNTPASTDUE+DESC%2C+COMPANYNAME+DESC`}
          >
            {(isActive: boolean) => (
              <Activity className={"all-customers"} variant={`customers` as ActivityVariants} isActive={isActive}>
                All Customer Connections
              </Activity>
            )}
          </NavLinkWrapper>
        )}
        {props.expanded &&
          props.navList.map((item) => {
            return item.name ? (
              <NavLinkWrapper className={props.className} exact={false} strict={false} key={item.id} to={`${navUrl}/${item?.route ?? item.id}`}>
                {(isActive: boolean) => (
                  <Activity
                    className={item.id === "customers" ? "all-customers" : ""}
                    variant={`${item.id}` as ActivityVariants}
                    isActive={isActive}
                    count={item.count}
                  >
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </Activity>
                )}
              </NavLinkWrapper>
            ) : (
              ""
            );
          })}
      </div>
    </div>
  );
}
