import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import FullStory from "react-fullstory";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import App from "./App";
import { msalConfig } from "./auth/authConfig";
import Utils from "./utils/utils";
import "./css/index.scss";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { primaryTheme } from "./css/MUITheme";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

ReactDOM.render(<FullStory org={Utils.getFullStoryConfig().fullStoryId} />, document.getElementById("fullstory-node"));

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(async (event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || ""

  });

ReactDOM.render(
  <React.StrictMode>
     <LDProvider>
      <ThemeProvider theme={primaryTheme}>
        <Router>
          <App pca={msalInstance} />
        </Router>
      </ThemeProvider>
    </LDProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
