import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useState } from "react";
import Dropdown from "../../../../library/Dropdown/Dropdown";
import { DropdownArrow } from "../../../../library/Icons/Icons";
import { formatDate } from "../../../../../db/utils/date";
import "./ActivityAttachmentTab.scss";

const DUMMYDATA = [
  {
    id: "0",
    name: "Document Name",
    createdBy: "Raychel Green",
    type: "Document Type",
    createdDate: "2021-05-30",
  },
  {
    id: "1",
    name: "3252151.pdf",
    createdBy: "Raychel Green",
    type: "Email attachment",
    createdDate: "2021-11-30",
  },
  {
    id: "2",
    name: "Onboarding.doc",
    createdBy: "Raychel Green",
    type: "Email attachment",
    createdDate: "2021-08-30",
  },
  {
    id: "3",
    name: "WWBC",
    createdBy: "Raychel Green",
    type: "Credit application",
    createdDate: "2021-01-30",
  },
  {
    id: "4",
    name: "Document Name",
    createdBy: "Raychel Green",
    type: "W9",
    createdDate: "2021-02-30",
  },
  {
    id: "5",
    name: "Document Name",
    createdBy: "Raychel Green",
    type: "Other",
    createdDate: "2021-03-30",
  },
];

type AttachmentProps = {
  id: string;
  name: string;
  createdBy: string;
  type: string;
  createdDate: string;
};

function Attachment(props: AttachmentProps): React.ReactElement {
  const handleOnClick = () => {
    console.info(`Clicked Attachment #${props.id}`);
  };
  return (
    <div className="activity-attachment-item" onClick={() => handleOnClick()}>
      <div className="head">
        <p className="body1">{props.name}</p>
        <p className="body1">{props.createdBy}</p>
      </div>
      <div className="body">
        <p className="caption">{props.type}</p>
        <p className="caption">{formatDate(props.createdDate)}</p>
      </div>
    </div>
  );
}

export default function AttachmentTab(): React.ReactElement {
  const [sort, setSort] = useState<string>("name");

  return (
    <div className="activity-attachment-tab">
      <div className="head">
        <Dropdown
          icon={<DropdownArrow />}
          iconAlign="right"
          defaultValue={"Sort By Name"}
          onClick={(onClickValue: string) => {
            setSort(onClickValue);
          }}
          options={[
            {
              value: "Sort By Name",
              id: "Sort By Name",
              onClickValue: "name",
            },
            {
              value: "Sort By Type",
              id: "Sort By Type",
              onClickValue: "type",
            },
            {
              value: "Sort By Date",
              id: "Sort By Date",
              onClickValue: "date",
            },
          ]}
        />
      </div>
      <div className="body">
        <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
          <div>
            {DUMMYDATA.sort((a, b) => {
              switch (sort) {
                case "name":
                  return a.name === b.name ? (a.type < b.type ? -1 : 1) : a.name < b.name ? -1 : 1;
                case "type":
                  return a.type === b.type ? (a.name < b.name ? -1 : 1) : a.type < b.type ? -1 : 1;
                case "date":
                  return a.createdDate === b.createdDate ? (a.name < b.name ? -1 : 1) : a.createdDate < b.createdDate ? -1 : 1;
                default:
                  return a.name === b.name ? (a.type < b.type ? -1 : 1) : a.name < b.name ? -1 : 1;
              }
            }).map((document: { id: string; name: string; createdBy: string; type: string; createdDate: string }, index) => {
              return (
                <Attachment
                  key={`attachment-${index}`}
                  id={document.id}
                  name={document.name}
                  createdBy={document.createdBy}
                  type={document.type}
                  createdDate={document.createdDate}
                />
              );
            })}
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
}
