import React, { useState, useRef, useEffect } from "react";
import { CSVLink } from "react-csv";

import Button, { ButtonProps } from "../../Button/Button";
import { Export } from "../../Icons/Icons";

interface ExportButtonProps extends ButtonProps {
  exportFileName: string;
  getExportData?: any;
}

export function ExportButton(props: ExportButtonProps): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [exportData, setExportData]: any[] = useState([]);
  const csvInstance = useRef<any | null>(null);

  async function handleOnClick() {
    setLoading(true);

    try {
      const data = await props.getExportData();
      setExportData(data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  useEffect(() => {
    if ((exportData?.length ?? 0) > 0 && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  return (
    <>
      <Button icon={<Export />} alignIcon={props.alignIcon} onClick={() => handleOnClick()} disabled={props.disabled} loading={isLoading}>
        Export
      </Button>

      {(exportData?.length ?? 0) > 0 ? (
        <CSVLink
          data={exportData}
          filename={`${props.exportFileName} ${new Date().toLocaleDateString()} Export.csv`}
          ref={csvInstance}
          style={{ display: "none" }}
        />
      ) : undefined}
    </>
  );
}
