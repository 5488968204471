import { Configuration, PopupRequest, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import * as UUID from "uuid";

// Config object to be passed to MSAL on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
    knownAuthorities: [`${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}`],
    redirectUri: window.location.origin,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

// The scopes used for acquiring an access token from various MS Identity Platform endpoints are specified in the following configuration objects
export const loginRequest: RedirectRequest = {
  scopes: ["openid"],
  nonce: "defaultNonce",
  prompt: "login",
  redirectUri: window.location.origin,
};

export const silentRequest: SilentRequest = {
  scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID ?? "openid"}`],
  redirectUri: window.location.origin + "/start",
  authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
};

export const silentRequestForgotPassword: SilentRequest = {
  scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID ?? "openid"}`],
  redirectUri: window.location.origin + "/start",
  authority: process.env.REACT_APP_AZURE_B2C_FORGOT_PASSWORD_AUTHORITY,
};

export const forgotPasswordRequest: PopupRequest = {
  scopes: ["openid"],
  nonce: "defaultNonce",
  prompt: "login",
  redirectUri: window.location.origin,
  authority: process.env.REACT_APP_AZURE_B2C_FORGOT_PASSWORD_AUTHORITY,
};

export const forgotPasswordRedirectRequest: RedirectRequest = {
  scopes: ["openid"],
  nonce: "defaultNonce",
  prompt: "login",
  redirectUri: window.location.origin,
  redirectStartPage: window.location.origin,
  authority: process.env.REACT_APP_AZURE_B2C_FORGOT_PASSWORD_AUTHORITY,
};

export const buildAuthRedirectURL = (connector: string, appId: string, localStorageKey?: string): string => {
  const id = UUID.v4().replace(/-/g, "");
  const state = encodeURIComponent(`${connector}|${appId}|` + id);

  // We save the state value so we can compare it to what the given ERP returns in its redirect, as a security measure
  localStorage.setItem(localStorageKey ?? "ERPAuthState", id);

  const redirectURL = process.env.REACT_APP_REDIRECT_URL ?? "";
  let scopes: Array<string>;
  let url = "";

  switch (connector) {
    case "Quickbooks Online":
      scopes = ["openid", "profile", "email", "address", "phone", "com.intuit.quickbooks.accounting"];

      url =
        "https://appcenter.intuit.com/connect/oauth2?" +
        "response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_QUICKBOOKS_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state;

      break;
    case "Xero":
      scopes = [
        "openid",
        "profile",
        "email",
        "offline_access",
        "accounting.transactions.read",
        "accounting.contacts.read",
        "accounting.settings.read",
      ];

      url =
        "https://login.xero.com/identity/connect/authorize?" +
        "response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_XERO_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state;

      break;
    case "Microsoft Outlook":
      scopes = ["user.read", "openid", "profile", "offline_access", "mail.read", "mail.send", "mail.readwrite"];

      url =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_MICROSOFT_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state;

      break;
  }

  return url;
};
