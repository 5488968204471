import React, { Dispatch, useEffect, useState } from "react";
import { Alert, Drawer, Snackbar, TextField } from "@mui/material";
import Button from "../../../../library/Button/Button";
import { TextArea } from "../../../../library/TextArea/TextArea";
import { MoveTo } from "../../../../library/Icons/Icons";
import { AppContext } from "../../../../../contexts/AppContext";
import { activitiesClient, notesClient, companiesClient } from "../../../../../db/accessor";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { NoteType } from "../../../../../types/enums";

// eslint-disable-next-line @typescript-eslint/ban-types
type ActivityMoveProps = {
  activityId: string;
  noteValue: string;
  setNoteValue: Dispatch<string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActivityMove(props: ActivityMoveProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companyListOpen, setCompanyListOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>("");
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [companyList, setCompanyList] = useState<Partial<CompanyModel>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Partial<CompanyModel> | null>(null);

  const fetchCompanyList = async () => {
    try {
      const predefinedFilter = "(ISACTIVE EQ true) AND (COMPANYTYPE EQ 'Customer')";
      await companiesClient.getCompanies(predefinedFilter).then((data) => {
        setCompanyList(
          data.records?.map((company: CompanyModel) => {
            return {
              companyId: company.companyId,
              companyName: company.companyName,
            };
          }) ?? []
        );
      });
      setIsLoading(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  // Load list of user choices for moving
  useEffect(() => {
    if (!isLoading) {
      return undefined;
    }
    fetchCompanyList();
  }, [isLoading]);

  const handleMove = async () => {
    try {
      await activitiesClient.patch(props.activityId, {
        companyId: selectedCompany?.companyId,
      });

      await notesClient.post([
        {
          tableKey: "Activity",
          objectKey: props.activityId,
          noteText: `(Moved) To Connection: ${selectedCompany?.companyName} by ${userStatus.userName} Note: ${props.noteValue}`,
          noteType: NoteType.Note,
        },
      ]);
    } catch (e: unknown) {
      setToastMsg("Activity was not moved");
      setToastError(true);
    } finally {
      props.setNoteValue(props.noteValue);
      setShowToast(true);
      setIsOpen(false);
    }
  };

  return (
    <div className="activity-move">
      <Button
        icon={<MoveTo />}
        onClick={() => setIsOpen(true)}
        variant={"solo-icon"}
        alignIcon={"left"}
        tooltip={"Move"}
        className={"detail-icon-btn"}
      />

      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)} disablePortal>
        <div className="activity-flyout">
          <h3 className="header">Move Assignment</h3>
          <p className="subheader body1">Move this assignment to another connection.</p>
          <label htmlFor={"string"}>{"Connection"}</label>
          <Autocomplete
            getOptionLabel={(option: Partial<CompanyModel>) => option.companyName ?? "N/A"}
            options={companyList}
            loading={isLoading}
            open={companyListOpen}
            onOpen={() => {
              setCompanyListOpen(true);
            }}
            onClose={() => {
              setCompanyListOpen(false);
            }}
            onChange={async (_event, value: Partial<CompanyModel>) => {
              setSelectedCompany(value);
              setToastMsg(value.companyName ?? "");
            }}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Choose A Connection",
                  disableUnderline: true,
                }}
              />
            )}
            isOptionEqualToValue={(option: Partial<CompanyModel>, value: string) => {
              return option.companyId === value;
            }}
          />
          <TextArea
            label={{ regularText: "Note", extraText: " (optional)" }}
            placeholder={""}
            onChange={props.setNoteValue}
            defaultValue={props.noteValue}
          />
          <div className="btn-grp">
            <Button variant="secondary" size="lg" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button size="lg" onClick={() => handleMove()}>
              Move
            </Button>
          </div>
        </div>
      </Drawer>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? (
            toastMsg
          ) : (
            <p>
              Successfully changed connection to <span style={{ fontWeight: 700 }}>{toastMsg}</span>
            </p>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}
