import React, { useState, useEffect, MouseEvent } from "react";
import Loading from "../../../library/Loading/Loading";
import Error from "../../../library/Error/Error";
// Imports from react-router-dom
import { useHistory, useRouteMatch } from "react-router-dom";
// Import contxt
import { DashboardContext } from "../../../../contexts/DashboardContext";
// Import assets
import "./TopCustomers.scss";
import NoData from "../../../library/NoData/NoData";
import { getCurrencySuffix, shortenCurrency } from "../../../../db/utils/currency";
import { Tooltip, IconButton } from "@mui/material";
import { TooltipIcon } from "../../../library/Icons/Icons";

/**
 * * Defines the Top 5 Customers with Invoices Past Due Widget
 */
export default function TopCustomers(): React.ReactElement {
  const { getTopRisksData } = React.useContext(DashboardContext) as DashboardType;
  const { path } = useRouteMatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<CustomerSummaryModel[] | null>(null);

  // GET on /api/v1/Companies/views/customer-summary
  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await getTopRisksData();
      setData(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (e: MouseEvent<HTMLDivElement>, id: string) => {
    history.push(`${path.substring(0, path.lastIndexOf("/"))}/customers/${id}/open_invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC`);
  };

  const handleOnClick = (e: MouseEvent<HTMLParagraphElement>) => {
    e.preventDefault();
    history.push(`${path}/top_customers?sort=AMOUNTPASTDUE+DESC%2C+TOTALINVOICESPASTDUE+DESC`);
  };

  return isLoading ? (
    <div className={`tr-wrapper`}>
      <Loading />
    </div>
  ) : errorMessage ? (
    <div className={`tr-wrapper`}>
      <Error>{errorMessage}</Error>
    </div>
  ) : (
    <div className={`tr-wrapper`}>
      <div className={`tr-head`}>
        <span className={`title`} onClick={(e: MouseEvent<HTMLParagraphElement>) => handleOnClick(e)}>
          Top 5 Customers with Invoices Past Due
        </span>
        <Tooltip title="The top 5 customers with the largest past due balance." placement="right" arrow disableInteractive>
          <IconButton size="small">
            <TooltipIcon />
          </IconButton>
        </Tooltip>
      </div>
      {data && data.length !== 0 ? (
        // TODO: Talk with API team about why pageSize doesn't where here. This is a hot-fix until the issue with the endpoint is resolved
        data.slice(0, 5).map((val: CustomerSummaryModel, idx: number) => {
          return (
            <div className={`tr-row`} key={`${val.companyName}-${idx}`} onClick={(e: MouseEvent<HTMLDivElement>) => handleRowClick(e, val.companyId)}>
              <div className={`customer-wrapper`}>
                <p className={`customer`}>{val?.companyName ?? "N/A"}</p>
              </div>
              <p className="value">{val?.totalInvoicesPastDue}</p>
              <p className="type">Past Due Invoices</p>
              <p className="value">
                {(val?.amountPastDue ?? 0) < 0 ? "-" : ""}${Math.abs(parseInt(shortenCurrency(val?.amountPastDue ?? 0, false), 10))}
                <span>{getCurrencySuffix(val?.amountPastDue ?? 0)}</span>
              </p>
              <p className="type">Past Due Balance</p>
            </div>
          );
        })
      ) : (
        <NoData />
      )}
    </div>
  );
}
