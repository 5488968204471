import APIClient from "../APIClient";

export class InvoicesClient {
  /**
   * * GET on /api/v1/Invoices/{id}
   * @param id - Database ID associated with an invoice
   * @param include
   * @returns Promise of type InvoiceModel or Exception
   */
  getInvoice(id: string, include?: string): Promise<InvoiceModel> {
    let url = "api/v1/Invoices/{id}?";

    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.");
    }

    url = url.replace("{id}", encodeURIComponent("" + id));

    if (include === null) {
      throw new Error("The parameter 'include' cannot be null.");
    }

    if (include !== undefined) {
      url += "include=" + encodeURIComponent("" + include) + "&";
    }

    url = url.replace(/[?&]$/, "");

    return APIClient.get(url).then(response => {
      return response.data;
    })
  }

  /**
   * * GET on /api/v1/Invoices/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceModelFetchResult or Exception
   */
  getInvoices(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<InvoiceModelFetchResult> {
    const url = "api/v1/Invoices/query";

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then(response => {
      return response.data;
    })
  }

  /**
   * * GET on /api/v1/Invoices/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceSummaryModelFetchResult or Exception
   */
  getInvoiceSummaries(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<InvoiceSummaryModelFetchResult> {
    const url = "api/v1/Invoices/views/summary";

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then(response => {
      return response.data;
    })
  }

  /**
   * * GET on /api/v1/Invoices/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceSummaryModelFetchResult or Exception
   */
   queryAtRiskSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<AtRiskInvoiceSummaryModelFetchResult> {
    const url = "api/v1/invoices/views/at-risk-summary";

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then(response => {
      return response.data;
    });
  }
}
