import React from "react";
import Button from "../../library/Button/Button";
import { RightArrowWhite, Edit, BackArrow, Company } from "../../library/Icons/Icons";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import Utils from "../../../utils/utils";
import { DataMode, OnboardStep } from "../../../types/enums";

type ViewCompanyInfoProps = {
  setShowCancel: (prev: boolean) => void;
};

function ViewCompanyInfo(props: ViewCompanyInfoProps): React.ReactElement {
  const { setOnboardStep, setDataMode } = React.useContext(OnboardContext) as OnboardType;
  const { onboardData } = React.useContext(AppContext) as AppType;

  const handleSubmit = () => {
    setDataMode(DataMode.Edit);
    setOnboardStep(OnboardStep.ConnectEmail);
  };

  const handleEdit = () => {
    setDataMode(DataMode.Edit);
    props.setShowCancel(true);
  };

  return (
    <>
      <Button
        className="back-arrow-button"
        variant="transparent"
        icon={<BackArrow />}
        alignIcon="left"
        onClick={handleEdit}
        data-testid="EditYourCompanyInfoBackButton"
      >
        Your Company Info
      </Button>
      <h1>Almost Done.</h1>
      <p className="p-body desc">Please confirm your company's information is correct.</p>

      <div className="data-container">
        <div className="data-row">
          <div className="icon-col">
            <span className="icon">
              <Company />
            </span>
          </div>

          <div className="data-col">
            <div className="view-mode-label">Company Name</div>
            <div className="value" data-testid="ReadonlyCompanyInfoCompanyName">
              {onboardData?.company?.companyName}
            </div>
          </div>

          <div className="data-col">
            <div className="view-mode-label">Company Phone Number</div>
            <div className="value" data-testid="ReadonlyCompanyInfoPhone">
              {onboardData?.company?.phoneNumber ? Utils.formatPhoneNumber(onboardData.company.phoneNumber) : ""}
            </div>
          </div>
        </div>

        <div className="data-row">
          <div className="icon-col" />

          <div className="data-col">
            <div className="view-mode-label">Company Address</div>

            <div>
              <div className="value" data-testid="ReadonlyCompanyInfoAddressLine1">
                {onboardData.company.address1}
              </div>
              <div className="value" data-testid="ReadonlyCompanyInfoAddressLine1">
                {onboardData.company.address2}
              </div>
              <div className="value" data-testid="ReadonlyCompanyInfoCity">
                {onboardData?.company?.city}, {onboardData?.company?.stateRegion}
              </div>
              <div className="value" data-testid="ReadonlyCompanyInfoPostalCode">
                {onboardData?.company?.postalCode}
              </div>
              <div className="value" data-testid="ReadonlyCompanyInfoCountry">
                {onboardData?.company?.country}
              </div>
            </div>
          </div>

          <div className="data-col">
            {onboardData?.company?.faxNumber && (
              <>
                <div className="view-mode-label">Company Fax Number</div>
                <div className="value" data-testid="ReadonlyCompanyInfoFax">
                  {Utils.formatPhoneNumber(onboardData?.company?.faxNumber)}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="button-row">
        <Button icon={<RightArrowWhite />} data-testid="ReadonlyCompanyInfoLooksGreatButton" onClick={handleSubmit}>
          Looks Great
        </Button>

        <Button icon={<Edit />} data-testid="ReadonlyCompanyInfoMakeEditButton" variant="secondary" onClick={handleEdit} alignIcon={"right"}>
          I Need to Make an Edit
        </Button>
      </div>
    </>
  );
}

export default ViewCompanyInfo;
