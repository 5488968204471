import React from "react";
import InvoiceDetail from "./InvoiceDetail/InvoiceDetail";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import { InvoiceContext } from "../../contexts/InvoiceContext";
import Navbar from "../library/Navbar/Navbar";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import InvoiceTable from "./InvoiceTable/InvoiceTable";
import "./Invoices.scss";

// Define PredefinedFilter routes for the Invoices Page
const routes = [
  {
    displayName: "Invoices",
    routeName: "?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Past Due",
    routeName: "pastDue?sort=PAYMENTDUEDATE%2C+INVOICENUMBER",
  },
  {
    displayName: "Open",
    routeName: "open?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
  {
    displayName: "Closed",
    routeName: "closed?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
  },
];

/**
 * * Defines the Invoices Tab (Top-level route)
 */
export default function Invoices(): React.ReactElement {
  const { path } = useRouteMatch();
  const { invoiceData } = React.useContext(InvoiceContext) as InvoiceType;

  return (
    <div className={`invoices-wrapper`}>
      <div className={`head`}>
        <Switch>
          <Route
            exact
            path={[`${path}`, `${path}/pastDue`, `${path}/open`, `${path}/closed`]}
            component={() => {
              return <Navbar tabs={routes} rootPath={path} />;
            }}
          />
          <Route
            path={`${path}/:invoiceId`}
            component={() => {
              const { invoiceId } = useParams<{ invoiceId: string }>();
              const companyName = invoiceData?.customer?.companyName;
              const displayName = companyName ? (companyName.length >= 15 ? companyName.slice(0, 15) + "..." : companyName) : "";
              return (
                <Navbar
                  tabs={[
                    { displayName: "Invoice Details", routeName: invoiceId },
                    { displayName: "Customer Profile", routeName: `${invoiceId}/customer_profile/${invoiceData?.customerId}` },
                  ]}
                  dropdown={[
                    {
                      displayName: `${displayName ? displayName : "All"} Invoices`,
                      routeName: companyName
                        ? `invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC&customer_name=${companyName}`
                        : "invoices?sort=PAYMENTDUEDATE+DESC%2C+INVOICENUMBER+DESC",
                    },
                    {
                      displayName: `${displayName ? displayName : "All"} Payments`,
                      routeName: companyName
                        ? `payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC&customer_name=${companyName}`
                        : "payments?sort=PAYMENTDATE+DESC%2C+REFERENCECODE+DESC",
                    },
                  ]}
                  dropdownRootPath={path.substr(0, path.lastIndexOf("/"))}
                  dropdownDefault={`Invoice ${invoiceData?.referenceCode ?? "N/A"}`}
                  rootPath={path}
                  backRoute={path.substr(0, 3) + "/invoices/" ?? path}
                  backDisplayName="All Invoices"
                />
              );
            }}
          />
        </Switch>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
        <div className={`body`}>
          <Switch>
            <Route
              exact
              path={[`${path}`, `${path}/pastDue`, `${path}/open`, `${path}/closed`]}
              render={() => {
                return <InvoiceTable />;
              }}
            />
            <Route
              path={`${path}/:invoiceId`}
              render={() => {
                return <InvoiceDetail />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}
