import React from "react";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { useParams } from "react-router-dom";
import ActivitiesTable from "../../../Activities/ActivitiesTable/ActivitiesTable";

const COLUMNS = [
  {
    Header: "From",
    accessor: "from",
    width: "20%",
    showFilter: true,
    showSort: true,
    // TODO: Change to CreatedUserName
    searchlightField: "CREATEDUSERID",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivity(props.value, props.row.original.isUnread),
  },
  {
    Header: "Activity",
    accessor: "activity",
    width: "30%",
    showFilter: true,
    showSort: true,
    searchlightField: "ACTIVITYNAME",
    sortField: "ACTIVITYNAME, DATEASSIGNED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: { subject: string | null; body: string | null }; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivity(props.value, props.row.original.isUnread),
  },
  {
    Header: "",
    accessor: "has_attachment",
    width: "5%",
    showFilter: false,
    showSort: false,
    alignment: "left",
    Cell: (props: { value: boolean }) => TableUtils.formatAttachmentIcon(props.value),
  },
  {
    Header: "Status",
    accessor: "status",
    width: "13%",
    showFilter: true,
    popupPlaceholder: "ex. Open, Closed, Past Due",
    showSort: true,
    searchlightField: "ACTIVITYSTATUS",
    sortField: "ACTIVITYSTATUS, DATEASSIGNED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null }) => TableUtils.formatActivityStatus(props.value),
  },

  {
    Header: "Assigned To",
    accessor: "assigned_to",
    width: "17%",
    showFilter: true,
    showSort: true,
    // TODO: Change to UserAssignToName
    searchlightField: "USERASSIGNEDTO",
    sortField: "USERASSIGNEDTO",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivity(props.value, props.row.original.isUnread),
  },
  {
    Header: "Date Received",
    accessor: "date_received",
    width: "15%",
    showSort: true,
    searchlightField: "DATEASSIGNED",
    sortField: "DATEASSIGNED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivitySentDate(props.value, props.row.original.isUnread),
  },
];

/**
 * * Define the Activities Tab of the Customer Detail's page
 */
export default function Activities(): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();

  return (
    <ActivitiesTable
      predefinedFilter={[
        { route: `${customerId}`, searchlightFilter: `(COMPANYID EQ ${customerId}) AND (ISOPEN EQ true) AND (ISARCHIVED EQ false)` },
      ]}
      columns={COLUMNS}
    />
  );
}
