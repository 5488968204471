import React from "react";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";

const COLUMNS = [
  {
    Header: "From",
    accessor: "from",
    width: "20%",
    showFilter: true,
    showSort: true,
    // TODO: Change to CreatedUserName
    searchlightField: "CREATEDUSERID",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivity(props.value, props.row.original.isUnread),
  },
  {
    Header: "Activity",
    accessor: "activity",
    width: "50%",
    showFilter: true,
    showSort: true,
    searchlightField: "ACTIVITYNAME",
    sortField: "ACTIVITYNAME, CREATED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: { subject: string | null; body: string | null }; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivity(props.value, props.row.original.isUnread),
  },
  {
    Header: "",
    accessor: "has_attachment",
    width: "5%",
    showFilter: false,
    showSort: false,
    alignment: "left",
    Cell: (props: { value: boolean }) => TableUtils.formatAttachmentIcon(props.value),
  },
  {
    Header: "Date Received",
    accessor: "date_received",
    width: "25%",
    showSort: true,
    searchlightField: "CREATED",
    sortField: "CREATED",
    searchlightToken: "CONTAINS",
    alignment: "left",
    Cell: (props: { value: string | null; row: { original: { isUnread: boolean } } }) =>
      TableUtils.formatActivitySentDate(props.value, props.row.original.isUnread),
  },
];

export default function Unassigned(): React.ReactElement {
  return (
    <div className="table-activity-wrapper">
      <div className="body-table">
        <ActivitiesTable
          // TODO: Discuss with API team about the correct predefined-filters
          predefinedFilter={[
            { route: "unassigned", searchlightFilter: "(USERASSIGNEDTO IS NULL) AND (ISOPEN EQ true) AND (ISARCHIVED EQ false)" },
            { route: "closed", searchlightFilter: "(ISOPEN EQ false) AND (ISARCHIVED EQ false)" },
            { route: "archived", searchlightFilter: "(ISARCHIVED EQ true)" },
          ]}
          columns={COLUMNS}
        />
      </div>
    </div>
  );
}
