import React, { useState } from "react";
import { log } from "react-fullstory";
import { OnboardContext } from "../../../contexts/OnboardContext";
import { AppContext } from "../../../contexts/AppContext";
import { OnboardStep } from "../../../types/enums";
import OnboardError from "../OnboardError/OnboardError";
import { provisioningClient } from "../../../db/accessor";
import "./CreateAccount.scss";

function CreateAccount(): React.ReactElement {
  const { setOnboardStep, bearerToken, setBearerToken } = React.useContext(OnboardContext) as OnboardType;
  const { onboardData, setOnboardData } = React.useContext(AppContext) as AppType;

  const [apiError, setAPIError] = useState<boolean>(false);
  const [apiRetry, setAPIRetry] = useState<boolean>(false);

  const provisionUser = async (code: string) => {
    try {
      await provisioningClient.provision(code, onboardData).then((res: ProvisioningResponseModel) => {
        // TODO: Remove localStorage hack for AppEnrollmentId once Finalize calls Sync automatically
        localStorage.setItem(`SyncRequestAppEnrollmentId-${onboardData.email}`, res?.appEnrollmentId ?? "");
        setOnboardStep(OnboardStep.PersonalInfo);
      });
    } catch (err: any) {
      log("warn", `Error calling the initial Provisioning endpoint: ${err.toString()}`);
      setAPIError(true);
    }
  };

  // Callbacks from B2C IFrame
  window.onmessage = async function (event) {
    // B2C Account Creation callback
    if (event?.data?.type === "id_token") {
      setBearerToken(event?.data?.code);
      await provisionUser(event?.data?.code);
    }
    // B2C Account Already Exists callback
    else if (event?.data?.type === "existing_account") {
      setOnboardStep(OnboardStep.ExistingAccount);
    }
    // B2C Verfication Code Email callback
    else if (event?.data?.type === "b2c_email") {
      setOnboardData((prev: OnboardDataModel) => {
        return { ...prev, email: event?.data?.code };
      });
    }
  };

  const handleTryAgain = async () => {
    setAPIRetry(true);
    await provisionUser(bearerToken);
    setAPIRetry(false);
  };

  return (
    <div className="create-account-wrapper">
      {!apiError && (
        <iframe
          frameBorder="0"
          width="100%"
          height="100%"
          src={`https://${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${
            process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY
          }/oauth2/v2.0/authorize?p=B2C_1A_EMBEDDED_SIGNUP&client_id=${
            process.env.REACT_APP_AZURE_CLIENT_ID
          }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
            window.location.origin + "/signupSuccess"
          )}&scope=openid&response_type=id_token&prompt=login&response_mode=query`}
        />
      )}
      {apiError && !apiRetry && (
        <div className="error-message-wrapper">
          <OnboardError onTryAgain={() => handleTryAgain()} />
        </div>
      )}
    </div>
  );
}

export default CreateAccount;
