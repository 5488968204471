/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../library/Table/Table";
import Button from "../../../library/Button/Button";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import APIClientUtils from "../../../../utils/APIClientUtils/APIClientUtils";
import { AppContext } from "../../../../contexts/AppContext";
import { Remind, Delete } from "../../../library/Icons/Icons";
import { UserActions } from "../../../../types/enums";
import { InviteMembersFlyoutButton } from "../InviteMemberFlyoutButton/InviteMemberFlyoutButton";
import { userAccountsClient } from "../../../../db/accessor";
import { Snackbar, Alert, AlertColor, TextField } from "@mui/material";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import "./Invitations.scss";
import { formatDate } from "../../../../db/utils/date";

export default function Invitations(): React.ReactElement {
  const { hasPermission, userRoles } = React.useContext(AppContext) as AppType;

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastSeverity, setToastSeverity] = useState<string>("success");
  const [toastMsg, setToastMsg] = useState<string>("");

  const invitePermission = hasPermission(UserActions.InviteMembers);
  const checkboxPermission = hasPermission(UserActions.InvitationsCheckbox);

  const tableRef = useRef(null);

  const handleRemindClick = async (e: React.MouseEvent, email: string) => {
    e.stopPropagation();
    await userAccountsClient
      .postInvite([{ email: email }])
      .then((data: InviteModel[]) => {
        if (data[0].success) {
          setToastSeverity("success");
          setToastMsg("Reminder Sent");
        } else {
          setToastSeverity("error");
          setToastMsg("Reminder was not sent");
        }
        setShowToast(true);
      })
      .catch(() => {
        setToastSeverity("error");
        setToastMsg("Reminder was not sent");
        setShowToast(true);
      });
  };

  const handleDeleteClick = async (e: React.MouseEvent, id: string) => {
    await userAccountsClient
      .deleteUserAccount(id)
      .then(() => {
        (tableRef?.current as any)?.softRefresh();
        setToastSeverity("success");
        setToastMsg("Invitation Deleted");
        setShowToast(true);
      })
      .catch(() => {
        setToastSeverity("error");
        setToastMsg("Invitation was not deleted");
        setShowToast(true);
      });
  };

  async function handleChangePermission(currentPermission: string, targetPermission: string, userId: string) {
    if (currentPermission === targetPermission) return;
    return await userAccountsClient.patchUserAccount(userId, { userRole: targetPermission }).then(() => {
      (tableRef?.current as any)?.softRefresh();
    });
  }

  const COLUMNS = [
    {
      Header: "Email",
      accessor: "email",
      width: "26.67%",
      showFilter: true,
      popupPlaceholder: "abbieblue@example.com",
      showSort: true,
      searchlightField: "EMAIL",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{props.value}</p>
          </div>
        );
      },
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      width: "26.67%",
      showSort: true,
      showFilter: false,
      searchlightField: "ACCOUNTINGROLECODEDEFID",
      searchlightToken: "CONTAINS",
      sortField: "ACCOUNTINGROLECODEDEFID, EMAIL",
      alignment: "left",
      Cell: function format(props: { row: { original: { id: string; permissions: string } } }) {
        const [isLoading, setLoading] = useState<boolean>(false);
        const role = userRoles.find((userRole: UserRoleModel) => userRole.userRoleId === props.row.original.permissions)?.userRoleName;
        const options = useMemo(() => {
          return userRoles
            .filter((option: UserRoleModel) => option.userRoleId !== "00000000-0000-0000-0000-000000000001")
            .map((role: UserRoleModel) => {
              return {
                label: role.userRoleName,
                id: role.userRoleId,
              };
            });
        }, [userRoles]);
        return (
          <div className={"userPermission-formatted-cell userPermission-permissions-col"} onClick={(e) => e.stopPropagation()}>
            {hasPermission(UserActions.ChangeRoles) ? (
              <Autocomplete
                options={options}
                onChange={async (_event, value: { label: string; id: string }) => {
                  setLoading(true);
                  await handleChangePermission(props.row.original.permissions, value.id, props.row.original.id);
                  setLoading(false);
                }}
                value={role}
                blurOnSelect
                disableClearable
                disablePortal
                disabled={isLoading}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value;
                }}
              />
            ) : (
              <p className="body1">{role}</p>
            )}
          </div>
        );
      },
    },
    {
      Header: "Date Sent",
      accessor: "date_sent",
      width: "26.67%",
      showSort: true,
      showFilter: true,
      popupPlaceholder: "yyyy-mm-dd",
      searchlightField: "INVITESENT",
      searchlightToken: "CONTAINS",
      sortField: "INVITESENT, EMAIL",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{formatDate(props.value)}</p>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      width: "20%",
      showSort: false,
      showFilter: false,
      alignment: "right",
      Cell: function format(props: { value: boolean; row: { original: { email: string; id: string } } }) {
        return (
          <div className={`userPermission-formatted-cell`}>
            <div className="btn-grp">
              <Button
                className="remind-btn"
                variant="secondary"
                size="sm"
                onClick={(e: React.MouseEvent) => handleRemindClick(e, props.row.original.email)}
              >
                Remind
              </Button>
              <Button
                className="remove-btn"
                variant="secondary"
                size="sm"
                onClick={(e: React.MouseEvent) => handleDeleteClick(e, props.row.original.id)}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const fetchParser = (fetchResult: UserAccountModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: UserAccountModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record?.userId,
          }),
          ...((variant === "export" || variant === "all") && {
            email: record?.email,
            date_sent: record?.inviteSent ?? "",
            permissions: record?.userRole,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const handleRowClick = () => {
    return null;
  };

  const remindInvitations = async (ids: string[]) => {
    const emails = await userAccountsClient
      .queryUserAccounts(`(USERID IN (${ids.map((id) => `'${id}'`).join(", ")}))`)
      .then((data: UserAccountModelFetchResult) => {
        return (
          data.records?.map((user: UserAccountModel) => {
            return { email: user.email };
          }) ?? []
        );
      });
    return await userAccountsClient
      .postInvite(emails)
      .then((data: InviteModel[]) => {
        if (data.length > 1) {
          if (data.every((reminder) => reminder.success)) {
            setToastSeverity("success");
            setToastMsg(`${data.length} Reminders Sent`);
          } else {
            setToastSeverity("error");
            setToastMsg(`${data.filter((reminder) => !reminder.success).length ?? data.length} Reminders were not sent`);
          }
        } else {
          if (data[0].success) {
            setToastSeverity("success");
            setToastMsg("Reminder Sent");
          } else {
            setToastSeverity("error");
            setToastMsg("Reminder was not sent");
          }
        }
      })
      .catch(() => {
        setToastSeverity("error");
        setToastMsg(`${emails.length} Reminders were not sent`);
      })
      .finally(() => {
        setShowToast(true);
      });
  };

  const removeInvitations = async (ids: string[]) => {
    return await Promise.all(
      ids.map((id: string) => {
        return userAccountsClient.deleteUserAccount(id).catch((err) => err);
      })
    ).then((values) => {
      if (values.every((value) => !(value instanceof Error))) {
        setToastSeverity("success");
        setToastMsg(`${ids.length} Invitations Deleted`);
      } else {
        setToastSeverity("error");
        setToastMsg(`${values.filter((value) => value instanceof Error).length ?? ids.length} Invitations were not sent`);
      }
      (tableRef?.current as any)?.softRefresh();
      setShowToast(true);
    });
  };

  return (
    <div className={`invitations-wrapper`}>
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Invitations",
            displayName: "Invitations",
            rowSelectToken: "email",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: userAccountsClient.queryUserAccounts,
              fetchParser: fetchParser,
            },
            columns: COLUMNS,
            hiddenColumns: hasPermission(UserActions.InvitationsCheckbox) ? undefined : ["actions"],
            defaultSort: "EMAIL",
            defaultSortToken: "DESC",
            handleRowClick: handleRowClick,
            predefinedFilters: [
              {
                route: "invitations",
                searchlightFilter: "(STATUS EQ 'Invited')",
              },
            ],
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
        }}
        toggles={{
          showCaption: invitePermission,
          showRowSelect: checkboxPermission,
          showNavigation: true,
          showExportBtn: false,
          showSearchbar: false,
        }}
        headerBtns={[
          {
            component: (
              <InviteMembersFlyoutButton
                refreshTable={() => (tableRef?.current as any)?.softRefresh()}
                setShowToast={setShowToast}
                setToastSeverity={setToastSeverity}
                setToastMsg={setToastMsg}
              />
            ),
            hideOnRowSelect: true,
          },
        ]}
        rowSelectBtns={[
          {
            icon: <Remind />,
            variant: "secondary",
            alignIcon: "left",
            children: "Remind",
            callback: (ids) => remindInvitations(ids),
          },
          {
            icon: <Delete />,
            variant: "secondary",
            alignIcon: "left",
            children: "Delete Invite",
            callback: (ids) => removeInvitations(ids),
          },
        ]}
      />

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
