/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useIntercom } from "react-use-intercom";
import CompanyProfile from "../../library/Sidebar/CompanyProfile/CompanyProfile";
import Userpic from "../../library/Sidebar/Userpic/Userpic";
import Dropdown from "../../library/Dropdown/Dropdown";
import Tracking from "../../Tracking/Tracking";
import { AppContext } from "../../../contexts/AppContext";
import { formatDate, formatTime, formatTimeElapsed, formatTimeZone } from "../../../db/utils/date";
import { enrollmentsClient, syncClient } from "../../../db/accessor";
import { Sync } from "../../library/Icons/Icons";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { IconButton } from "@mui/material";
import { UserActions } from "../../../types/enums";

type UserId = {
  id: string;
  username: string;
  custom: {
    groupKey: string;
    accountName: string;
  };
};

function LastSync(): React.ReactElement {
  const { hasPermission } = React.useContext(AppContext) as AppType;
  const { syncHasUpdated, setLastSync, lastSync, setSyncHasUpdated } = React.useContext(SettingsContext) as SettingsType;
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleSyncClick = () => {
    // Sync is running, do nothing
    if (!(lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled")) {
      return;
    }

    if (!hasPermission(UserActions.RunSync)) {
      history.push(`${path}/settings/accountingSoftware`);
    }

    try {
      enrollmentsClient
        .getEnrollments("(isActive eq 'true')")
        .then((data: AppEnrollmentModelFetchResult) => {
          return data?.records?.filter((record: AppEnrollmentModel) => record.app.appType === "ERP Connector") ?? [];
        })
        .then((data: AppEnrollmentModel[]) => {
          // Active ERP exists, then run sync
          if (data[0]) {
            syncClient.startSync({ appEnrollmentId: data[0].appEnrollmentId }).then(() => {
              setSyncHasUpdated((prev: number): number => prev + 1);
            });
          }
          // Otherwise, route to AS settings
          else {
            history.push(`${path}/settings/accountingSoftware`);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkSyncStatus = async () => {
      try {
        const response = await syncClient.querySync(2).then((res) => res.records);
        const lastSync = response && response[0];
        if (lastSync) {
          setLastSync(lastSync);
          if (lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled") {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(pollSync);
          }
        }
      } catch (e) {
        console.info(e);
      }
    };
    checkSyncStatus();
    const pollSync = setInterval(() => {
      checkSyncStatus();
    }, 5000);
    return () => {
      clearInterval(pollSync);
    };
  }, [syncHasUpdated]);

  return (
    <div className="last-sync-wrapper">
      {!lastSync?.statusCode ? (
        <></>
      ) : lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled" ? (
        <>
          <span className="light">LAST SYNC </span>
          <span className="bold">{lastSync?.created ? `${formatTimeElapsed(lastSync.created)} ago` : "N/A"}</span>
          <IconButton size="small" onClick={() => handleSyncClick()}>
            <Sync />
          </IconButton>
        </>
      ) : (
        <>
          <span className="light">RUNNING SYNC </span>
          <IconButton size="small" onClick={() => handleSyncClick()}>
            <Sync />
          </IconButton>
        </>
      )}
    </div>
  );
}

/**
 * * Defines the Head of the Main Content being displayed to the user for a given Route
 */
export default function UserContentHead(): React.ReactElement {
  const { logout, getStatus } = React.useContext(AppContext) as AppType;
  const [accountName, setAccountName] = useState("Pending...");
  const [userInfo, setUserInfo] = useState<UserId>({
    id: "",
    username: "unknown",
    custom: {
      groupKey: "",
      accountName: "",
    },
  });

  const history = useHistory();
  const { boot, shutdown, update } = useIntercom();
  const { instance } = useMsal();
  const { path } = useRouteMatch();

  const dropOptions = [
    { onClickValue: "myProfile", value: "My Profile", id: "myProfile" },
    { onClickValue: "companyProfile", value: "Company Profile", id: "companyProfile" },
    { onClickValue: "signOut", value: "Sign Out", id: "signOut" },
  ];

  const handleDropdownClick = (value: string) => {
    if (value === "myProfile") {
      history.push(`${path}/profiles`);
    } else if (value === "companyProfile") {
      history.push(`${path}/profiles/company`);
    } else if (value === "signOut") {
      logout();
    }
  };

  useEffect(() => {
    getStatus()
      .then((data) => {
        if (data.loggedIn) {
          setAccountName(data?.loggedIn ? data?.accountName ?? "N/A" : "N/A");
          setUserInfo({
            id: data.userId ? data.userId : "",
            username: data.userName ? data.userName : "",
            custom: {
              groupKey: data.groupKey ? data.groupKey : "",
              accountName: data.accountName ? data.accountName : "",
            },
          });

          shutdown();

          boot({
            userId: data?.userId ?? "",
            name: data?.userName ?? "Guest User",
            email: instance.getActiveAccount()?.username ?? "",
          });

          update({ hideDefaultLauncher: false });
        } else {
          // The user has an Azure B2C account but is not recognized as being set up in Insights, so we need to send them through onboarding
          history.push("/onboard");
        }
      })
      .catch((error: unknown) => {
        console.log(error);
        setAccountName("N/A");
      });
  }, []);

  return (
    <>
      <Tracking identity={userInfo} />
      <CompanyProfile accountType="Accounts Receivable" companyName={accountName || ""} />
      <div className="userInfo-wrapper">
        <LastSync />
        {/* TODO: Add this icon back once the sync button is useable */}
        <div style={{ position: "relative" }}>
          <Dropdown
            iconOnly={true}
            icon={<Userpic id="logout-btn" onClick={() => null} />}
            className="profile-dropdown"
            defaultValue=""
            onClick={(onClickValue: string) => handleDropdownClick(onClickValue)}
            options={dropOptions}
            displayValue=""
          />
        </div>
      </div>
    </>
  );
}
