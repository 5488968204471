/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Header from "./Header/Header";
import ActivityFeed from "./ActivityFeed/ActivityFeed";
import ActivityTab from "./ActivityTab/ActivityTab";
import "./ActivityDetail.scss";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { useParams } from "react-router-dom";

export default function ActivityDetail(): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const { getCustomerDetails } = React.useContext(CustomerContext) as CustomerType;

  useEffect(() => {
    (async function fetchData() {
      if (customerId) {
        await getCustomerDetails(customerId);
      }
    })();
  }, []);

  return (
    <div className="activity-detail-wrapper">
      <Header />
      <div className="body">
        <div className="left">
          <ActivityFeed />
        </div>
        <div className="right">
          <ActivityTab />
        </div>
      </div>
    </div>
  );
}
