import React, { useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { Alert, Modal, Snackbar } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Detail from "../../../../library/Detail/Detail";
import Userpic from "../../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../../library/Button/Button";
import { Edit, Email, Delete, Fax, LinkedIn, Location, Phone, Position } from "../../../../library/Icons/Icons";
import { DataMode } from "../../../../../types/enums";
import { formatAddress } from "../../../../../db/utils/address";
import { countries } from "../../../../../Countries";
import NotesProvider from "../../../../../contexts/NoteContext";
import NoteStream from "../../../../library/NoteStream/NoteStream";
import { formatDate } from "../../../.././../db/utils/date";
import { contactsClient } from "../../../../../db/accessor";

type ViewMyProfileProps = {
  setDataMode: (prev: DataMode) => void;
  data: Partial<ContactModel>;
  erpContact: boolean;
  companyData: CompanyModel;
};

export default function ViewContactProfile(props: ViewMyProfileProps): React.ReactElement {
  const { contactId, customerId } = useParams<{ contactId: string; customerId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [toast, setToast] = useState<boolean>(false);
  const [toastError, setToastError] = useState<boolean>(false);
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleDeleteContact = async () => {
    try {
      await contactsClient.patchContact(contactId, { isActive: false });
      setToastError(false);
    } catch (error: any) {
      setToastError(true);
    } finally {
      setShowDeleteModal(false);
      setToast(true);
    }
  };

  return (
    <div className="profile-detail">
      <div className="profile-edit-bar">
        {props.erpContact ? (
          <>
            <div className={"erp-contact-header"}>
              This contact was synced from your accounting system. Contacts from your accounting system are not editable or deleteable.
            </div>
            <div className={"last-updated"}>
              <p>Updated {formatDate(props.data?.modified ?? "N/A")}</p>
              <p>from Accounting System Sync</p>
            </div>
          </>
        ) : (
          <>
            <div className="btn-grp">
              <Button icon={<Edit />} variant="primary" size="lg" alignIcon={"left"} onClick={() => props.setDataMode(DataMode.Edit)}>
                Edit
              </Button>
              <Button icon={<Delete />} variant="secondary" size="lg" alignIcon={"left"} onClick={() => setShowDeleteModal(true)}>
                Delete
              </Button>
            </div>
            <div className={"last-updated"}>
              <p>Updated {formatDate(props.data?.modified ?? "N/A")}</p>
              {/* TODO: replace by you with modifiedUserName when that is tracked by the API */}
              {/* <p>by {props.data?.modifiedUserId}</p> */}
              <p>by You</p>
            </div>
          </>
        )}
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
        <div className="view-container">
          <div>
            <div className="name-row">
              <Userpic id="user-pic" />
              <h4 className="profile-name">{props.data?.contactName}</h4>
            </div>
            {/* TODO: replace company name with the actual data once it is tracked by the contact API*/}
            <Detail
              icon={<Position />}
              columns={{
                title: "Position",
                value: `${
                  props.data?.title && props.data.roleCode
                    ? `${props.data?.title} (${props.data?.roleCode}), ${props.companyData.companyName}`
                    : props.data?.title
                    ? `${props.data.title}, ${props.companyData.companyName}`
                    : props.data?.roleCode
                    ? `${props.data.roleCode}, ${props.companyData.companyName}`
                    : `Employee, ${props.companyData.companyName}`
                }`,
              }}
            />
            <Detail icon={<Email />} columns={{ title: "Email", value: props.data?.emailAddress ?? "", format: "email" }} />
            <Detail icon={<Phone />} columns={{ title: "Phone", value: props.data?.phone ?? "", format: "tel" }} />
            <Detail icon={<Fax />} columns={{ title: "Fax", value: props.data?.fax ?? "", format: "tel" }} />
            <Detail
              icon={<Location />}
              columns={{
                title: "Address",
                value: formatAddress(
                  props.data?.address1 ?? "",
                  props.data?.address2 ?? "",
                  "",
                  props.data?.city ?? "",
                  props.data?.stateRegion ?? "",
                  props.data?.postalCode ?? "",
                  countries.filter((country) => {
                    return country.id === props.data?.countryCode;
                  })[0]?.label ?? ""
                ),
              }}
            />
            <Detail icon={<LinkedIn />} columns={{ title: "LinkedIn", value: props.data?.webpageUrl ?? "", format: "link" }} />
          </div>

          <div className="right">
            <NotesProvider>
              <NoteStream title="Notes" tableKey="Active_Members" objectKey={contactId} />
            </NotesProvider>
          </div>
        </div>
      </OverlayScrollbarsComponent>
      {showDeleteModal && (
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <div className="as-modal">
            <h3>Delete Contact</h3>
            <p>Are you sure you want to delete this contact</p>
            <div className="btn-grp">
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="error" onClick={() => handleDeleteContact()}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast}
        onClose={() => {
          setToast(false);
          !toastError && history.push(`${path.substr(0, path.lastIndexOf(`customers/`))}` + `customers/${customerId}/contacts/?sort=CONTACTNAME`);
        }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setToast(false)}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastError ? "Deletion Failed" : "Contact Deleted"}
        </Alert>
      </Snackbar>
    </div>
  );
}
