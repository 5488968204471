import React from "react";

import { LargeLoading } from "../../library/Icons/Icons";
import "./WaitScreen.scss";

export default function WaitScreen(): React.ReactElement {
  return (
    <div className={"ws-wrapper"}>
      <div className={"ws-card"}>
        <div data-testid='WaitscreenLoader' className={"ws-loading-wrapper"}>
          <LargeLoading />
        </div>

        <p className={"text"}>Hold tight, your dashboard is loading.</p>
      </div>
    </div>
  );
}
