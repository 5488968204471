import React, { useState, useEffect, MouseEvent, useRef, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DateTime } from "luxon";
import Table from "../../../library/Table/Table";
import { Delete, UserPic } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { userAccountsClient } from "../../../../db/accessor";
import { AppContext } from "../../../../contexts/AppContext";
import CenterPopup from "../../../library/CenterPopup/CenterPopup";
import { UserActions } from "../../../../types/enums";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { InviteMembersFlyoutButton } from "../InviteMemberFlyoutButton/InviteMemberFlyoutButton";
import "./ActiveMembers.scss";
import { Snackbar, Alert, AlertColor, TextField } from "@mui/material";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";

export default function ActiveMembers(): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { tableData, setTableData } = React.useContext(SettingsContext) as SettingsType;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { userStatus, hasPermission, getStatus, userRoles } = React.useContext(AppContext) as AppType;
  const [toRemoveMember, setToRemoveMember] = useState<{ name: string; id: string }[]>([]);
  const [showRemoveMember, setShowRemoveMember] = useState<boolean>(false);
  const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false);
  const [toNewOwnerId, setToNewOwnerId] = useState<string>();
  const [toNewOwnerName, setToNewOwnerName] = useState<string>();

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastSeverity, setToastSeverity] = useState<string>("success");
  const [toastMsg, setToastMsg] = useState<string>("");

  const [selectedRows, setSelectedRows] = useState<UserAccountGridData[]>([]);

  const tableRef = useRef(null);

  const handleRemoveClick = (e: React.MouseEvent, id: string, name: string) => {
    e.stopPropagation();
    setToRemoveMember([{ name, id }]);
    setShowRemoveMember(true);
  };

  async function handleChangeOwnerShip(id?: string) {
    setShowChangeOwner(false);
    await userAccountsClient.transferOwnership(id);
    getStatus();
    (tableRef?.current as any)?.softRefresh();
  }

  async function handleChangePermission(currentPermission: string, targetPermission: string, userId: string, userName: string) {
    if (currentPermission === targetPermission) return;

    // Transfer ownership
    if (targetPermission === "00000000-0000-0000-0000-000000000001") {
      setShowChangeOwner(true);
      setToNewOwnerId(userId);
      setToNewOwnerName(userName);
    }
    // Assigning any other role
    else {
      return await userAccountsClient.patchUserAccount(userId, { userRole: targetPermission }).then(() => {
        (tableRef?.current as any)?.softRefresh();
      });
    }
  }

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
      width: "22%",
      showSort: true,
      showFilter: true,
      popupPlaceholder: "abbie blue",
      searchlightField: "USERNAME",
      searchlightToken: "CONTAINS",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell userPermission-name-col">
            <div className="icon-wrapper">
              <UserPic />
            </div>
            <p className="body1">{props.value}</p>
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "22%",
      showSort: true,
      showFilter: true,
      popupPlaceholder: "abbieblue@example.com",
      searchlightField: "EMAIL",
      searchlightToken: "CONTAINS",
      sortField: "EMAIL, USERNAME",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{props.value}</p>
          </div>
        );
      },
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      width: "22%",
      showSort: true,
      showFilter: false,
      searchlightField: "ACCOUNTINGROLECODEDEFID",
      searchlightToken: "CONTAINS",
      sortField: "ACCOUNTINGROLECODEDEFID, USERNAME",
      alignment: "left",
      Cell: function format(props: { row: { original: { name: string; id: string; permissions: string } } }) {
        const [isLoading, setLoading] = useState<boolean>(false);
        const role = userRoles.find((userRole: UserRoleModel) => userRole.userRoleId === props.row.original.permissions)?.userRoleName;
        const ownerOptions = useMemo(() => {
          return userRoles.map((role: UserRoleModel) => {
            return {
              label: role.userRoleName,
              id: role.userRoleId,
            };
          });
        }, [userRoles]);
        const adminOptions = useMemo(() => {
          return ownerOptions.filter((option: { label: string; id: string }) => option.id !== "00000000-0000-0000-0000-000000000001");
        }, [ownerOptions]);
        return (
          <div className={"userPermission-formatted-cell userPermission-permissions-col"} onClick={(e) => e.stopPropagation()}>
            {hasPermission(UserActions.ChangeRoles) && props.row.original.permissions !== "00000000-0000-0000-0000-000000000001" ? (
              <Autocomplete
                options={userStatus.roles.includes("Group Owner") ? ownerOptions : adminOptions}
                onChange={async (_event, value: { label: string; id: string }) => {
                  setLoading(true);
                  await handleChangePermission(props.row.original.permissions, value.id, props.row.original.id, props.row.original.name);
                  setLoading(false);
                }}
                value={role}
                blurOnSelect
                disableClearable
                disablePortal
                disabled={isLoading}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value;
                }}
              />
            ) : (
              <p className="body1">{role}</p>
            )}
          </div>
        );
      },
    },
    {
      Header: "Last Seen",
      accessor: "b2CLastLoggedIn",
      width: "22%",
      showSort: true,
      showFilter: false,
      searchlightField: "B2CLASTLOGGEDIN",
      alignment: "left",
      Cell: function format(props: { value: string }) {
        const displayValue = props.value ? DateTime.fromISO(props.value, { zone: "utc" }).toRelative() : "Unknown";
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            <p className="body1">{displayValue}</p>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "remove_button",
      width: "10%",
      showSort: false,
      showFilter: false,
      alignment: "left",
      Cell: function format(props: { value: boolean; row: { index: number; original: { name: string; id: string; permissions: string } } }) {
        return TableUtils.withFormattedCellElement(
          <div className="userPermission-formatted-cell">
            {!props.row.original.name.includes("(You)") &&
              hasPermission(UserActions.ActiveMembersRemove) &&
              props.row.original.permissions != "00000000-0000-0000-0000-000000000001" && (
                <Button
                  className="remove-btn"
                  variant="secondary"
                  size="sm"
                  onClick={(e: React.MouseEvent) => handleRemoveClick(e, props.row.original.id, props.row.original.name)}
                >
                  Remove
                </Button>
              )}
          </div>
        );
      },
    },
  ];

  const fetchParser = (fetchResult: UserAccountModelFetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: UserAccountModel) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.userId,
          }),
          ...((variant === "export" || variant === "all") && {
            name: record.userId === userStatus.userId ? `${record.userName} (You)` : record.userName ? record.userName : "N/A",
            email: record.email,
            permissions: record.userRole,
            b2CLastLoggedIn: record.b2CLastLoggedIn,
          }),
          ...(variant === "all" && {
            disableCheckbox: record.userId === userStatus.userId,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      original: { id?: string };
    }
  ) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(
      `${path.substr(0, path.lastIndexOf("/userPermissions"))}/profile${row.original.id !== userStatus.userId ? `/${row.original.id}` : ""}`
    );
  };

  const handleCancelRemove = () => {
    setShowRemoveMember(false);
  };

  const handleCancelRoleChange = () => {
    setShowChangeOwner(false);
  };

  const handleRemoveMember = async () => {
    return await Promise.all(
      toRemoveMember.map((val: { id: string; name: string }) => {
        return userAccountsClient.deleteUserAccount(val.id).catch((err) => err);
      })
    ).then((values) => {
      if (values.every((value) => !(value instanceof Error))) {
        setToastSeverity("success");
        if (values.length > 1) {
          setToastMsg(`${toRemoveMember.length} Users Removed`);
        } else {
          setToastMsg("User Removed");
        }
      } else {
        setToastSeverity("error");
        if (values.length > 1) {
          setToastMsg(`${values.filter((value) => value instanceof Error).length ?? toRemoveMember.length} Users were not removed`);
        } else {
          setToastMsg("User was not removed");
        }
      }
      (tableRef?.current as any)?.softRefresh();
      setShowToast(true);
      setShowRemoveMember(false);
    });
  };

  const handleGroupRemove = () => {
    setToRemoveMember(
      selectedRows.reduce((filtered: { id: string; name: string }[], val: UserAccountGridData) => {
        if (val.id !== userStatus.userId) {
          filtered.push({ id: val.id, name: val.name });
        }
        return filtered;
      }, [])
    );
    setShowRemoveMember(true);
  };

  useEffect(() => {
    return () => {
      setPageNumber(0);
      setPageCount(0);
      setTotalCount(0);
    };
  }, []);

  return (
    <div className="activeMembers-wrapper">
      <Table
        ref={tableRef}
        dataSets={[
          {
            id: "Active Members",
            displayName: "Active Members",
            rowSelectToken: "email",
            data: {
              tableData: tableData,
              setTableData: setTableData,
              fetchCall: userAccountsClient.queryUserAccounts,
              fetchParser: fetchParser,
              selectAllFilter: `(USERID NE '${userStatus.userId}')`,
            },
            columns: COLUMNS,
            defaultSort: "USERNAME",
            defaultSortToken: "DESC",
            handleRowClick: handleRowClick,
            predefinedFilters: [
              {
                route: "userPermissions",
                searchlightFilter: "(STATUS EQ 'Active')",
              },
            ],
          },
        ]}
        states={{
          isLoading: isLoading,
          setLoading: setLoading,
          isError: isError,
          setError: setError,
          errorMessage: errorMessage,
          setErrorMessage: setErrorMessage,
        }}
        pagination={{
          pageCount: pageCount,
          setPageCount: setPageCount,
          pageNumber: pageNumber,
          setPageNumber: setPageNumber,
          totalCount: totalCount,
          setTotalCount: setTotalCount,
        }}
        toggles={{
          showCaption: hasPermission(UserActions.InviteMembers),
          showRowSelect: hasPermission(UserActions.ActiveMembersCheckbox),
          showNavigation: true,
          showExportBtn: false,
          showSearchbar: false,
        }}
        onRowSelect={(val: any) => setSelectedRows(val)}
        headerBtns={[
          {
            component: (
              <InviteMembersFlyoutButton
                refreshTable={() => (tableRef?.current as any)?.softRefresh()}
                setShowToast={setShowToast}
                setToastSeverity={setToastSeverity}
                setToastMsg={setToastMsg}
              />
            ),
            hideOnRowSelect: true,
          },
        ]}
        rowSelectBtns={[
          {
            icon: <Delete />,
            alignIcon: "left",
            variant: "secondary",
            children: "Remove",
            callback: () => handleGroupRemove(),
          },
        ]}
      />
      {showRemoveMember && (
        <CenterPopup
          count={toRemoveMember.length}
          onClickCancel={handleCancelRemove}
          onClickRightBtn={handleRemoveMember}
          mainDisplay={{
            Cell: function format() {
              return (
                <>
                  If you remove
                  <span className={"removee-text"}> {toRemoveMember.map((val) => val.name).join(", ")} </span>
                  they will no longer have access to Lockstep. Are you sure you want to remove them?
                </>
              );
            },
          }}
        />
      )}
      {showChangeOwner && (
        <CenterPopup
          count={1}
          onClickCancel={handleCancelRoleChange}
          onClickRightBtn={() => handleChangeOwnerShip(toNewOwnerId)}
          rightBtnText={"Change Owner"}
          headerText={"Change Ownership"}
          mainDisplay={{
            Cell: function format() {
              return (
                <>
                  Are you sure you want to change the ownership of this Lockstep team to
                  <span className="removee-text"> {toNewOwnerName}</span>? You will not be able to undo this action.
                </>
              );
            },
          }}
        />
      )}

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showToast} onClose={() => setShowToast(false)} autoHideDuration={3000}>
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity as AlertColor}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setShowToast(false)}>
              CLOSE
            </p>
          }
        >
          {toastMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
