import APIClient from "../APIClient";

export class DefinitionsClient {
  public getUnsupportedERPs = (): Promise<UnsupportedERPModel[]> => {
    const url = "api/v1/Definitions/financialsystems?filter=IsSupported eq false";

    return APIClient.get(url, undefined, true).then(response => {
      return response.data.records;
    });
  }

  public getCurrencies = (): Promise<CurrencyModel[]> => {
    const url = "api/v1/Definitions/currencies";

    return APIClient.get(url, undefined, true).then(response => {
      return response.data.records;
    });
  };
}
